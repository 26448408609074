import { DetailsGatePassRequisitionComponent } from './../details-gate-pass-requisition/details-gate-pass-requisition.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { Gatepass } from '../model/gatePass';
import { MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { CommonService } from '../services/common.service';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { ActivatedRoute,Router } from '@angular/router';
import { async } from '@angular/core/testing';

@Component({
    selector: 'app-view-gate-pass-requisition',
    templateUrl: './view-gate-pass-requisition.component.html',
    styleUrls: ['./view-gate-pass-requisition.component.css']
})
export class ViewGatePassRequisitionComponent implements OnInit, OnDestroy {


    loader: boolean;
    p = 1;
    template:boolean = true;
    gatepassUserRequisitionList: Gatepass[];
    gatepassRequisitionApprovalList: Gatepass[];
    displayedgatepassUserRequisitionColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    displayedgatepassRequisitionApprovalColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status', 'Action'];
    requisitions: any;

    finalgatepassrequsition$: Observable<Gatepass[]>;
    approvalgatepassrequsition$: Observable<Gatepass[]>;
    recommendgatepassrequsition$: Observable<Gatepass[]>;
    ownergatepassrequsition$: Observable<Gatepass[]>;
    rejectgatepassrequsition$: Observable<Gatepass[]>;
    // admingatepassrequsition$: Observable<Gatepass[]>;
    subscriptions: Subscription[] = [];
    tabIndex: number=0;

    config: any = {
        url: 'gate_pass',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        reject: 'user_requisition_reject',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName : 'gate_pass_requisitions'
    } ;

    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        public dialog: MatDialog,
        public commonService: CommonService,
        private route : ActivatedRoute,
        private router:Router
    ) { }
    createGatePass(){
        this.router.navigate[('/create-gate-pass-requisition')];
    }
    getAllGatePassRequisition(){
        this.loader = true;
        // this.finalgatepassrequsition$       = this.requisitionService.requisitionList(this.config.url, this.config.final);
        this.approvalgatepassrequsition$    = this.requisitionService.requisitionList(this.config.url, this.config.approve);
        this.recommendgatepassrequsition$   = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
        this.ownergatepassrequsition$       = this.requisitionService.requisitionList(this.config.url, this.config.owner);
        this.rejectgatepassrequsition$      = this.requisitionService.requisitionList(this.config.url, this.config.reject);
        this.loader = false;
        this.ownergatepassrequsition$.subscribe(res=>{
            console.log('datalist',res);
        })
        
       };
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }
        this.getAllGatePassRequisition();
   //  this.admingatepassrequsition$ = this.requisitionService.gatePassRequisitionList();
        this.subscriptions.push(this.commonService.approvalEvent.subscribe((data) => {
            console.log('approved');
            this.giveApprove(data);
           // console.log("Common Service",data);
        }));

        // this.subscriptions.push(this.commonService.rejectEvent.subscribe((data) => {
        //     console.log('rejected');
        //     this.rejectGatePass(data);
        // }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }


    // rejectGatePass(data)    {
    //    console.log(data);
    //     this.requisitionService.gatePassRequisitionApprove(data, '').subscribe(res => {
    //         this.loader = false;
    //         this.openSnackBar('Approved', 'ok');
    //     }, err => {
    //         this.loader = false;
    //         if (err.error.message) this.openSnackBar(' ' + err.error.message, 'ok');
    //         else this.openSnackBar(' ' + err.error, 'ok');
    //     });
    // }

    giveApprove(data) {
        this.loader = true;
        this.requisitionService.requisitionApprove(data).subscribe(res => {
            console.log('apprave',res);
            // this.dialog.closeAll();
            this.loader = false;
            this.openSnackBar( 'Successfull', 'ok');
           this.getAllGatePassRequisition();
        }, err => {
            this.loader = false;
            if (err.error.message) {
                 this.openSnackBar('' + err.error.message, 'ok');
            } else {
                this.openSnackBar('' + err.error, 'ok');
            }
            this.getAllGatePassRequisition();
      
        });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    showDetail(data, approveCategory = '') {
       // console.log('here',approveCategory,data);
        const testData = {
            requisition_id: data.id,
            To: data.final_approved_by,
            Date: data.date,
            Subject: data.subject,
            Description: data.description,
            Approve_by: data.approved_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            final_approval_status: data.final_approval_status,
            recommendation_status: data.recommendation_status,
            Category: null,
            approvecategory: approveCategory,
            requested_by:   data.requested_from,
            requested_by_name:  data.requested_by,
            approve_sig: data.approved_by_sig,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,
            url: this.config.url
        };
        this.requisitionService.gatePassRequisitionDetailByID(data.id).subscribe(d => {
            testData.Category = d;
            console.log('Test data after api call',testData);
            
         const dialog_ref =   this.dialog.open(DialogComponent, {
                data: testData
            });
            dialog_ref.afterClosed().subscribe(res=>{
                if(res !== 'reload'){
                    this.giveApprove(res);
                }
                console.log('afterclosedData',res);
                
            })
        });
    }

    delegate(data:any, approveCategory = '') {
        console.log('delegate_data',data);
        
        const testData = {
             requisition_id: data.id,
             approvecategory: approveCategory,
             url: this.config.url,
             tableName : this.config.tableName,
             req_type : 'visiting_card'
         };
     const dialog_ref =   this.dialog.open(DelegateModalComponent, {
                 data: testData
             });
             dialog_ref.afterClosed().subscribe(res=>{
                this.getAllGatePassRequisition();
                })
     }








}

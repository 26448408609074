import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'green-delta-app';
  constructor(private snackbar : MatSnackBar){
    if(navigator.onLine === false){
      this.snackbar.open("You are offline, check your internet connection!",'ok',{horizontalPosition :  "center",verticalPosition : "top"})
    }
  }
}

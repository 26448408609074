import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { fuseAnimations } from '../animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-gate-pass-item-wise',
  templateUrl: './gate-pass-item-wise.component.html',
  styleUrls: ['./gate-pass-item-wise.component.css'],
  animations : fuseAnimations
})
export class GatePassItemWiseComponent implements OnInit {

  loader: boolean;
  dataList:any=[];
  itemList : any = [];
  tableData: MatTableDataSource<any>;
  columnsFieldName: any;
  ReportForm: FormGroup;
  displayedColumns:any= [{display : 'Subject',field: 'subject'},{display : 'Description',field: 'description'},{display : 'Requested By',field: 'requested_by'},{display : 'Requisition Date',field: 'created_at'}];

  @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;
  constructor(
    public userService: UserService,
    private snackBar: MatSnackBar,
    private fb : FormBuilder,
  ) { }
  getList(){
    this.loader = true;
    this.ReportForm.get('start_date').setValue(formatDate(this.ReportForm.get('start_date').value ? this.ReportForm.get('start_date').value : new Date(),'yyyy-MM-dd','en'));
    this.ReportForm.get('end_date').setValue(formatDate(this.ReportForm.get('end_date').value ? this.ReportForm.get('end_date').value : new Date(),'yyyy-MM-dd','en'));
    const payload = Object(this.ReportForm.value);
    console.log('payload',payload);
    this.userService.getpassreportCatwise(payload).subscribe(res=>{
        this.dataList = res;
        console.log('Mat table Data',res);
        this.loader = false;
        this.tableData = new MatTableDataSource<any>(this.dataList);
        this.tableData.paginator = this.tableOnePaginator;
        this.tableData.sort = this.tableOneSort;
        console.log('Mat table Data',this.tableData);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}

getOfficeItem(){
  this.userService.getOfficeItemCategory().subscribe(res=>{
    this.itemList = res;
    console.log('itemList',this.itemList);


},err=>{ 
    this.loader = false;
    this.openSnackBar('Internal Error : ' + err.error.message ? err.error.message : err.error,'ok',8000);
})
}
  ngOnInit() {
    this.getOfficeItem();
    this.columnsFieldName = this.displayedColumns.map(item => item.field);
    this.ReportForm = this.fb.group({
        start_date : ['',Validators.required],
        end_date : ['',Validators.required],
        requisition_type : ['item_requisitions'],
        report_type : ['',Validators.required],
        cat_id : [''],
    })

  }
  openSnackBar(message: string, action: string,time?:number) {
    this.snackBar.open(message, action, {
        duration : time
    });
}

}

import { Component, OnInit } from '@angular/core';
import { User } from '../model/user';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { RequisitionService } from '../services/requisition.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-create-food-requisition',
  templateUrl: './create-food-requisition.component.html',
  styleUrls: ['./create-food-requisition.component.css']
})
export class CreateFoodRequisitionComponent implements OnInit {

  loader: boolean;
  employee:User[];
  foodRequisitionForm: FormGroup;
  employee_name: string;
  employeeId: any;

  recommendAuthority$: Observable<any>;
  approveAuthority$ : Observable<any>;
  finalapproveAuthority$: Observable<any>;
  employee_spinner: boolean;
  users: User[]=[];


  constructor(
    private snackBar:MatSnackBar,
    public userService:UserService,
    public requisitionService:RequisitionService,
    private fb : FormBuilder,
    public router :Router,
    private datepipe:DatePipe
  ) { }

  createFoodRequisition(){
    if(this.foodRequisitionForm.invalid){
      this.snackBar.open('Please fill up all the required field first,then try again','ok',{
        duration : 5000
      });
    }else{

    this.loader = true;
   this.foodRequisitionForm.value.requested_by = this.foodRequisitionForm.value.requested_from;
    this.foodRequisitionForm.get('date').setValue(formatDate(this.foodRequisitionForm.get('date').value ? this.foodRequisitionForm.get('date').value : new Date(),"yyyy-MM-dd","en"));
    const payload = Object(this.foodRequisitionForm.value);
    payload.url = 'food';
    payload.path = 'requisition_create';
    console.log("payload",payload);
    this.requisitionService.requisitionCreate(payload).subscribe(res => {
        console.log('res',res);
        this.loader = false;
        this.snackBar.open(res.message, "ok",{duration : 3000});
        this.router.navigate(['view-food-requisition']);
      }, err => {
        this.loader = false;
          this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:5000});
    }); 
  }
  }
  
  getEmployeeList(){
    this.loader = true;
    this.userService.getUserList()
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.employee = data;
      }, err => {
        this.loader = false;
        //if(err.status == 400) location.reload(true);
        this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:6000});
      });
  }

  getEmployee(id){
    console.log(id)
    this.loader = true;
    this.userService.getParticularUser(id)
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        //this.employee = data;
        this.employeeId=id;
        this.employee_name = data[0].f_name+' '+data[0].l_name;
         this.foodRequisitionForm.patchValue({
          name: this.employee_name,
          department: data[0].dept_name,
          designation: data[0].title,
        }); 
      }, err => {
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:6000});
      });
  }

  getUserList(){
    this.employee_spinner = true;
    this.userService.getUsers()
      .subscribe(data => {
        console.log('user_short_info');
        this.employee_spinner = false;
        this.users = data;
      }, err => {
        this.employee_spinner = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration : 5000});
      });
  }
  selected_recomended_by(data){
    this.foodRequisitionForm.get('recommended_by').setValue(data);
    console.log('form value',this.foodRequisitionForm.value);
    
  }
  selected_approved_by(data){
    this.foodRequisitionForm.get('approved_by').setValue(data);
    console.log('form value approved by',this.foodRequisitionForm.value);
  }
  selected_final_approved_by(data){
    this.foodRequisitionForm.get('final_approved_by').setValue(data);
    console.log('form value approved by',this.foodRequisitionForm.value);
  }
  
  ngOnInit() {
      this.getUserList();
    this.recommendAuthority$ = this.userService.getRecommendationApproveauthoritylist('food');
    this.approveAuthority$ = this.userService.getApproveApproveauthoritylist('food');
    this.finalapproveAuthority$ = this.userService.getFinalApproveauthoritylist('food');
    this.foodRequisitionForm = this.fb.group({
      approval_status:[''],
      date:[''],
      requested_by: [''],
      requested_from: [''],
      subject:['',Validators.required],
      description:[''],
      recommended_by:['',Validators.required],
      approved_by: ['',Validators.required],
      final_approved_by:['',Validators.required],
      recommand_status:[],
      final_status:[],
      }); 
      // let id = +sessionStorage.getItem('user_id');
      // this.getEmployee(id);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

}

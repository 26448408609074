import { Component, OnInit, Inject, Output, EventEmitter,AfterContentChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { CommonService } from '../../services/common.service';
import { SafePipeComponent } from '../../pipe/pipe.component';
import { UserService } from '../../services/user.service';
import * as _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '../../animations';
import { FilePreviewerComponent } from '../../file-previewer/file-previewer.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-approval-note-details',
  templateUrl: './approval-note-details.component.html',
  styleUrls: ['./approval-note-details.component.css']
})
export class ApprovalNoteDetailsComponent implements OnInit {
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public userService: UserService,
    public snackBar: MatSnackBar,
    public dialog_ref: MatDialogRef<ApprovalNoteDetailsComponent>,
    public preview_dialog : MatDialog
  ) {
    this.printData = this.data.detail.note_info; 
    console.log('printData',this.printData)
    this.type_of_purchase.setValue(this.printData.type_of_purchase);
    if(this.printData.head_title){
      this.headsinfo = {head_title : this.printData.head_title,budget: this.printData.budget,expense : this.printData.expense,head_id:this.printData.head_id};
      this.remaining_balance = this.printData.remaining_balance ? this.printData.remaining_balance : 0;
      this.remaining_balance_percentage = this.printData.remaining_balance_percentage ? this.printData.remaining_balance_percentage : 0;
  }
  }
  approval:any=[]
  approvaldetails:any;
  approvaldetailsitem:any=[]
  remarks:any = '';
  loader: boolean;
  headsinfo: any;
  remaining_balance : any;
  remaining_balance_percentage:any;
  inputOption: boolean;
  selectedAccountsHead:any
  HeadList:any[]=[];
  budgetInfo: any[]=[];
  reqapprove: any;
  userId: any;
  deptId: any;
  Acc_head_selection =  new FormControl('');
  type_of_purchase = new FormControl();
  budget_amount  = new FormControl();
  printData: any

  getAllHeads(){
    this.loader = true;
    this.userService.getaccountsHeadYearly().subscribe( res =>{
        console.log('yearly headlist',res)
        this.loader = false;
        this.HeadList = res;
        this.HeadList.push({head_id: 0,head_title: "Others",
        budget: 0,
        created_at: "2020-08-25 17:13:55",
        expense: 0,
        id : 0,
        updated_at: "2020-08-25 17:13:55",
        year: 2020});
        //this.openSnackBar("Success ", "ok");
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }
  
  ngOnInit() {
    this.deptId = sessionStorage.getItem('dept_id');
    this.userId = sessionStorage.getItem('user_id');
    // console.log('user',this.userId)
    // console.log('deptId',this.deptId)


    if(this.deptId == '9'){
      this.getAllHeads();
    }


    this.approval=this.data.approvecategory;
    this.approvaldetails= this.data.detail;
    console.log('ApprovalNoteDetails', this.approvaldetails)
    console.log('approval', this.approval)
    this.reqapprove = this.data.detail.approve_info;
    
  }

  type_of_budget(data){
    console.log('type_of_budget',this.type_of_purchase.value);
 }
  AccountsHeadChange(data){
    this.headsinfo = data.value;
    // console.log('hdinfo', this.headsinfo)
    if(this.headsinfo.head_id === 0){
            this.inputOption = true;
    }else{
        this.inputOption = false;
    }
    this.remaining_balance = (this.headsinfo.budget - (this.headsinfo.expense + this.printData.total_amount
      )).toFixed(2);
    this.remaining_balance_percentage = ((100/this.headsinfo.budget)*this.remaining_balance).toFixed(2);
    // console.log('selection change',data.value);
    // console.log('remaining_balance',this.remaining_balance);
    // console.log('remaining_balance_percentage',this.remaining_balance_percentage);

}
calculate(){
  this.remaining_balance = (this.headsinfo.budget - (this.headsinfo.expense + this.printData.total_amount)).toFixed(2);
  this.remaining_balance_percentage = ((100/this.headsinfo.budget)*this.remaining_balance).toFixed(2);
}
  get acc_budget_person(){
    if(this.reqapprove[0].approved_by == this.userId && this.deptId == '9'){
      // console.log('budget====================')
      return true;
    } else{
      // console.log('non budget')
      return false
    }
}
  get accountsValidation(){
    if(this.acc_budget_person === true && this.Acc_head_selection.hasError('required')){
        return true;
    }else{
      return false;
    }
  }
  deleteEditFile(data:any,index){
    this.snackBar.open("Sorry! You couldn't delete this file, read only!",'ok',{duration:3000});
  }
  download(f){
      const link = document.createElement('a');
      const downloadUrl = f;
      link.setAttribute('href',  downloadUrl);
      link.setAttribute('download', 'FileName');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link); // Required for FF
      link.click();
  }

  previewFile(f) {
  const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
          data : {url : f}
      })
      dialog_ref.afterClosed().subscribe(res=>{
          // console.log('preview_closed',res);
      })
  }

  isarrovarprint:boolean=false;

  printsection(){
    // console.log('hi')
   this.isarrovarprint= true;
  }

//   openSnackBar(message: string, action: string) {
//     this.snackBar.open(message, action, {
//         duration: 4000,
//     });
// }

  close() {
    this.dialog_ref.close('reload');
}

approve() {
      this.loader = true;
      const noteApproveData:any = {
      note_id : this.approvaldetails.note_info.id,
      requested_by : this.approvaldetails.note_info.created_by,
      remarks : this.remarks,
      

  };
  if(this.deptId == '9'){
    noteApproveData.type_of_purchase = this.type_of_purchase.value;
    noteApproveData.headsinfo = this.headsinfo;
    noteApproveData.remaining_balance = this.remaining_balance;
    noteApproveData.remaining_balance_percentage = this.remaining_balance_percentage;
    noteApproveData.expense = this.printData.total_amount;
    // memoSheetApproveData.budget_amount = this.printData.amount;
}

  console.log('notesheet1',noteApproveData)

  // if (this.data.sheetType === 'Note') {
      this.userService.officeItemNoteApprove(noteApproveData).subscribe((response) => {
        this.snackBar.open(response['message'], 'ok');
        this.loader = false;
        this.dialog_ref.close('reload');
         

      },err=>{
        this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:5000});
        
    });
     
 
}

reject(status:number) {
 
  const payload = {
      requisition_id : this.approvaldetails.note_info.id,
      requisition_type : 'item_approval_memo',
      remarks : this.remarks ? this.remarks : ' ',
      status : status,
  };
  this.userService.requisitionReject(payload).subscribe((response) => {
      this.snackBar.open(response['message'], 'ok');
    
      this.dialog_ref.close('reload');
  },err=>{
      this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:5000});
      
  });

}

  


}

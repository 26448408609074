import { CommonService } from './../services/common.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { VisitingCardRequisition } from '../model/visitingCardRequisition';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';


@Component({
    selector: 'app-view-visiting-card-requisition',
    templateUrl: './view-visiting-card-requisition.component.html',
    styleUrls: ['./view-visiting-card-requisition.component.css']
})
export class ViewVisitingCardRequisitionComponent implements OnInit {
    loader: boolean;
    template : boolean = true;
    p = 1;
    visitingCardRequisitionList: VisitingCardRequisition[];
    displayedcarRequisitionApprovalColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    requisitions: any;
    config: any = {
        url: 'visiting_card',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName: 'visiting_card_requisitions'
    } ;

    finalCarRequisition$: Observable<any>;
    approvalCarRequisition$: Observable<any>;
    recommendCarRequisition$: Observable<any>;
    ownerCarRequisition$: Observable<any>;
    tabIndex: number = 0;
  //  adminCarRequisition$: Observable<any>;



    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService

    ) { }




    createVisitingCard() {
        this.router.navigate(['/create-visiting-card-requisition']);
    }

get_requistiondata(){
    this.approvalCarRequisition$  = this.requisitionService.requisitionList(this.config.url, this.config.approve);
        this.recommendCarRequisition$ = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
        this.ownerCarRequisition$     = this.requisitionService.requisitionList(this.config.url, this.config.owner);
}
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }
        this.get_requistiondata();
    }

    giveApprove(data) {
        this.loader = true;
        this.requisitionService.requisitionApprove(data).subscribe(res => {
            console.log(res);
            this.loader = false;
            this.openSnackBar(' Successfull ', 'ok');
            this.get_requistiondata();
        }, err => {
            this.loader = false;
            if (err.error.message) {
                this.openSnackBar(' ' + err.error.message, 'ok');
            } else {
                this.openSnackBar(' ' + err.error, 'ok');
            }
            this.get_requistiondata();
        });

    }

    showDetail(data, approveCategory = '') {
        console.log('dialog data',data);

        const testData = {
            requisition_id: data.id,
            To: data.final_approved_by,
            Date: data.date,
            Subject: data.subject,
            Description: data.description,
            quantity : data.quantity,
            Approve_by: data.approved_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            recommendation_status: data.recommendation_status,
            Category: null,
            approvecategory: approveCategory,
            requested_by:   data.requested_from,
            requested_by_name:   data.requested_by,
            url: this.config.url,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,

        };
      //  console.log("Testdata", testData);
      const dialog_ref =  this.dialog.open(DialogComponent, {
            data: testData
        });
        dialog_ref.afterClosed().subscribe(res=>{
            if(res !== 'reload'){
                this.giveApprove(res);
            }
            console.log('afterclosedData',res);
            
        })

    }

    delegate(data, approveCategory = '') {
        const testData = {
             requisition_id: data.id,
             approvecategory: approveCategory,
             tableName : this.config.tableName,
             req_type : 'visiting_card'
         };
       const dialog_ref =  this.dialog.open(DelegateModalComponent, {
                 data: testData
             });
            dialog_ref.afterClosed().subscribe(res=>{
                this.get_requistiondata();
            })
     }


     openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

}

import { Component, OnInit, Inject,OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit,OnDestroy,AfterViewInit{
  loader:boolean;
  status_text:any;
  // @ViewChild('passwordDiv',{static : true} as any) passwordDiv : ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    @Inject(DOCUMENT) public document: HTMLDocument,
    public dialog_ref : MatDialogRef<PasswordChangeComponent>,
    private _formBuilder: FormBuilder,
    private service : UserService,
    private snackbar : MatSnackBar
  ) {
    console.log('dialogdata',this.data);
    this.dialog_ref.disableClose = true;
  }

 close(){
   if(this.data.type === 'reset_password'){
     alert('you will be able to closed this window after password reset submission');
   }else{
    this.dialog_ref.close('dialogClosed');
   }

 }
 current_password :  boolean = false;
 new_password :  boolean = false;


 resetPasswordForm: FormGroup;

 // Private
 private _unsubscribeAll: Subject<any>;


 submitForm(){
   if(this.data.type === 'reset_password'){
    this.loader = true;
    const fd = new FormData();
    fd.append('otp',this.resetPasswordForm.value.otp);
    fd.append('password',this.resetPasswordForm.value.password);
    fd.append('confirm_password',this.resetPasswordForm.value.passwordConfirm);
    console.log('payload',fd);
   this.service.resetPassword(fd).subscribe(res=>{
     console.log('reset_password_response',res);
     this.snackbar.open(res.message,'ok',{
       duration : 3000
     });
   
      this.dialog_ref.close(res.message);
     
     this.loader = false;
   },err=>{
     this.snackbar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
       duration : 4000
     });
     this.loader = false;
     
   })
   }else{
   this.loader = true;
   const fd = new FormData();
   fd.append('current_password',this.resetPasswordForm.value.current_password);
   fd.append('new_password',this.resetPasswordForm.value.password);
   fd.append('confirm_password',this.resetPasswordForm.value.passwordConfirm);
   console.log('payload',fd);
   this.service.passwordChange(fd).subscribe(res=>{
     this.snackbar.open(res.message,'ok',{
       duration : 3000
     })
     this.dialog_ref.close(res.message);
     this.loader = false;
   },err=>{
    this.dialog_ref.close(err.error.message ? err.error.message : JSON.stringify(err.error));
     this.snackbar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
       duration : 4000
     });
     this.loader = false;
   })
  }
 }

 // -----------------------------------------------------------------------------------------------------
 // @ Lifecycle hooks
 // -----------------------------------------------------------------------------------------------------

 /**
  * On init
  */


ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
const strengthMeter =  this.document.getElementById('strength-meter')
const passwordInput = this.document.getElementById('password-input') as HTMLInputElement;
const reasonsContainer = this.document.getElementById('reasons');
strengthMeter.style.display = 'none';
passwordInput.addEventListener('input', updateStrengthMeter);
updateStrengthMeter()
function updateStrengthMeter() {
  if(passwordInput.value !== ''){
    strengthMeter.style.display = 'block';
    const weaknesses = calculatePasswordStrength(passwordInput.value);
    let strength:any = 100
    reasonsContainer.innerHTML = ''
    weaknesses.forEach(weakness => {
      if (weakness == null) return
      strength -= weakness.deduction
      const messageElement = document.createElement('div')
      messageElement.innerText = weakness.message
      reasonsContainer.appendChild(messageElement)
    })
    strengthMeter.style.setProperty('--strength', strength)
  }
}

function calculatePasswordStrength(password) {
  const weaknesses = []
  weaknesses.push(lengthWeakness(password))
  weaknesses.push(lowercaseWeakness(password))
  weaknesses.push(uppercaseWeakness(password))
  weaknesses.push(numberWeakness(password))
  weaknesses.push(specialCharactersWeakness(password))

  // weaknesses.push(repeatCharactersWeakness(password))
  return weaknesses
}

function lengthWeakness(password) {
  const length = password.length

  if (length <= 5) {
    return {
      message: 'Your password is too short',
      deduction: 20
    }
  }

  if (length < 8) {
    return {
      message: 'Your password length should be 8 digit',
      deduction: 40
    }
  }
}

function uppercaseWeakness(password) {
  return characterTypeWeakness(password, /[A-Z]/g, 'uppercase characters')

}

function lowercaseWeakness(password) {
  return characterTypeWeakness(password, /[a-z]/g, 'lowercase characters')
}

function numberWeakness(password) {
  return characterTypeWeakness(password, /[0-9]/g, 'numbers')
}

function specialCharactersWeakness(password) {
  return characterTypeWeakness(password, /[^0-9a-zA-Z\s]/g, 'special characters')
}

function characterTypeWeakness(password, regex, type) {
  const matches = password.match(regex) || []

  if (matches.length === 0) {
    return {
      message: `Your password has no ${type}`,
      deduction: 40
    }
  }

  // if (matches.length <= 1) {
  //   return {
  //     message: `Your password could use more ${type}`,
  //     deduction: 5
  //   }
  // }
}

function repeatCharactersWeakness(password) {
  const matches = password.match(/(.)\1/g) || []
  if (matches.length > 0) {
    return {
      message: 'Your password has repeat characters',
      deduction: matches.length * 10
    }
  }
}
}
 ngOnInit(): void
 {


     if(this.data.type === 'reset_password'){
      this.resetPasswordForm = this._formBuilder.group({
        otp  : ['', Validators.required],
        password : ['', Validators.required],
        passwordConfirm : ['', [Validators.required, confirmPasswordValidator]]
        });
     }else{
        this.resetPasswordForm = this._formBuilder.group({
        current_password  : ['', Validators.required],
        password       : ['', Validators.required],
        passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
     }
     // Update the validity of the 'passwordConfirm' field
     // when the 'password' field changes
     this.resetPasswordForm.get('password').valueChanges
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {
             this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
         });
 }


 /**
  * On destroy
  */
 ngOnDestroy(): void
 {
     // Unsubscribe from all subscriptions
     this._unsubscribeAll.next();
     this._unsubscribeAll.complete();
 }
}

/**
* Confirm password validator
*
* @param {AbstractControl} control
* @returns {ValidationErrors | null}
*/
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

 if ( !control.parent || !control )
 {
     return null;
 }

 const password = control.parent.get('password');
 const passwordConfirm = control.parent.get('passwordConfirm');

 if ( !password || !passwordConfirm )
 {
     return null;
 }

 if ( passwordConfirm.value === '' )
 {
     return null;
 }

 if ( password.value === passwordConfirm.value )
 {
     return null;
 }

 return {passwordsNotMatching: true};

}

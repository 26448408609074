import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-create-authority',
    templateUrl: './create-authority.component.html',
    styleUrls: ['./create-authority.component.css']
})
export class CreateAuthorityComponent implements OnInit {
    loader: boolean;
    authorityForm: any;
    authorityId: number;
    authorityEdit: boolean;
    departmentList: any[] = [];
    employeeList: any

    requisition_list: any = [
        {
        name: 'Gate Pass',
        value: 'gate_pass'

    },
    {
        name: 'Office item',
        value: 'office_item'

    },
     {
        name: 'Meeting Room',
        value: 'meeting_room',

    }, {
        name: 'Pool Car',
        value: 'pool_car',

    },
    {
        name: 'Food',
        value: 'food'

    },
    {
        name: 'Visiting Card',
        value: 'visiting_card'

    },
    {
        name: 'ID card',
        value: 'id_card'
    }
    ]

    approval_type: any[] = [{
        name: 'Recommendation',
        value: 'recommendation'

    }, {
        name: 'Approval',
        value: 'approval'
    }, {
        name: 'Final Approval',
        value: 'final_approval'
    }]
    spinner: boolean;

    constructor(
        public userService: UserService,
        public snackBar: MatSnackBar,
        private fb: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,

    ) { }
    requisitionSelect(event){
        // console.log('change',event.value);
        if(event.value !== 'food'){
            this.approval_type.splice(2,1);
        }else{
            this.approval_type.splice(2,1,{
                name: 'Final Approval',
                value: 'final_approval'
            });
        }
        
    }

    get employeeForms() {
        return this.authorityForm.get('employeeName') as FormArray;
    }



    getUser() {
        this.loader = true;
        this.userService.getUsers() .subscribe(data => {
                this.employeeList = data;
                this.loader = false;
            }, err => {
                this.snackBar.open(JSON.stringify(err.error));
                this.loader = false;
            });

    }

    addMoreItem() {
        this.employeeForms.push(this.addItem());
    }

    getDepartmentlist() {

        this.userService.getDepartmentList()
            .subscribe(data => {
                this.loader = false;
                this.departmentList = data;
            }, err => {
                this.loader = false;

                console.log(err);
            });
    }

    addItem() {
        return this.fb.group({
            user_id: ['',Validators.required],
            approval_type : ['',Validators.required],
        })
    }

    deleteItem(i) {
        this.employeeForms.removeAt(i)
    }

    createauthority(data) {/* department save service */
        this.loader = true;
        this.userService.authorityCreate(data).subscribe(res => {
            this.loader = false;
            this.openSnackBar("Success ", "ok");
            this.router.navigate(['view-authority']);
        }, err => {
            this.loader = false;
            this.openSnackBar(" " + err.error.message, "ok");
        });
        this.router.navigate(['view-authority']);

    }

    authorityFormSubmit() {/* department form submit */
        //console.log(this.authorityForm.value);
        if (this.authorityEdit) {
            let fd = new FormData()
            fd.append('auth_id', this.authorityId.toString());
            fd.append('requisition_type', this.authorityForm.value.requisition_type);
            fd.append('approval_type', this.authorityForm.value.approval_type);
            fd.append('approval_department', this.authorityForm.value.approval_department);
            fd.append('employeeName', JSON.stringify(this.employeeForms.value));
            this.editAuthority(fd);
        } else {
            /* let fd = new FormData()
            fd.append('room_title', this.authorityForm.value.room_title);
            fd.append('room_no', this.authorityForm.value.room_no);
            this.createauthority(fd); */
            let fd = new FormData();
            fd.append('requisition_type', this.authorityForm.value.requisition_type);
            fd.append('approval_type', this.authorityForm.value.approval_type);
            fd.append('approval_department', this.authorityForm.value.approval_department);
            fd.append('employeeName', JSON.stringify(this.employeeForms.value));

            this.createauthority(fd);

        }
    }

    editAuthority(data) {/* department save service */
        this.loader = true;
        this.userService.editAuthority(data).subscribe(res => {
          this.loader = false;
          this.openSnackBar("Successfully Updated", "ok");
          this.router.navigate(['view-authority']);
        },err=>{
          this.loader = false;
          this.snackBar.open(err.error.message ? err.error.message :JSON.stringify(err.error), 'ok',{
            duration:5000
         });
        });
    }

    getSpecificAuthority(authorityId) {
        this.loader = true;

        // let fd = new FormData()
        // fd.append('id', departmentId)
        this.userService.getParticularAuthority(authorityId)
            .subscribe(data => {
                console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH", data)
               

                let emplist:any[] = data['approval_authority_details'].map(data => {
                    return {
                        user_id : data.user_id,
                        approval_type : data.approval_type,
                    };
                });

                this.userService.getUsers()
                    .subscribe(employeeList => {
                        this.employeeList = employeeList;
                        this.loader = false;
                        this.authorityForm.patchValue({
                            id: authorityId,
                            requisition_type: data['approval_authority'][0].requisition_type,
                            approval_type: data['approval_authority'][0].approval_type,
                            approval_department: data['approval_authority'][0].approval_department,
                        });
                        this.authorityForm.setControl('employeeName', 
                            this.fb.array(emplist.map(item=> {
                                return this.fb.group({
                                    user_id: [item.user_id],
                                    approval_type :  [item.approval_type],

                                })
                            }))
                        );
                    }, err => {
                        this.snackBar.open(err.error.message ? err.error.message :JSON.stringify(err.error), 'ok',{
                            duration:5000
                         });
                        this.loader = false;
                    });
            }, err=> {
                this.loader = false;
                this.snackBar.open(err.error.message ? err.error.message :JSON.stringify(err.error), 'ok',{
                    duration:5000
                 });
                console.log(err);
            });
    }

    ngOnInit() {
        this.getUser();
        this.userService.getParticularAuthority(15).subscribe(data => { console.log("Back Data", data) })
        console.log("Before Edit", this.route.snapshot.paramMap.get('id'));
        if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
            this.authorityId = +this.route.snapshot.paramMap.get('id');
            console.log("KAISAR", this.authorityId);
            this.authorityEdit = true;
            this.authorityForm = this.fb.group({
                id: ['', Validators.required],
                requisition_type: ['', Validators.required],
                approval_type: [''],
                approval_department: ['', Validators.required],
                employeeName: this.fb.array([this.addItem()])
            });
            this.getSpecificAuthority(this.authorityId);
        } else {
            this.authorityForm = this.fb.group({
                requisition_type: ['', Validators.required],
                approval_type: [''],
                approval_department: ['', Validators.required],
                employeeName: this.fb.array([this.addItem()])
            });
        }

        this.getDepartmentlist();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

}

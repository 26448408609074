import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-create-vehicle-type',
  templateUrl: './create-vehicle-type.component.html',
  styleUrls: ['./create-vehicle-type.component.css']
})
export class CreateVehicleTypeComponent implements OnInit {

  vehicleTypeForm: any;
  loader: boolean;
  vehicleTypeId: number;
  vehicleTypeEdit: boolean;

  constructor(
    private fb: FormBuilder,
    private snackBar:MatSnackBar,
    public userService:UserService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  createVehicleType(data){
    this.loader = true;
    this.userService.vehicleTypeCreate(data)
    .subscribe(data => {
      console.log(data)
      this.loader = false;
      this.openSnackBar("Successfully created", "ok");
      this.router.navigate(['view-vehicle']);
    }, error => {
      this.loader = false;
      if(error.error.message)this.openSnackBar("Error :"+error.error.message, "ok")
      else this.openSnackBar("Error :"+error.error, "ok")
      console.log(error);
    });
  }

  editVehicleType(vehicleTypeData){
    
    this.loader = true;
    this.userService.editVehicleType(vehicleTypeData)
      .subscribe(data => {
        console.log(data)
        this.loader = false;
        this.openSnackBar(" Successfully updated ", "ok");
        this.router.navigate(['view-vehicle']);
      }, err => {
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
        console.log(err);
      });
  }


  getSpecificVehicleType(vehicleTypeId){
    this.loader = true;
    this.userService.getParticularVehicleType(vehicleTypeId)
      .subscribe(data => {
        console.log(data)
        this.loader = false;
        this.vehicleTypeForm.patchValue({
          id: vehicleTypeId,
          type_name: data[0].type_name,
        });
      }, err => {
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
        console.log(err);
      });
  }

  ngOnInit() {

    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.vehicleTypeId = +this.route.snapshot.paramMap.get('id');
      this.vehicleTypeEdit = true;
      console.log(this.vehicleTypeId);
      this.vehicleTypeForm = this.fb.group({
        type_name: ['', Validators.required],
      });
      this.getSpecificVehicleType(this.vehicleTypeId);
    }else{
      this.vehicleTypeForm = this.fb.group({
        type_name: ['', Validators.required],
      });
    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  vehicleTypeFormSubmit(){/* Vehicle form submit */
    console.log(this.vehicleTypeForm.value);
    if(this.vehicleTypeId){
      let fd = new FormData()
      fd.append('type_id', this.vehicleTypeId.toString());
      fd.append('type_name', this.vehicleTypeForm.value.type_name);
    
      this.editVehicleType(fd);

    }else{
      let fd = new FormData()
      fd.append('type_name', this.vehicleTypeForm.value.type_name);
      this.createVehicleType(fd);
    }
   
  }

}

import { RequisitionService } from '../services/requisition.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { OfficeItemRequisition } from '../model/officeItemRequisition';

@Component({
    selector: 'app-details-office-item-requisition',
    templateUrl: './details-office-item-requisition.component.html',
    styleUrls: ['./details-office-item-requisition.component.css']
})
export class DetailsOfficeItemRequisitionComponent implements OnInit {
    loader: boolean;
    OfficeItemRequisition: OfficeItemRequisition[];
    requisitionId: number;
    itemDetails: OfficeItemRequisition[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
         public requisitionService: RequisitionService,
    ) {
        console.log(data.requisition_id);
        this.requisitionId = data.requisition_id;
        //itemDetails: this.OfficeItemRequisition[];
    }

    /* constructor(
      @Inject(MAT_DIALOG_DATA) public data: any, public requisitionService: RequisitionService,
    ) {
      console.log(data.id);
      this.getSpecificOfficeItem(data.id);
    } */

    ngOnInit() {
      //  this.getOfficeItemRequisition(this.requisitionId);
    }

    getOfficeItemRequisition(id) {
        this.loader = true;
        this.requisitionService.officeItemRequisitionDetails(id).subscribe(res => {
            console.log(res)
            this.loader = false;
            this.OfficeItemRequisition = res;
        }, err => {
            this.loader = false;
            if (err.error.message) this.openSnackBar(" " + err.error.message, "ok");
            else this.openSnackBar(" " + err.error, "ok");
        });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    getSpecificOfficeItem(id) {
        // this.loader = true;
        this.requisitionService.getSpecificOfficeItem(id)
            .subscribe(data => {
                // this.loader = false;
                this.itemDetails = data;
                console.log(data);
            }, error => {
                // this.loader = false;
                console.log(error);
            });
    }

}

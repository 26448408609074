import { Component, OnInit, ViewChild} from '@angular/core';
import { Memo } from '../model/memo';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router,ActivatedRoute } from '@angular/router';
import { MemoDetailsComponent } from '../memo-details/memo-details.component';
import {DelegateModalComponent} from '../delegate-modal/delegate-modal.component';

import { CommonService } from '../services/common.service';
import { GenerateNotesheetComponent } from '../generate-notesheet/generate-notesheet.component';
import { SharedDataService } from '../services/shared-data.service';
import { fuseAnimations } from '../animations';
// import {DashboardComponent} from '../dashboard/dashboard.component';


@Component({
    selector: 'app-view-memo-sheet-requisition',
    templateUrl: './view-memo-sheet-requisition.component.html',
    styleUrls: ['./view-memo-sheet-requisition.component.css'],
    animations : fuseAnimations
})
export class ViewMemoSheetRequisitionComponent implements OnInit  {
    template:boolean = true;
    loader: boolean;
    p = 1;
    memoList: Memo[]=[];
    memoownList: Memo[]=[];
    reqChangeList : any[]=[];
    approvedList : any[]=[];
    rejected_list : any[]=[];
    ApproveCount:number;
    OwnerTableData : MatTableDataSource<any>;
    ApproverTableData :  MatTableDataSource<any>;
    requestChangeTableData :  MatTableDataSource<any>;
    approvedTableData :  MatTableDataSource<any>;
    rejected_table_data :  MatTableDataSource<any>;
    displayedColumns: string[] = ['Position', 'Subject', 'Description','Details'];
    OwnerdisplayedColumns: string[] = ['Position','ref_no', 'Subject', 'Description','status','Details'];
    @ViewChild('TableOnePaginator', {static: true} as any) ownerpaginator: MatPaginator;
    @ViewChild('TableTwoPaginator', {static: true} as any) ApproverPaginator: MatPaginator;
    @ViewChild('TableThreePaginator', {static: true} as any) reqTablepaginator: MatPaginator;
    @ViewChild('TableFourPaginator', {static: true} as any) approvedTablepaginator: MatPaginator;
    @ViewChild('TableFivePaginator', {static: true} as any) rejected_table_paginator : MatPaginator;
    tabIndex: number=0;
    constructor(
        public userService: UserService,
        public commonService: CommonService,
        public snackBar: MatSnackBar,
        private router: Router,
        private route:ActivatedRoute,
        public dialog: MatDialog,
        public OwnerDetailsDialog : MatDialog,
        public sharedData : SharedDataService
        // public dashboard:DashboardComponent
    ) { }

    editMemo(id) {
        this.router.navigate(['/edit-memo/', id]);
    }

    OwnerTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.OwnerTableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.OwnerTableData.filter);

      }
    ApproverTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ApproverTableData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.NoteTabeData.filter);

    }
    reqChangeTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.requestChangeTableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }
    approvedTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.approvedTableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }
    rejectedTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.rejected_table_data.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }
    getAllMemo() {
        this.loader = true;
        this.userService.getMemoList().subscribe(res => {
            // console.log(res);
            this.loader = false;
            this.memoList = res;
            this.ApproverTableData = new MatTableDataSource<any>(this.memoList);
            this.ApproverTableData.paginator = this.ApproverPaginator;

            // console.log('tab',this.template);
        }, err => {
            this.loader = false;
            this.openSnackBar('' + err.error.message, 'ok');
        });

        this.userService.getMemoOwnList().subscribe(res => {
            console.log('Owner', res);
            this.loader = false;
            this.memoownList = res;
            this.OwnerTableData = new MatTableDataSource<any>(this.memoownList);
            this.OwnerTableData.paginator = this.ownerpaginator;
            this.ApproveCount = res.length;
            // console.log('ApproveCount',this.ApproveCount);

        }, err => {
            this.loader = false;
            this.openSnackBar('' + err.error.message, 'ok');
        });
        this.userService.getMemorfcList().subscribe(res => {
            // console.log("getMemorfcList", res);
            this.loader = false;
            this.reqChangeList = res;
            this.requestChangeTableData = new MatTableDataSource<any>(this.reqChangeList);
            this.requestChangeTableData.paginator = this.reqTablepaginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
        })
        this.userService.getApprovedList(1).subscribe(res => {
            // console.log("getApprovedList", res);
            this.loader = false;
            this.approvedList = res;
            this.approvedTableData = new MatTableDataSource<any>(this.approvedList);
            this.approvedTableData.paginator = this.approvedTablepaginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error,null,1), 'ok',{duration:6000});
        })
        this.userService.submit_reqList(1,2).subscribe(res => {
            // console.log("rejected_list", res);
            this.loader = false;
            this.rejected_list = res;
            this.rejected_table_data = new MatTableDataSource<any>(this.rejected_list);
            this.rejected_table_data.paginator = this.rejected_table_paginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error,null,1), 'ok',{duration:6000});
        })
    }
    viewOwnerDetails(_element, approvecategory) {
        this.loader = true;
          this.userService.getMemoDetailById(_element.id).subscribe(res => {
            this.OwnerDetailsDialog.open(MemoDetailsComponent, {
                data: {
                    detail: res,
                    main: _element,
                    approvecategory: approvecategory
                }
            });
            this.loader = false;
        },err=>{
          this.snackBar.open('something went wrong'+ err.error.message ? err.error.message : err.error );
          this.loader = false;
        });

    }

    getPageSizes(): number[] {
        if (this.OwnerTableData.data.length > 100) {
          return [5, 10, 25, 100, this.OwnerTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes2(): number[] {
        if (this.ApproverTableData.data.length > 100) {
          return [5, 10, 25, 100, this.ApproverTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes3(): number[] {
        if (this.requestChangeTableData.data.length > 100) {
          return [5, 10, 25, 100, this.requestChangeTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes4(): number[] {
        if (this.approvedTableData.data.length > 100) {
          return [5, 10, 25, 100, this.approvedTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes5(): number[] {
        if (this.rejected_table_data.data.length > 50) {
          return [5, 10, 25, 100, this.rejected_table_data.data.length];
        }
        else {
         return [5, 10, 25, 50];
        }
      }

   

    generate(element:any) {
        let transferObj = {
          subject: element.subject,
          reference : element.ref_no,
          created_by : element.created_by,
          requested_by : element.requested_by,
          budget_amount : element.budget_amount,
          amount : element.amount,
          tin : element.tin,
          bin_nid : element.bin_nid,
          memo_id : element.id
        }
        console.log('generete data',element);

        this.sharedData.supplyData(element);
        this.router.navigate(['/create-note-sheet-requisition']);
        // this.commonService.MemoDataPass.emit(transferObj);
        // let encryptedData = CryptoES.AES.encrypt(JSON.stringify(transferObj), "mahbub").toString();
        // this.router.navigate(['/create-note-sheet-requisition'],{ queryParams:{memo: encryptedData}});
      }
    createMemo() {
        this.router.navigate(['/create-memo-sheet-requisition']);
    }
    text_collapse:boolean = true;
    expandText(){
    if(this.text_collapse == true){
        this.text_collapse = false;
    }
    else{
        this.text_collapse = true;
    }
    }
    showDetails(_element,approvecategory?) {
        this.loader = true
        this.userService.getMemoDetailById(_element.id).subscribe(res => {
            this.loader = false;
           const dailog_ref = this.dialog.open(MemoDetailsComponent, {
                data: {
                    detail: res,
                    main: _element,
                    approvecategory: approvecategory
                }
            });
            dailog_ref.afterClosed().subscribe(res=>{
                this.getAllMemo();
                console.log('MemoDialogclosedData',res);

            })

        });
    }

    delegate(data, approveCategory = '') {

        const testData = {
            requisition_id: data.id,
            approvecategory: approveCategory,
            tableName: 'memo_sheet_approvals',
            req_type : 'memo_sheet'
        };
       const dailog_ref =  this.dialog.open(DelegateModalComponent, {
            data: testData
        });
        dailog_ref.afterClosed().subscribe(res=>{
            this.getAllMemo();
        })
    }



    ngOnInit() {
        console.log(this.route.snapshot.paramMap.get('visible'))
        const route=this.route.snapshot.paramMap.get('visible');
        if (route == "false") {
            this.tabIndex = 1;
        } else if(route=="approved"){
          this.tabIndex = 3;
        }
        this.getAllMemo();
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

    isok:boolean=true;
    isprintok:boolean =false;
    isexport:boolean=true;
    gocheck(){
    this.isexport=false;
    this.isok = false;
    this.isprintok= true;
    if(this.isok == false){
        this.OwnerdisplayedColumns = ['Position','ref_no', 'Subject', 'Description','status'];
       
    }
    
    }
    isok2:boolean= true;
    isprintok2:boolean= false;
    isexport2:boolean=true;
    goApproval(){
        this.isexport2=false;
        this.isok2 = false;
        this.isprintok2= true;
        if(this.isok2 == false){
        this.displayedColumns = ['Position', 'Subject', 'Description'];
        }
    }
    isok3:boolean= true;
    isexport3:boolean=true;
    isprintok3:boolean= false;
    goRequest(){
        this.isexport3=false;
        this.isok3 = false;
        this.isprintok3= true;
        if(this.isok3 == false){
        this.displayedColumns = ['Position', 'Subject', 'Description'];
        }

    }
    isok4:boolean= true;
    isprintok4:boolean= false; 
    isexport4:boolean=true;
    goApproved(){
        this.isexport4=false;
        this.isok4 = false;
        this.isprintok4= true;
        if(this.isok4 == false){
        this.displayedColumns = ['Position', 'Subject', 'Description'];
        }

    }
    isok5:boolean= true;
    isprintok5:boolean= false; 
    isexport5:boolean=true;
    goRejected(){
        this.isexport5=false;
        this.isok5 = false;
        this.isprintok5= true;
        if(this.isok5 == false){
        this.displayedColumns = ['Position', 'Subject', 'Description'];
        }

    }

    
}


import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Designation } from '../model/designation';
import { RequisitionService } from '../services/requisition.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-create-process',
    templateUrl: './create-process.component.html',
    styleUrls: ['./create-process.component.css']
})
export class CreateProcessComponent implements OnInit {
    loader: boolean;
    processForm: any;
    processId: number;
    processEdit: boolean;
    designationList: Designation[];
    
    constructor(
        public requisitionService: RequisitionService,
        public userService: UserService,
        public snackBar: MatSnackBar,
        private fb: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,

    ) { }

    get designationForms() {
        return this.processForm.get('designationName') as FormArray;
    }


    addMoreItem() {
        this.designationForms.push(this.addItem());
    }

    addItem() {
        return this.fb.group({
            designation_id: ['', Validators.required]
        });
    }

    deleteItem(i) {
        this.designationForms.removeAt(i);
    }

    createProcess(data) {/* department save service */
        data.url = 'process';
        data.path = 'create';
        this.loader = true;
        this.requisitionService.requisitionCreate(data).subscribe(res => {
            this.loader = false;
            this.openSnackBar('Success ', 'ok');
            this.router.navigate(['view-process']);
        }, err => {
            this.loader = false;
            this.openSnackBar(' ' + err.error.message, 'ok');
        }); 
        this.router.navigate(['view-process']);

    }

    processFormSubmit() {/* department form submit */
        // console.log(this.processForm.value);
        if (this.processEdit) {
            const fd = new FormData();
            fd.append('process_id', this.processId.toString());
            fd.append('processName', this.processForm.value.processName);
            fd.append('designationName', JSON.stringify(this.designationForms.value));
            this.editProcess(fd);
        } else {
            const fd = new FormData();
            fd.append('processName', this.processForm.value.processName);
            fd.append('designationName', JSON.stringify(this.designationForms.value));
            this.createProcess(fd);
        }
    }

    editProcess(data) {/* department save service */
        this.loader = true;
        /* this.userService.editProcess(data).subscribe(res => {
            this.loader = false;
            this.openSnackBar('Successfully Updated', 'ok');
            this.router.navigate(['view-process']);
        }, err => {
            this.loader = false;
            if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
        }); */
    }

    getSpecificProcess(processId) {
        this.loader = true;

        // let fd = new FormData()
        // fd.append('id', departmentId)
        this.userService.getParticularAuthority(processId)
            .subscribe(data => {
                console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH', data);
                this.loader = false;

                const designations: any[] = data['designation'].map(item => {
                    return {
                        designation_id: item.designation_id
                    };
                });

                this.userService.getDesignationList()
                    .subscribe(response => {
                        this.designationList = response;
                        this.processForm.patchValue({
                            id: processId,
                            processName: data['processName'][0].processName
                        });
                        this.processForm.setControl('designationName',
                            this.fb.array(designations.map(item => {
                                return this.fb.group({
                                    designation_id: [item.designation]
                                });
                            }))
                        );
                    }, err => {
                        console.log(err);
                    });
            }, error => {
                this.loader = false;
                this.openSnackBar('Internal error', 'ok');
                console.log(error);
            });
    }

    getDesignationList() {
        this.userService.getDesignationList()
            .subscribe(data => {
                this.designationList = data;
            }, err => {
                console.log(err);
            });
    }

    ngOnInit() {

       
        console.log('Before Edit', this.route.snapshot.paramMap.get('id'));
        if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
            this.processId = +this.route.snapshot.paramMap.get('id');
            this.processEdit = true;
            this.processForm = this.fb.group({
                id: ['', Validators.required],
                processName: ['', Validators.required],
                designationName: this.fb.array([this.addItem()])
            });
            this.getSpecificProcess(this.processId);
        } else {
            this.processForm = this.fb.group({
                processName: ['', Validators.required],
                designationName: this.fb.array([this.addItem()])
            });
        }

        this.getDesignationList();
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, FormArrayName } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { formatDate, DOCUMENT } from '@angular/common';
import { fuseAnimations } from '../animations';
import { UserService } from '../services/user.service';
import { Department } from '../model/department';

@Component({
  selector: 'app-special-payment-note',
  templateUrl: './special-payment-note.component.html',
  styleUrls: ['./special-payment-note.component.css'],
  animations : fuseAnimations
})
export class SpecialPaymentNoteComponent implements OnInit {
  CurrentDate:any = formatDate(new Date(),'dd-MM-yyyy','en');
  loader: boolean;
  memoForm: FormGroup;
  memoEdit: boolean;
  files: any;
  url: any;
  fileUrl: string | ArrayBuffer;
  fileArray: any = [];
  departmentList: Department[];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SpecialPaymentNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private snackBar: MatSnackBar,
    
  ) { }
  close(){
    this.dialogRef.close();
  }
  getDepartmentList(){
    this.userService.getDepartmentList()
        .subscribe(data => {
            this.departmentList = data;
        }, err => {
            console.log(err);
        });
}
onFileInput(event: any) {
  this.files = event.target.files;
  console.log('files',this.files);
  if (event.target.files && event.target.files[0]) {

      for (let item = 0; item < this.files.length; item++) {

          const reader = new FileReader();
          reader.readAsDataURL(this.files[item]);
          reader.onload = (_event: any) => {
              this.fileUrl = _event.target.result;
              this.fileArray.push({
                  fileName: this.files[item].name,
                  fileUrl: this.fileUrl
              });
          };
      }
      console.log(this.fileArray);
  }
}
Create_note(status) {
  this.loader = true;
      console.log("form payload",this.memoForm.value);
      const fd = new FormData();
      fd.append('date', formatDate(new Date(this.memoForm.value.date), 'yyyy-M-dd', 'en'));
      fd.append('from_dept', this.memoForm.value.from_dept);
      fd.append('subject', this.memoForm.value.subject);
      fd.append('objective', this.memoForm.value.objective);
      fd.append('background', this.memoForm.value.background);
      fd.append('decision', this.memoForm.value.decision);
      fd.append('benifits', this.memoForm.value.benifits);
      fd.append('riskChallenges', this.memoForm.value.riskChallenges);
      fd.append('paymentCondition', this.memoForm.value.benifits);
      fd.append('negotiationHistory', this.memoForm.value.negotiationHistory);
      fd.append('financialBreakdown', this.memoForm.value.financialBreakdown);
      fd.append('implementationPlan', this.memoForm.value.implementationPlan);
      fd.append('conclusion', this.memoForm.value.conclusion);
      fd.append('amount', this.memoForm.value.amount);
      fd.append('summary', this.memoForm.value.summary);
      fd.append('type_of_purchase', this.memoForm.value.type_of_purchase);
      fd.append('status', status);
      if (this.files) {
          if (this.files.length > 0) {
              for (let x = 0; x < this.files.length; x++) {
                  fd.append('file[]', this.files[x], this.files[x].name);
              }
          }
      }
      console.log("form payload",fd);
     this.userService.create_payment_note(fd).subscribe(res=>{
       this.openSnackBar(res.message,"ok",3000);
       this.loader = false;
       this.dialogRef.close('reload');
     },err=>{
       this.openSnackBar(err.error.message ? err.error.message : err.error,"ok",6000 );
       this.loader = false;
       this.dialogRef.close('reload');
     })
  }




  ngOnInit() {
    this.getDepartmentList();
    this.memoForm = this.fb.group({
      from_dept: ['', Validators.required],
      subject: ['', Validators.required],
      date: ['', Validators.required],
      objective: [''],
      background: [''],
      decision: [''],
      paymentCondition: [''],
      riskChallenges: [''],
      benifits: [''],
      negotiationHistory: [''],
      financialBreakdown: [''],
      implementationPlan: [''],
      conclusion: [''],
      amount : ['',Validators.required],
      summary : ['',Validators.required],
      type_of_purchase : ['',Validators.required],
      files: [null]
  });
  }
  openSnackBar(message: string, action: string,time?:number) {
    this.snackBar.open(message, action, {
        duration: time,
    });
}

}

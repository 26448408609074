import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RequisitionService } from '../services/requisition.service';
import { MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-generate-notesheet',
  templateUrl: './generate-notesheet.component.html',
  styleUrls: ['./generate-notesheet.component.css']
})
export class GenerateNotesheetComponent implements OnInit {
    [x: string]: any;

    loader: boolean;
    notesheetForm: FormGroup;
    memoId: number;
    memoEdit: boolean;
    employeeList: [];
    emplist: any;
    employeeList$;
    selectedEmployee;

    memoData: any;
    files: any;
    url: any;
    fileUrl: string | ArrayBuffer;
    fileArray: any = [];


  constructor(
    public userService: UserService,
    public requisitionService: RequisitionService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
        this.userService.getUserList().subscribe((res) => {
        this.employeeList$ = res;
      });
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
        this.memoId = +this.route.snapshot.paramMap.get('id');
        this.memoEdit = true;
        this.notesheetForm = this.fb.group({
          amount: ['', Validators.required],
          notedesc: ['', Validators.required],
          employeeName : this.fb.array([this.addempItem()])
        });
    } else {
        this.notesheetForm = this.fb.group({
          amount: ['', Validators.required],
          notedesc: ['', Validators.required],
          employeeName : this.fb.array([this.addempItem()]),
          files:  [null]
        });
    }
    this.employListAutoCompleteControl(0);

  }
  get employeeForms() {
    return this.notesheetForm.get('employeeName') as FormArray;
}

addMoreempItem() {
  this.employeeForms.push(this.addempItem());
  this.employListAutoCompleteControl(this.employeeForms.length - 1);
}

addempItem() {
  return this.fb.group({
      employee_id: ['', Validators.required]
  });
}
deleteempItem(i) {
  const employeeArraySize = this.employeeForms.length - 1;
  if (employeeArraySize === 0) {
      this.memoForm.patchValue({
          employee_id: ''
      });
  }
  this.employeeForms.removeAt(i);
}

getEmployee(value) {
  this.selectedEmployee = value;
}

employListAutoCompleteControl(index: number) {
  const arrayControl = this.notesheetForm.get('employeeName') as FormArray;
  this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
      .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.f_name),
          map(name => name ? this._filter(name) : this.employeeList$.slice())
      );

}

displayFn(data?: any): string | undefined {
  return data ? data.f_name : undefined;
}

private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();

  return this.employeeList$.filter(option => option.f_name.toLowerCase().includes(filterValue));
}

onFileInput(event: any) {
   this.files = event.target.files;
  if (event.target.files && event.target.files[0]) {

     for (let item = 0; item < this.files.length; item++) {

          const reader = new FileReader();
          reader.readAsDataURL(this.files[item]);
          reader.onload = (_event: any) => {
              this.fileUrl = _event.target.result;
              this.fileArray.push({
                  fileName: this.files[item].name,
                  fileUrl: this.fileUrl
              });
          };
      }
      console.log(this.fileArray);
 }
}
noteFormSubmit() {/* department form submit */
  if (this.memoEdit) {
      const fd = new FormData();
      fd.append('note_id', this.memoId.toString());
      fd.append('memoName', this.notesheetForm.value.memoName);
      fd.append('designationName', JSON.stringify(this.employeeForms.value));
      this.editMemo(fd);
  } else {
      const fd = new FormData();
      console.log(this.notesheetForm.value);
      fd.append('requisition_id', this.data.id);
      fd.append('notedesc', this.notesheetForm.value.notedesc);
      fd.append('approval_users', JSON.stringify(this.employeeForms.value));
      fd.append('amount', this.notesheetForm.value.amount);
      if (this.files) {
        if (this.files.length > 0) {
          for (let x = 0; x < this.files.length; x++) {
            fd.append('uploadFile[]', this.files[x], this.files[x].name);
            }
        }
      }

      this.saveNoteSheet(fd);
  }
}
  saveNoteSheet(data) {
    this.loader = true;
    this.requisitionService.notesheetCreate(data).subscribe(res => {
       this.loader = false;
       this.openSnackBar('Success ', 'ok');
      // this.router.navigate(['view-memo']);
   }, err => {
       this.loader = false;
       this.openSnackBar(' ' + err.error.message, 'ok');
   }); 
  }
}

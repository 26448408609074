import { Component, OnInit, ViewChild } from '@angular/core';
import { UserType } from '../model/userType';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-view-user-role',
  templateUrl: './view-user-role.component.html',
  styleUrls: ['./view-user-role.component.css']
})
export class ViewUserRoleComponent implements OnInit {
  loader: boolean;
  p: number = 1;
  // userTypeList:UserType[];
  displayedUserTypeColumns: string[] = ['Position','Name','Edit'];
  data:any = [];
  userTypeList : MatTableDataSource<UserType>;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  constructor(
    public userService:UserService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) {}

  editUserRole(id,name){
    console.log(id)
    this.router.navigate(['edit-user-role/', id,name]);
  }

  ngOnInit() {
    this.getAllUserType();
  }
  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.userTypeList.filter = filterValue.trim().toLowerCase();
  }
  getAllUserType(){
    this.loader = true;
    this.userService.getUserTypeList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data = res;
        this.data.sort((a,b)=>b.type_id - a.type_id);
        this.data.map((v,i)=>v.sn = i+1);
        this.userTypeList = new MatTableDataSource<UserType>(this.data);
        this.userTypeList.paginator = this.paginator;

      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}

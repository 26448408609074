import { UserService } from '../services/user.service';
import { MeetingRoom } from '../model/meetingRoom';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Authority } from '../model/authority';
import { AuthorityDetailsComponent } from '../authority-details/authority-details.component';

@Component({
  selector: 'app-view-authority',
  templateUrl: './view-authority.component.html',
  styleUrls: ['./view-authority.component.css']
})
export class ViewAuthorityComponent implements OnInit {
  loader: boolean;
  p: number = 1;
  authorityList$: MatTableDataSource<Authority>;
  displayedColumns: string[] = ['sn','req_type','dept_name','Edit'];
  @ViewChild(MatPaginator,{static:true} as any) paginator :MatPaginator;
  data:any;
  constructor(
      public userService:UserService,
      public snackBar: MatSnackBar,
      private router: Router,
      private dialog : MatDialog,
  ) { }

  editMeetingRoom(id){
    console.log(id)
    this.router.navigate(['edit-authority/', id]);
  }
  applyFilter(event:any){
    let filterdeta = event.target.value;
    this.authorityList$.filter = filterdeta.trim().toLowerCase();
  }
  details(data){
    const dialog_ref = this.dialog.open(AuthorityDetailsComponent,{
      data : data,
      panelClass : 'custom-modalbox'
    })
    console.log('data',data);
    dialog_ref.afterClosed().subscribe(res=>{
      console.log('dialog_ref',res);
      
    })
    
  }
getAuthorityData(){
  this.loader = true;
  this.userService.getauthorityList().subscribe(res=>{
    this.data = res;
    this.data.sort((a,b)=>b.id - a.id);
    this.data.map((v,i)=>v.sn = i+1);
    console.log('authoirty data',this.data);
    
    this.authorityList$ = new MatTableDataSource<Authority>(this.data);
    this.authorityList$.paginator = this.paginator;
    this.loader = false;
  },
  err=>{
    this.openSnackBar('something went wrong on data loading' + err.err.message,'ok');
    this.loader =false;
  })
}
  ngOnInit() {
    this.getAuthorityData();
  }

  // getAllMeetingRoom(){
  //   this.loader = true;

  //   this.userService.getauthorityList().subscribe( res =>{
  //     console.log("HHHHH",res)
  //     this.loader = false;
  //    // this.meetingRoomList = res;
  //     //this.openSnackBar("Success ", "ok");
  //   },err=>{
  //     this.loader = false;
  //     this.openSnackBar(" "+err.error.message, "ok");
  //   });
  // }


  createMeetingRoom() {
    this.router.navigate(['/create-authority']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../model/user';
@Component({
    selector: 'app-delegate-modal',
    templateUrl: './delegate-modal.component.html',
    styleUrls: ['./delegate-modal.component.css']
})
export class DelegateModalComponent implements OnInit {

    delegateForm: FormGroup;
    loader: boolean;
    recommendationapprovalauthority$: Observable<any>;
    selected2;
    users: User[]=[];
    employee_spinner: boolean;

    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog_ref : MatDialogRef<DelegateModalComponent>
        ) { }

    delegateFormSubmit() {
         this.delegateForm.value.tableName      = this.data.tableName;
         this.delegateForm.value.requisition_id = this.data.requisition_id;
         this.delegateForm.value.approval_type  = this.data.approvecategory;
         this.delegateForm.value.req_type  = this.data.req_type ? this.data.req_type  : '';
         this.loader = true;
         console.log(this.delegateForm.value);
         this.userService.delegate(this.delegateForm.value)
            .subscribe(() => {
                this.loader = false;
               this.dialog_ref.close('reload');
            }, () => {
                this.loader = false;
                this.dialog_ref.close('reload');
            });
         }
    close(){
        this.dialog_ref.close('reload');
    }
    selected_delegate_person(data){
        this.delegateForm.get('user_id').setValue(data);
        console.log('form value approved by',this.delegateForm.value);
    }
    ngOnInit() {
        console.log('Delegate_Data',this.data);
        
        this.dialog_ref.addPanelClass('custom-modalbox');
        // this.recommendationapprovalauthority$ = this.userService.getDelegationauthoritylist(this.data);
        this.employee_spinner = true;
        this.userService.getUsers().subscribe(res=>{
            this.employee_spinner = false;
            this.users = res;
            console.log('delegate_userlist',res);
            
        })
        this.delegateForm = this.fb.group({
            user_id: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

}

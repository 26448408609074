import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { dummyphotoUrl} from '../commonUrl';
import { dummysignatureUrl} from '../commonUrl';
import { User } from '../model/user';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class ViewUserComponent implements AfterViewInit, OnInit {

  dummyUrl = dummyphotoUrl;
  signatureUrl = dummysignatureUrl;
  loader: boolean;
  tableData: MatTableDataSource<any>;
  p = 1;
  userList: User[] | null;
  permission = [];
  userCount;
  course: User;



  columnsToDisplay: string[] = ['Name', 'Designation', 'Department', 'Email', 'Phone', 'Edit'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public userService: UserService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.permission = [
      {
        'id': 'PF-35682',
        'name': 'Mr.Jamal',
        // "Permission":"Get_Pass",
        'get_pass': false,
        'pool_car': true,
        'car': true,
        'office_item': true,
        'visiting_card': false,
        'id_card': true,
        'metting_room': true,
        'food': false
      }
    ];
  }

  editUser(id) {
    console.log(id);
    this.router.navigate(['edit-user/', id]);
  }

  ngOnInit() {
   this.getAllUser(1, 20, '');
   this.tableData = new MatTableDataSource<any>(this.userList) ;
  }

  getAllUser(pageNumber, pageSize, search) {
    this.loader = true;
    this.userService.getPaginateUserlist(pageNumber, pageSize, search).subscribe( res => {
        console.log(res);
        this.loader = false;
        this.userList = res;
        this.tableData = new MatTableDataSource<any>(this.userList);
        //   this.tableData.paginator = this.paginator;
      }, err => {
        this.loader = false;
        if (err.error.message) {this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
  ngAfterViewInit() {
    this.paginator.page
        .pipe(
            tap(() => this.loadLessonsPage())
        )
        .subscribe();
}

loadLessonsPage() {
  console.log(this.paginator.pageIndex);
  this.getAllUser(this.paginator.pageIndex, this.paginator.pageSize, '');
}
  applyFilter(filterValue: string) {
    this.getAllUser(1, 10, filterValue);
}

}

import { CommonService } from './../services/common.service';
import { Component, OnInit, Inject, Directive,Input,ElementRef,HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import {dummysignatureUrl} from '../commonUrl';
import { UserService } from '../services/user.service';
import { fuseAnimations } from '../animations';
// @Directive({
//     selector :'img[appImgFallback]' 
// })
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
    animations : fuseAnimations
}) 
export class DialogComponent implements OnInit { 
    // @Input() appImgFallback:string;
    loader:boolean;
    signUrl = dummysignatureUrl;
    remarks: '';
    updateData: any;
    print: boolean;
    remarksList: any[]=[];
    selectedVehicle:any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public commonService: CommonService,
        public dialog_ref: MatDialogRef<DialogComponent>,
        private eRef : ElementRef,
        private userService : UserService
        ) {
        this.updateData = data;
        this.selectedVehicle = data.vehicleinfo ? data.vehicleinfo[0] : {};
    }

    public keepOriginalOrder = (a, b) => a.key;

    checkimg(data){
        console.log(data)
    }
    approve(status:number) {
        console.log('status',status)
        this.loader = true;
        this.updateData.remarks = this.remarks;
        this.updateData.status = status;
        this.dialog_ref.close(this.updateData);
        // this.commonService.approvalEvent.emit(this.updateData);
        this.loader = false
        console.log('Dialog approved function call',this.updateData);
    }

 
getRemarks(){
    this.loader = true;
    this.userService.getRemarks(this.updateData.requisition_id,this.updateData.url).subscribe(res=>{
        this.remarksList = res;
        console.log('Remarks',this.remarksList);
        this.loader = false;
        
    })
}
    ngOnInit() {
        this.getRemarks();
        console.log('Dialogdata',this.updateData);
        
    }

    close() {
        this.dialog_ref.close('reload');
    }

}

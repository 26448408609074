import {Component, OnInit, Input, Output,EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { fuseAnimations } from '../animations';
import { User} from '../model/users';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  animations : fuseAnimations

})
export class AutocompleteComponent implements OnInit {

  myControl = new FormControl();
  // options: User[] = [
  //   {f_name: 'Mary',id:1},
  //   {f_name: 'Sohag',id:1},
  //   {f_name: 'mahbub',id:1},
  // ];
  @Input() FieldName: any;
  @Input() options:User[];
  @Input() _required: boolean;
  @Output() selectedOption = new EventEmitter();
  optionSelect(event){
    this.selectedOption.emit(event.option.value.id);
    console.log('optionSelect',event.option.value.id);
  }

  filteredOptions: Observable<User[]>;

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.f_name),
        map(f_name => f_name ? this._filter(f_name) : this.options.slice())
      ); 
  }

  displayFn(user: User): string {
    return user && user.f_name ? user.f_name : '';
  }

  private _filter(name: any): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option =>  {
      return option.f_name.toLowerCase().indexOf(filterValue) !== -1 || option.pf_no  == name;
    })
    // option.f_name.toLowerCase().indexOf(filterValue) !== -1);
  }
}

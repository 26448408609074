import { Component, OnInit } from '@angular/core';
import { dummy } from '../commonUrl';
import { MatSnackBar } from '@angular/material';
import { User } from '../model/user';
import { UserService } from '../services/user.service';
import { RequisitionService } from '../services/requisition.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import{dummyphotoUrl} from '../commonUrl';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-create-visiting-card-requisition',
  templateUrl: './create-visiting-card-requisition.component.html',
  styleUrls: ['./create-visiting-card-requisition.component.css']
})
export class CreateVisitingCardRequisitionComponent implements OnInit {
  
  ImageUrl = dummyphotoUrl;
  assetsImage = dummy; 
  loader: boolean;
  employee:User[];
  visitingCardForm: any;
  employee_name: string;
  employee_image:any;
  finalapprovalauthority$;
  recommendationapprovalauthority$;
  approvalauthority$;
  users: User[]=[];
  employee_spinner: boolean;

  constructor(
    private snackBar:MatSnackBar,
    public userService:UserService,
    public requisitionService:RequisitionService,
    private fb : FormBuilder,
    public router :Router,
  ) { }

  createVisitingCardRequisition(){
    this.visitingCardForm.get('date').setValue(formatDate(this.visitingCardForm.get('date').value ? this.visitingCardForm.get('date').value : new Date(),"yyyy-MM-dd","en"));
    this.loader = true;
    console.log('formdata',this.visitingCardForm.value);
    const payload = Object(this.visitingCardForm.value);
    payload.url = "visiting_card";
    payload.path = 'requisition_create';
    console.log('payload',payload);
    this.requisitionService.requisitionCreate(payload)
       .subscribe(res => {
        console.log('response',res);
        this.openSnackBar(res.message, "ok");
        this.router.navigate(['view-visiting-card-requisition']);
        this.loader = false;
      }, err => {
        this.loader = false;
       // if(err.status == 400) location.reload(true);
        if(err.error.message)this.openSnackBar("Internal error :"+err.error.message, "ok")
        else this.openSnackBar("Error :"+err.error, "ok")
    }); 
  }
  
  // getEmployeeList(){
  //   this.loader = true;
  //   this.userService.getUserList()
  //     .subscribe(data => {
  //       console.log('userData',data);
  //       this.loader = false;
        
  //     }, err => {
  //       this.loader = false;
  //      // if(err.status == 400) location.reload(true);
  //       if(err.error.message)this.openSnackBar("Internal error :"+err.error.message, "ok")
  //       else this.openSnackBar("Error :"+err.error, "ok")
  //     });
  // }

  getEmployee(id){
    console.log(id)
    this.loader = true;
    this.userService.getParticularUser(id)
      .subscribe(data => {
        console.log('loginuserData',data);
        this.loader = false;
        this.employee = data;
        if(data[0].l_name){
        this.employee_name = data[0].f_name+' '+data[0].l_name;}else{
          this.employee_name = data[0].f_name;
        }
        let userId = data[0].id.toString();
        if(data[0].user_image){this.employee_image = this.ImageUrl + data[0].user_image}else{
          this.employee_image = '/assets/images/idCardHead.png';
        }
         this.visitingCardForm.patchValue({
          name:userId,
          department: data[0].dept_name,
          designation: data[0].title,
          email: data[0].email,
          phone: data[0].phone,
          dob: data[0].dob,
          joining_date: data[0].joining_date,
        }); 
      }, err => {
        this.loader = false;
        if(err.status == 400) location.reload(true);
        if(err.error.message)this.openSnackBar("Internal error :"+err.error.message, "ok")
        else this.openSnackBar("Error :"+err.error, "ok")
      });
  }

  getEmployeeList(){
    this.employee_spinner = true;
    this.userService.getUsers()
      .subscribe(data => {
        console.log('user_short_info');
        this.employee_spinner = false;
        this.users = data;
      }, err => {
        this.employee_spinner = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
          duration : 5000
        })
      });
  }
  selected_recomended_by(data){
    this.visitingCardForm.get('recommended_by').setValue(data);
    console.log('form value',this.visitingCardForm.value);
    
  }
  selected_approved_by(data){
    this.visitingCardForm.get('approved_by').setValue(data);
    console.log('form value approved by',this.visitingCardForm.value);
  }

  ngOnInit() {
    this.getEmployeeList();
    this.approvalauthority$ = this.userService.getFinalApproveauthoritylist('visiting_card');
    this.recommendationapprovalauthority$ = this.userService.getRecommendationApproveauthoritylist('visiting_card');
    // this.approvalauthority$ = this.userService.getApproveApproveauthoritylist('visiting_card');
    this.visitingCardForm = this.fb.group({
        subject: ['', Validators.required],
        description: ['',Validators.required],
        date : [''],
        quantity: ['',Validators.required],
        approved_by: ['',Validators.required],
        final_approved_by:[''],
        recommended_by:['']
/*         file: [null] */
      }); 
      let id = +sessionStorage.getItem('user_id');
      this.getEmployee(id);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }
}

import { CommonService } from './../services/common.service';
import { DetailsIdCardRequisitionComponent } from './../details-id-card-requisition/details-id-card-requisition.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialog, MatTabChangeEvent } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { IdCardRequisition } from '../model/idCardRequisition';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { DialogComponent } from '../dialog/dialog.component';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';

@Component({
    selector: 'app-view-id-card-requisition',
    templateUrl: './view-id-card-requisition.component.html',
    styleUrls: ['./view-id-card-requisition.component.css']
})
export class ViewIdCardRequisitionComponent implements OnInit, OnDestroy {

    loader: boolean;
    p: 1;
    template:boolean = true;
    IdCardRequisitionList: IdCardRequisition[];
    displayedcarRequisitionApprovalColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    requisitions: any;

    finalCarRequisition$: Observable<any>;
    approvalCarRequisition$: Observable<any>;
    recommendCarRequisition$: Observable<any>;
    ownerCarRequisition$: Observable<any>;
  //  adminCarRequisition$: Observable<Gatepass[]>;
    tabText: string;
    subscriptions: Subscription[] = [];
    tabIndex:number = 0;

    config: any = {
        url: 'id_card',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName : 'id_card_requisitions'
    } ;


    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        public dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
        public commonService: CommonService
    ) { }

    createIdCard() {
        this.router.navigate(['/create-id-card-requisition']);
    }
    get_requistionlist(){
        this.approvalCarRequisition$    = this.requisitionService.requisitionList(this.config.url, this.config.approve);
        this.recommendCarRequisition$   = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
        this.ownerCarRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.owner);
    }
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }
        this.get_requistionlist();
       // this.getAllIdCardRequisition();
        // this.finalCarRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.final);
   

        this.subscriptions.push(this.commonService.approvalEvent.subscribe((data) => {
            console.log('approve Event');
            this.approveIdCard(data);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    approveIdCard(data)  {
        // console.log(data);
        this.giveApprove(data);
    }

    getAllIdCardRequisition() {
        // this.loader = true;
        // this.requisitionService.idcardRequisitionList().subscribe(res => {
        //     console.log(res);
        //     this.requisitions = res.length;
        //     this.loader = false;
        //     this.IdCardRequisitionList = res;
        // }, err => {
        //     this.loader = false;
        //     if (err.error.message) {
        //         this.openSnackBar('' + err.error.message, '');
        //     } else {
        //         this.openSnackBar('' + err.error, 'ok');
        //     }
        // });
    }

    giveApprove(data) {
        this.loader = true;
        this.requisitionService.requisitionApprove(data).subscribe(res => {
            console.log(res);
            this.dialog.closeAll();
            this.loader = false;
            this.openSnackBar( 'Successfull', 'ok');
          this.get_requistionlist();
        }, err => {
            this.loader = false;
            if (err.error.message) {
                 this.openSnackBar('' + err.error.message, 'ok');
            } else {
                this.openSnackBar('' + err.error, 'ok');
            }
            this.get_requistionlist();
        });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    onTabChange(event: MatTabChangeEvent)   {
        this.tabText = event.tab.textLabel;
        console.log(event);
    }

    delegate(data, approveCategory = '') {
        const testData = {
             requisition_id: data.id,
             approvecategory: approveCategory,
             tableName : this.config.tableName,
             url: this.config.url,
             req_type : 'id_card'
         };
       const dialog_res = this.dialog.open(DelegateModalComponent, {
                 data: testData
             });
             dialog_res.afterClosed().subscribe(res=>{
                 this.getAllIdCardRequisition();
             })
     }

    showDetail(data, approveCategory = '') {
        // console.log('here',approveCategory);
        const testData = {
            To: data.final_approved_by,
            Date: data.date,
            Subject: data.subject,
            Description: data.description,
            Approve_by: data.approved_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            final_approval_status: data.final_status,
            recommendation_status: data.recommand_status,
            Category: null,
            approvecategory: approveCategory,
            requested_by: data.requested_from,
            requested_by_name: data.requested_by,
            requisition_id: data.id,
            approve_sig: data.approved_by_sig,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,
            url: this.config.url
          //  tabName: this.tabText
        };

         const dialog_ref = this.dialog.open(DialogComponent, {
                data: testData
            });
            dialog_ref.afterClosed().subscribe(res=>{
                if(res !== 'reload'){
                    this.giveApprove(res);
                }
            })
    
    }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicleassign',
  templateUrl: './vehicleassign.component.html',
  styleUrls: ['./vehicleassign.component.css']
})
export class VehicleassignComponent implements OnInit {

  loader: boolean;
  p = 1;
  departmentList: any;
  displayedColumns: string[] = ['Position','Department','Edit'];
  constructor(
    public userService: UserService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getAllAssign();
  }


  getAllAssign() {
    this.loader = true;
    this.userService.getAssignList().subscribe( res =>{
        console.log(res);
        this.loader = false;
        this.departmentList = res;
        // this.openSnackBar("Success ", "ok");
      }, err => {
        this.loader = false;
        this.openSnackBar('' + err.error.message, 'ok');
    });
  }
  assignCarpool(data) {
    console.log('assign carpool',data);
    this.router.navigate(['/pool-car-requisition-details/' + data.id ]);
}
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { fuseAnimations } from '../animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-report-office-item-accepted-employee-wise',
  templateUrl: './report-office-item-accepted-employee-wise.component.html',
  styleUrls: ['./report-office-item-accepted-employee-wise.component.css'],
  animations : fuseAnimations,
})
export class ReportOfficeItemAcceptedEmployeeWiseComponent implements OnInit {
  loader: boolean;
  dataList:any=[];
  tableData: MatTableDataSource<any>;
  Userlist:any;
  selectedEmployeeId : number;
  columnsFieldName: any;
  ReportForm: FormGroup;
  displayedColumns:any= [{display : 'Subject',field: 'subject'},{display : 'Requested By',field: 'requested_by'},{display : 'Requisition Date',field: 'date'},{display : 'Department',field: 'dept_name'}];

  @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;
  constructor(
    public userService: UserService,
    private snackBar: MatSnackBar,
    private fb : FormBuilder,
  ) { }

  selectedUser(event){
    this.selectedEmployeeId = event;
    console.log('selecteduserparent',event);
    
  }
  getList(){
    this.loader = true;
    this.ReportForm.get('start_date').setValue(formatDate(this.ReportForm.get('start_date').value ? this.ReportForm.get('start_date').value : new Date(),'yyyy-MM-dd','en'));
    this.ReportForm.get('end_date').setValue(formatDate(this.ReportForm.get('end_date').value ? this.ReportForm.get('end_date').value : new Date(),'yyyy-MM-dd','en'));
    const payload = Object(this.ReportForm.value);
    payload.user_id = this.selectedEmployeeId;
    console.log('payload',payload);
    this.userService.getitemreportEmployee(payload).subscribe(res=>{
        this.dataList = res;
        console.log('Mat table Data',res);
        this.loader = false;
        this.tableData = new MatTableDataSource<any>(this.dataList);
        this.tableData.paginator = this.tableOnePaginator;
        this.tableData.sort = this.tableOneSort;
        console.log('Mat table Data',this.tableData);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}
getUser(){
  this.loader = true;
    this.userService.getUsers().subscribe(res=>{
      this.Userlist = res;
      console.log('userlist',res);
      this.loader = false;
      
    },err=>{
      this.openSnackBar('Somthing went wrong, Internal Error'+err,'ok');
    })
}
  ngOnInit() {
      this.getUser();
    this.columnsFieldName = this.displayedColumns.map(item => item.field);
    this.ReportForm = this.fb.group({
        start_date : ['',Validators.required],
        end_date : ['',Validators.required],
        report_type : ['',Validators.required],
        item_id : [''],
    })

  }
  openSnackBar(message: string, action: string,time?:number) {
    this.snackBar.open(message, action, {
        duration : time
    });
}

}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isAuthorize: boolean;

  constructor(private router: Router, private userService:UserService,private route:ActivatedRoute) {
    console.log('ActivatedRoute',this.route.url);
    console.log('router',this.router.url);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      let url: string = state.url;
      
      
      this.isAuthorize = this.checkLogin(url);
      if(this.isAuthorize && 
          (
          (sessionStorage.getItem('isViewDashboard') == 'true' && state.url == '/dashboard')|| 
          (sessionStorage.getItem('isAddUser') == 'true' && state.url == '/create-user')|| 
          (sessionStorage.getItem('isViewUser') == 'true' && state.url == '/view-user') ||
          (sessionStorage.getItem('isEditUser') == 'true' && state.url.match(/edit-user/)) ||
          (sessionStorage.getItem('isAddUserRole') == 'true' && state.url == '/create-user-role')||
          (sessionStorage.getItem('isViewUserRole') == 'true' && state.url == '/view-user-role')||
          (sessionStorage.getItem('isEditUserRole') == 'true' &&  state.url.match(/edit-user-role/))||

          (sessionStorage.getItem('isAddDepartment') == 'true' && state.url == '/create-department')||
          (sessionStorage.getItem('isViewDepartment') == 'true' && state.url == '/view-department')||
          (sessionStorage.getItem('isEditDepartment') == 'true' && state.url.match(/edit-department/))||

          (sessionStorage.getItem('isAddAccountsHead') == 'true' && state.url == '/create-accounts_head')||
          (sessionStorage.getItem('isViewAccountsHead') == 'true' && state.url == '/view-accounts_head')||
          (sessionStorage.getItem('isEditAccountsHead') == 'true' && state.url.match(/edit-accounts_head/))||

          (sessionStorage.getItem('isAddDesignation') == 'true' && state.url == '/create-designation')||
          (sessionStorage.getItem('isViewDesignation') == 'true' && state.url == '/view-designation')||
          (sessionStorage.getItem('isEditDesignation') == 'true' && state.url.match(/edit-designation/))||
          
          (sessionStorage.getItem('isAddVehicle') == 'true' && state.url == '/create-vehicle')||
          (sessionStorage.getItem('isViewVehicle') == 'true' && state.url == '/view-vehicle')||
          (sessionStorage.getItem('isEditVehicle') == 'true' && state.url.match(/edit-vehicle/))||
          (sessionStorage.getItem('isAddVehicleType') == 'true' && state.url == '/create-vehicle-type')||
          (sessionStorage.getItem('isViewVehicleType') == 'true' && state.url == '/view-vehicle-type')||
          (sessionStorage.getItem('isEditVehicleType') == 'true' && state.url.match(/edit-vehicle-type/))||

          (sessionStorage.getItem('isCreateofficeItemctg') == 'true' && state.url == '/create-officeItem-ctg')||
          (sessionStorage.getItem('isViewofficeitemlist') == 'true' && state.url == '/view-officeItem-ctg')||
          (sessionStorage.getItem('isEditofficeItemctg') == 'true' && state.url.match(/edit-officeItem-ctg/))||

          (sessionStorage.getItem('isAddOfficeItem') == 'true' && state.url == '/create-office-item')||
          (sessionStorage.getItem('isViewOfficeItem') == 'true' && state.url == '/view-office-item')||
         
          (sessionStorage.getItem('isEditOfficeItem') == 'true' && state.url.match(/edit-office-item/))||

          (sessionStorage.getItem('isAddMeetingRoom') == 'true' && state.url == '/create-meeting-room')||
          (sessionStorage.getItem('isViewMeetingRoom') == 'true' && state.url == '/view-meeting-room')||
          (sessionStorage.getItem('isEditMeetingRoom') == 'true' && state.url.match(/edit-meeting-room/))||

          (sessionStorage.getItem('isAddMeetingRoom') == 'true' && state.url == '/create-authority')||
          (sessionStorage.getItem('isViewMeetingRoom') == 'true' && state.url == '/view-authority')||
          (sessionStorage.getItem('isEditMeetingRoom') == 'true' && state.url.match(/edit-authority/))||

          (sessionStorage.getItem('isAddMeetingRoom') == 'true' && state.url == '/create-process')||
          (sessionStorage.getItem('isViewMeetingRoom') == 'true' && state.url == '/view-process')||
          (sessionStorage.getItem('isEditMeetingRoom') == 'true' && state.url.match(/edit-process/))||
        
          (sessionStorage.getItem('isAddOfficeItemRequisition') == 'true' && state.url == '/create-office-item-requisition')||
          (sessionStorage.getItem('iseditOfficeItemRequisition') == 'true' && state.url.match(/edit-office-item-requisition/))||
          (sessionStorage.getItem('isViewOfficeItemRequisition') == 'true' && state.url == '/view-office-item-requisition')||
          (sessionStorage.getItem('ApprovalNoteListPermission') == 'true' && state.url == '/approval-note-list')|| 
          (sessionStorage.getItem('isPayment_note_permission') == 'true' && state.url == '/payment-note-list')|| 
          (sessionStorage.getItem('ApprovalNoteListPermission') == 'true' && state.url.match(/approval-note-list/))|| 
          (sessionStorage.getItem('isPayment_note_permission') == 'true' && state.url.match(/payment-note-list/))|| 

          (sessionStorage.getItem('isAddGatePassRequisition') == 'true' && state.url == '/create-gate-pass-requisition')||
          (sessionStorage.getItem('isViewGatePassRequisition') == 'true' && state.url == '/view-gate-pass-requisition')||

          (sessionStorage.getItem('isAddVisitingCardRequisition') == 'true' && state.url == '/create-visiting-card-requisition')||
          (sessionStorage.getItem('isViewVisitingCardRequisition') == 'true' && state.url == '/view-visiting-card-requisition')||

          (sessionStorage.getItem('isAddMeetingRoomRequisition') == 'true' && state.url == '/create-meeting-room-requisition')||
          (sessionStorage.getItem('isViewMeetingRoomRequisition') == 'true' && state.url == '/view-meeting-room-requisition')||

          (sessionStorage.getItem('isAddPoolCarRequisition') == 'true' && state.url == '/create-pool-car-requisition')||
          (sessionStorage.getItem('isViewPoolCarRequisition') == 'true' && state.url == '/view-pool-car-requisition')||
          (sessionStorage.getItem('isViewPoolCarRequisitionDetails') == 'true' &&  state.url.match(/pool-car-requisition-details/))||

          (sessionStorage.getItem('isAddCarRequisition') == 'true' && state.url == '/create-car-requisition')||
          (sessionStorage.getItem('isViewCarRequisition') == 'true' && state.url == '/view-car-requisition')||

          (sessionStorage.getItem('isAddFoodRequisition') == 'true' && state.url == '/create-food-requisition')||
          (sessionStorage.getItem('isViewFoodRequisition') == 'true' && state.url == '/view-food-requisition')||

          (sessionStorage.getItem('isAddIdCardRequisition') == 'true' && state.url == '/create-id-card-requisition')||
          (sessionStorage.getItem('isViewIdCardRequisition') == 'true' && state.url == '/view-id-card-requisition')||

          (sessionStorage.getItem('isAddMemoSheetRequisition') == 'true' && state.url == '/create-memo-sheet-requisition')||
          (sessionStorage.getItem('isViewMemoSheetRequisition') == 'true' && state.url == '/view-memo-sheet-requisition')||
            // get memo edit  local Storage cache/token
          (sessionStorage.getItem('isEditMemoSheetRequisitionPermission') == 'true' && state.url.match(/edit-memo/)) ||
         
          (sessionStorage.getItem('isEditnoteSheetRequisitionPermission') == 'true' && state.url.match(/edit-note/)) ||
          (sessionStorage.getItem('isAddNoteSheetRequisition') == 'true' && state.url == '/create-note-sheet-requisition') ||
          // (sessionStorage.getItem('isAddNoteSheetRequisition') == 'true' && state.url.match('/create-note-sheet-requisition')) ||
          (sessionStorage.getItem('isViewNoteSheetRequisition') == 'true' && state.url == '/view-note-sheet-requisition')||
          
          (sessionStorage.getItem('isEditPaymentNoteRequisitionPermission') == 'true' && state.url.match(/edit-paymentnote/)) ||
          (sessionStorage.getItem('isAddPaymentNoteSheetRequisition') == 'true' && state.url == '/create-paymentnote-sheet-requisition') ||
          // Report routes office item
          (sessionStorage.getItem('rep_pendingpayment_note') == 'true' && state.url == '/rep_pendingpayment_note')||
          (sessionStorage.getItem('rep_officeitem_itemwise') == 'true' && state.url == '/rep_officeitem_itemwise')||
          (sessionStorage.getItem('rep_officeitem_employeewise') == 'true' && state.url == '/rep_officeitem_employeewise')||
          (sessionStorage.getItem('rep_officeitem_durationwise') == 'true' && state.url == '/rep_officeitem_durationwise')||
          (sessionStorage.getItem('rep_officeitem_approvalauthoritywise') == 'true' && state.url == '/rep_officeitem_approvalauthoritywise')||

          // Report routes gate pass
          (sessionStorage.getItem('rep_gatePass_itemwise') == 'true' && state.url == '/rep_gatePass_itemwise')||
          (sessionStorage.getItem('rep_gatePass_employeewise') == 'true' && state.url == '/rep_gatePass_employeewise')||
          (sessionStorage.getItem('rep_gatePass_approvalauthoritywise') == 'true' && state.url == '/rep_gatePass_approvalauthoritywise')||

          // report 
          (sessionStorage.getItem('report_meetingromm') == 'true' && state.url == '/report_meetingromm')||
          (sessionStorage.getItem('report_food') == 'true' && state.url == '/report_food')||
          (sessionStorage.getItem('report_visitingCard') == 'true' && state.url == '/report_visitingCard')||
          (sessionStorage.getItem('report_id_card') == 'true' && state.url == '/report_id_card')||
          (sessionStorage.getItem('generel_notes_report') == 'true' && state.url == '/generel_notes_report')||
            //get all pending requisiton local storage cache
            (sessionStorage.getItem('isViewPendingMemoSheetRequisitionPermission') == 'true' && state.url.match(/view-pending-memo-sheet-requisition/)) ||
            (sessionStorage.getItem('isViewPendingNoteSheetRequisitionPermission') == 'true' && state.url.match(/view-pending-note-sheet-requisition/)) ||
            (sessionStorage.getItem('isViewPendingGatePassRequisitionPermission') == 'true' && state.url.match(/view-pending-gate-pass-requisition/)) ||
            (sessionStorage.getItem('isViewPendingPoolCarRequisitionPermission') == 'true' && state.url.match(/view-pending-pool-car-requisition/)) ||
            (sessionStorage.getItem('isViewPendingOfficeItemRequisitionPermission') == 'true' && state.url.match(/view-pending-office-item-requisition/)) ||
            (sessionStorage.getItem('isViewPendingIdCardRequisitionPermission') == 'true' && state.url.match(/view-pending-id-card-requisition/)) ||
            (sessionStorage.getItem('isViewPendingVisitingCardRequisitionPermission') == 'true' && state.url.match(/view-pending-visiting-card-requisition/)) ||
            (sessionStorage.getItem('isViewPendingMettingRoomRequisitionPermission') == 'true' && state.url.match(/view-pending-meeting-room-requisition/)) ||
            (sessionStorage.getItem('isViewPendingFoodRequisitionPermission') == 'true' && state.url.match(/view-pending-food-requisition/)) ||
            state.url == '/' ||
            state.url == '/profile'
          )
        ){
        return true;
      }
  }

  checkLogin(url: string): boolean {
    if (this.userService.isLoggedIn) {//check logged in or not
      //console.log(this.userService.redirectUrl)
      // this.router.navigate([url]);
      console.log('loggedIn true',this.userService.redirectUrl);
      return true; 
    }
    this.userService.redirectUrl = url;// Store the attempted URL for redirecting
    console.log('userServiceredirectUrl',this.userService.redirectUrl);
    
    // console.log(this.authService.redirectUrl)
    this.router.navigate(['/login']); // Navigate to the login page with extras
    return false;
  }

}

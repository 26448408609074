import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Department } from '../model/department';
import { Router } from '@angular/router';
import { fuseAnimations } from '../animations';

@Component({
  selector: 'app-view-department',
  templateUrl: './view-department.component.html',
  styleUrls: ['./view-department.component.css'],
  animations : fuseAnimations
})
export class ViewDepartmentComponent implements OnInit {
  loader: boolean;
  p: number = 1;
  displayedColumns: string[] = ['Position','Department','Department_Head','Edit'];
  data:any[] = [];
  departmentList : MatTableDataSource<Department>;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true} as any) sort: MatSort;

  constructor(
    public userService:UserService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) {
    
   }

  editDepartment(id){
    console.log(id)
    this.router.navigate(['edit-department/', id]);
  }

  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.departmentList.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit() {
    this.getAllDepartment();
  }

  getAllDepartment(){
    this.loader = true;
    this.userService.getDepartmentList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data = res;
        this.data.sort((a,b)=>b.id - a.id);
        this.data.map((v,i)=>v.sn = i+1);
        this.departmentList = new MatTableDataSource<Department>(this.data);
        this.departmentList.paginator = this.paginator;
         this.departmentList.sort = this.sort;
        //this.openSnackBar("Success ", "ok");
      },err=>{
        this.loader = false;
        this.openSnackBar(" "+err.error.message, "ok");
    });
  }

  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

import { Component, OnInit, Input, ViewChild} from '@angular/core';
import {Remarks} from '../model/remarks';
import {Version} from '../model/version';
import { Router } from '@angular/router';
import { MatSnackBar, MatPaginator, MatTableDataSource} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';
import { UserService } from '../services/user.service';
// import { VersionDetailsComponent } from '../version-details/version-details.component';
import {MemoDetailsComponent} from '../memo-details/memo-details.component';
import {NoteSheetDetailsComponent} from '../note-sheet-details/note-sheet-details.component';
import { fuseAnimations } from '../animations';
import CryptoES from 'crypto-es';
import { CommonService } from '../services/common.service';
import { SharedDataService } from '../services/shared-data.service';
// import CryptoJS from 'crypto-es';
@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css'],
  animations : fuseAnimations
})
export class VersionComponent implements OnInit {
  @Input() parentInputdata: any;
  @Input() notesheetInputdata: any;
  memoTableData : MatTableDataSource<any>;
  NoteTabeData :  MatTableDataSource<any>;
  loader: boolean;
  versiondata: Version[];
  remarkdata: Remarks[];
  versionTemplate = false;
  requestTemplate = false;
  state;
  displayedColumns: string[] = ['Position','ref_no', 'Subject', 'Description', 'Details'];
  displayedColumns_note: string[] = ['Position','ref_no', 'Subject', 'Requested_By','Description', 'Details'];
  @ViewChild('TableOnePaginator', {static: false} as any) set TableOnePaginator(memoSheetPaginator: MatPaginator){
    if(this.memoTableData) {
      this.memoTableData.paginator = memoSheetPaginator;
    }
  };
  @ViewChild('TableTwoPaginator', {static: false} as any) set TableTwoPaginator (noteSheetPaginator: MatPaginator){
    if(this.NoteTabeData) {
      this.NoteTabeData.paginator = noteSheetPaginator;
    }
  };

//   @ViewChild(MatPaginator, {static: false}) set paginator(value: MatPaginator) {
//     if(this.dataSource) {
//       this.dataSource.paginator = value;
//     }
// }
   constructor( public userService: UserService,
    public snackBar: MatSnackBar,
    private router: Router,
    public memoversiondialog: MatDialog,
    public noteversiondialog: MatDialog,
    public commonService : CommonService,
    private sharedData : SharedDataService,

   ) {}

   MemoFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.memoTableData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.memoTableData.filter);
    
  }
  NoteFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.NoteTabeData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.NoteTabeData.filter);
    
  }
  ngOnInit() {
    if(this.parentInputdata){
      this.memoTableData = new MatTableDataSource<any>(this.parentInputdata);
      console.log('memoTableData',this.memoTableData);
    }
    if(this.notesheetInputdata){
         this.NoteTabeData = new MatTableDataSource<any>(this.notesheetInputdata);
    console.log('NoteTabeData',this.NoteTabeData);
    }
 
  }

// Start Methods for Memo sheet 

memoVersionDetails(id, _element) {
  this.requestTemplate = false;
  this.versionTemplate = true;
  const type = _element.requestion_type = 'memo_sheet';
  this.loader = true;
  this.userService.versionDetails(id, type).subscribe(res => {
    console.log('memoversionDetails', res);
    this.versiondata = res;
    this.loader = false;
 });
}

memoRemarksDetails(id, _element) {
  this.versionTemplate = false;
  this.requestTemplate = true;
 const type = _element.requestion_type = 'memo_sheet';
  // console.log('RequestDetails', _element);
  this.loader = true;
  this.userService.remarkDetails(id, type).subscribe(res => {
  console.log('memosheet Remark', res);
   this.remarkdata = res;
   this.loader = false;
});
}

// versionDialog(_versiondata) {
//   console.log(_versiondata);
//   this.versiondialog.open(VersionDetailsComponent, {
//     data: {
//       versiondata: _versiondata,
//     }
//   });

// }

viewMemoSheet(_element, approvecategory) {
this.loader = true;
  this.userService.getMemoDetailById(_element.id).subscribe(res => {
    this.memoversiondialog.open(MemoDetailsComponent, {
        data: {
            detail: res,
            main: _element,
            approvecategory: approvecategory
        }
    });
    this.loader = false;
},err=>{
  this.snackBar.open('something went wrong'+ err.error.message ? err.error.message : err.error );
  this.loader = false;
});

}
viewNoteSheet(_element, approvecategory){
this.loader = true;
  this.userService.getNoteDetailById(_element.id).subscribe(res => {
    this.memoversiondialog.open(NoteSheetDetailsComponent, {
        data: {
          requestResponse: res,   
          main_row: _element,
          approvecategory: approvecategory,
        
        }
    });
    this.loader = false;
},err=>{
  this.snackBar.open('something went wrong'+ err.error.message ? err.error.message : err.error,'ok',{
    duration : 5000
  });
  this.loader =false
});
}

generate(element) {
  let transferObj = {
    subject: element.subject,
    reference : element.ref_no,
    created_by : element.created_by,
    requested_by : element.requested_by,
    budget_amount : element.budget_amount,
    amount : element.amount,
    memo_id : element.id
  }
  this.sharedData.supplyData(transferObj);
  this.router.navigate(['/create-note-sheet-requisition']); 
  // this.commonService.MemoDataPass.emit(transferObj);
  // let encryptedData = CryptoES.AES.encrypt(JSON.stringify(transferObj), "mahbub").toString();
  // this.router.navigate(['/create-note-sheet-requisition'],{ queryParams:{memo: encryptedData}}); 
}

memoSheetversionDialog(_element, approvecategory) {
  this.loader = true;
  this.userService.getMemoDetailById(_element.id).subscribe(res => {
      console.log('show ALL ', res);
      this.memoversiondialog.open(MemoDetailsComponent, {
          data: {
              detail: res,
              main: _element,
              approvecategory: approvecategory,

          }
      });
      this.loader =false;
  });
}

editMemo(id) {
  this.router.navigate(['edit-memo/',id]);
  console.log('Memo Edit Id',id);
}

// Start Method for Note sheet 
noteVersionDetails(id, _element) {
  this.requestTemplate = false;
  this.versionTemplate = true;
  const type = _element.requestion_type = 'note_sheet';
  this.loader = true;
  this.userService.versionDetails(id, type).subscribe(res => {
    console.log('noteVersionDetails', res);
    this.versiondata = res;
    this.loader = false;
 });
}

noteRemarksDetails(id, _element) {
  this.versionTemplate = false;
  this.requestTemplate = true;
 const type = _element.requestion_type = 'note_sheet';
  // console.log('RequestDetails', _element);
  this.loader = true;
  this.userService.remarkDetails(id, type).subscribe(res => {
  console.log('noteSheet Remark', res);
   this.remarkdata = res;
   this.loader = false;
});
}

// versionDialog(_versiondata) {
//   console.log(_versiondata);
//   this.versiondialog.open(VersionDetailsComponent, {
//     data: {
//       versiondata: _versiondata,
//     }
//   });

// }

noteVersionDialog(_element, approvecategory) {
  this.loader = true;
  this.userService.getNoteDetailById(_element.id).subscribe(res => {
      console.log('Note Dialog Detail', res);
      this.noteversiondialog.open(NoteSheetDetailsComponent, {
          data: {
              detail: res,
              main: _element,
              approvecategory: approvecategory,
          }
          
      });
      this.loader = false;
  });
}

editNote(id) {
  this.router.navigate(['edit-note/',id]);
  console.log('NoteEdit Id',id);
}

text_collapse:boolean = true;
expandText(){
  if(this.text_collapse == true){
    this.text_collapse = false;
  }
  else{
    this.text_collapse = true;
  }
}

}

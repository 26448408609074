import { Component, OnInit } from '@angular/core';
import { Designation } from '../model/designation';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FormBuilder, FormArray, Validators, FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RequisitionService } from '../services/requisition.service';
import { SafePipeComponent } from '../pipe/pipe.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
// import CryptoJS from 'crypto-js';
import CryptoES from 'crypto-es';
import { SharedDataService } from '../services/shared-data.service';
import { async } from '@angular/core/testing';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { RefDetailsDialogComponent } from '../ref-details-dialog/ref-details-dialog.component';
import { currency } from '../json_data';

@Component({
    selector: 'app-create-note-sheet-requisition',
    templateUrl: './create-note-sheet-requisition.component.html',
    styleUrls: ['./create-note-sheet-requisition.component.css']
})
export class CreateNoteSheetRequisitionComponent implements OnInit {
    loader: boolean;
    notesheetForm: FormGroup;
    myControl = new FormControl();
    notesheetId: number;
    notesheetEdit: boolean;
    designationList: Designation[];
    employeeList$ = [];
    emplist: any;
    filteredOptions: Observable<any[]>[] = [];
    notesheetData: any;
    files: any;
    url: any;
    fileUrl: string | ArrayBuffer;
    fileArray:any[]= [];
    selectedEmployee: any;
    Expension_Head :[];
    MemId;
    referelKey: string;
    filteredOption: Observable<any>;
    paymentNoteId:any;
    budgetLimit: any;
    generateFormMemo: boolean = false;
    memo_id: any;
    accounts_list:any[]=[];
    accounts_person_id: any;
    accounts_spinner: boolean;
    spinner: boolean;
    file_delete_option: boolean;
    editFiles:any[] = [];
    deletedFiles:any[]=[];
    progress: number = 0;
    ref_no_For_backend: string;
    CurrArray :any = currency;
    paymentNoteCreate: boolean;
    approveeditemp:any=[];
    editdata:any=[];
    deleteon:boolean=true;
    editempform:any=[];
    approvarDeleteList:any=[];
    constructor(
        public userService: UserService,
        public requisitionService: RequisitionService,
        public snackBar: MatSnackBar,
        private fb: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
        private sharedService : SharedDataService,
        private preview_dialog : MatDialog,
        private ref_dialog : MatDialog

    ) {

    }

   issame:boolean=false;
   acc:boolean=false;
    SelectedAccounts(data){
        this.issame=false;
        // console.log('newvalue',this.selectedEmployee)

        console.log('selected accounts_person',data);
       
        if(data!= null){
         
          this.acc=true;
        }
        if(this.selectedEmployee.id === data){
            console.log('found')
            this.issame = true;
        }
        if(this.newemp !== undefined)
        {
            for(let i = 0; i<this.newemp.length; i++)
            {
                    if(this.newemp[i].employee_id.id === data)
                    {
                    // console.log('found')
                    this.issame = true;
                      break;
                      
                    }
               
                }
   
            }

            this.notesheetForm.get('accounts_person').setValue(data);

    }
    get employeeForms() {
        return this.notesheetForm.get('employeeName') as FormArray;
    }
    newemp:any
    // addMoreempItem() {
    //     this.isadd=false;
    //     this.employeeForms.push(this.addempItem());
    //     console.log('empv', this.employeeForms.value)
    //     this.newemp = this.employeeForms.value;
    //     this.employListAutoCompleteControl(this.employeeForms.length - 1);
    // }
    addMoreempItem() {
        this.isadd=false;
        if(this.notesheetEdit){
          
          this.employeeForms.push(this.addempItem());
          this.newemp = this.employeeForms.value;
          console.log('edit newemp',this.newemp)
          this.employListAutoCompleteControl(this.employeeForms.length - 1);
          if(this.newemp){
            for(let i=0;i<=this.editempform.length;i++){
              for(let j=0;j<=this.newemp.length;j++){
                if(this.editempform[i]==this.newemp[j].employee_id.id){
                  this.isok=true;
                }
                else{
                  this.isok = false;
                }
                if(!this.isok && this.newemp[j].employee_id !=''){
                  this.editempform.push(this.newemp[j].employee_id.id)
                  this.approveeditemp.push(this.newemp[j].employee_id.id)
                  console.log('edit entry',this.editempform)
          
                }
              }
              
            }
          
          }
          
        }
        if(!this.notesheetEdit){
        this.employeeForms.push(this.addempItem());
        // console.log('empv', this.employeeForms.value)
        this.newemp = this.employeeForms.value;
        this.employListAutoCompleteControl(this.employeeForms.length - 1);
      }
      }

    addempItem() {
      if(this.editNotesheet){
        return this.fb.group({
          employee_id: ['']
      });
      }else if(!this.editNotesheet){
        return this.fb.group({
          employee_id: ['', Validators.required]
      });

      }
       
    }

    deleteItem(i) {
        this.issame=false;
        console.log('deleteIndex', i);
        this.filteredOptions.splice(i, 1);
    }
    deleteempItem(i) {
        this.isok=false;
        const employeeArraySize = this.employeeForms.length - 1;
        if (employeeArraySize === 0) {
            this.notesheetForm.patchValue({
                employee_id: ''
            });
        }
        this.employeeForms.removeAt(i);
    }

delar:any=[]
x:boolean=false;
editdeleteempItem(i) {
   
  for(let j=0;j<=this.editdata.requisition_details.length;j++){
    if( i.approval_status!=1
      && i.approved_by== this.editdata.requisition_details[j].approved_by){
       this.x=true;
      
     this.editempform.splice(j, 1)
     console.log('editempform',this.editempform)
    //  this.approvarDeleteList.push(this.editempform.splice(j,1)[0]) 
     this.approvarDeleteList.push(this.editdata.requisition_details.splice(j,1)[0]) 
    //  this.newapprovarDeleteList.push(this.approvaldetails.approve_info.splice(j,1)[0]) 
    
     console.log('approvarDeleteList',this.approvarDeleteList)
    }
       if (!this.delar.some(el => el.id === this.editdata.requisition_details.id)) {
      this.delar.push(this.editdata.requisition_details);
      console.log('delar',this.editdata.requisition_details)
    }
  }
   
   
    
}
    createNotesheet(data) {/* department save service */
        this.loader = true;
        this.requisitionService.notesheetCreate(data).subscribe((event: HttpEvent<any>) => {
            // this.loader = false;
            switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request has been made!');
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  console.log(`Uploaded! ${this.progress}%`);

                  break;
                case HttpEventType.Response:
                  console.log('User successfully created!', event.body);
                //   this.snackBar.open('File uploaded Successfull','ok');
                  setTimeout(() => {
                    this.progress = 0;
                    this.openSnackBar('successfully created! ', 'ok');
                    this.loader = false;
                    this.router.navigate(['view-note-sheet-requisition']);
                  }, 1500);

              }


        }, err => {
            // this.loader = false;
            this.snackBar.open(' ' + err.error.message ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
        });
        // this.router.navigate(['view-notesheet']);

    }
    isok:boolean= false;
    isadd:boolean= false;
    // getEmployee(value) {
    //     this.isadd = true;
    //     this.selectedEmployee = value;
    //     console.log('val',value)
    //     if(this.newemp !== undefined)
    //     {
    //         for(let i = 0; i<this.newemp.length; i++)
    //         {
    //                 if(this.newemp[i].employee_id.id === value.id)
    //                 {
    //                   this.isok=true;
    //                   break;
    //                 }
    //                 else{
    //                     this.isok=false;
    //                     continue;
    //                 }
               
    //             }
   
    //         }
    // }
    getEmployee(value) {
        this.selectedEmployee = value;
        this.isadd = true;// new add
        console.log('value', value)
        if(this.notesheetEdit){
       
  
          for(let i=0; i<=this.approveeditemp.length; i++){
            if(this.approveeditemp[i]==value.id){
              console.log(this.approveeditemp[i],value.id)
              console.log('found')
              this.isok = true;
                break;
  
            }
            else{
              this.isok = false;
         
            }
            // if(!this.isok){
            //   console.log('push')
            //   this.editempform.push(value.id)
            //   console.log('rem',this.editempform)
            // }
          }
          if(this.approvarDeleteList){
              for(let j=0;j<=this.approvarDeleteList.length;j++){
                if(this.approvarDeleteList[j].approved_by == value.id){
                  this.isok = false;
                  this.editempform.push(value.id)
                  this.approveeditemp.push(value.id)
                  this.approvarDeleteList.splice(j, 1)
                   console.log('rem',this.editempform)
                   console.log('rem1',this.approveeditemp)
                
                }
                
              }
              if(!this.isok){
                console.log('push')
                
              }
         
            }
           
         
        }else{
            if(this.editempform.indexOf(value.id) == -1){
            this.editempform.push(value.id)
            this.isok= false;
          } 
          else{
            this.isok = true; 
          }
          // if(this.approvaldetails.)
          
          
        
       
        console.log('editenter',this.editempform) 
        console.log('data',this.editdata)
  
          if(this.selectedEmployee.id === this.editdata)
          {
              console.log('found')
              this.isok = true;   
              
          }
              
          if(this.newemp !== undefined )
          {
              for(let i = 0; i<this.newemp.length; i++)
              {
                  
                      if(this.newemp[i].employee_id.id === value.id)
                      {
                        this.isok=true;
                        break;
                      }
                      
                      else{
                          this.isok=false;
                          continue;
                      }
                 
                  }
  
                 
                  
              }
        }
  
          // if(this.approvaldetails.approve_info.indexOf(value.id)== -1){
          //   this.editempform.push(value.id)
          //   console.log('rem',this.editempform)
          // }
        
              // if(this.NoteEdit && this.newemp !== undefined){
  
              // }
          }
    getErrorMessage(){
        let inputBudget = this.notesheetForm.get('amount').value;
        if(this.budgetLimit && inputBudget>this.budgetLimit){
            this.snackBar.open('The amount will not be exceed the budgeted amount,The budget amount is ' + this.budgetLimit,'ok');
            this.notesheetForm.get('amount').setValue(this.budgetLimit);
        }else{
            return false;
        }
    }
    notesheetFormSubmit(status:any) {
        if(this.notesheetForm.invalid){
            this.snackBar.open('Please fill up all required field,then try again','ok');
        }
        else{
        console.log('form Value',this.notesheetForm.value,'myControl',this.myControl.value.id);

        if (this.notesheetEdit) {
            console.log("EDIT",this.notesheetForm.value);
            const fd = new FormData();
            if(this.generateFormMemo){
                fd.append('memo_id', this.memo_id);
            }

            // fd.append('ref_no', this.referelKey);
            fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
            fd.append('amount', this.notesheetForm.value.amount);
            fd.append('cur_type', this.notesheetForm.value.cur_type);
            fd.append('subject', this.notesheetForm.value.subject);
            fd.append('tin', this.notesheetForm.value.tin);
            fd.append('bin_nid', this.notesheetForm.value.bin_nid);
            fd.append('pay_to', this.myControl.value.f_name ? this.myControl.value.f_name : this.myControl.value);
            fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
            fd.append('payee_address', this.notesheetForm.value.payee_address);
            fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
            // fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
            // fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
            // fd.append('routing_no', this.notesheetForm.value.routing_no);
            fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
            fd.append('purpose', this.notesheetForm.value.purpose);
            fd.append('requisition_id', this.paymentNoteId);
            fd.append('status',status);
            fd.append('deletedFile',JSON.stringify(this.deletedFiles));
            if (this.fileArray) {
                if (this.fileArray.length > 0) {
                    for (let x = 0; x < this.fileArray.length; x++) {
                        fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                    }
                }
            }
            fd.append('employeeName', JSON.stringify(this.employeeForms.value));
            fd.append('editapproval_list',JSON.stringify(this.editempform));
            fd.append('approvars',JSON.stringify(this.delar))
            fd.append('approvarDeleteList',JSON.stringify(this.approvarDeleteList)) 
              // console.log('PaymentFormData',fd);
            this.editNotesheet(fd);
            console.log('PaymentFormData',fd);
        } else {

            const fd = new FormData();
            if(this.generateFormMemo){
                fd.append('memo_id', this.memo_id);
                fd.append('genFormMemo', '1');
                fd.append('ref_no', this.referelKey);
            }else{
                fd.append('genFormMemo', '0');
                fd.append('ref_no', this.ref_no_For_backend);
            }
            const fc = this.fb.group({
                employee_id: [{id : this.notesheetForm.value.accounts_person, check_issue : 1}],
              })
            this.employeeForms.push(fc);
            console.log('employeforms_value',this.employeeForms.value);
            fd.append('employeeName', JSON.stringify(this.employeeForms.value));
          
           
            
            fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
            fd.append('pay_to', this.myControl.value.f_name ? this.myControl.value.f_name : this.myControl.value);
            fd.append('amount', this.notesheetForm.value.amount);
            fd.append('cur_type', this.notesheetForm.value.cur_type);
            fd.append('subject', this.notesheetForm.value.subject);
            fd.append('tin', this.notesheetForm.value.tin);
            fd.append('bin_nid', this.notesheetForm.value.bin_nid);
            fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
            fd.append('payee_address', this.notesheetForm.value.payee_address);
            // fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
            // fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
            // fd.append('routing_no', this.notesheetForm.value.routing_no);
            fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
            fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
            fd.append('purpose', this.notesheetForm.value.purpose);
            fd.append('paynote_id', this.MemId);
            fd.append('status',status);
            if (this.fileArray) {
                if (this.fileArray.length > 0) {
                    for (let x = 0; x < this.fileArray.length; x++) {
                        fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                    }
                }
            }
            console.log('PaymentFormData',fd);
            this.createNotesheet(fd);
        }
    }
}


    editNotesheet(data) {/* department save service */
        this.loader = true;
         this.userService.editNote(data).subscribe((event: HttpEvent<any>) => {

            switch (event.type) {
                case HttpEventType.Sent:
                  console.log('Request has been made!');
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  console.log(`Uploaded! ${this.progress}%`);
                  break;
                case HttpEventType.Response:
                  console.log('User successfully created!', event.body);
                  this.openSnackBar('User successfully created! ', 'ok');
                  this.loader = false;
                  this.router.navigate(['view-note-sheet-requisition']);
                  setTimeout(() => {
                    this.progress = 0;
                  }, 1500);

              }
        }, err => {
            this.loader = false;
            if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
        });
    }
    deleteFile(index){
        console.log('index',index);
        this.fileArray.splice(index,1);

    }


    onFileInput(event: any) {
        this.files = event.target.files;
        if (event.target.files && event.target.files[0]) {

            for (let item = 0; item < this.files.length; item++) {

                const reader = new FileReader();
                reader.readAsDataURL(this.files[item]);
                reader.onload = (_event: any) => {
                    this.fileUrl = _event.target.result;
                    if(this.files[item].size > 358400){
                        this.snackBar.open('File size limit exceed,your file size is  ' + (this.files[item].size/1024).toFixed(2)+' KB, Upload file size will not be exceed 350 KB','ok',{duration:10000});
                    }else{
                        this.fileArray.push({
                            files : this.files[item],
                            fileName: this.files[item].name,
                            fileUrl: this.fileUrl
                        });
                    }
                };
            }
            console.log('filesArray',this.fileArray);
            // console.log(this.fileArray);
        }
        console.log('files',this.files);

    }

    employListAutoCompleteControl(index: number) {
        const arrayControl = this.notesheetForm.get('employeeName') as FormArray;
        this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.f_name),
                map(name => name ? this._filter(name) : this.employeeList$.slice())
            );

    }

    private _filter(value: any): string[] {
        const filterValue = value.toLowerCase();
        return this.employeeList$.filter(option =>{
            return option.f_name.toLowerCase().indexOf(filterValue) !== -1 ||  option.pf_no == value;
        })

    }
    displayFn(data?: any): string | undefined {
        return data ? data.f_name : undefined;
    }


// Note sheet Edit Methods
getSpecificNote(noteId) {
    this.loader = true;
    this.userService.getParticualrNote(noteId).subscribe(data => {
        this.loader = false;
             console.log('Paymentnote Edit data',data);
             this.editFiles = data.file_info;
             this.notesheetForm.patchValue({
                apply_date : data.requisition_info[0].apply_date,
                amount: data.requisition_info[0].amount,
                cur_type : data.requisition_info[0].cur_type,
                subject: data.requisition_info[0].subject,
                Payee_account_no: data.requisition_info[0].Payee_account_no,
                payee_address: data.requisition_info[0].payee_address,
                // payee_bankname: data.requisition_info[0].payee_bankname,
                // payee_branchname: data.requisition_info[0].payee_branchname,
                // routing_no: data.requisition_info[0].routing_no,
                vendor_bill_no: data.requisition_info[0].vendor_bill_no,
                vendor_bill_date: data.requisition_info[0].vendor_bill_date,
                purpose: data.requisition_info[0].purpose,
                tin : data.requisition_info[0].tin,
                bin_nid : data.requisition_info[0].bin_nid,
        });
        let pay_to = {id : null, f_name: data.requisition_info[0].pay_to}
        this.myControl.setValue(pay_to);
        this.referelKey = data.requisition_info[0].ref_no;
        this.notesheetForm.get('vendor_bill_date').setValue(new Date(data.requisition_info[0].vendor_bill_date));
      }, error => {
        this.loader = false;
        this.openSnackBar('Internal error' + error.error.message, 'ok');
        console.log(error);
      });
  }
    deleteEditFile(data:any,index){
      this.deletedFiles.push(data);
      this.editFiles.splice(index,1);
    }
    download(f){
        const link = document.createElement('a');
        const downloadUrl = f;
        link.setAttribute('href',  downloadUrl);
        link.setAttribute('download', 'FileName');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    previewFile(f) {
    const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
            data : {url : f}
        })
        dialog_ref.afterClosed().subscribe(res=>{
            console.log('preview_closed',res);
        })
    }
  generateKey(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  autocomplete(){
    this.filteredOption= this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.f_name),
      map(name => name ? this._filter(name) : this.employeeList$.slice())
    );
  }
  headsinfo:any;
  thousand_separator(num){
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}
  getMemoData() {
     const subscribe =  this.sharedService.DeliveryData.subscribe((routeDycriptData)=>{
            console.log('data',routeDycriptData);
            if(routeDycriptData.id){
                this.generateFormMemo = true;
                this.referelKey = routeDycriptData.ref_no;
                this.myControl.setValue({id : routeDycriptData.requested_by, f_name : routeDycriptData.created_by});
                this.budgetLimit = routeDycriptData.budget_amount;
                this.notesheetForm.get('subject').setValue(routeDycriptData.subject);
                this.notesheetForm.get('tin').setValue(routeDycriptData.tin);
                this.notesheetForm.get('bin_nid').setValue(routeDycriptData.bin_nid);
                this.notesheetForm.get('amount').setValue(routeDycriptData.amount);
                this.memo_id = routeDycriptData.id;
                this.headsinfo = {
                    head_title : routeDycriptData.head_title,
                    budget : routeDycriptData.budget,
                    expense  : routeDycriptData.expense,
                    amount : routeDycriptData.amount,
                    remaining_balance : routeDycriptData.remaining_balance,
                    remaining_balance_percentage : routeDycriptData.remaining_balance_percentage
                }

            }


    });
    this.sharedService.supplyData({});
    subscribe.unsubscribe();
}
getAccounts_persons(){
    this.accounts_spinner = true;
    this.userService.getAccountsPerson().subscribe(res=>{
        this.accounts_list = res;
        console.log('accounts_personList',this.accounts_list);
        this.accounts_spinner = false;

    },err=>{
        this.accounts_spinner = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error));
    })
}


getReferance(){
    this.userService.get_referance('payment_note').subscribe(res=> {
        console.log('ref_id_backend',res);
        if(res.id){
            var str = "" +  ++res.id;
        }else{
            var str = "" + 1;
        }
        let date = new Date();
        var pad = "0000"
        var ans = pad.substring(0, pad.length - str.length) + str
        const refFormat = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'PaymentMemo'+ '/' + ans;
        const refFor_back = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'PaymentMemo'+ '/';
        if(this.generateFormMemo === false){
            this.referelKey  = refFormat;
            this.ref_no_For_backend =refFor_back;
        }
        console.log('referelKey',this.referelKey);
         });
    }
    view_ref_details(ref){
        console.log('ref_work',ref);
        const dialog_ref = this.ref_dialog.open(RefDetailsDialogComponent,{
            data : {ref_no : ref}
        })
        dialog_ref.afterClosed().subscribe(res=>{
            console.log('diloag_close',res);

        })
    }

    ngOnInit() {
       
        this.getAccounts_persons();
        if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
            this.paymentNoteId = +this.route.snapshot.paramMap.get('id');
            this.notesheetEdit = true;
            this.notesheetForm = this.fb.group({
                apply_date : [''],
                subject : ['',Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                amount: ['', Validators.required],
                cur_type : ['', Validators.required],
                Payee_account_no: ['', Validators.required],
                payee_address: [''],
                // payee_bankname:[''],
                // payee_branchname:[''],
                // routing_no:[''],
                vendor_bill_no: ['' ],
                vendor_bill_date: [''],
                purpose: [''],
                accounts_person : [''],
                employeeName: this.fb.array([this.addempItem()]),
                files: [null]
            });
           this.getSpecificNote(this.paymentNoteId);
           this.userService.getParticualrNote(this.paymentNoteId).subscribe(data=>{
                this.editdata=data;
                console.log('edit data',this.editdata)
                if(this.editdata.requisition_info){
                    this.notesheetEdit = true;
                    if(this.editdata.requisition_details.approval_status == 1){
                      this.deleteon = false;
                  }
                  else{
                    this.deleteon = true;
                  }
                  if(this.editdata.requisition_details.length>0){
                    for( let i=0;i<this.editdata.requisition_details.length;i++){
                      if(this.editdata.requisition_details[i].approval_status!=1){
                        this.editempform.push(this.editdata.requisition_details[i].approved_by)
                        console.log('empnew',this.editempform)
                         }
                       this.approveeditemp.push(this.editdata.requisition_details[i].approved_by)
                       console.log('approveeditemp',this.approveeditemp)
                    }
                    
                  }
                }

           })
           
        } else {
            this.getReferance();
            this.notesheetForm = this.fb.group({
                apply_date : [''],
                subject : ['',Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                amount: ['', Validators.required],
                cur_type : ['', Validators.required],
                Payee_account_no: ['', Validators.required],
                payee_address: [''],
                // payee_bankname:[''],
                // payee_branchname:[''],
                // routing_no:[''],
                vendor_bill_no: ['' ],
                vendor_bill_date: [''],
                purpose: [''],
                accounts_person : ['',Validators.required],
                employeeName: this.fb.array([this.addempItem()]),
                files: [null]
            });
            
        }
        this.employListAutoCompleteControl(0);

    //    this.snackBar.open('Please waiting for user data loading...','ok');
        this.spinner = true;
        this.userService.getUserList().subscribe((res) => {
            this.employeeList$ = res;
            console.log('userList',this.employeeList$);
            this.spinner = false;
            // this.myControl.setValidators(this.forbiddenNamesValidator(this.employeeList$));
            // this.snackBar.dismiss();

        });
        this.autocomplete();
        this.getMemoData();

        // let memoData:any = JSON.parse(this.route.snapshot.queryParamMap.get('memo'));
        //  let routeDycriptData =JSON.parse(CryptoES.AES.decrypt(memoData,"mahbub").toString(CryptoES.enc.Utf8));
        // console.log('memodata',routeDycriptData);
        // if(memoData){
        //     this.generateFormMemo = true;
        //     this.referelKey = memoData.reference;
        //     this.myControl.setValue({id : memoData.requested_by, f_name : memoData.created_by});
        //     this.budgetLimit = memoData.budget_amount;
        //     this.notesheetForm.get('subject').setValue(memoData.subject);
        //     this.memo_id = memoData.memo_id;
        // }
        // if(routeDycriptData){
        //     this.generateFormMemo = true;
        //     this.referelKey = routeDycriptData.reference;
        //     this.myControl.setValue({id : routeDycriptData.requested_by, f_name : routeDycriptData.created_by});
        //     this.budgetLimit = routeDycriptData.budget_amount;
        //     this.notesheetForm.get('subject').setValue(routeDycriptData.subject);
        //     this.memo_id = routeDycriptData.memo_id;
        // }

    }
    forbiddenNamesValidator(names:any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          // below findIndex will check if control.value is equal to one of our options or not
          const index = names.findIndex(name => {
            return (new RegExp('\^' + name.f_name + '\$')).test(control.value.f_name);
          });
          return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
        };
      }





    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            horizontalPosition : "end",
        });
    }

}


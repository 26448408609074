import { RequisitionService } from '../services/requisition.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Gatepass } from '../model/gatePass';

@Component({
  selector: 'app-details-gate-pass-requisition',
  templateUrl: './details-gate-pass-requisition.component.html',
  styleUrls: ['./details-gate-pass-requisition.component.css']
})
export class DetailsGatePassRequisitionComponent implements OnInit {
  gatePass: Gatepass[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public requisitionService: RequisitionService,
  ) {
    console.log(data.id);
    this.getSpecificGetPass(data.id);
  }

  ngOnInit() {
  }

  getSpecificGetPass(id) {
    // this.loader = true;
    this.requisitionService.getSpecificGetPass(id)
      .subscribe(data => {
        // this.loader = false;
        this.gatePass = data;
        console.log(data);
      }, error => {
        // this.loader = false;
        console.log(error);
      });
  }

}

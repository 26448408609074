import { CommonService } from './../services/common.service';
import { Component, OnInit} from '@angular/core';
import { dummy } from '../commonUrl';
import { MatSnackBar, MatDialog } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { PoolCarRequisition } from '../model/poolCarRequisition';
import { Observable} from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-view-pool-car-requisition',
    templateUrl: './view-pool-car-requisition.component.html',
    styleUrls: ['./view-pool-car-requisition.component.css']
})
export class ViewPoolCarRequisitionComponent implements OnInit{
    Dummyurl = dummy;
    loader: boolean;
    p = 1;
    template:boolean = true;
    PoolCarUserRequisitionList: PoolCarRequisition[];
    //  PoolCarRequisitionApprovalList: PoolCarRequisition[];
    poolCarRequisitionRecomandationList: PoolCarRequisition[];
    displayedPoolCarUserRequisitionColumns: string[] = ['Position', 'Requested', 'Recomanded', 'Status'];
    // displayedPoolCarRequisitionApprovalColumns: string[] = ['Position','Requested','Recomanded','Status','Action'];
    displayedPoolCarRequisitionRecomandationColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    requisitions: any;
    config: any = {
        url: 'pool_car',
        final: 'user_requisition_for_final',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName: 'car_requisitions'
    } ;

  
    finalPoolCarRequisition$: Observable<PoolCarRequisition[]>;
    approvalPoolCarRequisition$: Observable<PoolCarRequisition[]>;
    recommendPoolCarRequisition$: Observable<PoolCarRequisition[]>;
    ownerPoolCarRequisition$: Observable<PoolCarRequisition[]>;
    tabIndex: number=0;
  //  adminPoolCarRequisition$: Observable<Gatepass[]>;

    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        private userService : UserService,
        public dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService
    ) { }
getAllrequistion(){
         // this.finalPoolCarRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.final);
         this.approvalPoolCarRequisition$    = this.requisitionService.requisitionList(this.config.url, this.config.approve);
         this.recommendPoolCarRequisition$   = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
         this.ownerPoolCarRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.owner);
    // adminPoolCarRequisition$ = this.requisitionService.gatePassRequisitionList();
}
    ngOnInit() {
        this.getAllrequistion();
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }
    }
  
    giveApprove(data) {
        this.loader = true;
      this.requisitionService.requisitionApprove(data).subscribe(res => {
        console.log(res);
        this.loader = false;
        this.openSnackBar('Successfull', 'ok');
        this.getAllrequistion();
      }, err => {
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
            duration : 5000
        })
        this.getAllrequistion();
      });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    delegate(data:any, approveCategory = '') {
        const testData = {
             requisition_id: data.id,
             approvecategory: approveCategory,
             tableName : this.config.tableName,
             req_type : 'pool_car'
         };
     const dialog_ref =   this.dialog.open(DelegateModalComponent, {
                 data: testData
             });
             dialog_ref.afterClosed().subscribe(res=>{
               this.getAllrequistion();
               console.log('aftercloseddata',res);
               
             })
     }


    showDetail(data, approveCategory = '') {
         console.log('here', data);
        const testData = {
            requisition_id: data.id,
            To: data.final_approved_by,
            Date: data.created_at,
            // Subject: data.subject,
            use_date: data.use_date,
            place_from: data.place_from,
            place_to: data.place_to,
            time_from: data.time_from,
            time_to: data.time_to,
            Description: data.subject,
            Approve_by: data.approved_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            final_approval_status: data.final_status,
            recommendation_status: data.recommendation_status,
            approvecategory: approveCategory,
            requested_by: data.requested_by,
            requested_from: data.requested_from,
            url: this.config.url,
            approve_sig: data.approved_by_sig,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,
            vehicleinfo : []

        };
        this.loader = true;
        this.userService.getParticularVehicle(data.vehicle_id).subscribe(res=>{
            testData.vehicleinfo = res;
            const dialog_ref = this.dialog.open(DialogComponent, {
                data: testData
            });
            dialog_ref.afterClosed().subscribe(res=>{
                if(res !== 'reload'){
                    this.giveApprove(res);
                }
                console.log('afterclosedData',res);
                
            })
            this.loader =false;
        },err=>{
            this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
                duration : 5000
            })
        })
    //  console.log('After', testData);
       
    }


    assignCarpool(data) {
        console.log('assign carpool',data);
        this.router.navigate(['/pool-car-requisition-details/' + data.id ]);
    }
}

import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { OfficeItem } from '../model/officeItem';
import { MatSnackBar, MatDialog} from '@angular/material';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { User } from '../model/user';
import { RequisitionService } from '../services/requisition.service';
import { DatePipe, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../animations';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';

@Component({
  selector: 'app-create-office-item-requisition',
  templateUrl: './create-office-item-requisition.component.html',
  styleUrls: ['./create-office-item-requisition.component.css'],
  animations : fuseAnimations
})
export class CreateOfficeItemRequisitionComponent implements OnInit {
  loader: boolean;
  officeItemRequisitioForm: FormGroup;
  employee: User[];
  categoryList: any = [];
  ItemList: any;
  displayColumn:string[] = ['Category Name','Items','Last Supply Date','Reason'];
  lastSupplydate: any;
  officeItemId: number;

  // for file input
  files: any;
  url: any;
  fileUrl: string | ArrayBuffer;
  fileArray: any = [];
  officeItemEdit: boolean;
  speceficData: any;
  downloadbtn: boolean = false;
  referelKey:any;
  deletedFiles:any[] = [];

  constructor(
    public userService: UserService,
    public requisitionService: RequisitionService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    public router: Router,
  public route: ActivatedRoute,
  public preview_dialog: MatDialog
    ) { }

  
    showcloud(){
      if(this.downloadbtn == false){
        this.downloadbtn =true;
      }
      else{
        this.downloadbtn = false;
      }

      
    }

    getReferance(){
          
      this.userService.get_referance('office_item').subscribe(res=> {
          console.log('ref_id_backend',res);
          if(res.id){
              var str = "" + ++res.id;
          }else{
              var str = "" + 1;
          }
          let date = new Date();
          var pad = "0000"
          var ans = pad.substring(0, pad.length - str.length) + str
          const refFormat = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'OfficeItem'+ '/' + ans;
          this.referelKey  = refFormat;
          console.log('referelKey',this.referelKey);
           });
      }
    getAllOfficeItemCategory(){
      // this.loader = true;
      this.userService.getOfficeItemCategory().subscribe( res =>{
          console.log(res)
          this.loader = false;
          this.categoryList = res;
          console.log('categorylist',this.categoryList);
        },err=>{
          // this.loader = false;
          this.openSnackBar(err.error.message ? err.error.message : err.error ,"ok");
      });
    }
    openadd:boolean= false;
    officeItem:any;
    data:any;
    onclick:boolean= false;
   
    ctgChange(event,officeItem){
      // console.log('hlw')
      this.onclick=true;
      // this.addtable = false;
      // if(this.officeItemEdit){
      //   console.log('edititem',officeItem)
       
      // }
      // else{
      while (officeItem.get("items").length !== 0) {
        officeItem.get("items").removeAt(0)
      }
      this.loader = true;
      let data = event.value;
      // console.log('n',data)
      officeItem.get('category_title').setValue(data.category_title);
      officeItem.get('category_id').setValue(data.id);
      this.userService.CategoryWiseItem(data.id).subscribe(res=>{
        this.ItemList = res;
        // console.log('ItemList',this.ItemList);
        
        if(this.ItemList){
          this.ItemList.forEach(element => {
           let fc = this.fb.group({
              name : [element.title],
              item_id : [element.item_id],
              quantity : [''],
              last_supply_date : [element.last_supply_date],
              unit:[element.unit],
              remarks:[element.remarks]
            })
            officeItem.get("items").push(fc);
          });
        }
        console.log('items',this.items());
        this.loader = false;
      },err=>this.openSnackBar(err.error.message ? err.error.message : err.error ,"ok")
      )
    
    // }
    
    }
  
 officeItems() : FormGroup {
    return this.fb.group({
      category_id: [''],
      category : [''],
      category_title : [''],
      items : this.fb.array([]),
      dateOfuse: [''],
      reason: [''],
    });
  }

 items() : FormGroup {
    return this.fb.group({
      name : ['test'],
      item_id : [''],
      quantity : [''],
      last_supply_date : [''],
      unit:[''],
      remarks:[''],
    })
}
gotadd(){
  // console.log('hi')
  this.openadd =true;
  this.addtable = true;
  
}
addtable:boolean= false;
 
  addofficeItems() {
   
    this.openadd=false;
    
    // this.addtable = true;
    (this.officeItemRequisitioForm.get('officeItems') as FormArray).push(this.officeItems())
    console.log('controls',(this.officeItemRequisitioForm.get('officeItems') as FormArray).controls);
   
  }
  deleteofficeItems(index) {
    this.openadd=true;
    (this.officeItemRequisitioForm.get("officeItems") as FormArray).removeAt(index);
  }
  additems(officeItems) {
  console.log ('uuuuu',officeItems.get("items").push(this.items()));
  }
  
  deleteitems(officeItems, index) {
    officeItems.get("items").removeAt(index);
  }


  get officeItemControl () {
    return this.officeItemRequisitioForm.get('officeItems') as FormArray  ;
    
  }
itemid:any=[]
edititemid:any=[]
editFiles:any[]=[]
getSpecificOfficeItemId(officeItemId) {
    // console.log('edit',officeItemId)
    // console.log('itemli',this.ItemList)
 
    this.loader = true;
    this.userService.getSpeceficEditRequistionDetails(officeItemId).subscribe(res => {
        console.log('specific items',res)
        this.editFiles = res.file_info;
        this.loader = false;
        this.speceficData = res;
        this.referelKey = res.requisition_info.ref_no;
        this.officeItemRequisitioForm.patchValue({
          date : res.requisition_info.date ? res.requisition_info.date : '',
          subject:  res.requisition_info.subject ? res.requisition_info.subject : '',
          description:  res.requisition_info.description ? res.requisition_info.description : '',
          
        });
       
        console.log('new',this.officeItemRequisitioForm)
        
        console.log('office_item',res.category_items);
        res.category_items.forEach(element => { 
             const officeItems = this.fb.group({
              category_id: [element.category_id],
              category_title:[element.category_name],
              items : this.fb.array([]),
              dateOfuse: [element.dateOfuse],
              reason: [element.reason],
            });
     
            this.officeItemRequisitioForm.value.officeItems=officeItems.value
            console.log('xx', this.officeItemRequisitioForm.value.officeItems)
            
           const itemsFormArray = (officeItems.get("items") as FormArray); 
           console.log('noi',itemsFormArray)
            
            element.items.forEach(item => {
               

                const office_item_id = item.item_id;
               
                  const itemArray = this.fb.group({
                    name : [item.title],
                    item_id : [item.item_id],
                    quantity : [item.quantity],
                    unit:[item.unit],
                    remarks:[item.remarks]
                   //  last_supply_date : [item.last_supply_date],
                   
                  });
                
                 
                  itemsFormArray.push(itemArray);
                
                
      });
            
      // console.log('ihhhhhhhhhhhhhi',officeItems)
          this.officeItemControl.push(officeItems);
          // console.log('oi',this.officeItemControl)
        });
        this.officeItemControl.removeAt(0);

      }, err => {
        this.loader = false;
        this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);
      
      });
  }
  // TempCategoryData:any=[];
  // addItems(){
  //   let  tempCategory:any;
  //   let tempItemArray:any = [];
  //   // console.log('formvalue',this.officeItemRequisitioForm.value);
  //   // console.log('ArrayControls',this.officeItemControl);
  //   // console.log('control Value Detect',this.officeItemControl.value[0].items);
  //   // console.log('OfficeItemsData',this.officeItemRequisitioForm.value.officeItems); 
  //   // console.log('ItemsArray',this.officeItemRequisitioForm.value.officeItems[0].items);
  //   this.officeItemRequisitioForm.value.officeItems[0].dateOfuse = this.officeItemRequisitioForm.value.officeItems[0].dateOfuse ? formatDate(this.officeItemRequisitioForm.value.officeItems[0].dateOfuse ,'yyyy-MM-dd','en') : null;
  //   tempCategory = this.officeItemRequisitioForm.value.officeItems[0];
  //   this.officeItemRequisitioForm.value.officeItems[0].items.forEach(element => {
  //     console.log('element',element);
  //     if(element.item_id){
  //       this.snackBar.open('Please change items  or category value first before add new one','ok')
  //     }
      
  //    let string = element.split(',');
  //    const data = {
  //      item_id : string[0],
  //      quantity : string[1],
  //      name : string[2],
  //      last_supply_date : string[3]
  //    }
  //    tempItemArray.push(data);  
    
  //   });
  //  tempCategory.items = tempItemArray;
  //   let ctg = tempCategory.category.split(',');
  //  tempCategory.category_id = ctg[0],
  //  tempCategory.category_name = ctg[1],
  //  this.TempCategoryData.push(tempCategory);
  //  console.log('tempCategory',this.TempCategoryData);
  // }


  // deleteItem(item){
  //   const index = this.TempCategoryData.indexOf(item);
  // if (index !== -1) {
  //   this.TempCategoryData.splice(index, 1);
  //   }
  // }


  submitForm(data:number){
    this.officeItemRequisitioForm.get('status').setValue(data);
    this.officeItemRequisitioForm.get('date').setValue(formatDate(this.officeItemRequisitioForm.get('date').value ? this.officeItemRequisitioForm.get('date').value : new Date() ,'yyyy-MM-dd','en'));
    this.loader = true;
  const fd = new FormData();
  fd.append('ref_no', this.referelKey);
   fd.append('date', this.officeItemRequisitioForm.value.date);
   fd.append('subject', this.officeItemRequisitioForm.value.subject);
   fd.append('description', this.officeItemRequisitioForm.value.description);
   fd.append('officeItems',JSON.stringify(this.officeItemRequisitioForm.value.officeItems));
   fd.append('status', this.officeItemRequisitioForm.value.status);
   if(this.officeItemEdit){
    
    fd.append('requisition_id', this.officeItemId.toString());
    
   }
// if (this.files) {
//        if (this.files.length > 0) {
//            for (let x = 0; x < this.files.length; x++) {
//                fd.append('file[]', this.files[x], this.files[x].name);
//            }
//        }
//    }   
if (this.fileArray) {
  if (this.fileArray.length > 0) {
      for (let x = 0; x < this.fileArray.length; x++) {
          fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
      }
  }
}
    console.log('officeitem',this.officeItemRequisitioForm.value.officeItems);
    if(this.officeItemEdit){
      this.openadd=true;
          this.requisitionService.officeItemRequisitionEdit(fd).subscribe(res => {
            this.loader = false;
            this.openSnackBar(res.message,"ok",2000);
            this.router.navigate(['view-office-item-requisition']);
          }, err => {
            this.loader = false;
          this.openSnackBar(err.error.message ? err.error.message : err.error , "ok",6000);
        });

    } else{
          this.requisitionService.officeItemRequisitionCreate(fd).subscribe(res => {
            this.loader = false;
            this.openSnackBar(res.message,"ok",2000);
            this.router.navigate(['view-office-item-requisition']);
          }, err => {
            this.loader = false;
          this.openSnackBar(err.error.message ? err.error.message : err.error , "ok",6000);
        });
    }
      
    
   
    
  } 

//   onFileInput(event: any) {
//     this.fileArray = [];
//     this.files = event.target.files;
//     console.log('files',this.files);
//     if (event.target.files && event.target.files[0]) {

//         for (let item = 0; item < this.files.length; item++) {

//             const reader = new FileReader();
//             reader.readAsDataURL(this.files[item]);
//             reader.onload = (_event: any) => {
//                 this.fileUrl = _event.target.result;
//                 this.fileArray.push({
//                     fileName: this.files[item].name,
//                     fileUrl: this.fileUrl
//                 });
//             };
//         }
        
//     }
//     console.log(this.fileArray);
//     console.log()
// }

onFileInput(event: any) {
  this.files = event.target.files;
  console.log('files', this.files);
  if (event.target.files ) {

    for (let item = 0; item < this.files.length; item++) {

      const reader = new FileReader();
      reader.readAsDataURL(this.files[item]);
      reader.onload = (_event: any) => {
        this.fileUrl = _event.target.result;
        if(this.files[item].size > 358400){
          this.snackBar.open('File size limit exceed,your file size is  ' + (this.files[item].size/1024).toFixed(2)+' KB, Upload file size will not be exceed 350 KB','ok',{duration:10000});
      }else{
        this.fileArray.push({
          files :  this.files[item],
          fileName: this.files[item].name,
          fileUrl: this.fileUrl
        });
      }
    };
  }

  }
  console.log(this.fileArray);
}

deleteEditFile(data:any,index){
  this.snackBar.open("Sorry! You couldn't delete this file, read only!",'ok',{duration:3000});
}

download(f){
const link = document.createElement('a');
const downloadUrl = f;
link.setAttribute('href',  downloadUrl);
link.setAttribute('download', 'FileName');
link.setAttribute('target', '_blank');
document.body.appendChild(link); // Required for FF
link.click();
}

previewFile(f) {
  const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
          data : {url : f}
      })
      dialog_ref.afterClosed().subscribe(res=>{
          console.log('preview_closed',res);
      })
  }


  ngOnInit() {
    // this.getReferance(); 
    // this.getOfficeItemList();
    this.getAllOfficeItemCategory();
    this.officeItemRequisitioForm = this.fb.group({ 
      // ref_no:[''],
      date : [new Date(),Validators.required],
      subject: ['', Validators.required],
      description: [''],
      officeItems: this.fb.array([this.officeItems()]),
      status : [''],
      files: [null],
      // file : [null],

    });
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.officeItemId = +this.route.snapshot.paramMap.get('id');
      console.log(this.officeItemId);
      this.officeItemEdit = true;
      this.onclick=true;
      // setTimeout(()=>{                          
        this.getSpecificOfficeItemId(this.officeItemId);
    // }, 1000);
      
      
    }
    else{
      this.getReferance(); 
    }
  }

  openSnackBar(message: any, action: string,time?:number) {
    this.snackBar.open(message, action, {
      duration : time
    });
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { UserService } from '../services/user.service';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-ref-details-dialog',
  templateUrl: './ref-details-dialog.component.html',
  styleUrls: ['./ref-details-dialog.component.css']
})
export class RefDetailsDialogComponent implements OnInit {
  spinner:boolean;
  errorText: string;
  memo_details_data:any
  loader: boolean;
  requestData : any;
  reqDetail: any;
  remarks:any = '';
  reqData: any;
  files: any[];
  fileUrl: string;
  mouseoverevent: boolean;
  printData: any;
  reqapprove: any;
  budget_amount  = new FormControl();
  sig: any;
  deptId: any;

  type_of_purchase = new FormControl();
  headsinfo: { head_title: any; budget: any; expense: any; amount: any; remaining_balance: any; remaining_balance_percentage: any; };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data :any,
    public dialog_ref : MatDialogRef<RefDetailsDialogComponent>,
    private service : UserService,
    private snackBar : MatSnackBar,
    private preview_dialog : MatDialog
  ) { 
    console.log('ref_dialog_data',data);
    this.loader = true;
    this.spinner = true
    this.dialog_ref.addPanelClass('custom-modalbox'); 
    this.service.getDetailsByRef(data.ref_no).subscribe(res=>{
      this.spinner = false;
      this.memo_details_data = res;
      console.log('response',this.memo_details_data);
      this.sig = 'https://gdams.green-delta.com/services/public/uploads/user_signatures/';
      this.reqDetail = this.memo_details_data;
      this.requestData =  this.memo_details_data
      this.reqapprove = this.memo_details_data.requisition_details;
      this.deptId = sessionStorage.getItem('dept_id');
      // this.fileUrl = this.memo_details_data.file_info[0].dirname + '/' + this.memo_details_data.file_info[0].basename;
      this.printData = this.memo_details_data.requisition_info[0];
      this.type_of_purchase.setValue(this.printData.type_of_purchase);
      this.loader = false;
      this.headsinfo = {
        head_title : this.printData.head_title,
        budget : this.printData.budget,
        expense  : this.printData.expense,
        amount : this.printData.amount,
        remaining_balance : this.printData.remaining_balance,
        remaining_balance_percentage : this.printData.remaining_balance_percentage
    }
      
    },err=>{
      this.spinner = false;
      this.errorText = "The Reference No should be Approval Memo otherwise not found!"
    })
  }
  thousand_separator(num){
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}
  deleteEditFile(data:any,index){
    this.snackBar.open("Sorry! You couldn't delete this file, read only!",'ok',{duration:3000});
  }
  download(f){
      const link = document.createElement('a');
      const downloadUrl = f;
      link.setAttribute('href',  downloadUrl);
      link.setAttribute('download', 'FileName');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link); // Required for FF
      link.click();
  }

  previewFile(f) {
  const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
          data : {url : f}
      })
      dialog_ref.afterClosed().subscribe(res=>{
          console.log('preview_closed',res);
      })
  }
close(){
  this.dialog_ref.close('dialog_close');
}
  ngOnInit() {
  }

}

import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup
} from '@angular/forms';
import {
  MatSnackBar
} from '@angular/material';
import {
  UserService
} from '../services/user.service';
import {
  VehicleType
} from '../model/vehicleType';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  Vehicle
} from '../model/vehicle';

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.css']
})
export class CreateVehicleComponent implements OnInit {
  vehicleForm: FormGroup;
  loader: boolean;
  vehicleType: VehicleType[];
  vehicleId: number;
  vehicle: Vehicle[];
  vehicleEdit: boolean;
  diverList: any[]=[];

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
  ) {}

  createVehicle(data) {
    this.loader = true;
    this.userService.vehicleCreate(data)
      .subscribe(data => {
        console.log(data)
        this.loader = false;
        this.openSnackBar("Successfully created", "ok");
        this.router.navigate(['view-vehicle']);
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }

  editVehicle(vehicleData) {
    this.loader = true;
    this.userService.editVehicle(vehicleData)
      .subscribe(data => {
        console.log("After subscribe",data)
        this.loader = false;
        this.openSnackBar(" Successfully updated ", "ok");
        this.router.navigate(['view-vehicle']);
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }

  getAllVehicle() {
    this.loader = true;
    this.userService.getVehicleTypeList()
      .subscribe(data => {
        console.log(data)
        this.loader = false;
        this.vehicleType = data;
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }

  getSpecificVehicle(vehicleId) {
    this.loader = true;
    this.userService.getParticularVehicle(vehicleId)
      .subscribe(data => {
        console.log(data)
        this.loader = false;
        this.vehicleForm.patchValue({
          id: data[0].id,
          registration_no: data[0].registration_no,
          type_id: data[0].type_id,
          vehicle_identification:data[0].vehicle_identification,
          chassis:data[0].chassis,
          rented:data[0].rented,
          seat:data[0].seat,
          engine_no:data[0].engine_no,
          cylinder:data[0].cylinder,
          cc:data[0].cc,
          empty_vehicle_weight:data[0].empty_vehicle_weight,
          kg_full_weight:data[0].kg_full_weight,
          kg_color:data[0].kg_color,
          tyre_amount:data[0].tyre_amount,
          tyre_size:data[0].tyre_size,
          measure:data[0].measure,
          overhang:data[0].overhang,
          driver : data[0].driver_id,
        
        });
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }
  getDriverLists(){
    this.loader = true;
    this.userService.getDriverlist()
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.diverList = data;
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }
  ngOnInit() {
    this.getAllVehicle();
    this.getDriverLists();
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.vehicleId = +this.route.snapshot.paramMap.get('id');
      this.vehicleEdit = true;
      console.log(this.vehicleId);
      this.vehicleForm = this.fb.group({
        vehicle_identification: ['', Validators.required],
        registration_no: ['', Validators.required],
        type_id: ['', Validators.required],
        driver : ['', Validators.required],
        chassis: [],
        rented: [],
        seat: [],
        engine_no: [],
        cylinder: [],
        cc: [],
        empty_vehicle_weight: [],
        kg_full_weight: [],
        kg_color: [],
        tyre_amount: [],
        tyre_size: [],
        measure: [],
        overhang: [],
      });
      this.getSpecificVehicle(this.vehicleId);
    } else {
      this.vehicleForm = this.fb.group({
        vehicle_identification: ['', Validators.required],
        registration_no: ['', Validators.required],
        type_id: ['', Validators.required],
        driver : ['', Validators.required],
        chassis: [],
        rented: [],
        seat: [],
        engine_no: [],
        cylinder: [],
        cc: [],
        empty_vehicle_weight: [],
        kg_full_weight: [],
        kg_color: [],
        tyre_amount: [],
        tyre_size: [],
        measure: [],
        overhang: [],
      });
    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  vehicleFormSubmit() {
    if(this.vehicleForm.invalid){
      this.snackBar.open('Please fill up all required field then submit','ok',{
        duration : 4000
      })
    }else{

    /* Vehicle form submit */
    console.log("TESTING ",this.vehicleForm.value,this.vehicleId);
    if(this.vehicleId){

       console.log("EDIT  HERE I AM ....",this.vehicleForm.value);
   //    debugger
      let fd = new FormData()
      fd.append('id', this.vehicleId.toString() );
      fd.append('vehicle_identification', this.vehicleForm.value.vehicle_identification);
      
      fd.append('registration_no', this.vehicleForm.value.registration_no);
      fd.append('chassis',this.vehicleForm.value.chassis);

      fd.append('rented','1');
      fd.append('seat',this.vehicleForm.value.seat);
      fd.append('engine_no',this.vehicleForm.value.engine_no);
      fd.append('cylinder',this.vehicleForm.value.cylinder);
      fd.append('cc',this.vehicleForm.value.cc);
      fd.append('empty_vehicle_weight',this.vehicleForm.value.empty_vehicle_weight);
      fd.append('kg_full_weight',this.vehicleForm.value.kg_full_weight);
      fd.append('kg_color',this.vehicleForm.value.kg_color);
      fd.append('tyre_amount',this.vehicleForm.value.tyre_amount);
      fd.append('tyre_size',this.vehicleForm.value.tyre_size);
      fd.append('measure',this.vehicleForm.value.measure);
      fd.append('overhang',this.vehicleForm.value.overhang);
      fd.append('type_id', this.vehicleForm.value.type_id);
      fd.append('driver', this.vehicleForm.value.driver);
     
      this.editVehicle(fd);

    }else{
      let fd = new FormData()
      fd.append('vehicle_identification', this.vehicleForm.value.vehicle_identification);
      fd.append('registration_no', this.vehicleForm.value.registration_no);
     
      fd.append('chassis',this.vehicleForm.value.chassis);

      fd.append('rented',this.vehicleForm.value.rented);
      fd.append('seat',this.vehicleForm.value.seat);
      fd.append('engine_no',this.vehicleForm.value.engine_no);
      fd.append('cylinder',this.vehicleForm.value.cylinder);
      fd.append('cc',this.vehicleForm.value.cc);
      fd.append('empty_vehicle_weight',this.vehicleForm.value.empty_vehicle_weight);
      fd.append('kg_full_weight',this.vehicleForm.value.kg_full_weight);
      fd.append('kg_color',this.vehicleForm.value.kg_color);
      fd.append('tyre_amount',this.vehicleForm.value.tyre_amount);
      fd.append('tyre_size',this.vehicleForm.value.tyre_size);
      fd.append('measure',this.vehicleForm.value.measure);
      fd.append('overhang',this.vehicleForm.value.overhang);
      fd.append('driver',this.vehicleForm.value.driver);
      fd.append('type_id', this.vehicleForm.value.type_id);
      this.createVehicle(fd);
    } 
    // debugger
    this.router.navigate(['view-vehicle']);

  }
  }
}

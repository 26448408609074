import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class SharedDataService {
  payload : any ={};
  private initDelivery = new BehaviorSubject(this.payload);
  DeliveryData = this.initDelivery.asObservable();
  constructor() { }
  supplyData(data:any) {
    this.initDelivery.next(data);
  }
}

import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { PasswordChangeComponent } from 'src/app/password-change/password-change.component';
import { fuseAnimations } from 'src/app/animations';
import { window } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations : fuseAnimations
})
export class LoginComponent implements OnInit {

  forgetPassword: boolean = false;
  loader = false;
  loginform: FormGroup;

  email = new FormControl('',[Validators.required, Validators.email]);
  loginData: any;
  constructor(
    public fb: FormBuilder,
    public userService: UserService,
    public router: Router,
    public snackBar: MatSnackBar,
    public commonService: CommonService,
    private passwordChangeDialog : MatDialog
  ) {
    /****login */
    this.loginform = fb.group({
      pf_no: ['', Validators.required],
      password: ['', Validators.required]
    });

  }
  forgetpassword(){
    console.log('check this','asdf');

    if(this.email.invalid){
      this.snackBar.open('Please write your valid email address only,then try again','ok',{
        duration : 5000
      })
    }else{
      this.loader = true;

      const payload ={
        email: this.email.value,
        pf_no :  this.loginform.value.pf_no
      }
      console.log('forgetpassword payload',payload);
      this.userService.forgetPassword(payload).subscribe(res => {
        this.snackBar.open(res.message,'ok',{
          duration : 3000
        })
        this.loader = false;
        setTimeout(res=>{
          const data ={
            type : 'reset_password'
          }
              const dialog_ref = this.passwordChangeDialog.open(PasswordChangeComponent,{
                panelClass :  'custom-modalbox',
                data : data,

                })
                dialog_ref.afterClosed().subscribe(res=>{
                    console.log('dialogClose',res);
                    this.forgetPassword = false;

                })



        },3000)


    }),err=>{
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
        duration : 6000
      })
    }

  }
}
  login() {
    this.loader = true;
    sessionStorage.clear();
    const fd = new FormData();
    fd.append('pf_no', this.loginform.value.pf_no);
    fd.append('password', this.loginform.value.password);
    this.userService.loginUser(fd).subscribe(data => {
      console.log('login_response',data); // get user data
      this.userService.userPermission = [];
      this.permissionArrayProcess(data); // retrive from permission array
      sessionStorage.setItem('token', data['token']); // set authoraization token in sessionStorage
      sessionStorage.setItem('user_type', data['user']['type_id']); // set typeId in sessionStorage note_creator
      sessionStorage.setItem('user_id', data['user']['id']);
      sessionStorage.setItem('dept_id', data['user']['dept_id']);
      sessionStorage.setItem('department', data['user']['dept_name']);
      sessionStorage.setItem('branch', data['user']['branch']);

      sessionStorage.setItem('note_creator', data['user']['note_creator']);
      sessionStorage.setItem('designation_id',data['user']['designation_id']);

      this.userService.isLoggedIn = true; // set loggied in true
      sessionStorage.setItem('isLoggedIn', 'true');
      this.loader = false;
      this.openSnackBar('Waiting for data loading.... ', 'ok');
      let pass_expire_date = data.user.password_expire_date;
      setTimeout(()=>{
        if(pass_expire_date){
          let expire_date:any = new Date(pass_expire_date).getTime();
          let cur_date:any = new Date().getTime();
          let date_deff = Math.floor((expire_date - cur_date) / (1000*60*60*24)) ;
          if(date_deff <= 7){
            let spelling:string;
            if(date_deff <=1){spelling = ' day'}else{spelling = ' days'};
            confirm("Your password will be expired after "+date_deff+spelling +". Please reset your password");
          }
        }
      },30000)

      // location.reload(true);
      console.log('userServiceredirectUrl_login',this.userService.redirectUrl);

      if (this.userService.isLoggedIn) {
        if(this.userService.redirectUrl){
          this.router.navigate([this.userService.redirectUrl]);
        }else{
          this.router.navigateByUrl('/');
        }
        this.commonService.loginEvent.emit(data);
      }
    }, err => {
      this.loader = false;
      this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
        duration:5000
      });
    });
  }

  ngOnInit() {
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  permissionArrayProcess(data) {
    for (let i = 0; i < data['permissions'].length; i++) {
      /*******all permission****/
      console.log('Menu id : ' + data['permissions'][i].menu_id +
        '\ncan_read : ' + data['permissions'][i].can_read +
        '\ncan_write :' + data['permissions'][i].can_write);
       /************dashboard nav menu *******/
      if (data['permissions'][i].menu_id == 1) {
          if (data['permissions'][i].can_write == 1) {
            // this.userService.userPermission.push('/change-password');
          }
          if (data['permissions'][i].can_read == 1 ) {

            // this.userService.userPermission.push('/change-password');
          }

        }
        if (data['permissions'][i].menu_id == 1) {
          if (data['permissions'][i].can_read == 1) {
            this.userService.userPermission.push('/dashboard');
          }
        }

      /************user nav menu *******/

      // ********* "ADMISTRATION" Menu ************//
      if (data['permissions'][i].menu_id == 2) {
        if (data['permissions'][i].can_write == 1) {
          this.userService.userPermission.push('/create-user');
          this.userService.userPermission.push('/edit-user');
          this.userService.userPermission.push('/create-user-role');
          this.userService.userPermission.push('/edit-user-role');
          this.userService.userPermission.push('/create-department');
          this.userService.userPermission.push('/edit-department');
          this.userService.userPermission.push('/create-accounts_head');
          this.userService.userPermission.push('/edit-accounts_head');
          this.userService.userPermission.push('/create-designation');
          this.userService.userPermission.push('/edit-designation');
          this.userService.userPermission.push('/create-vehicle');
          this.userService.userPermission.push('/view-vehicle');
          this.userService.userPermission.push('/edit-vehicle');
          this.userService.userPermission.push('/create-vehicle-type');
          this.userService.userPermission.push('/edit-vehicle-type');

          this.userService.userPermission.push('/create-officeItem-ctg');
          this.userService.userPermission.push('/edit-officeItem-ctg');


          this.userService.userPermission.push('/create-office-item');
          this.userService.userPermission.push('/edit-office-item');
          this.userService.userPermission.push('/create-meeting-room');
          this.userService.userPermission.push('/edit-meeting-room');
        }
        if (data['permissions'][i].can_read == 1 ) {
          this.userService.userPermission.push('/view-user');
          this.userService.userPermission.push('/view-user-role');
          this.userService.userPermission.push('/view-department');
          this.userService.userPermission.push('/view-accounts_head');
          this.userService.userPermission.push('/view-designation');
          this.userService.userPermission.push('/view-vehicle');
          this.userService.userPermission.push('/view-officeItem-ctg');
          this.userService.userPermission.push('/view-office-item');
          this.userService.userPermission.push('/view-meeting-room');
        }
      }
      /************Purchase/Memo menu*******/
      if (data['permissions'][i].menu_id == 3) {
        if (data['permissions'][i].can_write == 1) {
          this.userService.userPermission.push('/create-memo-sheet-requisition');
          this.userService.userPermission.push('/edit-memo');
          this.userService.userPermission.push('/create-note-sheet-requisition');
          this.userService.userPermission.push('/edit-note');
        }
        if (data['permissions'][i].can_read == 1) {
          this.userService.userPermission.push('/view-memo-sheet-requisition');
          this.userService.userPermission.push('/view-note-sheet-requisition');
          this.userService.userPermission.push('/view-pending-memo-sheet-requisition');
          this.userService.userPermission.push('/view-pending-note-sheet-requisition');
        }
      }
      /************General requisition menu*******/
      if (data['permissions'][i].menu_id == 4) {
        if (data['permissions'][i].can_write == 1) {
          this.userService.userPermission.push('/create-office-item-requisition');
          this.userService.userPermission.push('/edit-office-item-requisition');
          this.userService.userPermission.push('/create-gate-pass-requisition');
          this.userService.userPermission.push('/create-visiting-card-requisition');
          this.userService.userPermission.push('/create-meeting-room-requisition');
          this.userService.userPermission.push('/create-pool-car-requisition');
          this.userService.userPermission.push('/create-car-requisition');
          this.userService.userPermission.push('/create-food-requisition');
          this.userService.userPermission.push('/create-id-card-requisition');
          this.userService.userPermission.push('/pool-car-requisition-details');
        }
        if (data['permissions'][i].can_read == 1) {
          this.userService.userPermission.push('/view-office-item-requisition');
          this.userService.userPermission.push('/approval-note-list');
          this.userService.userPermission.push('/payment-note-list');
          this.userService.userPermission.push('/view-gate-pass-requisition');
          this.userService.userPermission.push('/view-visiting-card-requisition');
          this.userService.userPermission.push('/view-meeting-room-requisition');
          this.userService.userPermission.push('/view-pool-car-requisition');
          this.userService.userPermission.push('/view-car-requisition');
          this.userService.userPermission.push('/view-food-requisition');
          this.userService.userPermission.push('/view-id-card-requisition');
          //for pending requision
          this.userService.userPermission.push('/view-pending-memo-sheet-requisition');
          this.userService.userPermission.push('/view-pending-note-sheet-requisition');
          this.userService.userPermission.push('/view-pending-gate-pass-requisition');
          this.userService.userPermission.push('/view-pending-pool-car-requisition');
          this.userService.userPermission.push('/view-pending-office-item-requisition');
          this.userService.userPermission.push('/view-pending-id-card-requisition');
          this.userService.userPermission.push('/view-pending-visiting-card-requisition');
          this.userService.userPermission.push('/view-pending-meeting-room-requisition');
          this.userService.userPermission.push('/view-pending-food-requisition');




        }
      }
        //************ Report Menu **********//
      if (data['permissions'][i].menu_id == 5) {
        if (data['permissions'][i].can_read == 1) {
              // for office item report
          this.userService.userPermission.push('/rep_pendingpayment_note');
          this.userService.userPermission.push('/rep_officeitem_itemwise');
          this.userService.userPermission.push('/rep_officeitem_durationwise');
          this.userService.userPermission.push('/rep_officeitem_employeewise');
          this.userService.userPermission.push('/rep_officeitem_approvalauthoritywise');
          // for gate pass report
          this.userService.userPermission.push('/rep_gatePass_itemwise');
          this.userService.userPermission.push('/rep_gatePass_durationwise');
          this.userService.userPermission.push('/rep_gatePass_employeewise');
          this.userService.userPermission.push('/rep_gatePass_approvalauthoritywise');
          // other's report
          this.userService.userPermission.push('/report_meetingromm');
          this.userService.userPermission.push('/report_food');
          this.userService.userPermission.push('/report_visitingCard');
          this.userService.userPermission.push('/report_id_card');
          this.userService.userPermission.push('/generel_notes_report');
        }
      }


    }


    for (let i = 0; i < this.userService.userPermission.length; i++) {
     // console.log(this.userService.userPermission[i] + '\n');
             // dashboard
   /*    if (this.userService.userPermission[i] == '/query-show') {
        this.userService.is = true;
        sessionStorage.setItem('isViewQuery', 'true');

      }
      if (this.userService.userPermission[i] == '/change-password') {
        this.userService.isViewPasswordChangePermission = true;
        sessionStorage.setItem('isViewChangePassword', 'true');

      } */
      if (this.userService.userPermission[i] == '/dashboard') {
        this.userService.isViewDashboard = true;
        sessionStorage.setItem('isViewDashboard', 'true');
      }
      // user
      if (this.userService.userPermission[i] == '/create-user') {
        this.userService.isAddUserPermission = true;
        sessionStorage.setItem('isAddUser', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-user') {
        this.userService.isEditUserPermission = true;
        sessionStorage.setItem('isEditUser', 'true');
      }
      if (this.userService.userPermission[i] == '/view-user') {
        this.userService.isViewUserPermission = true;
        sessionStorage.setItem('isViewUser', 'true');
      }
      if (this.userService.userPermission[i] == '/create-user-role') {
        this.userService.isAddUserRolePermission = true;
        sessionStorage.setItem('isAddUserRole', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-user-role') {
        this.userService.isEditUserRolePermission = true;
        sessionStorage.setItem('isEditUserRole', 'true');
      }
      if (this.userService.userPermission[i] == '/view-user-role') {
        this.userService.isViewUserRolePermission = true;
        sessionStorage.setItem('isViewUserRole', 'true');
      }
      if (this.userService.userPermission[i] == '/create-department') {
        this.userService.isAddDepartmentPermission = true;
        sessionStorage.setItem('isAddDepartment', 'true');
      }
      if (this.userService.userPermission[i] == '/view-department') {
        this.userService.isViewDepartmentPermission = true;
        sessionStorage.setItem('isViewDepartment', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-department') {
        this.userService.isEditDepartmentPermission = true;
        sessionStorage.setItem('isEditDepartment', 'true');
      }
      if (this.userService.userPermission[i] == '/create-accounts_head') {
        this.userService.isAddAccountsHead = true;
        sessionStorage.setItem('isAddAccountsHead', 'true');
      }
      if (this.userService.userPermission[i] == '/view-accounts_head') {
        this.userService.isViewAccountsHead = true;
        sessionStorage.setItem('isViewAccountsHead', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-accounts_head') {
        this.userService.isEditAccountsHead = true;
        sessionStorage.setItem('isEditAccountsHead', 'true');
      }
      if (this.userService.userPermission[i] == '/create-designation') {
        this.userService.isAddDesignationPermission = true;
        sessionStorage.setItem('isAddDesignation', 'true');
      }
      if (this.userService.userPermission[i] == '/view-designation') {
        this.userService.isViewDesignationPermission = true;
        sessionStorage.setItem('isViewDesignation', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-designation') {
        this.userService.isEditDesignationPermission = true;
        sessionStorage.setItem('isEditDesignation', 'true');
      }
      if (this.userService.userPermission[i] == '/create-vehicle') {
        this.userService.isAddVehiclePermission = true;
        sessionStorage.setItem('isAddVehicle', 'true');
      }

      if (this.userService.userPermission[i] == '/view-vehicle') {
        this.userService.isViewVehiclePermission = true;
        sessionStorage.setItem('isViewVehicle', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-vehicle') {
        this.userService.isEditVehiclePermission = true;
        sessionStorage.setItem('isEditVehicle', 'true');
      }
      if (this.userService.userPermission[i] == '/create-vehicle-type') {
        this.userService.isAddVehicleTypePermission = true;
        sessionStorage.setItem('isAddVehicleType', 'true');
      }
      if (this.userService.userPermission[i] == '/view-vehicle-type') {
        this.userService.isViewVehicleTypePermission = true;
        sessionStorage.setItem('isViewVehicleType', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-vehicle-type') {
        this.userService.isEditVehicleTypePermission = true;
        sessionStorage.setItem('isEditVehicleType', 'true');
      }
      if (this.userService.userPermission[i] == '/create-office-item') {
        this.userService.isAddOfficeItemPermission = true;
        sessionStorage.setItem('isAddOfficeItem', 'true');
      }
      if (this.userService.userPermission[i] == '/view-office-item') {
        this.userService.isViewOfficeItemPermission = true;
        sessionStorage.setItem('isViewOfficeItem', 'true');
      }

      if (this.userService.userPermission[i] == '/create-officeItem-ctg') {
        this.userService.isCreateofficeItemctg = true;
        sessionStorage.setItem('isCreateofficeItemctg', 'true');
      }
      if (this.userService.userPermission[i] == '/view-officeItem-ctg') {
        this.userService.isViewofficeitemlist = true;
        sessionStorage.setItem('isViewofficeitemlist', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-officeItem-ctg') {
        this.userService.isEditofficeItemctg = true;
        sessionStorage.setItem('isEditofficeItemctg', 'true');
      }

      if (this.userService.userPermission[i] == '/edit-office-item') {
        this.userService.isEditOfficeItemPermission = true;
        sessionStorage.setItem('isEditOfficeItem', 'true');
      }
      if (this.userService.userPermission[i] == '/create-meeting-room') {
        this.userService.isAddMeetingRoomPermission = true;
        sessionStorage.setItem('isAddMeetingRoom', 'true');
      }
      if (this.userService.userPermission[i] == '/view-meeting-room') {
        this.userService.isViewMeetingRoomPermission = true;
        sessionStorage.setItem('isViewMeetingRoom', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-meeting-room') {
        this.userService.isEditMeetingRoomPermission = true;
        sessionStorage.setItem('isEditMeetingRoom', 'true');
      }
      // Generel Requisition
      if (this.userService.userPermission[i] == '/create-office-item-requisition') {
        this.userService.isAddOfficeItemRequisitionPermission = true;
        sessionStorage.setItem('isAddOfficeItemRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-office-item-requisition') {
        this.userService.iseditOfficeItemRequisition = true;
        sessionStorage.setItem('iseditOfficeItemRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-office-item-requisition') {
        this.userService.isViewOfficeItemRequisitionPermission = true;
        sessionStorage.setItem('isViewOfficeItemRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/approval-note-list') {
        this.userService.ApprovalNoteListPermission = true;
        sessionStorage.setItem('ApprovalNoteListPermission', 'true');
      }
      if (this.userService.userPermission[i] == '/payment-note-list') {
        this.userService.isPayment_note_permission = true;
        sessionStorage.setItem('isPayment_note_permission', 'true');
      }
      if (this.userService.userPermission[i] == '/create-gate-pass-requisition') {
        this.userService.isAddGatePassRequisitionPermission = true;
        sessionStorage.setItem('isAddGatePassRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-gate-pass-requisition') {
        this.userService.isViewGatePassRequisitionPermission = true;
        sessionStorage.setItem('isViewGatePassRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/create-visiting-card-requisition') {
        this.userService.isAddVisitingCardRequisitionPermission = true;
        sessionStorage.setItem('isAddVisitingCardRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-visiting-card-requisition') {
        this.userService.isViewVisitingCardRequisitionPermission = true;
        sessionStorage.setItem('isViewVisitingCardRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/create-meeting-room-requisition') {
        this.userService.isAddMeetingRoomRequisitionPermission = true;
        sessionStorage.setItem('isAddMeetingRoomRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-meeting-room-requisition') {
        this.userService.isViewMeetingRoomRequisitionPermission = true;
        sessionStorage.setItem('isViewMeetingRoomRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/create-pool-car-requisition') {
        this.userService.isAddPoolCarRequisitionPermission = true;
        sessionStorage.setItem('isAddPoolCarRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-pool-car-requisition') {
        this.userService.isViewPoolCarRequisitionPermission = true;
        sessionStorage.setItem('isViewPoolCarRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/pool-car-requisition-details') {
        this.userService.isViewPoolCarRequisitionDetailsPermission = true;
        sessionStorage.setItem('isViewPoolCarRequisitionDetails', 'true');
      }
      if (this.userService.userPermission[i] == '/create-car-requisition') {
        this.userService.isAddCarRequisitionPermission = true;
        sessionStorage.setItem('isAddCarRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-car-requisition') {
        this.userService.isViewCarRequisitionPermission = true;
        sessionStorage.setItem('isViewCarRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/create-food-requisition') {
        this.userService.isAddFoodRequisitionPermission = true;
        sessionStorage.setItem('isAddFoodRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-food-requisition') {
        this.userService.isViewFoodRequisitionPermission = true;
        sessionStorage.setItem('isViewFoodRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/create-id-card-requisition') {
        this.userService.isAddIdCardRequisitionPermission = true;
        sessionStorage.setItem('isAddIdCardRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/view-id-card-requisition') {
        this.userService.isViewIdCardRequisitionPermission = true;
        sessionStorage.setItem('isViewIdCardRequisition', 'true');
      }
      // Purchase Requisition
      if (this.userService.userPermission[i] == '/create-memo-sheet-requisition') {
        this.userService.isAddMemoSheetRequisitionPermission = true;
        sessionStorage.setItem('isAddMemoSheetRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-memo') {
        this.userService.isEditMemoSheetRequisitionPermission = true;
        sessionStorage.setItem('isEditMemoSheetRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-memo-sheet-requisition') {
        this.userService.isViewMemoSheetRequisitionPermission = true;
        sessionStorage.setItem('isViewMemoSheetRequisition', 'true');
      }

      if (this.userService.userPermission[i] == '/create-note-sheet-requisition') {
        this.userService.isAddNoteSheetRequisitionPermission = true;
        sessionStorage.setItem('isAddNoteSheetRequisition', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-note') {
        this.userService.isEditnoteSheetRequisitionPermission = true;
        sessionStorage.setItem('isEditnoteSheetRequisitionPermission', 'true');
      }
      if (this.userService.userPermission[i] == '/create-paymentnote-sheet-requisition') {
        this.userService.isEditPaymentNoteRequisitionPermission = true;
        sessionStorage.setItem('isEditPaymentNoteRequisitionPermission', 'true');
      }
      if (this.userService.userPermission[i] == '/edit-paymentnote') {
        this.userService.isEditPaymentNoteRequisitionPermission = true;
        sessionStorage.setItem('isEditPaymentNoteRequisitionPermission', 'true');
      }
      if (this.userService.userPermission[i] == '/view-note-sheet-requisition') {
        this.userService.isViewNoteSheetRequisitionPermission = true;
        sessionStorage.setItem('isViewNoteSheetRequisition', 'true');
      }
      //pending requistion permission
      if (this.userService.userPermission[i] == '/view-pending-memo-sheet-requisition') {
        this.userService.isViewPendingMemoSheetRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingMemoSheetRequisitionPermission', 'true');
      }
      if (this.userService.userPermission[i] == '/view-pending-note-sheet-requisition') {
        this.userService.isViewPendingNoteSheetRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingNoteSheetRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-gate-pass-requisition') {
        this.userService.isViewPendingGatePassRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingGatePassRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-pool-car-requisition') {
        this.userService.isViewPendingPoolCarRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingPoolCarRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-office-item-requisition') {
        this.userService.isViewPendingOfficeItemRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingOfficeItemRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-id-card-requisition') {
        this.userService.isViewPendingIdCardRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingIdCardRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-visiting-card-requisition') {
        this.userService.isViewPendingVisitingCardRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingVisitingCardRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-meeting-room-requisition') {
        this.userService.isViewPendingMettingRoomRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingMettingRoomRequisitionPermission', 'true');
      }

      if (this.userService.userPermission[i] == '/view-pending-food-requisition') {
        this.userService.isViewPendingFoodRequisitionPermission = true;
        sessionStorage.setItem('isViewPendingFoodRequisitionPermission', 'true');
      }

    // for office item report
    if (this.userService.userPermission[i] == '/rep_pendingpayment_note') {
      this.userService.rep_pendingpayment_note = true;
      sessionStorage.setItem('rep_pendingpayment_note', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_officeitem_itemwise') {
      this.userService.rep_officeitem_itemwise = true;
      sessionStorage.setItem('rep_officeitem_itemwise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_officeitem_employeewise') {
      this.userService.rep_officeitem_employeewise = true;
      sessionStorage.setItem('rep_officeitem_employeewise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_officeitem_durationwise') {
      this.userService.rep_officeitem_durationwise = true;
      sessionStorage.setItem('rep_officeitem_durationwise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_officeitem_approvalauthoritywise') {
      this.userService.rep_officeitem_approvalauthoritywise = true;
      sessionStorage.setItem('rep_officeitem_approvalauthoritywise', 'true');
    }
    // for gate pass report
    if (this.userService.userPermission[i] == '/rep_gatePass_itemwise') {
      this.userService.rep_gatePass_itemwise = true;
      sessionStorage.setItem('rep_gatePass_itemwise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_gatePass_employeewise') {
      this.userService.rep_gatePass_employeewise = true;
      sessionStorage.setItem('rep_gatePass_employeewise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_gatePass_durationwise') {
      this.userService.rep_gatePass_durationwise = true;
      sessionStorage.setItem('rep_gatePass_durationwise', 'true');
    }
    if (this.userService.userPermission[i] == '/rep_gatePass_approvalauthoritywise') {
      this.userService.rep_gatePass_approvalauthoritywise = true;
      sessionStorage.setItem('rep_gatePass_approvalauthoritywise', 'true');
    }

    // for other's report
    if (this.userService.userPermission[i] == '/report_meetingromm') {
      this.userService.report_meetingromm = true;
      sessionStorage.setItem('report_meetingromm', 'true');
    }
    if (this.userService.userPermission[i] == '/report_food') {
      this.userService.report_food = true;
      sessionStorage.setItem('report_food', 'true');
    }
    if (this.userService.userPermission[i] == '/report_visitingCard') {
      this.userService.report_visitingCard = true;
      sessionStorage.setItem('report_visitingCard', 'true');
    }
    if (this.userService.userPermission[i] == '/report_id_card') {
      this.userService.report_id_card = true;
      sessionStorage.setItem('report_id_card', 'true');
    }

    if (this.userService.userPermission[i] == '/generel_notes_report') {
      this.userService.generel_notes_report = true;
      sessionStorage.setItem('generel_notes_report', 'true');
    }




    }
  }



}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '../model/user';
import { RequisitionService } from '../services/requisition.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { Authority } from '../model/authority';

@Component({
  selector: 'app-create-get-pass-requisition',
  templateUrl: './create-get-pass-requisition.component.html',
  styleUrls: ['./create-get-pass-requisition.component.css']
})
export class CreateGetPassRequisitionComponent implements OnInit {
  isDisable : boolean = true;
  loader: boolean;
  employee:User[] = [];
  gatePassForm: FormGroup;
  finalapprovalauthority$ : Observable<any>;
  recommendationapprovalauthority$ :  Observable<any>;
  approvalauthority$ : Observable<any>;
  selected2 = 2;

  selected3 = 2;
  @ViewChild('subject_field') _subject : ElementRef;
  // category: any = [{
  //   name: 'cat_1',
  //   value:1

  // },{
  //   name: 'cat_2',
  //   value:2

  // },{
  //   name: 'cat_3',
  //   value:3

  // }]
  category : any;
  employee_spinner: boolean;
  constructor(
    private snackBar:MatSnackBar,
    public userService:UserService,
    private fb : FormBuilder,
    public requisitionService:RequisitionService,
    public router :Router,
  ) { }
  toogleReadonly(){
    if(this.isDisable){
      this.gatePassForm.get('subject').enable();
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this._subject.nativeElement.focus();
      },20); 
    }else{
      this.gatePassForm.get('subject').disable();
    }
    this.isDisable = !this.isDisable;
    
      console.log('works');  
  }
  getAllOfficeItemCategory(){
    this.loader = true;
    this.userService.getOfficeItem().subscribe( res =>{
        console.log("Items List",res);
        this.loader = false;
        this.category = res;
      },err=>{
        this.loader = false;
        this.openSnackBar(err.error.message ? err.error.message : err.error ,"ok");
    });
  }
  createGatePassRequisition(data)
  {
    this.loader = true;
    this.requisitionService.requisitionCreate(data).subscribe(res => {
        console.log(res);
        this.loader = false;
        this.openSnackBar(res.message ? res.message : "Something went wrong","OK");
        this.router.navigate(['view-gate-pass-requisition']);
      }, err => {
        this.loader = false;
        if(err.status == 400) {location.reload(true);}
        this.openSnackBar("Internal error :"+ err.error.message ? err.error.message : err.error, "ok");
    });
  }
  getEmployeeList(){
    this.employee_spinner = true;
    this.userService.getUsers()
      .subscribe(data => {
        console.log('user_short_info');
        this.employee_spinner = false;
        this.employee = data;
      }, err => {
        this.employee_spinner = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
          duration : 5000
        })
      });
  }
  selected_recomended_by(data){
    this.gatePassForm.get('recommended_by').setValue(data);
    console.log('form value',this.gatePassForm.value);
    
  }
  selected_approved_by(data){
    this.gatePassForm.get('approved_by').setValue(data);
    console.log('form value approved by',this.gatePassForm.value);
  }
  gatePassFormSubmit(){
    this.gatePassForm.get('subject').enable();
    // let fd = new FormData();
    // fd.append('requisition_to',this.gatePassForm.value.requisition_to);
    // fd.append('subject',this.gatePassForm.value.subject);
    // fd.append('description',this.gatePassForm.value.description);
    // fd.append('category',JSON.stringify(this.gatePassForm.value.category));
    // fd.append('recommended_by',this.gatePassForm.value.recommended_by);
    // fd.append('approved_by',this.gatePassForm.value.approved_by);
    // fd.append('final_approved_by',this.gatePassForm.value.final_approved_by);
    this.gatePassForm.value.category = JSON.stringify(this.gatePassForm.value.category);
    this.gatePassForm.value.url = 'gate_pass';
    this.gatePassForm.value.path = 'create';
   
    console.log('payload',this.gatePassForm.value);
    this.createGatePassRequisition(this.gatePassForm.value);
  }

  get categoryQuantityForms() {
    return this.gatePassForm.get('category') as FormArray;
  }

  addMoreItem() {
    this.categoryQuantityForms.push(this.addItem());
  }

  addItem(){
    return this.fb.group({
      category: [''],
      quantity: ['']
    })
  }

  deleteItem(i) {
    this.categoryQuantityForms.removeAt(i)
  }

  ngOnInit() {
    this.getAllOfficeItemCategory();
    this.getEmployeeList();
    this.approvalauthority$ = this.userService.getFinalApproveauthoritylist('gate_pass');
    this.recommendationapprovalauthority$ = this.userService.getRecommendationApproveauthoritylist('gate_pass');
    // this.approvalauthority$ = this.userService.getApproveApproveauthoritylist('gate_pass');
    this.gatePassForm = this.fb.group({
      requisition_to: [''],
      subject: ['Gate Pass Requisition Request',Validators.required],
      category: this.fb.array([this.addItem()]),
      description: ['', Validators.required],
      approved_by: ['',Validators.required],
      recommended_by: ['',Validators.required],
      final_approved_by: ['']
/*         file: [null] */
    });
    this.gatePassForm.get('subject').disable();

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { fuseAnimations } from '../animations';

@Component({
  selector: 'app-view-item-categorylist',
  templateUrl: './view-item-categorylist.component.html',
  styleUrls: ['./view-item-categorylist.component.css'],
  animations : fuseAnimations 
})
export class ViewItemCategorylistComponent implements OnInit {

  loader: boolean;
  p: number = 1;
  officeItemList: MatTableDataSource <any>;
  displayedOfficeItemColumns: string[] = ['Position','Title','Note_Creator_Name','Edit'];
  @ViewChild(MatPaginator,{static:true} as any) paginator : MatPaginator;
  data: any;
  constructor(
    public userService : UserService,
    private snackBar :MatSnackBar,
    private router: Router,

  ) { }

  editOfficeItemCategory(id){
    console.log(id)
    this.router.navigate(['edit-officeItem-ctg/', id]);
  }

  ngOnInit() {
    this.getAllOfficeItemCategory();
  }

  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.officeItemList.filter = filterValue.trim().toLowerCase();
  }
  getAllOfficeItemCategory(){
    this.loader = true;
    this.userService.getOfficeItemCategory().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data = res;
        this.data.sort((a,b)=>b.id - a.id);
        this.data.map((v,i)=>v.sn = i+1);
        this.officeItemList = new MatTableDataSource<any>(this.data);
        this.officeItemList.paginator = this.paginator;
      },err=>{
        this.loader = false;
        this.openSnackBar(err.error.message ? err.error.message : err.error ,"ok");
    });
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

}

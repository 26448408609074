import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, FormArrayName } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { formatDate, DOCUMENT } from '@angular/common';
import { fuseAnimations } from '../animations';
import { UserService } from '../services/user.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import{currency} from '../json_data';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-approval-note',
  templateUrl: './approval-note.component.html',
  styleUrls: ['./approval-note.component.css'],
  animations: fuseAnimations
})
export class ApprovalNoteComponent implements OnInit {

  // Forms and other's feature type variables
  includeCheckbox = new FormControl();
  CurrentDate: any = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  officeItemRequisitioForm: FormGroup;
  ApproverForm: FormGroup;
  budget = new FormControl();
  remarks = new FormControl();
  fileUpload = new FormControl();
  approval_authority_options = new FormControl();

  TemporaryItemsArray = [];
  TempArray=[]
  // Data store type Variables
  employeeList$ = [];
  filteredOptions: Observable<any[]>[] = [];
  admin_selected_Status: any;
  AccouciateHead_selected_option: any;
  agree_disagree_status: any;
  totalAmount: number = 0;

  // permission or boolean type Variables
  associated_head: boolean;
  NoteEdit: boolean;
  AccountsPerson: boolean;
  Admin_head: boolean;
  approval_authority: boolean;
  owner: boolean;
  creator: boolean;
  othersPerson: boolean;
  select_approver: boolean;
  recomendedOption: boolean;
  agree_disagree: boolean;
  loader: boolean;
  isReadOnly: boolean = true;
  paymentNoteCreate: boolean;
  
  isadd:boolean=false;
  isdetail:boolean=false;
  progress: number = 0;
  // for files type upload
  files: any;
  url: any;
  fileUrl: string | ArrayBuffer;
  fileArray: any = [];
  referelKey:any;
  deletedFiles:any[] = [];
  data5:any=[]
  approvaldetails:any=[]
  editFiles:any=[];
  deleteon:boolean=true;
  editempform:any=[];
  newQuantity:number;
  oldQuantity:number;
  totalQuantity:number;
  isquantity:boolean=false;
  isStop:boolean= false;
  unit_price:number;
  delar:any=[]
  x:boolean=false;
  approvarDeleteList:any=[];

  // Reference type  variables
  @ViewChild('budgetInput') bugetInpurref: ElementRef;
  // @ViewChild("testInput") unit_price;
  // @ViewChild('save', { static: true } as any)  save : ElementRef;

  getId: any;
  accounts_spinner: boolean;
  accounts_list: any[] = [];
  readOnlyForapproved: boolean;
  CurrArray :any = currency;
  isok:boolean=false;
  selectedEmployee: any;
  approveeditemp:any=[];

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ApprovalNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: UserService,
    public snackBar: MatSnackBar,
    public router: Router,
    public route: ActivatedRoute,
    // @Inject(DOCUMENT) document,
    
  ) {
    //  let elem = document.getElementById('test');
    //  elem.innerHTML = "testbb";
    // this.data.budget_info.map(v => v.subtotal = 0);
    // console.log('data', data);

  }
  close() {
    this.dialogRef.close();
  }

  type_of_budget(data) {
    if (data == 1) {
      this.data.category_items.forEach(item => {
        let find = this.data.budget_info.findIndex(v => v.id === item.category_id);
        this.data.budget_info.map(v=>v.subtotal = item.subtotal);
        console.log('find', find);

        this.data.budget_info[find].subtotal = item.subtotal;
      })


    }
    console.log('budgetInfo', this.data.budget_info);

    let yearly_budget  = this.data.budget_info[0].budget;
    let consumed  = this.data.budget_info[0].consumed;
    let remaining = yearly_budget - consumed;
    let total_amount =  this.officeItemRequisitioForm.get('amount').value;

    if (total_amount > remaining) {
        this.opensnackBar("Total amount exceeds your budget amount",'ok',3000);
        this.officeItemRequisitioForm.get('type_of_purchase').setValue('2');
    }





  }

 
  QuantityCount(catIndex,deptIndex,itemIndex,event,unit_price:number){
   
    if(unit_price == undefined){
      unit_price =0;
      console.log('unit p',unit_price)
    }
    
    this.isquantity=true;
    this.oldQuantity= parseInt(this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity);
   
  
    this.newQuantity= event.target.value;
    
    // if(!this.newQuantity){
    //   this.oldQuantity= parseInt(this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity);
    //   console.log('old',this.oldQuantity)
    //   this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= this.oldQuantity * unit_price;
    //   console.log('subtotal',  this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)
    // }else{
      if(this.newQuantity > this.oldQuantity){
      alert('new quantity can not exceed pervious quantity')
        // this.newQuantity = this.oldQuantity
        this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity=this.oldQuantity;
        this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= this.oldQuantity * unit_price;
      }
      else {
        //this.oldQuantity= this.newQuantity;
        console.log('new',this.newQuantity);
        this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity= this.newQuantity
        this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= this.newQuantity * unit_price;
        // console.log('subtotal',  this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)
      }
    //}
   
    
  
    
    // console.log('new',this.newQuantity)
    // if(this.newQuantity > this.oldQuantity){
      // alert('new quantity can not exceed pervious quantity')
      // this.newQuantity = this.oldQuantity
      
    // } 
    // else{
      // this.isStop = false;
      //this.officeItemRequisitioForm.controls['total_qyantity'].enable()
      // this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= this.newQuantity * unit_price;
      // console.log('subtotal',  this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)
    // }

    this.getTotalAmount();  
    
  }

  u:number;
  // Note Creator Functions
  PriceCount(catIndex,deptIndex,itemIndex,event,quantity) {
    // if(event.keyCode === 8){
    //   // event.preventDefault();
    // }else{
       //this.u = +(<HTMLInputElement>event.target).value.split(',').join('');
    // }
    
    // console.log('quantity',quantity)
    // console.log('unit_price',this.u );

    // this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].unit_price= this.u
    // if(this.isquantity == true){
    //   this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total = this.u * this.oldQuantity;
    // }
    // else if(this.isquantity == false){
    //   this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total = this.u * quantity;
    // }
    
    // console.log('new',this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)

    //this.oldQuantity= parseInt(this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity);
   
  
    this.unit_price= event.target.value;
    
    // if(!this.newQuantity){
    //   this.oldQuantity= parseInt(this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].total_qyantity);
    //   console.log('old',this.oldQuantity)
    //   this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= this.oldQuantity * unit_price;
    //   console.log('subtotal',  this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)
    // }else{
      
      this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].unit_price= this.unit_price
      this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total= quantity * this.unit_price;
      // console.log('subtotal',  this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex].sub_total)
      
    this.getTotalAmount();
  }

  quantity:number;
  editQuantity(catIndex,itemIndex,q:number,unit_price){
  
    this.data.category_items[catIndex].cat_items[itemIndex].quantity= q; 
    // console.log('quantity',q)
   this.quantity=this.data.category_items[catIndex].cat_items[itemIndex].quantity
  
  this.data.category_items[catIndex].cat_items[itemIndex].sub_total = unit_price * this.quantity;
  // console.log('new',this.data.category_items[catIndex].cat_items[itemIndex].sub_total)
  this.editTotalAmount();
}


unitprice:number;
editUnitPrice(catIndex,itemIndex,unitprice:number,quantity){
  // console.log('unitp',unitprice)
  this.data.category_items[catIndex].cat_items[itemIndex].unit_price=unitprice;
  this.unitprice=this.data.category_items[catIndex].cat_items[itemIndex].unit_price;
  this.data.category_items[catIndex].cat_items[itemIndex].sub_total = this.unitprice * quantity;
  // console.log('new',this.data.category_items[catIndex].cat_items[itemIndex].sub_total)
  this.editTotalAmount();
  
}

  

  valueSet
  getTotalAmount() {
    let count = 0;
    this.data.category_items.forEach(element => {
      let categorySubtotal = 0;
      element.dept_items.forEach(element => {
        let sub_total = element.cat_items.map(t => t.sub_total).reduce((acc, value) => acc + value, 0);
        count += sub_total;
        categorySubtotal += sub_total;
      });
      element.subtotal = categorySubtotal;
      // console.log("kaisar", this.calculation(this.data.budget_info, element));
      // this.data.budget_info.findIndex(element.category_id);

    });
    this.officeItemRequisitioForm.get('amount').setValue(Math.round(count));
    // for budget or non budget calculation
    // let yearly_budget = this.data.budget_info[0].budget;
    // let consumed = this.data.budget_info[0].consumed;
    // let remaining = yearly_budget - consumed;


    //   if (count > remaining) {
    //     this.opensnackBar("Total amount exceeds your budget amount",'ok',3000);
    //     this.officeItemRequisitioForm.get('type_of_purchase').setValue('2');
    // }else{
    //   this.officeItemRequisitioForm.get('type_of_purchase').reset();
    // }
    // console.log('categoryConsole', this.data.category_items, this.data.budget_info);

  }
  editTotalAmount() {
    let count_new = 0;
    this.approvaldetails.category_items.forEach(element => {
      let categorySubtotal = 0;
     
        let sub_total = element.cat_items.map(t => t.sub_total).reduce((acc, value) => acc + value, 0);
        count_new += sub_total;
        categorySubtotal += sub_total;
      // console.log('count',count_new)
      element.subtotal = categorySubtotal;
      //console.log("kaisar", this.calculation(this.data.budget_info, element));
      // this.data.budget_info.findIndex(element.category_id);
      // console.log(sub_total)
    });
    
    this.officeItemRequisitioForm.get('amount').setValue(Math.round(count_new));
    // for budget or non budget calculation
    // let yearly_budget = this.data.budget_info[0].budget;
    // let consumed = this.data.budget_info[0].consumed;
    // let remaining = yearly_budget - consumed;


    //   if (count > remaining) {
    //     this.opensnackBar("Total amount exceeds your budget amount",'ok',3000);
    //     this.officeItemRequisitioForm.get('type_of_purchase').setValue('2');
    // }else{
    //   this.officeItemRequisitioForm.get('type_of_purchase').reset();
    // }
    // console.log('categoryConsole', this.data.category_items, this.data.budget_info);

  }

  calculation = function (nameKey, myArray) {
    nameKey.forEach(element => {
      if (element.id === myArray.category_id) {
        element.subtotal = myArray.subtotal;
        return nameKey;
      }
    });
  };





  deletedItemsArray = [];
  deleteItems(catIndex, deptIndex, itemIndex) {
    this.deletedItemsArray.push(this.data.category_items[catIndex].dept_items[deptIndex].cat_items[itemIndex]);
    this.data.category_items[catIndex].dept_items[deptIndex].cat_items.splice(itemIndex, 1);
    // console.log('deletedItems', this.deletedItemsArray);
    this.getTotalAmount();
  }
  editDeleteItems(catIndex, itemIndex){
  
    this.deletedItemsArray.push(this.approvaldetails.category_items[catIndex].cat_items[itemIndex]);
    this.approvaldetails.category_items[catIndex].cat_items.splice(itemIndex, 1);
    // console.log('deletedItems', this.deletedItemsArray);
    this.editTotalAmount();
  }

 
  // UndoDelete(){


  // }

  getReferance(){
          
    this.service.get_referance('office_item_approval_memo').subscribe(res=> {
        // console.log('ref_id_backend',res);
        if(res.id){
            var str = "" + ++res.id;
        }else{
            var str = "" + 1;
        }
        let date = new Date();
        var pad = "0000"
        var ans = pad.substring(0, pad.length - str.length) + str
        const refFormat = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'OfficeItemApprovalMemo'+ '/' + ans;
        this.referelKey  = refFormat;
        console.log('referelKey',this.referelKey);
         });
    }
    uniqueItems:any = [];


    createApproval(data) {
      this.loader = true;
      // console.log('payload',data);
      this.service.approvalNoteSubmit(data).subscribe((event: HttpEvent<any>)  => {
          switch (event.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round(event.loaded / event.total * 100);
                break;
              case HttpEventType.Response:
                console.log('User successfully created!', event.body);

                this.snackBar.open(event.body['message'],'ok',{
                  duration : 3000
                });
               this.router.navigate(['approval-note-list']);
                this.loader = false;

                setTimeout(() => {
                  this.progress = 0;
                }, 1500);

            }
      }, err => {
          this.loader = false;
          this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
              duration:5000
          });
      });


  }
  editMemo(data) {
    this.loader = true;
    console.log('payload',data);
    this.service.approvalNoteEdit(data).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
                this.snackBar.open('File uploaded successfull','ok')
              setTimeout(() => {
                this.progress = 0;
                this.snackBar.open(event.body['message'],'ok',{
                    duration : 3000
                  });
                 this.router.navigate(['approval-note-list']);
                  this.loader = false;
              }, 1500);

          }

    },e=>{
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
}
    
  // create_approval_note_submit by note creator
  create_approval_note_submit(data: number) {
   
          this.loader = true;
          this.officeItemRequisitioForm.get('status').setValue(data);
          if (!this.NoteEdit) {
          const itemsArray: Array<any> = this.data.category_items.map(item => item.dept_items.map(ditem => ditem.cat_items));
          console.log('itemsArray', itemsArray);
        
          
          this.uniqueItems = [];
          itemsArray.forEach(element => {
            element.forEach(item => {
             item.forEach(x=>{
              if(x.delivery_status==0){
                this.uniqueItems.push(...x);
              }
             })
    
            });

          });
         
          }
          if (this.NoteEdit) {
            
            const itemsArray: Array<any> = this.approvaldetails.category_items.map(item => item.cat_items);
            console.log('itemsArray', itemsArray);
            
            this.uniqueItems = [];
            itemsArray.forEach(element => {
              element.forEach(item => {
                this.uniqueItems.push(...item);
              });
  
            });
            }
          let totalCategory_id = Array.from(new Set(this.data.category_items.map(item => item.category_id)));

          // console.log('totalCategory_id', totalCategory_id);
          const fd = new FormData();
          fd.append('date', this.officeItemRequisitioForm.value.date);
          fd.append('subject', this.officeItemRequisitioForm.value.subject);
          fd.append('tin', this.officeItemRequisitioForm.value.tin);
          fd.append('bin_nid', this.officeItemRequisitioForm.value.bin_nid);
          fd.append('objective', this.officeItemRequisitioForm.value.objective);
          fd.append('background', this.officeItemRequisitioForm.value.background);
          fd.append('decision', this.officeItemRequisitioForm.value.decision);
         
          fd.append('summary', this.officeItemRequisitioForm.value.summary);
          fd.append('amount', this.officeItemRequisitioForm.value.amount);
          fd.append('type_of_purchase', this.officeItemRequisitioForm.value.type_of_purchase);
          fd.append('requistions', JSON.stringify(this.officeItemRequisitioForm.value.requistions));
          fd.append('categorys', JSON.stringify(totalCategory_id));
          // fd.append('budget_info', JSON.stringify(this.data.budget_info));
          fd.append('items', JSON.stringify(this.uniqueItems));
          fd.append('deletd_items', JSON.stringify(this.deletedItemsArray));
          fd.append('deletedFile',JSON.stringify(this.deletedFiles));
          fd.append('status', this.officeItemRequisitioForm.value.status);
          if (this.NoteEdit) {
            fd.append('ref_no',this.data.note_info.ref_no);
            fd.append('note_id', this.data.note_info.id);
            fd.append('editapproval_list',JSON.stringify(this.editempform));
            fd.append('approvars',JSON.stringify(this.delar))
            // console.log('json',JSON.stringify(this.delar))
            fd.append('approvarDeleteList',JSON.stringify(this.approvarDeleteList))
            // fd.append('newapprovarDeleteList',JSON.stringify(this.newapprovarDeleteList))
           
          }
          const employeeName:Array<any> = this.employeeForms.value;
          if(this.includeCheckbox.value === true){
            employeeName.unshift({employee_id : {id : this.AccountsPersonControl.value.accounts_person}})
          }
          
          fd.append('employeeName', JSON.stringify(this.ApproverForm.get('employeeName').value));
          let date = new Date();
          if (!this.NoteEdit){
            fd.append('ref_no',this.referelKey);
          }
          if (this.fileArray) {
            if (this.fileArray.length > 0) {
                for (let x = 0; x < this.fileArray.length; x++) {
                    fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                }
            }
        }

          if (this.NoteEdit) {
            console.log('FormData', fd);
            // this.editMemo(fd);

            this.service.approvalNoteEdit(fd).subscribe(res => {
          
              this.router.navigate(['approval-note-list']);
              this.loader = false;
              this.dialogRef.close('reload');
            }, err => {
              this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
                duration: 5000
              });
            })
          }
          else {
            console.log('FormData', fd);
            this.service.approvalNoteSubmit(fd).subscribe(res => {
              this.opensnackBar(res.message, 'ok', 20000);
              this.router.navigate(['approval-note-list']);
              this.loader = false;
              this.dialogRef.close('reload');
             
            }, err => {
              this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
                duration: 5000
              });
            })
          }

  }



  // Associate Head Functions
  AccouciateHead(data) {
    this.select_approver = true;
    this.AccouciateHead_selected_option = data
  }

  admin_headFirst(status) {
    this.agree_disagree = true;
    this.agree_disagree_status = status
  }

  adminHeadAcceptReject() {
    const payload = {
      note_id: this.data.note_info.id,
      accept_status: this.agree_disagree_status,
      remarks: this.remarks.value ? this.remarks.value : '',
    }
    this.loader = true;
    // console.log('adminHeadAcceptReject Payload', payload)
    this.service.adminHeadAcceptReject(payload).subscribe(res => {
      this.opensnackBar(res.message, 'ok', 2000);
      this.loader = false;
      console.log('payload', payload);
      this.dialogRef.close('reload');

    }, err => {
      this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
        duration: 5000
      });
      this.loader = false;
      this.dialogRef.close('reload');
    })
  }

  // Accounts Persons functions
  limit(input, budget) {
    // if (this.budget.value > budget) {
    //   alert('budget exceeds your current budget limit');
    //   this.budget.setValue(0);
    // }
  }
  accountsPersonBudget(status) {
    // const payload = {
    //   note_id : this.data.note_info.id,
    //   category_id : this.data.category_items[0].category_id,
    //   budget : this.budget.value,
    //   status : status,
    //   remarks : this.remarks.value,
    // }
    // this.loader= true;
    const fd = new FormData();
    fd.append('note_id', this.data.note_info.id);
    fd.append('category_id', this.data.category_items[0].category_id);
    fd.append('budget', this.budget.value);
    fd.append('status', status);

    fd.append('remarks', this.remarks.value ? this.remarks.value : '');
 
    if (this.fileArray) {
      if (this.fileArray.length > 0) {
          for (let x = 0; x < this.fileArray.length; x++) {
              fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
          }
      }
  }
    console.log('accountsPersonBudget', fd);
    this.service.accountsPersonBudget(fd).subscribe(res => {
      this.opensnackBar(res.message, 'ok', 2000);

      this.loader = false;
      this.dialogRef.close('reload');

    }, err => {
      this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
        duration: 5000
      });
      this.loader = false;

      this.dialogRef.close('reload');
    })
  }


  // Admin head  functions

  Admin_head_action(data: number) {

    this.select_approver = true;
    if (data === 1) {
      this.recomendedOption = true;
    }
    else {
      this.recomendedOption = false;
    }

    this.admin_selected_Status = data;

    console.log('status', this.admin_selected_Status);
  }

  getAccounts_persons(){
    //console.log('niloy testing', event )
    //if(event.checked == true){
        this.accounts_spinner = true;
        this.service.getAccountsPerson().subscribe(res=>{
            this.accounts_list = res;
            for(let i =0;i<this.accounts_list.length;i++){
                if(this.accounts_list[i].is_budget_person== 1){
                    this.accounts_person_list.push({f_name:this.accounts_list[i].f_name,
                    id:this.accounts_list[i].id,
                    pf_no:this.accounts_list[i].pf_no})
                    // console.log('accounts_person_name', this.accounts_person_list )
                }
            }

            console.log('accounts_person_name', this.accounts_person_list[0].id )

            this.AccountsPersonControl.patchValue({
                accounts_person : this.accounts_person_list[0].id
            })

            // this.AccountsPersonControl.setValue(this.accounts_person_list[0].id);
            //console.log('accounts_personList',this.accounts_person_list);
            // this.memoForm.controls['accounts_person'].setValue( this.accounts_person_list[0], {onlySelf: true});
            if(this.accounts_person_list.length===1){
                this.selectedValue = this.accounts_person_list[0].f_name
                console.log('selectvalue',this.selectedValue)
            }
            
        
            this.accounts_spinner = false;

        },err=>{
            this.accounts_spinner = false;
            this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error));
        })
  
}


  getEmployee1(data) {
    console.log('data',data)
    console.log('employee auto complete', this.ApproverForm.get('employeeName').value);
  }


  getEmployee(value) {
      this.selectedEmployee = value;
      this.isadd = true;// new add
      console.log('value', value)
      if(this.NoteEdit){
     

        for(let i=0; i<=this.approveeditemp.length; i++){
          if(this.approveeditemp[i]==value.id){
            console.log(this.approveeditemp[i],value.id)
            console.log('found')
            this.isok = true;
              break;

          }
          else{
            this.isok = false;
       
          }
        }
        if(this.approvarDeleteList){
            for(let j=0;j<=this.approvarDeleteList.length;j++){
              if(this.approvarDeleteList[j].approved_by == value.id){
                this.isok = false;
                this.editempform.push(value.id)
                this.approveeditemp.push(value.id)
                this.approvarDeleteList.splice(j, 1)
                 console.log('rem',this.editempform)
                 console.log('rem1',this.approveeditemp)
              
              }
              
            }
            if(!this.isok){
              console.log('push')
              
            }
       
          }
         
       
      }else{
          if(this.editempform.indexOf(value.id) == -1){
          this.editempform.push(value.id)
          this.isok= false;
        } 
        else{
          this.isok = true; 
        }
        // if(this.approvaldetails.)
        
        
      
     
      console.log('editenter',this.editempform) 
      console.log('data',this.data)

        if(this.selectedEmployee.id === this.data)
        {
            console.log('found')
            this.isok = true;   
            
        }
            
        if(this.newemp !== undefined )
        {
            for(let i = 0; i<this.newemp.length; i++)
            {
                
                    if(this.newemp[i].employee_id.id === value.id)
                    {
                      this.isok=true;
                      break;
                    }
                    
                    else{
                        this.isok=false;
                        continue;
                    }
               
                }

               
                
            }
      }

        // if(this.approvaldetails.approve_info.indexOf(value.id)== -1){
        //   this.editempform.push(value.id)
        //   console.log('rem',this.editempform)
        // }
      
            // if(this.NoteEdit && this.newemp !== undefined){

            // }
        }

  get employeeForms() {
    return this.ApproverForm.get('employeeName') as FormArray;
  }

  // addMoreempItem1() {
  //   this.employeeForms.push(this.addempItem());
  //   this.employListAutoCompleteControl(this.employeeForms.length - 1);
  // }
  newemp:any
  addMoreempItem() {
    this.isadd=false;
    if(this.NoteEdit){
    
        this.employeeForms.push(this.addempItem());
        this.newemp = this.employeeForms.value;
        console.log('edit newemp',this.newemp)
        this.employListAutoCompleteControl(this.employeeForms.length - 1);
        if(this.newemp){
          for(let i=0;i<=this.editempform.length;i++){
            for(let j=0;j<=this.newemp.length;j++){
              if(this.editempform[i]==this.newemp[j].employee_id.id){
                this.isok=true;
              }
              else{
                this.isok = false;
              }
              if(!this.isok && this.newemp[j].employee_id !=""){
                this.editempform.push(this.newemp[j].employee_id.id)
                this.approveeditemp.push(this.newemp[j].employee_id.id)
                console.log('edit entry',this.editempform)
                console.log('edit entry1',this.approveeditemp)
        
              }
            }
            
          }
        
        }
        
      }
    
    if(!this.NoteEdit){
    
   this.employeeForms.push(this.addempItem());
    
   console.log('empform',this.employeeForms.value)
   
    this.newemp = this.employeeForms.value;
    this.employListAutoCompleteControl(this.employeeForms.length - 1);
    }
}

  addempItem() {
    return this.fb.group({
      employee_id: ['']
    });
  }

  deleteItem(i) {
    console.log('deleteIndex', i);
   
    this.filteredOptions.splice(i, 1);
  }
  newapprovarDeleteList:any=[];
  editdeleteempItem(i) {
   console.log('new data i==',i)
    for(let j=0;j<=this.approvaldetails.approve_info.length;j++){
      if( i.approval_status!=1
        && i.approved_by== this.approvaldetails.approve_info[j].approved_by){
         this.x=true;
        
       this.editempform.splice(j, 1)
       console.log('editempform',this.editempform)
       this.approvarDeleteList.push(this.approvaldetails.approve_info.splice(j,1)[0])
       console.log('approvarDeleteList',this.approvarDeleteList)
      }
         if (!this.delar.some(el => el.id === this.approvaldetails.approve_info.id)) {
        this.delar.push(this.approvaldetails.approve_info);
        console.log('delar',this.approvaldetails.approve_info)
      }
    }
     
     
      
  }
  deleteempItem(i) {
    this.isok=false;
    console.log('hlw')
    const employeeArraySize = this.employeeForms.length - 1;
    if (employeeArraySize === 0) {
      this.ApproverForm.patchValue({
        employee_id: ''
      });
    }
    this.employeeForms.removeAt(i);
    // this.editempform.splice(i,1)
    console.log('delet',this.employeeForms)
  }
 

  employListAutoCompleteControl(index: number) {
    const arrayControl = this.ApproverForm.get('employeeName') as FormArray;
    this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.f_name),
        map(name => name ? this._filter(name) : this.employeeList$.slice())
      );
  }

  displayFn(data?: any): string | undefined {
    return data ? data.f_name : undefined;
  }


  private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.employeeList$.filter(option => {
      return option.f_name.toLowerCase().indexOf(filterValue) !== -1 || option.id == value;
    })
  }

  AdminHeadRecomended() {
    // const payload = {
    //   note_id :this.data.note_info.id,
    //   approval_status : this.admin_selected_Status,
    //   employeeName : this.ApproverForm.get('employeeName').value,
    //   remarks : this.remarks.value
    // }
    this.loader = true;
    const fd = new FormData();
    fd.append('note_id', this.data.note_info.id);
    fd.append('approval_status', this.admin_selected_Status);
    fd.append('employeeName', JSON.stringify(this.ApproverForm.get('employeeName').value));
    fd.append('remarks', this.remarks.value ? this.remarks.value : '');
 
    if (this.fileArray) {
      if (this.fileArray.length > 0) {
          for (let x = 0; x < this.fileArray.length; x++) {
              fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
          }
      }
  }


    this.service.AdminHeadRecomended(fd).subscribe(res => {
      this.opensnackBar(res.message, 'ok', 2000);
      this.loader = false;
      this.dialogRef.close('reload');

    }, err => {
      this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
        duration: 5000
      });
      this.loader = false;
      this.dialogRef.close('reload');

    })
  }


  // Approval Authority Functions

  Approval_authority(status: any) {
    // const payload = {
    //   note_id : this.data.note_info.id,
    //   approval_status : this.approval_authority_options.value,

    // }
    const fd = new FormData();
    fd.append('note_id', this.data.note_info.id);
    fd.append('approval_status', status);
    fd.append('remarks', this.remarks.value ? this.remarks.value : '');
 
    if (this.fileArray) {
      if (this.fileArray.length > 0) {
          for (let x = 0; x < this.fileArray.length; x++) {
              fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
          }
      }
  }
    this.loader = true;
    this.service.Approval_authority(fd).subscribe(res => {
      this.opensnackBar(res.message, 'ok', 2000);
      this.loader = false;
      this.dialogRef.close('reload');

    }, err => {
      this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error), 'ok', {
        duration: 5000
      });
      this.loader = false;
      this.dialogRef.close('reload');
    })

  }

  // Payment Note Create
  CreateNote_confrim: boolean;
  create_payment_note_dicision() {
    this.CreateNote_confrim = true;

  }
  create_payment_note_revise(status) {

  }
  create_payment_note_reject(status) {

  }
  getAccounts_persons1() {
    this.accounts_spinner = true;
    this.service.getAccountsPerson().subscribe(res => {
      this.accounts_list = res;
      console.log('accounts_personList', this.accounts_list);
      this.accounts_spinner = false;

    }, err => {
      this.accounts_spinner = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error));
    })
  }
  accounts_person_list:any=[]

  

  AccountsPersonControl = new FormControl('');
  // SelectedAccounts(data) {
  //   console.log('selected accounts_person', data);
  //   this.issame=false;
  //   console.log('newvalue',this.selectedEmployee)

  //   this.AccountsPersonControl.setValue(data);
  // }

  issame:boolean=false;
    data1:any;
    selectedValue:any
    SelectedAccounts(data1){
      this.issame=false;
      console.log('newvalue',this.selectedEmployee)

      console.log('selected accounts_person',data1);
      if(this.selectedEmployee.id === data1){
          console.log('found')
          this.issame = true;
      }
      if(this.newemp !== undefined)
      {
          for(let i = 0; i<this.newemp.length; i++)
          {
                  if(this.newemp[i].employee_id.id === data1)
                  {
                  console.log('found')
                  this.issame = true;
                    break;
                    
                  }
                  else{
                    
                  }
             
              }
 
          }
          this.AccountsPersonControl.setValue(data1);

          // this.ApproverForm.get('accounts_person').setValue(data1);
       


  }
  create_payment_note(status) {
    this.getReferance()
    this.loader = true;
    const fd = new FormData();
    fd.append('note_id', this.data.note_info.id);
    fd.append('expense_amount', this.data.note_info.total_amount);
    fd.append('status', status);

    if (this.fileArray) {
      if (this.fileArray.length > 0) {
          for (let x = 0; x < this.fileArray.length; x++) {
              fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
          }
      }
  }
    const employeeName:Array<any> = this.employeeForms.value;
    // if(this.includeCheckbox.value === true){
    //   employeeName.unshift({employee_id : {id : this.AccountsPersonControl.value.accounts_person}})
    // }
    //old

    if(this.includeCheckbox.value === true){
      const fc = this.fb.group({
        employee_id: [{ id: this.AccountsPersonControl.value, check_issue: 1,
        f_name: this.AccountsPersonControl.value}],
        
      })
      this.employeeForms.push(fc);
      console.log('vvvbvb',this.employeeForms)
    }
   
   
    console.log('employeforms_value', this.employeeForms.value);
    fd.append('employeeName', JSON.stringify(this.ApproverForm.get('employeeName').value));
//old end
console.log('employeeName after unshift',employeeName);
fd.append('employeeName', JSON.stringify(employeeName));
    this.service.create_payment_note(fd).subscribe(res => {
      this.opensnackBar(res.message, 'ok', 20000);
      this.dialogRef.close('Owner_reload');
      this.loader = false;

    }, err => {
      this.snackBar.open(err.error.message ? ' ' + err.error.message : JSON.stringify(err.error, undefined, 2), 'ok', {
        duration: 5000
      });
      this.dialogRef.close('Owner_reload');
      this.loader = false;
    })

  }



  // Other's Functions

  onFileInput(event: any) {
    this.files = event.target.files;
    console.log('files', this.files);
    if (event.target.files && event.target.files[0]) {

      for (let item = 0; item < this.files.length; item++) {

        const reader = new FileReader();
        reader.readAsDataURL(this.files[item]);
        reader.onload = (_event: any) => {
          this.fileUrl = _event.target.result;
          if(this.files[item].size > 358400){
            this.snackBar.open('File size limit exceed,your file size is  ' + (this.files[item].size/1024).toFixed(2)+' KB, Upload file size will not be exceed 350 KB','ok',{duration:10000});
        }else{
          this.fileArray.push({
            files :  this.files[item],
            fileName: this.files[item].name,
            fileUrl: this.fileUrl
          });
        }
      };
    }

    }
    console.log(this.fileArray);
    console.log()
  }

  deleteFile(index){
    console.log('index',index);
    this.fileArray.splice(index,1);

}
deleteEditFile(data:any,index){
  this.deletedFiles.push(data);
  this.editFiles.splice(index,1);
}
download(f){
  const link = document.createElement('a');
  const downloadUrl = f;
  link.setAttribute('href',  downloadUrl);
  link.setAttribute('download', 'FileName');
  link.setAttribute('target', '_blank');
  document.body.appendChild(link); // Required for FF
  link.click();
}


  opensnackBar(message: string, action: string, time?: number) {
    this.snackBar.open(message, action, {
      duration: time
    });
  }
  getApproverList() {
    this.service.getUserList().subscribe((res) => {
      this.employeeList$ = res;
    });
  }

getcal(){
  for(let i=0;i<=this.data.category_items.length;i++){
    for(let j=0;j<=this.data.category_items[i].dept_items.length;j++){
      for(let k=0;k<=this.data.category_items[i].dept_items[j].cat_items.length;k++){
        
        this.TempArray.push({quantity:this.data.category_items[i].dept_items[j].cat_items[k].total_qyantity})
        console.log('item========',this.TempArray)
      }
    }
  }
}

  ngOnInit() {
    console.log('editempform',this.editempform)
  //  this.onchangeQuantity()
   console.log('editapproval',this.data)
  // this.getcal();
   this.approvaldetails= this.data;
   this.editFiles=this.data.file_info;
  //  console.log('editfile',this.editFiles)
   if(this.approvaldetails.note_info){
     this.NoteEdit=true;
     
    //  console.log('true')

    this.getApproverList();
      this.isReadOnly = false;
      if(this.approvaldetails.approve_info.approval_status == 1){
          this.deleteon = false;
      }
      else{
        this.deleteon = true;
      }
      if(this.approvaldetails.approve_info.length>0){
        for( let i=0;i<this.approvaldetails.approve_info.length;i++){
          if(this.approvaldetails.approve_info[i].approval_status!=1){
            // this.employeeForms.push(this.approvaldetails.approve_info[i].approved_by);
            // this.addempItem[this.approvaldetails.approve_info[i].approved_by];
            this.editempform.push(this.approvaldetails.approve_info[i].approved_by)
            console.log('empnew',this.editempform)
            console.log('empform',this.approvaldetails.approve_info[i].approved_by_name)
            // this.newemp = this.approvaldetails.approve_info[i];
            // this.employListAutoCompleteControl(this.employeeForms.length - 1);
          }
          this.approveeditemp.push(this.approvaldetails.approve_info[i].approved_by)
          console.log('approveeditemp',this.approveeditemp)
        }
        
      }
   }
    console.log('ApprovalNotedata', this.data);
   
    console.log('permission', this.data.permission);
    
    if(this.data.permission == 'paymentNoteCreate'){
      this.paymentNoteCreate = true;
      this.officeItemRequisitioForm.addControl('accounts_person',new FormControl('',Validators.required))
    } else if(this.data.permission == "edit") {
      this.NoteEdit = true;
      this.officeItemRequisitioForm.get('type_of_purchase').enable();
      console.log('permission', this.data.permission);

    }  else if (this.data.permission == "readOnly") {
      this.readOnlyForapproved = true;
    }
    // this.isdetail=true;
    
    this.getReferance()
    console.log('TotalAmount', this.totalAmount);
    let categor_ids = Array.from(new Set(this.data.category_items.map(item => item.category_id)));
    console.log('category_ids', categor_ids);
    this.data.category_items.forEach(element => {
      this.TemporaryItemsArray.push(element);
      this.TempArray.push(element);
    });
    console.log('tempArray', this.TemporaryItemsArray);
    // console.log('temp', this.TempArray);

    // form init
    this.officeItemRequisitioForm = this.fb.group({
      date: [this.data.note_info && this.data.note_info.note_create_date ? this.data.note_info.note_create_date : formatDate(new Date(), 'yyyy-MM-dd', 'en')],
      subject: [this.data.note_info && this.data.note_info.subject ? this.data.note_info.subject : '', Validators.required],
      tin: [this.data.note_info && this.data.note_info.tin ? this.data.note_info.tin : ''],
      bin_nid: [this.data.note_info && this.data.note_info.bin_nid ? this.data.note_info.bin_nid : ''],
      objective: [this.data.note_info && this.data.note_info.objective ? this.data.note_info.objective : ''],
      background: [this.data.note_info && this.data.note_info.background ? this.data.note_info.background : ''],
      decision: [this.data.note_info && this.data.note_info.decision ? this.data.note_info.decision : ''],
      summary: [this.data.note_info && this.data.note_info.summary ? this.data.note_info.summary : ''],
      amount: [this.data.note_info && this.data.note_info.total_amount ? this.data.note_info.total_amount : 0],
      type_of_purchase: [this.data.note_info && this.data.note_info.type_of_purchase ? this.data.note_info.type_of_purchase : ''],
      requistions: [this.data.requisitionArray],
      status: [''],
      files: [null],
      // file: [null],//new
    })


    this.officeItemRequisitioForm.get('type_of_purchase').disable();

    // Permission Setting
    const role = this.data.role;
    console.log('role', role);
    if (role == "admin_head") {
      this.Admin_head = true;
      //   this.service.getUserList().subscribe((res) => {
      //     this.employeeList$ = res;
      //  });
    }
    else if (role == 'note_checker') {
      this.associated_head = true;
      console.log('note_cheker', this.associated_head);
    }
    else if (role == 'creator') {
      this.creator = true;
      console.log('permission', this.creator);
      // this.getAccounts_persons();
      this.includeCheckbox.valueChanges.subscribe(res=>{
        const accountsPerson = this.AccountsPersonControl.get('accounts_person');
        console.log('res',res)
        if(res === true){
       
          this.getAccounts_persons();
        }else{
          accountsPerson.clearValidators();
          accountsPerson.reset();
        }
  
      })
     
      this.getApproverList();
      this.isReadOnly = false;
      this.officeItemRequisitioForm.get('type_of_purchase').enable();

    }
    else if (role == 'accounts_person') {
      this.AccountsPerson = true;
      // this.budget.enable();
      this.budget.setValue(this.data.budget_info[0] && this.data.budget_info[0].budgeted ? this.data.budget_info[0].budgeted : 0);
      console.log('budget', this.budget);
    }
    else if (role == "approval_authority") {
      this.approval_authority = true
    }
    else if (role == 'owner') {
      this.owner = true;
    }
    else {
      this.othersPerson = true;
    }
  //  else if (this.data.permission == "paymentNoteCreate") {
      
     

      // this.officeItemRequisitioForm.addControl('accounts_person',new FormControl('',Validators.required))
    // }
  
   
    // recomondation person selection form
    this.ApproverForm = this.fb.group({
      employeeName: this.fb.array([this.addempItem()]),
    })
    this.employListAutoCompleteControl(0);
  }
  // ngAfterViewInit() {
  //   this.unit_price.nativeElement.focus();
  // }


}

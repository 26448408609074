import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-office-item-category',
  templateUrl: './create-office-item-category.component.html',
  styleUrls: ['./create-office-item-category.component.css']
})
export class CreateOfficeItemCategoryComponent implements OnInit {

  officeItemForm: FormGroup;
  loader: boolean = false;
  officeItemId: number;
  officeItemEdit: boolean;
  Accounts_person : any[]=[];
  speceficData: any;
  userList:any[]=[];
  audit_person_list :any[]=[];

  constructor(
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  // Accounts_person = [
  //   {value: '1', viewValue: 'Mr. Example 1'},
  //   {value: '2', viewValue: 'Mr. Example 2'},
  //   {value: '3', viewValue: 'Mr. Example 3'},
  // ];
  getAccountsPerson(){
    this.loader = true;
    this.userService.getAccountsPerson().subscribe(res => {

      this.Accounts_person = res;

      console.log('Accounts_person list',this.Accounts_person);
    },err=>{

      this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);
    });

  }
  SelectedNotePerson(data){
    this.officeItemForm.get('note_creator').setValue(data);
  }
  getAuditPerson(){
    this.loader = true;
    this.userService.getAuditPerson().subscribe(res => {

      this.audit_person_list = res;

      console.log('audit_person_list',this.audit_person_list);
    },err=>{

      this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);
    });

  }


  createOfficeItemCategory(){/* department save service */
    this.loader = true;
    this.userService.officeItemCategoryCreate(this.officeItemForm.value).subscribe(res => {
      this.loader = false;
      this.openSnackBar(res.message, "ok",2000);
      this.router.navigate(['view-officeItem-ctg']);
    },err=>{
      this.loader = false;
      this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);
    });
  }

  editOfficeItemCategory(){/* department save service */
    this.loader = true;
    console.log('payload',this.officeItemForm.value);
    this.userService.editOfficeItemCategory(this.officeItemForm.value,this.officeItemId).subscribe(res => {
      this.loader = false;
      this.openSnackBar(res.message, "ok",2000);
      this.router.navigate(['view-officeItem-ctg']);
    },err=>{
      this.loader = false;
      this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);
    });
  }


  getSpecificOfficeItemId(officeItemId) {
    this.loader = true;
    this.userService.getSpeceficeItemCategory(officeItemId).subscribe(res => {
        console.log('specific items',res)

        this.speceficData = res;
        this.officeItemForm.patchValue({
          category_title: res.category_title ? res.category_title : '',
          budget : res.budget ? res.budget : '',
          accounts_person : res.accounts_person ? res.accounts_person : '',
          note_creator : res.note_creator ? res.note_creator : '',
          pre_accounts_person : res.pre_accounts_person ? res.pre_accounts_person : '',
          audit_person : res.audit_person ? res.audit_person : '',
        })
        console.log('FormValue',this.officeItemForm.value);
      }, err => {

        this.openSnackBar(err.error.message ? err.error.message : err.error, "ok",6000);

      });
  }

  getUserlist(data){
    this.loader = true;

    this.userService.get_noteCreator(data).subscribe(res=>{
      this.userList = res;
      console.log('userlist',this.userList);
      this.loader = false;
    },
    err=>{
      this.openSnackBar(err.error.message ? err.error.message : err.error,'ok');
    })
  }

  ngOnInit() {
    // this.getAccountsPerson();
    // this.getAuditPerson();
    this.officeItemForm = this.fb.group({
      category_title: ['', Validators.required],
      budget :['',Validators.required],
      pre_accounts_person : [''],
      accounts_person : [''],
      note_creator : ['',Validators.required],
      audit_person : [''],
    });
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.officeItemId = +this.route.snapshot.paramMap.get('id');
      console.log(this.officeItemId);
      this.officeItemEdit = true;
      this.getSpecificOfficeItemId(this.officeItemId);
      const payload ={
        category_id : this.officeItemId,
      }
      this.getUserlist(payload);
    }else{
      const payload ={
        category_id : 0,
      }
      this.getUserlist(payload);
    }


  }

  openSnackBar(message: string, action: string,time?:number) {
    this.snackBar.open(message, action, {
      duration: time,
    });
  }


  // officeItemFormSubmit(){/* department form submit */
  //   console.log(this.officeItemForm.value);
  //   if(this.officeItemEdit){
  //     let fd = new FormData()
  //     fd.append('id', this.officeItemId.toString());
  //     fd.append('category_title', this.officeItemForm.value.category_title);
  //     fd.append('budget', this.officeItemForm.value.budget);
  //     fd.append('accounts_person', this.officeItemForm.value.accounts_person);
  //     this.editOfficeItemCategory();
  //   }else{
  //     let fd = new FormData()
  //     fd.append('category_title', this.officeItemForm.value.category_title);
  //     fd.append('budget', this.officeItemForm.value.budget);
  //     fd.append('accounts_person', this.officeItemForm.value.accounts_person);
  //     this.createOfficeItemCategory(fd);
  //   }

  // }

}

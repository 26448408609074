import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-car-requisition',
  templateUrl: './create-car-requisition.component.html',
  styleUrls: ['./create-car-requisition.component.css']
})
export class CreateCarRequisitionComponent implements OnInit {

  loader:boolean;
  constructor() { }

  ngOnInit() {
  }

}

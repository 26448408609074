import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-authority-details',
  templateUrl: './authority-details.component.html',
  styleUrls: ['./authority-details.component.css']
})
export class AuthorityDetailsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data :any,
    private dialog_ref : MatDialogRef<AuthorityDetailsComponent>
  ) { }
    close(){
      this.dialog_ref.close();
    }
  ngOnInit() {
    console.log('authority_dialog_data',this.data);
    
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-accounts-head-entry',
  templateUrl: './accounts-head-entry.component.html',
  styleUrls: ['./accounts-head-entry.component.css']
})
export class AccountsHeadEntryComponent implements OnInit {

  accountsHeadForm: FormGroup;

  loader: boolean = false;
  accountsHeadId: number;
  accountsHeadsEdit: boolean;
  accountsHeadList : any[]=[];
  employee_spinner: boolean;
  tabIndex:number=0;

  displayedColumns: string[] = ['Position','headsName','Edit'];
  data:any[] = [];
  AccountsHeadList : MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  constructor( 
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    private confirm_dialog :MatDialog
  ) { }
  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.AccountsHeadList.filter = filterValue.trim().toLowerCase();
  }
  CreateAccountsHeads(){
    this.loader = true;
    let fd = new FormData()
    fd.append('accounts_head', this.accountsHeadForm.value.accounts_head);
    this.userService.AccountsHeadCreate(fd).subscribe(res => {
      this.loader = false;
      console.log('create res',res);
      this.snackBar.open(res.message,'ok',{duration:2000});
     this.tabIndex = 1;
     this.getAllHeads();
    },err=>{
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }

deleteHead(data){
  const dialog_ref = this.confirm_dialog.open(ConfirmDialogComponent);
  dialog_ref.afterClosed().subscribe(res=>{
    console.log('dialog_ref',res);
    if(res === 1){
      this.loader = true;
      this.userService.deleteHeads(data.id).subscribe(res=>{
            this.snackBar.open(res.message,'ok',{duration:3000});
            this.loader = false;
            this.getAllHeads();
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
      })
    }
  })

}


  editAccountsHeads(){
    this.loader = true;
    let fd = new FormData()
    fd.append('head_id', this.accountsHeadForm.value.head_id);
    fd.append('accounts_head', this.accountsHeadForm.value.accounts_head);
    this.userService.editAccountsHeads(fd).subscribe(res => {
      this.loader = false;
      this.snackBar.open(res.message,'ok',{duration:2000});
      console.log('edit res',res);
      
      this.tabIndex = 1;
      this.getAllHeads();
    },err=>{
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }

  getAllHeads(){
    this.loader = true;
    this.userService.getaccountsHeadList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data = res;
        this.data.sort((a,b)=>b.id - a.id);
        this.data.map((v,i)=>v.sn = i+1);
        this.AccountsHeadList = new MatTableDataSource<any>(this.data);
        this.AccountsHeadList.paginator = this.paginator;
        //this.openSnackBar("Success ", "ok");
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }
  editHeads(data:any){
    this.accountsHeadsEdit = true;
    this.tabIndex = 0;
    this.accountsHeadForm.patchValue({
      head_id : data.id,
      accounts_head: data.head_title 
    })
  }
  reset(){
    this.accountsHeadForm.reset();
    this.accountsHeadsEdit = false;
  }
  ngOnInit() {
    this.getAllHeads();
    this.accountsHeadForm = this.fb.group({
      head_id : [''],
      accounts_head: ['', Validators.required],
    });
    
  }

 
  

}

import { Component, OnInit,  ViewChild } from '@angular/core';
import { MatSnackBar, MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router,ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../animations';
import { ApprovalNoteComponent } from '../approval-note/approval-note.component';
import { ApprovalNoteDetailsComponent } from '../approval-note-details/approval-note-details/approval-note-details.component';
import { SpecialApprovalNoteComponent } from '../special-approval-note/special-approval-note.component';
import {DelegateModalComponent} from '../delegate-modal/delegate-modal.component';
import { PaymentNoteComponent } from '../payment-note/payment-note.component';

@Component({
  selector: 'app-approval-note-list',
  templateUrl: './approval-note-list.component.html',
  styleUrls: ['./approval-note-list.component.css'],
  animations : fuseAnimations
})
export class ApprovalNoteListComponent implements OnInit {
    loader: boolean;
    template:boolean = true;
    ApprovalNoteListtableData: MatTableDataSource<any>;
    OwnerApprovalListtableData: MatTableDataSource<any>;
    BudgetedListtableData: MatTableDataSource<any>;
    req_for_changeListtableData :  MatTableDataSource<any>;
    approvedListTableData :  MatTableDataSource<any>;
    RejectedListTableData : MatTableDataSource<any>;
    displayedColumns:any= [{display : 'ID',field: 'id'},{display : 'Reference No',field:'ref_no'},{display : 'Note Creator',field: 'note_created_by'},{display : 'Subject',field: 'subject'},{display : 'Created Date',field: 'created_at'}];
    note_creatorPermission: boolean = false;
    ApprovalNoteList: any[]=[];
    BudgetedList : any[] = [];
    OwnerApprovalList: any[]=[];
    req_for_changeList:any=[];
    approvedList:any=[];
    rejectList:any=[];
    columnsFieldName: any[] = [];
  
    @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
    @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;

    @ViewChild('TableTwoPaginator', {static: true} as any) tableTwoPaginator: MatPaginator;
    @ViewChild('TableTwoSort', {static: true} as any) tableTwoSort: MatSort;

    @ViewChild('TableThreePaginator', {static: true} as any) tableThreePaginator: MatPaginator;
    @ViewChild('TableThreeSort', {static: true} as any) tableThreeSort: MatSort;

    @ViewChild('TableFourPaginator', {static: true} as any) tableFourPaginator: MatPaginator;
    @ViewChild('TableFourSort', {static: true} as any) tableFourSort: MatSort;

    @ViewChild('TableFivePaginator', {static: true} as any) tableFivePaginator: MatPaginator;
    @ViewChild('TableFiveSort', {static: true} as any) tableFiveSort: MatSort;

    @ViewChild('TableSixPaginator', {static: true} as any) tableSixPaginator: MatPaginator;
    @ViewChild('TableSixSort', {static: true} as any) tableSixSort: MatSort;
    admin_head_permission: boolean;
    tabIndex: number;

    constructor(
        public userService: UserService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,

    ) { }
// getBudgetedList(){
//   this.loader = true;
//     this.userService.getBudgetedList().subscribe(res=>{
//         this.BudgetedList =res;
//         this.loader = false;
//         console.log('BudgetedList',this.BudgetedList);
//         this.BudgetedListtableData = new MatTableDataSource<any>(this.BudgetedList);
//         this.BudgetedListtableData.paginator = this.tableThreePaginator;
//         this.BudgetedListtableData.sort = this.tableThreeSort;
//         // this.tableData.sort = this.sort;
//         console.log('Mat Budgeted table Data',this.BudgetedListtableData);
//     },err=>{ 
//         this.loader = false;
//         this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
//             duration:5000
//          });
//     })
// }
getOwnerApprovalList(){
    this.loader = true;
    this.userService.getOwnerApprovalNoteList().subscribe(res=>{ 
        this.OwnerApprovalList =res;
        console.log('OwnerApproval List',this.OwnerApprovalList);
        this.loader = false;
        this.OwnerApprovalListtableData = new MatTableDataSource<any>(this.OwnerApprovalList);
        this.OwnerApprovalListtableData.paginator = this.tableOnePaginator;
        this.OwnerApprovalListtableData.sort = this.tableOneSort;
       
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
          duration:5000
       });
    })
  }

getApprovalNoteList(){
    this.loader = true;
    this.userService.getApprovalNoteList().subscribe(res=>{
        this.ApprovalNoteList =res;
        this.loader = false;
        // console.log('ApprovalNoteList List',this.ApprovalNoteList);
        this.ApprovalNoteListtableData = new MatTableDataSource<any>(this.ApprovalNoteList);
        this.ApprovalNoteListtableData.paginator = this.tableTwoPaginator;
        this.ApprovalNoteListtableData.sort = this.tableTwoSort;
       
        console.log('Mat Approval table Data',this.ApprovalNoteListtableData);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
            duration:5000
         });
    })
}

getrequestChangeList(){
    this.loader = true;
    this.userService.getapprovalnoteRequestChangeList().subscribe(res=>{
        this.req_for_changeList = res;
        this.loader = false;
        this.req_for_changeListtableData = new MatTableDataSource<any>(this.req_for_changeList);
        this.req_for_changeListtableData.paginator = this.tableFourPaginator;
        this.req_for_changeListtableData.sort = this.tableFourSort;
        console.log('getrequestChangeList',this.req_for_changeList);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}
getApprovedList(){
    this.loader = true;
    this.userService.getapprovalnoteApprovedList().subscribe(res=>{
        this.approvedList = res;
        this.loader = false;
        this.approvedListTableData = new MatTableDataSource<any>(this.approvedList);
        this.approvedListTableData.paginator = this.tableFivePaginator;
        this.approvedListTableData.sort = this.tableFiveSort;
        console.log('getApprovedList',this.approvedList);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}

getRejectedList(){

this.userService.getApprovalNoteRejectedList().subscribe(res=>{
    this.rejectList =res;
    this.RejectedListTableData = new MatTableDataSource<any>(this.rejectList);
        this.RejectedListTableData.paginator = this.tableSixPaginator;
        this.RejectedListTableData.sort = this.tableSixSort;
        console.log('getRejectList',this.rejectList);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}


getAllData(){
    this.getOwnerApprovalList();
    this.getApprovalNoteList();
    //this.getBudgetedList();
    this.getApprovedList();
    this.getrequestChangeList();
    this.getRejectedList();
}

// delegate(data, approveCategory = '') {

//     const testData = {
//         requisition_id: data.id,
//         approvecategory: approveCategory,
//         tableName: 'memo_sheet_approvals',
//         req_type : 'memo_sheet'
//     };
//    const dailog_ref =  this.dialog.open(DelegateModalComponent, {
//         data: testData
//     });
//     dailog_ref.afterClosed().subscribe(res=>{
//         this.getAllData();
//     })
// }

aprovalNoteArray:any=[]
editNoteApproval(data) {
    
//    console.log('id',data.id)
   
   
    this.userService.getApprovalNoteDetails(data.id).subscribe(res=>{
       
        // console.log('orginal response',res);
       
        console.log('response Modified',res);
        const dialogRef = this.dialog.open(ApprovalNoteComponent,{
            data: res,
            panelClass : "custom-modalbox"
        })
        dialogRef.afterClosed().subscribe(res=>{
            if(res == 'reload'){
                console.log('reload')
               this.getAllData();
             }
        })
    },err=>{
       this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })

  }

showDetail(data,permission?) {
  data.note_creatorPermission = this.note_creatorPermission;
  data.permission = permission; 
//   console.log('permission',data.permission)
//   console.log('data',data.id);
  this.userService.getApprovalNoteDetails(data.id).subscribe(details_data=>{
    //   console.log('newdata',details_data)

      const dialogRef = this.dialog.open(ApprovalNoteDetailsComponent,{
        data: {
            detail: details_data,
            approvecategory: data.permission,
        }
        // data: details_data,
        

}) 
            dialogRef.afterClosed().subscribe(res=>{
              if(res == 'reload'){
                //   console.log('close_ref',res);
                  this.getAllData();
              }
              else if(res == 'Owner_reload'){
                // console.log('close_ref',res);
                this.getOwnerApprovalList();
              }
            
           
    
    })
  })
    

}

special_approval_note(){
    const dialogRef = this.dialog.open(SpecialApprovalNoteComponent,{
        panelClass : "custom-modalbox"
    })
        dialogRef.afterClosed().subscribe(res=>{
            if(res == 'reload'){
                // console.log('close_ref',res);
                this.getOwnerApprovalList();
            }
    })
}
generate(element:any) {
    let transferObj = {
      subject: element.subject,
      reference : element.ref_no,
      created_by : element.created_by,
      requested_by : element.requested_by,
      budget_amount : element.budget_amount,
      amount : element.amount,
      tin : element.tin,
      bin_nid : element.bin_nid,
      memo_id : element.id
    }
    console.log('generete data',element);

    // this.sharedData.supplyData(element);
    this.router.navigate(['/create-note-sheet-requisition']);
    // this.commonService.MemoDataPass.emit(transferObj);
    // let encryptedData = CryptoES.AES.encrypt(JSON.stringify(transferObj), "mahbub").toString();
    // this.router.navigate(['/create-note-sheet-requisition'],{ queryParams:{memo: encryptedData}});
  }
CreatePaymentNote(data,permission?,usertype?) {
    data.paymentNoteCreate = true;
    data.permission = permission;
    // console.log('data',data);
    const dialogRef = this.dialog.open(PaymentNoteComponent,{
            data: data,
            panelClass : "custom-modalbox"
        })
            dialogRef.afterClosed().subscribe(res=>{
                if(res == 'reload'){
                    console.log('close_ref',res);
                   this.getAllData()
                }
              
             
      
        })
}

ngOnInit() {
        
        // console.log('ngoninit',this.route.snapshot.paramMap.get('visible'))
        const route=this.route.snapshot.paramMap.get('visible');
        if (route == "for_approve") {
            this.tabIndex = 1;
        }
        const admin_head_permission:any = sessionStorage.getItem('designation_id')
        if(admin_head_permission == 28){
            this.admin_head_permission = true;
        }
        this.columnsFieldName = this.displayedColumns.map(item => item.field);
        this.columnsFieldName.push('status'); 
        this.columnsFieldName.push('details');
       this.getAllData();
        
    }

    openSnackBar(message: string, action: string,time?:number) {
        this.snackBar.open(message, action, {
          duration : time
        });
    }
   


}

import { Component, OnInit } from '@angular/core';
import { DialogComponent } from '../dialog/dialog.component';
import { Gatepass } from '../model/gatePass';
import { Observable } from 'rxjs';
import { MatSnackBar, MatDialog } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { DetailsGatePassRequisitionComponent } from '../details-gate-pass-requisition/details-gate-pass-requisition.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-car-requisition',
  templateUrl: './view-car-requisition.component.html',
  styleUrls: ['./view-car-requisition.component.css']
})
export class ViewCarRequisitionComponent implements OnInit {
    template:boolean = true;
  loader: boolean;
    p = 1;
    carRequisitionList: any;
    carRequisitionApprovalList: any;
    displayedcarUserRequisitionColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    displayedcarRequisitionApprovalColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    requisitions: any;

    finalCarRequisition$: Observable<any>;
    approvalCarRequisition$: Observable<any>;
    recommendCarRequisition$: Observable<any>;
    ownerCarRequisition$: Observable<any>;
    adminCarRequisition$: Observable<any>;

    carUserRequisitionList: any;

    config: any = {
        url: 'pool_car',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName: 'car_requisitions'
    } ;


    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        public dialog: MatDialog,
        private route : ActivatedRoute,
    ) { }

    gatePassDetails(id) {
        // console.log(id);
        this.openUserViewerDialog(id);
    }

    openUserViewerDialog(id) {
        const dialogRef = this.dialog.open(DetailsGatePassRequisitionComponent, {
            // height: '40%',
            width: '70%',
            data: { id: id },
        });
    }

    ngOnInit() {
     //   this.getAllGatePassRequisition();
    //    this.finalCarRequisition$ = this.requisitionService.gateCarUserFinalRequisitionList();
    //    this.approvalCarRequisition$ = this.requisitionService.gateCarUserRequisitionApprovalList();
    //    this.recommendCarRequisition$ = this.requisitionService.gateCarUserRecommendRequisitionList();
    //    this.ownerCarRequisition$ = this.requisitionService.gateCarUserRequisitionList();
       // this.adminCarRequisition$ = this.requisitionService.gatePassRequisitionList();
       if (this.route.snapshot.paramMap.get('visible') !== null) {
        this.template = false;
    }
        this.requisitionService.channel.bind('App\\Events\\GatePassRequisition', data => {
            console.log('id card requisition:' + JSON.stringify(data));
            this.openSnackBar(' ' + JSON.stringify(data.userName) + ' Provide Gate Pass requisition', 'ok');
        });
    }

    // getAllGatePassRequisition() {
    //     this.loader = true;
    //     this.requisitionService.gatePassUserRequisitionList().subscribe(res => {

    //         // this.requisitions = res.length;
    //         this.loader = false;
    //         this.carUserRequisitionList = res;
    //     }, err => {
    //         this.loader = false;
    //         if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    //     });

    //     this.requisitionService.gatePassUserRecommendRequisitionList().subscribe(res => {
    //         console.log('Recommend list', res);
    //         // this.requisitions = res.length;
    //         this.loader = false;
    //         this.carRequisitionApprovalList = res;
    //     }, err => {
    //         this.loader = false;
    //         if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    //     });


    //     this.requisitionService.gatePassUserRequisitionApprovalList().subscribe(res => {
    //         console.log('Approval list', res);
    //         // this.requisitions = res.length;
    //         this.loader = false;
    //         this.carRequisitionApprovalList = res;
    //     }, err => {
    //         this.loader = false;
    //         if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    //     });
    // }

    giveApprove(data) {
      
        // this.requisitionService.gateCarRequisitionApprove(data).subscribe(res => {
        //     console.log(res);
        //     this.loader = false;
        //     this.openSnackBar(' Successfull ', 'ok');
        //     this.requisitionService.channel.bind('App\\Events\\GatePassRequisitionApprove', data => {
        //         // console.log("id card requisition:"+JSON.stringify(data));
        //         this.openSnackBar(' ' + JSON.stringify(data.userName) + ' Provide Gate Pass requisition approve', 'ok');
        //     });
        //   //  this.getAllGatePassRequisition();
        // }, err => {
        //     this.loader = false;
        //     if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
        // });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    showDetail(data, approveCategory = '') {
        // console.log('here',approveCategory);
        const testData = {
            To: data.final_approved_by,
            Date: data.date,
            Subject: data.subject,
            Description: data.description,
            Approve_by: data.approve_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            final_approval_status: data.final_approval_status,
            recommendation_status: data.recommendation_status,
            Category: null,
            approve_sig: data.approved_by_sig,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,
            approvecategory: approveCategory,
            url: this.config.url

        };
            this.dialog.open(DialogComponent, {
                data: testData
            });
    }

}

import { Component, OnInit,  ViewChild } from '@angular/core';
import { MatSnackBar, MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router,ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../animations';
import { PaymentNoteDetailsComponent } from '../payment-note-details/payment-note-details.component';
import { SpecialPaymentNoteComponent } from '../special-payment-note/special-payment-note.component';
import { PaymentNoteComponent } from '../payment-note/payment-note.component';
import { PaymentNoteEditComponent } from '../payment-note-edit/payment-note-edit.component';


@Component({
  selector: 'app-payment-note-list',
  templateUrl: './payment-note-list.component.html',
  styleUrls: ['./payment-note-list.component.css'],
  animations: fuseAnimations
})
export class PaymentNoteListComponent implements OnInit {
  loader: boolean;
  template:boolean = true;
  
  ApprovalPaymentListtableData: MatTableDataSource<any>;
  OwnerPaymentListtableData: MatTableDataSource<any>;
  CheckedListtableData: MatTableDataSource<any>;
  req_for_changeListtableData :  MatTableDataSource<any>;
  approvedListTableData :  MatTableDataSource<any>;
  rejectedListTableData : MatTableDataSource<any>;
  note_creatorPermission: boolean = false;
  displayedColumns:any= [{display : 'ID',field: 'id'},{display : 'Reference',field: 'ref_no'},{display : 'Requested By',field: 'created_by'},{display : 'Created Date',field: 'created_at'}];
  // note_creatorPermission: boolean = false;
  ApprovalPaymentList: any[]=[];
  CheckedList : any[] = [];
  OwnerPaymentList: any[]=[];
  req_for_changeList:any=[];
  approvedList:any=[];
  rejectedList:any=[];
  columnsFieldName: any[] = [];

  @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;

  @ViewChild('TableTwoPaginator', {static: true} as any) tableTwoPaginator: MatPaginator;
  @ViewChild('TableTwoSort', {static: true} as any) tableTwoSort: MatSort;

  @ViewChild('TableThreePaginator', {static: true} as any) tableThreePaginator: MatPaginator;
  @ViewChild('TableThreeSort', {static: true} as any) tableThreeSort: MatSort;
  @ViewChild('TableFourPaginator', {static: true} as any) tableFourPaginator: MatPaginator;
  @ViewChild('TableFourSort', {static: true} as any) tableFourSort: MatSort;
  @ViewChild('TableFivePaginator', {static: true} as any) tableFivePaginator: MatPaginator;
  @ViewChild('TableFiveSort', {static: true} as any) tableFiveSort: MatSort;
  @ViewChild('TableSixPaginator', {static: true} as any) tableSixPaginator: MatPaginator;
  @ViewChild('TableSixeSort', {static: true} as any) tableSixSort: MatSort;
admin_head_permission: boolean;
  tabIndex: number;

  constructor(
      public userService: UserService,
      private snackBar: MatSnackBar,
      private router: Router,
      private route: ActivatedRoute,
      public dialog: MatDialog,

     ) { }
// getCheckedList(){
// this.loader = true;
//   this.userService.Admin_head_paymentsNote().subscribe(res=>{
//       this.CheckedList =res;
//       this.loader = false;
//       console.log('getCheckedList',this.CheckedList);
//       this.CheckedListtableData = new MatTableDataSource<any>(this.CheckedList);
//       this.CheckedListtableData.paginator = this.tableThreePaginator;
//       this.CheckedListtableData.sort = this.tableThreeSort;
//       // this.tableData.sort = this.sort;
//       console.log('Mat Budgeted table Data',this.CheckedListtableData);
//   },err=>{ 
//       this.loader = false;
//       this.openSnackBar(err.error.message ? err.error.message : err.error,'ok',6000);
//   })
// }

getApprovalPaymentList(){
  this.loader = true;
  this.userService.getApprovalPaymentList().subscribe(res=>{
      this.ApprovalPaymentList =res;
      this.loader = false;
      console.log('ApprovalNoteList List',this.ApprovalPaymentList);
      this.ApprovalPaymentListtableData = new MatTableDataSource<any>(this.ApprovalPaymentList);
      this.ApprovalPaymentListtableData.paginator = this.tableTwoPaginator;
      this.ApprovalPaymentListtableData.sort = this.tableTwoSort;
      
      console.log('Mat Approval table Data',this.ApprovalPaymentListtableData);
  },err=>{ 
      this.loader = false;
      this.openSnackBar(err.error.message ? err.error.message : err.error,'ok',6000);
  })
}

getOwnerPaymentList(){
this.loader = true;
this.userService.getOwnerPaymentList().subscribe(res=>{ 
    this.OwnerPaymentList =res;
    console.log('getOwnerPaymentList',this.OwnerPaymentList);
    this.loader = false;
    this.OwnerPaymentListtableData = new MatTableDataSource<any>(this.OwnerPaymentList);
    this.OwnerPaymentListtableData.paginator = this.tableOnePaginator;
    this.OwnerPaymentListtableData.sort = this.tableOneSort;
    // this.tableData.sort = this.sort;
    // console.log('Mat Owner table Data',this.OwnerApprovalListtableData);
    // this.OwnerApprovalListtableData.paginator = this.paginator2;
},err=>{ 
    this.loader = false;
    this.openSnackBar(err.error.message ? err.error.message : err.error,'ok',6000);
})
}
// new added
getrequestChangeList(){
  this.loader = true;
  this.userService.getpaymentnoteRequestChangeList().subscribe(res=>{
      this.req_for_changeList = res;
      this.loader = false;
      this.req_for_changeListtableData = new MatTableDataSource<any>(this.req_for_changeList);
      this.req_for_changeListtableData.paginator = this.tableFourPaginator;
      this.req_for_changeListtableData.sort = this.tableFourSort;
      console.log('getrequestChangeList',this.req_for_changeList);
  },err=>{ 
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
  })
}
getApprovedList(){
  this.loader = true;
  this.userService.getpaymentnoteApprovedList().subscribe(res=>{
      this.approvedList = res;
      this.loader = false;
      this.approvedListTableData = new MatTableDataSource<any>(this.approvedList);
      this.approvedListTableData.paginator = this.tableFivePaginator;
      this.approvedListTableData.sort = this.tableFiveSort;
      console.log('getApprovedList',this.approvedList);
  },err=>{ 
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
  })
}

getRejectedList(){
  this.loader = true;
  this.userService.getpaymentnoteRejectedList().subscribe(res=>{
      this.rejectedList = res;
      this.loader = false;
      this.rejectedListTableData = new MatTableDataSource<any>(this.rejectedList);
      this.rejectedListTableData.paginator = this.tableSixPaginator;
      this.rejectedListTableData.sort = this.tableSixSort;
      console.log('getRejectedList',this.rejectedList);
  },err=>{ 
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
  })
}


showDetail(data,permission?) {
data.note_creatorPermission = this.note_creatorPermission;
data.permission = permission; 
console.log('data',data.id);
this.userService.getPaymentNoteDetails(data.id).subscribe(details_data=>{
  console.log('newdata',details_data)

  const dialogRef = this.dialog.open(PaymentNoteDetailsComponent,{
    data: {
        detail: details_data,
        main_row: data,
        approvecategory: data.permission,
        sheetType: 'Note'
    }
    // data: details_data,
    

}) 
        dialogRef.afterClosed().subscribe(res=>{
          if(res == 'reload'){
              console.log('close_ref',res);
              this.getAll();
          }
          else if(res == 'Owner_reload'){
            console.log('close_ref',res);
            this.getOwnerPaymentList();
          }
        
       

})
})
}

editPaymentNoteApproval(data) {
    
  console.log('id',data.id)
  
  
   this.userService.getPaymentNoteDetails(data.id).subscribe(res=>{
      
      //  console.log('orginal response',res);
      
       console.log('response Modified',res);
       const dialogRef = this.dialog.open(PaymentNoteEditComponent,{
           data: res,
           panelClass : "custom-modalbox"
       })
       dialogRef.afterClosed().subscribe(res=>{
           if(res == 'reload'){
               console.log('reload')
              this.getAll();
            }
       })
   },err=>{
      this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
   })

 }

special_payment_note(){
  const dialogRef = this.dialog.open(SpecialPaymentNoteComponent,{
      panelClass : "custom-modalbox"
  })
      dialogRef.afterClosed().subscribe(res=>{
          if(res == 'reload'){
              console.log('close_ref',res);
              this.getOwnerPaymentList();
          }
  })
}
// CreatePaymentNote(data,permission?,usertype?) {
//   data.paymentNoteCreate = true;
//   data.permission = permission;
//   console.log('data',data);
//   const dialogRef = this.dialog.open(ApprovalNoteComponent,{
//           data: data,
//           panelClass : "custom-modalbox"
//       })
//           dialogRef.afterClosed().subscribe(res=>{
//               if(res == 'reload'){
//                   console.log('close_ref',res);
//                   this.getOwnerApprovalList();
//                   this.getApprovalNoteList();
//                   this.getBudgetedList();
//               }
            
           
    
//       })
// }

editNote(id) {
  this.router.navigate(['edit-paymentnote/',id]);
  console.log('NoteEdit Id',id);
  }


getAll(){
  this.getOwnerPaymentList();
  this.getApprovalPaymentList();
  this.getRejectedList();
  this.getApprovedList();
  this.getrequestChangeList();
}
ngOnInit() {
      console.log(this.route.snapshot.paramMap.get('visible'))
      const route=this.route.snapshot.paramMap.get('visible');
      if (route == "for_approve") {
          this.tabIndex = 1;
      } 
      const admin_head_permission:any = sessionStorage.getItem('designation_id')
      if(admin_head_permission == 28){
          this.admin_head_permission = true;
      }
      this.columnsFieldName = this.displayedColumns.map(item => item.field);
      this.columnsFieldName.push('status'); 
      this.columnsFieldName.push('details');
      this.getAll()
      
  }

  openSnackBar(message: string, action: string,time?:number) {
      this.snackBar.open(message, action, {
        duration : time
      });
  }
 

}

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../services/user.service';
import { User } from '../model/user';
import { MatSnackBar, MatSelectChange } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { Router } from '@angular/router';
import { MeetingRoom } from '../model/meetingRoom';
import { DatePipe, formatDate } from '@angular/common';
import { throttleTime } from 'rxjs/operators';
import { fuseAnimations } from '../animations';
import { pick } from 'lodash';

@Component({
    selector: 'app-create-meeting-room-requisition',
    templateUrl: './create-meeting-room-requisition.component.html',
    styleUrls: ['./create-meeting-room-requisition.component.css'],
    animations : fuseAnimations,
})
export class CreateMeetingRoomRequisitionComponent implements OnInit {
    spinner : boolean;
    meetingRoomRequisitionForm: FormGroup;
    loader: boolean;
    employee: User[];
    // gatePassForm: FormGroup;
    employee_name: string;
    meetingRoom: MeetingRoom[];
    selectedMeetingRoom: any;
    finalapprovalauthority$;
    recommendationapprovalauthority$;
    approvalauthority$;

    @Output() selectionChange: EventEmitter<MatSelectChange>;
    room_info: any[]=[];
    additional_info: boolean;
    employee_spinner: boolean;
    users: User[]=[];


    constructor(
        private snackBar: MatSnackBar,
        public userService: UserService,
        private fb: FormBuilder,
        public requisitionService: RequisitionService,
        public router: Router,
        private datepipe: DatePipe
    ) { }

 processRequistion(){
                this.loader = true;
                const room = JSON.parse(this.meetingRoomRequisitionForm.value.room_no);
                this.meetingRoomRequisitionForm.get('meeting_date').setValue(formatDate(this.meetingRoomRequisitionForm.get('meeting_date').value ? this.meetingRoomRequisitionForm.get('meeting_date').value : new Date(),"yyyy-MM-dd","en"));
                const payload = Object(this.meetingRoomRequisitionForm.value);
                payload.url = 'meeting_room';
                payload.path = 'requisition_create';
                payload.room_no = room.room_title;
                payload.room_id = room.id;
                console.log("payload",payload);
                this.requisitionService.requisitionCreate(payload).subscribe(res => {
                    this.loader = false;
                    this.openSnackBar(res.message, 'ok');
                    this.router.navigate(['view-meeting-room-requisition']);
                  }, e => {
                    this.loader = false;
                    this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
                        duration : 5000
                      })
                });
        }
    createMRoom () {
            const today = new Date();
            const pickDate = new Date(this.selectDate);
            let pickDay = pickDate.getDate();
            let todayDay = today.getDate();
            let pickMonth = pickDate.getMonth();
            let todayMonth = today.getMonth();
            let hour = today.getHours();
            let minute = today.getMinutes();
        const meeting_start_time = (this.meetingRoomRequisitionForm.get('meeting_start_time').value).split(':');
            let pickHour = parseInt(meeting_start_time[0]);
            let pickMinute = parseInt(meeting_start_time[1]);
        const meeting_end_time = (this.meetingRoomRequisitionForm.get('meeting_end_time').value).split(':');
             let endpickHour = parseInt(meeting_end_time[0]);
            let endpickMinute = parseInt(meeting_end_time[1]);
console.log('pickDay',pickDay,'todayDay',todayDay,'pickMonth',pickMonth,'todayMonth',todayMonth,'hour',hour,'minute',minute,'pickHour',pickHour,'pickMinute',pickMinute,'endpickHour',endpickHour,'endpickMinute',endpickMinute);
            
    if(this.meetingRoomRequisitionForm.invalid){
            this.openSnackBar('Please fill up the all required field then submit','ok');
    }else if(pickDay === todayDay && pickMonth === todayMonth){
            if((hour === pickHour && pickMinute < minute) || (hour > pickHour) ){
                    this.snackBar.open('You can not select previous time for meeting','ok',{
                        duration : 2000
                    })
            }else if ((endpickHour === pickHour && pickMinute > endpickMinute) || (endpickHour < pickHour)){
                this.snackBar.open('You can not select meeting end time less time of start time','ok',{
                    duration : 2000
                })
            }else{
                this.processRequistion();
            }
    }else{
        this.processRequistion();
    } 
      
}

   
    getEmployeeList() {
        this.loader = true;
        this.userService.getUserList()
            .subscribe(data => {
                console.log(data);
                this.loader = false;
                this.employee = data;
            }, e => {
                this.loader = false;
                this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
                    duration : 5000
                  })
            });
    }
    getMeetingRoomList() {
        this.loader = true;
        this.userService.getMeetingRoomList()
            .subscribe(data => {
                console.log(data);
                this.loader = false;
                this.meetingRoom = data;
            }, e => {
                this.loader = false;
                this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
                    duration : 5000
                  })
            });
    }
    selectDate:any
    dateChange(event){
        this.selectDate = event.target.value;
        const selectedTime = new Date(event.target.value).getTime() + 100000000;

        console.log('Selected time',selectedTime);
        const today = new Date().getTime();
        console.log('current time',today);

            if(selectedTime > today){
                console.log('ok',selectedTime);
                
            }else{
                this.openSnackBar('You can not select previous date for meeting','ok');
                this.meetingRoomRequisitionForm.get('meeting_date').reset();   
            }  
            
    }
    selectMeetingRoom(value){
        if(this.meetingRoomRequisitionForm.get('meeting_date').value){
            const room = JSON.parse(value);
            console.log('room',room);
            this.selectedMeetingRoom = room;
            this.additional_info = true;
            const payload ={
                date : formatDate(this.meetingRoomRequisitionForm.get('meeting_date').value,'yyyy-MM-dd','en'),
                room_id : this.selectedMeetingRoom.id,
                room_title : this.selectedMeetingRoom.room_title
            }
            console.log('payload',payload);
            // this.loader = true;
            this.spinner = true;
            this.userService.get_available_room(payload).subscribe(res=>{
                console.log('res',res);
                this.spinner = false;
                this.room_info = res;
                // this.loader = false;
            })
        }else{
            this.openSnackBar('Please Select Meetting Date first','ok');
            this.meetingRoomRequisitionForm.get('room_no').reset();
        }
    }

    getEmployee(id) {
        this.loader = true;
        this.userService.getParticularUser(id)
            .subscribe(data => {
                console.log(data);
                this.loader = false;
                this.employee_name = data[0].f_name + ' ' + data[0].l_name;
                this.meetingRoomRequisitionForm.patchValue({
                    name: this.employee_name,
                    department: data[0].dept_name,
                    designation: data[0].title,
                });
            }, e => {
                this.loader = false;
                this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
                    duration : 5000
                  })
            });
    }

    getUserList(){
        this.employee_spinner = true;
        this.userService.getUsers()
          .subscribe(data => {
            console.log('user_short_info');
            this.employee_spinner = false;
            this.users = data;
          }, err => {
            this.employee_spinner = false;
            this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
              duration : 5000
            })
          });
      }
      selected_recomended_by(data){
        this.meetingRoomRequisitionForm.get('recommended_by').setValue(data);
        console.log('form value',this.meetingRoomRequisitionForm.value);
        
      }
      selected_approved_by(data){
        this.meetingRoomRequisitionForm.get('approved_by').setValue(data);
        console.log('form value approved by',this.meetingRoomRequisitionForm.value);
      }
      
      ngOnInit() {
          this.getUserList();
        this.getEmployeeList();
        this.getMeetingRoomList();
        this.approvalauthority$ = this.userService.getFinalApproveauthoritylist('meeting_room');
        this.recommendationapprovalauthority$ = this.userService.getRecommendationApproveauthoritylist('meeting_room');
        // this.approvalauthority$ = this.userService.getApproveApproveauthoritylist('meeting_room');
        this.meetingRoomRequisitionForm = this.fb.group({
            name: [''],
            room_no: ['', Validators.required],
            purpose: ['', Validators.required],
            meeting_date: ['', Validators.required],
            meeting_start_time: ['', Validators.required],
            meeting_end_time: ['', Validators.required],
            approved_by: ['', Validators.required],
            recommended_by: ['',Validators.required],
            final_approved_by: ['']
            /*         file: [null] */
        });
        const id = +sessionStorage.getItem('user_id');
        this.getEmployee(id);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }


}

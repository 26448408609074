declare const Pusher: any;
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { api } from '../commonUrl';
import { Observable } from 'rxjs';
import { IdCardRequisition } from '../model/idCardRequisition';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Gatepass } from '../model/gatePass';
import { OfficeItemRequisition } from '../model/officeItemRequisition';
import { VisitingCardRequisition } from '../model/visitingCardRequisition';
import { MeetingRoomRequisition } from '../model/meetingRoomRequisition';
import { FoodRequisition } from '../model/foodRequisition';
import { PoolCarRequisition } from '../model/poolCarRequisition';
import { RequisitionNotification } from '../model/notification';
import { shareReplay } from 'rxjs/operators';


const header: any = new HttpHeaders();
header.append('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})

export class RequisitionService {

  pusher: any;
  channel: any;

  constructor(
    private http: HttpClient,
    public router: Router,
  ) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true
    });
    this.channel = this.pusher.subscribe('my-channel'); 
    console.log(this.channel);
  }


  getRequisitionNotification(): Observable<RequisitionNotification[]> {
    return this.http.get<RequisitionNotification[]>(api + 'user/notifications', {headers: header});
  }

  getNotificationDetail(data) {
    return this.http.get(api + 'notification/requisition_details/' + data.requisition_type + '/' + data.requisition_id, {headers: header});
  }
  requisitionApprove(data: any) {
    return this.http.post(api + data.url + '/' + data.approvecategory, data, {headers: header});
  }

  requisitionCreate(data: any) : Observable <any> {
    return this.http.post <any> (api + data.url + '/' + data.path, data, {headers: header});
  }

  requisitionList(url, path): Observable<[]>  {
    return this.http.get<[]>(api + url + '/' + path, {headers: header});
  }

  memoCreate(data: any):Observable<any>  {
    return this.http.post(api +  'memo_sheet/requisition_create', data, {headers: header,reportProgress: true,
      observe: 'events'});
  }

  notesheetCreate(data: any):Observable<any>  {
    return this.http.post(api +  'note_sheet/requisition_create', data, {headers: header,reportProgress: true,
      observe: 'events',});
  }
  officeItemNoteSheetCreate(data: any):Observable<any>  {
    return this.http.post(api +  'item/payment_note_create', data, {headers: header,reportProgress: true,
      observe: 'events',});
  }

  procesUdetail (data: any) {
    return this.http.get(api +  'user/get_process_users/' + data.id, {headers: header});
  }

  //////////////////////////////////////////////////////////////////////////////////////

  // gatePassUserRequisitionList(): Observable<Gatepass[]> {

  //   return this.http.get<Gatepass[]>(api + 'gate_pass/user_requisitions', {headers: header});
  // }

  // gatePassUserRecommendRequisitionList(): Observable<Gatepass[]> {
  //   return this.http.get<Gatepass[]>(api + 'gate_pass/user_requisition_for_recommendation', {headers: header});
  // }

  // gatePassUserRequisitionApprovalList(): Observable<Gatepass[]> {
  //   return this.http.get<Gatepass[]>(api + 'gate_pass/user_requisition_approvals', {headers: header});
  // }

  // gatePassUserFinalRequisitionList(): Observable<Gatepass[]> {
  //   return this.http.get<Gatepass[]>(api + 'gate_pass/user_requisition_final_approvals', {headers: header});
  // }


  gatePassRequisitionDetailByID(id) {
    return this.http.get<Gatepass[]>(api + 'gate_pass/requisition_details/' + id, {headers: header});
  }
//////////////////////////////////////////////////////////////////////////////////////


  // idcardRequisitionList(): Observable<IdCardRequisition[]> {
  //   return this.http.get<IdCardRequisition[]>(api + 'id_card/requisition_list', {headers: header});
  // }

  // idcardfinalapprovalRequisitionList()  {
  //   return this.http.get<IdCardRequisition[]>(api + 'id_card/user_requisition_final_approvals', {headers: header});
  // }

  // idcardApproveRequisitionList()  {
  //   return this.http.get<IdCardRequisition[]>(api + 'id_card/user_requisition_approvals', {headers: header});
  // }

  // idcardrecommendRequisitionList() {
  //   return this.http.get<IdCardRequisition[]>(api + 'id_card/user_requisition_for_recommendation', {headers: header});
  // }

  //////////////////////////////////////////////////////////////////////////////////////
  // visiting card services **************************************//////////////////////
  //////////////////////////////////////////////////////////////////////////////////////
  // visitingCardRequisitionList(): Observable<VisitingCardRequisition[]> {
  //   return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/requisition_list', {headers: header});
  // }


  // visitingCardrecommendRequisitionList(): Observable<VisitingCardRequisition[]> {
  //   return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/user_requisition_for_recommendation', {headers: header});
  // }
  // visitingCardapproveRequisitionList(): Observable<VisitingCardRequisition[]> {
  //   return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/user_requisition_approvals', {headers: header});
  // }
  // visitingCardfinalapproveRequisitionList(): Observable<VisitingCardRequisition[]> {
  //   return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/user_requisition_final_approvals', {headers: header});

  // }

  // visitingCarduserRequisitionList() {
  //   return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/user_requisitions', {headers: header});
  // }

/////////////////////////////////////////////////////////////////////////////////////////
  officeItemRequisitionCreate(data):any {
    return this.http.post(api + 'item/requisition_create', data, {headers: header});
  }
  officeItemRequisitionEdit(data):any {
    return this.http.post(api + 'item/requisition_edit', data, {headers: header});
  }


  // officeItemRequisitionList(): Observable<OfficeItemRequisition[]> {
  //   return this.http.get<OfficeItemRequisition[]>(api + 'item/requisition_list', {headers: header});
  // }

  // officeItemUserRequisitionList(): Observable<OfficeItemRequisition[]> {
  //   return this.http.get<OfficeItemRequisition[]>(api + 'item/user_requisitions', {headers: header});
  // }

  // officeItemUserRecommendRequisitionList(): Observable<OfficeItemRequisition[]> {
  //   return this.http.get<OfficeItemRequisition[]>(api + 'item/user_requisition_acknowledge', {headers: header});
  // }

  // officeItemRequisitionApprovalList(): Observable<OfficeItemRequisition[]> {
  //   return this.http.get<OfficeItemRequisition[]>(api + 'item/user_requisition_approvals', {headers: header});
  // }


  // officeItemRequisitionFinalApprovalList(): Observable<OfficeItemRequisition[]> {
  //   return this.http.get<OfficeItemRequisition[]>(api + 'item/user_requisition_final_approvals', {headers: header});
  // }

  // poolCarRequisitionCreate(data) {
  //   return this.http.post(api + 'pool_car/create', data, {headers: header});
  // }

  officeItemRequisitionDetails(data): Observable<OfficeItemRequisition[]> {
    return this.http.get<OfficeItemRequisition[]>(api + 'item/requisition_details/' + data, {headers: header});
  }



  poolCarRequisitionVheicleAllotCreate(data) {
    return this.http.post(api + 'pool_car/vehicle_allot', data, {headers: header});
  }

  // getSpecificPoolCarVheicleAllotment(data): Observable<PoolCarRequisition[]> {
  //   return this.http.get<PoolCarRequisition[]>(api + 'pool_car/requisition_info/' + data, {headers: header});
  // }

  // poolCarUserRequisitionList(path = ''): Observable<PoolCarRequisition[]> {
  //   return this.http.get<PoolCarRequisition[]>(api + 'pool_car/' + path, {headers: header});
  // }

  // poolCarRequisitionRecomandationList(): Observable<PoolCarRequisition[]> {
  //   return this.http.get<PoolCarRequisition[]>(api + 'pool_car/user_requisition_for_recommendation', {headers: header});
  // }
  // poolCarRequisitionApprovalList(): Observable<PoolCarRequisition[]> {
  //   return this.http.get<PoolCarRequisition[]>(api + 'pool_car/user_requisition_approvals', {headers: header});
  // }

  getSpecificPoolCarVheicleAllotment(data): Observable<PoolCarRequisition[]> {
    return this.http.get<PoolCarRequisition[]>(api + 'pool_car/requisition_info/' + data, {headers: header});
  }

  // meetingRoomRequisitionCreate(data) {
  //   return this.http.post(api + 'meeting_room/requisition_create', data, {headers: header});
  // }
  // meetingRoomRequisitionList(): Observable<MeetingRoomRequisition[]> {
  //   return this.http.get<MeetingRoomRequisition[]>(api + 'meeting_room/requisition_list', {headers: header});
  // }

  // meetingRoomRequisitionApprove(data) {
  //   return this.http.post(api + 'meeting_room/requisition_approve', data, {headers: header});
  // }

  // foodRequisitionList(): Observable<FoodRequisition[]> {
  //   return this.http.get<FoodRequisition[]>(api + 'food/requisition_list', {headers: header});
  // }


  // foodUserRequisitionList(): Observable<FoodRequisition[]> {
  //   return this.http.get<FoodRequisition[]>(api + 'food/user_requisitions', {headers: header});
  // }

  // foodUserRequisitionApprovalList(): Observable<FoodRequisition[]> {
  //   return this.http.get<FoodRequisition[]>(api + 'food/user_requisition_approvals', {headers: header});
  // }

  // foodUserRecommendRequisitionList(): Observable<FoodRequisition[]> {
  //   return this.http.get<FoodRequisition[]>(api + 'food/user_requisition_for_recommendation', {headers: header});
  // }

  // foodUserFinalRequisitionList(): Observable<FoodRequisition[]> {
  //   return this.http.get<FoodRequisition[]>(api + 'food/user_requisition_final_approvals', {headers: header});
  // }


  getSpecificGetPass(data): Observable<Gatepass[]> {
    return this.http.get<Gatepass[]>(api + 'gate_pass/requisition_details/' + data, {headers: header});
  }

  getSpecificOfficeItem(data): Observable<OfficeItemRequisition[]> {
    return this.http.get<OfficeItemRequisition[]>(api + 'item/requisition_details/' + data, {headers: header});
  }

  getSpecificVisitingCard(data): Observable<VisitingCardRequisition[]> {
    return this.http.get<VisitingCardRequisition[]>(api + 'visiting_card/requisition_details/' + data, {headers: header});
  }

  getSpecificMeetingRoom(data): Observable<MeetingRoomRequisition[]> {
    return this.http.get<MeetingRoomRequisition[]>(api + 'meeting_room/requisition_details/' + data, {headers: header});
  }

  getSpecificfoodItem(data): Observable<FoodRequisition[]> {
    return this.http.get<FoodRequisition[]>(api + 'food/requisition_details/' + data, {headers: header});
  }
  getSpecificIdCard(data): Observable<IdCardRequisition[]> {
    return this.http.get<IdCardRequisition[]>(api + 'id_card/requisition_details/' + data, {headers: header});
  }


}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
          public dialog_ref : MatDialogRef<ConfirmDialogComponent>
          ) {
            this.dialog_ref.addPanelClass('custom-modalbox');
            // this.dialog_ref.updateSize('250');
           }
close(status:any){
  this.dialog_ref.close(status);
}
  ngOnInit() {
  }

}

import { CommonService } from './../services/common.service';
import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChildren, QueryList } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { dummy } from '../commonUrl';
import { dummyphotoUrl } from '../commonUrl';
import { RequisitionService } from '../services/requisition.service';
import { MatSnackBar, MatDialog, MatSidenav } from '@angular/material';
import { RequisitionNotification } from '../model/notification';
import { User } from '../model/user';
import { NotificationModalComponent } from '../notification-modal/notification-modal.component';
import { PasswordChangeComponent } from '../password-change/password-change.component';
// import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnDestroy, OnInit {

    mobileQuery: MediaQueryList;
    Dummyurl = dummy;
    dummyphotoUrl = dummyphotoUrl;

    requisitionGivenBy: string;
    numberRequest: number;
    NotificationList: RequisitionNotification[];
    user: User[];
    dashboard_Menu : boolean = false;
    administration_menu : boolean = false;
    purshase_menu : boolean = false;
    generel_requisition : boolean = false;
    reports : boolean = false;
    private _mobileQueryListener: () => void;
    loader: boolean;
    @ViewChildren('start') sc: QueryList<MatSidenav>;

    permission : any
    loginData: any;
    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public router: Router,
        public userService: UserService,
        public requisitionService: RequisitionService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public passwordChangeDialog: MatDialog,
        public commonService: CommonService
    ) {
        this.navigationPermission();
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);

    }
    viewProifle(){
       this.router.navigate(['profile']);
    }
    passwordChange(){
        const data ={
            type : 'password_change'
          }
        const dialog_ref = this.passwordChangeDialog.open(PasswordChangeComponent,{
            panelClass :  'custom-modalbox',
            data : data
        })
        dialog_ref.afterClosed().subscribe(res=>{
            console.log('dialogClose',res);

        })
    }
    afterLogin() {
        this.userService.isLoggedIn = sessionStorage.getItem('token') ? true : false;
        console.log('isLoggedIn',this.userService.isLoggedIn);
        if (this.userService.isLoggedIn) {
            this.getUser(sessionStorage.getItem('user_id'));
            this.getNotificationList();
            this.requisitionService.channel.bind('App\\Events\\IdCardRequisitionCreate', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide ID card requisition';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });
            this.requisitionService.channel.bind('App\\Events\\ IdCardRequisitionApprove', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide ID card requisition approve';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });
            this.requisitionService.channel.bind('App\\Events\\GatePassRequisition', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide gate pass requisition ';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });
            this.requisitionService.channel.bind('App\\Events\\GatePassRequisitionApprove', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide gate pass requisition approval ';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });
            this.requisitionService.channel.bind('App\\Events\\FoodRequisitionCreate', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide food requisition  ';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });
            this.requisitionService.channel.bind('App\\Events\\FoodRequisitionApprove', data => {
                console.log('id card requisition:' + JSON.stringify(data));
                this.requisitionGivenBy = '' + JSON.stringify(data.userName) + ' Provide food requisition approval ';
                // this.openSnackBar(" "+JSON.stringify(data), "ok");
            });

            this.userService.notificationCountUpdate.subscribe((data) => {
                this.getNotificationList();
            });
        }
    }
    NavigationPermissionset(){
        this.loader = true;
        this.dashboard_Menu  = false;
        this.administration_menu  = false;
        this.purshase_menu = false;
        this.generel_requisition  = false;
        this.reports  = false;
        this.userService.getpermission().subscribe(data=>{
          console.log('permission_data',data);
            data.permissions.forEach(item => {
                switch (item.menu_id) {
                    case 1:
                        this.dashboard_Menu = true;
                        break;
                    case 2:
                        this.administration_menu = true;
                        break;
                    case 3:
                        this.purshase_menu = true;
                        break;
                    case 4:
                            this.generel_requisition = true;
                        break;
                    default:
                        this.reports = true;
                        break;
                }
            });
            this.loader = false;
        },err=>{
            this.loader = false;
            if(sessionStorage.getItem('token')){
                window.alert('Something Went worng, Please check your internet connection');
            this.snackBar.open(err.error.message ? err.error.message :JSON.stringify(err.error),'ok',{duration:5000});
            }

        })

    }

    ngOnInit() {
        this.afterLogin();
        this.NavigationPermissionset();
        this.commonService.loginEvent.subscribe((res) => {
            this.afterLogin();
            this.NavigationPermissionset();
            this.loginData = res;

        });
    }
    getNotificationList() {

        this.loader = true;
        this.requisitionService.getRequisitionNotification().subscribe(res => {
        //    console.log("Niloy",res);
            this.NotificationList = res;
            this.numberRequest = res.length;
            this.loader = false;
            // this.OfficeItemUserRequisitionList = res;
        }, err => {
            this.loader = false;
            if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
        });
    }

    getUser(id) {

        this.loader = true;
        this.userService.getParticualrUser(id).subscribe(res => {
            console.log("User",res);
            // this.NotificationList = res;
            this.loader = false;
            this.user = res;
        }, err => {
            this.loader = false;
            if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
        });

    }

    logout(): void {
        this.userService.isLoggedIn = false;
        sessionStorage.clear();
        this.userService.userPermission = [];
        /* sessionStorage.removeItem('token');
        sessionStorage.removeItem('user_type');
        sessionStorage.removeItem('user_id'); */
        this.router.navigate(['/login']);
        // reset user permission array
        // sessionStorage.clear();
        // this.resetsessionStorage(); // reset loaclstorage
        //location.reload(); // reload browser
    }

    showNotificationModal(data) {
        this.dialog.open(NotificationModalComponent, {
            data: data,
            panelClass : 'custom-modalbox',
        });
    }

    navigationPermission() {

        // user
        if (sessionStorage.getItem('isAddUser') === 'true') {
            this.userService.isViewDashboard = true;
        }
        if (sessionStorage.getItem('isAddUser') === 'true') {
            this.userService.isAddUserPermission = true;
        }
        if (sessionStorage.getItem('isViewUser') === 'true') {
            this.userService.isViewUserPermission = true;
        }
        if (sessionStorage.getItem('isAddUserRole') === 'true') {
            this.userService.isAddUserRolePermission = true;
        }
        if (sessionStorage.getItem('isViewUserRole') === 'true') {
            this.userService.isViewUserRolePermission = true;
        }
        if (sessionStorage.getItem('isAddDepartment') === 'true') {
            this.userService.isAddDepartmentPermission = true;
        }
        if (sessionStorage.getItem('isViewDepartment') === 'true') {
            this.userService.isViewDepartmentPermission = true;
        }
        if (sessionStorage.getItem('isAddDesignation') === 'true') {
            this.userService.isAddDesignationPermission = true;
        }
        if (sessionStorage.getItem('isViewDesignation') === 'true') {
            this.userService.isViewDesignationPermission = true;
        }
        if (sessionStorage.getItem('isEditDesignation') === 'true') {
            this.userService.isEditDesignationPermission = true;
        }
        if (sessionStorage.getItem('isAddVehicle') === 'true') {
            this.userService.isAddVehiclePermission = true;
        }
        if (sessionStorage.getItem('isViewVehicle') === 'true') {
            this.userService.isViewVehiclePermission = true;
        }
        if (sessionStorage.getItem('isAddVehicleType') === 'true') {
            this.userService.isAddVehicleTypePermission = true;
        }
        if (sessionStorage.getItem('isViewVehicleType') === 'true') {
            this.userService.isViewVehicleTypePermission = true;
            sessionStorage.setItem('isViewVehicleType', 'true');
        }
        if (sessionStorage.getItem('isAddOfficeItem') === 'true') {
            this.userService.isAddOfficeItemPermission = true;
        }
        if (sessionStorage.getItem('isViewOfficeItem') === 'true') {
            this.userService.isViewOfficeItemPermission = true;
        }
        if (sessionStorage.getItem('isAddMeetingRoom') === 'true') {
            this.userService.isAddMeetingRoomPermission = true;
        }
        if (sessionStorage.getItem('isViewMeetingRoom') === 'true') {
            this.userService.isViewMeetingRoomPermission = true;
        }
        // Generel Requisition
        if (sessionStorage.getItem('isAddOfficeItemRequisition') === 'true') {
            this.userService.isAddOfficeItemRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewOfficeItemRequisition') === 'true') {
            this.userService.isViewOfficeItemRequisitionPermission = true;
        }
        if (sessionStorage.getItem('ApprovalNoteListPermission') === 'true') {
            this.userService.ApprovalNoteListPermission = true;
        }
        if (sessionStorage.getItem('isPayment_note_permission') === 'true') {
            this.userService.isPayment_note_permission = true;
        }
        if (sessionStorage.getItem('isAddGatePassRequisition') === 'true') {
            this.userService.isAddGatePassRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewGatePassRequisition') === 'true') {
            this.userService.isViewGatePassRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddVisitingCardRequisition') === 'true') {
            this.userService.isAddVisitingCardRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewVisitingCardRequisition') === 'true') {
            this.userService.isViewVisitingCardRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddMeetingRoomRequisition') === 'true') {
            this.userService.isAddMeetingRoomRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewMeetingRoomRequisition') === 'true') {
            this.userService.isViewMeetingRoomRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddPoolCarRequisition') === 'true') {
            this.userService.isAddPoolCarRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewPoolCarRequisition') === 'true') {
            this.userService.isViewPoolCarRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddCarRequisition') === 'true') {
            this.userService.isAddCarRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewCarRequisition') === 'true') {
            this.userService.isViewCarRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddFoodRequisition') === 'true') {
            this.userService.isAddFoodRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewFoodRequisition') === 'true') {
            this.userService.isViewFoodRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddIdCardRequisition') === 'true') {
            this.userService.isAddIdCardRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewIdCardRequisition') === 'true') {
            this.userService.isViewIdCardRequisitionPermission = true;
        }
        // Purchase Requisition
        if (sessionStorage.getItem('isAddMemoSheetRequisition') === 'true') {
            this.userService.isAddMemoSheetRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewMemoSheetRequisition') === 'true') {
            this.userService.isViewMemoSheetRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isAddNoteSheetRequisition') === 'true') {
            this.userService.isAddNoteSheetRequisitionPermission = true;
        }
        if (sessionStorage.getItem('isViewNoteSheetRequisition') === 'true') {
            this.userService.isViewNoteSheetRequisitionPermission = true;
        }
        // for office item report
        if (sessionStorage.getItem('rep_pendingpayment_note') === 'true') {
            this.userService.rep_pendingpayment_note = true;
        }
        if (sessionStorage.getItem('rep_officeitem_itemwise') === 'true') {
            this.userService.rep_officeitem_itemwise = true;
        }
        if (sessionStorage.getItem('rep_officeitem_employeewise') === 'true') {
            this.userService.rep_officeitem_employeewise = true;
        }
        if (sessionStorage.getItem('rep_officeitem_durationwise') === 'true') {
            this.userService.rep_officeitem_durationwise = true;
        }
        if (sessionStorage.getItem('rep_officeitem_approvalauthoritywise') === 'true') {
            this.userService.rep_officeitem_approvalauthoritywise = true;
        }
         // for Gatepass report
         if (sessionStorage.getItem('rep_gatePass_itemwise') === 'true') {
            this.userService.rep_gatePass_itemwise = true;
        }
        if (sessionStorage.getItem('rep_gatePass_employeewise') === 'true') {
            this.userService.rep_gatePass_employeewise = true;
        }
        if (sessionStorage.getItem('rep_gatePass_durationwise') === 'true') {
            this.userService.rep_gatePass_durationwise = true;
        }
        if (sessionStorage.getItem('rep_gatePass_approvalauthoritywise') === 'true') {
            this.userService.rep_gatePass_approvalauthoritywise = true;
        }
        // Other's
        if (sessionStorage.getItem('report_meetingromm') === 'true') {
            this.userService.report_meetingromm = true;
        }
        if (sessionStorage.getItem('report_food') === 'true') {
            this.userService.report_food = true;
        }
        if (sessionStorage.getItem('report_visitingCard') === 'true') {
            this.userService.report_visitingCard = true;
        }
        if (sessionStorage.getItem('report_id_card') === 'true') {
            this.userService.report_id_card = true;
        }
        if (sessionStorage.getItem('generel_notes_report') === 'true') {
            this.userService.generel_notes_report = true;
        }


    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }


    /*  resetsessionStorage() {
       //user
      sessionStorage.setItem('isAddUser','false')
      sessionStorage.setItem('isViewUser','false')
      sessionStorage.setItem('isAddUserRole','false')
      sessionStorage.setItem('isViewUserRole','false')
      sessionStorage.setItem('isAddDepartment','false')
      sessionStorage.setItem('isViewDepartment','false')
      sessionStorage.setItem('isAddDesignation','false')
      sessionStorage.setItem('isViewDesignation','false')
      sessionStorage.setItem('isEditDesignation','false')
      sessionStorage.setItem('isAddVehicle','false')
      sessionStorage.setItem('isViewVehicle','false')
      sessionStorage.setItem('isAddVehicleType','false')
      sessionStorage.setItem('isViewVehicleType','false')
      sessionStorage.setItem('isAddOfficeItem','false')
      sessionStorage.setItem('isViewOfficeItem','false')
      sessionStorage.setItem('isAddMeetingRoom','false')
      sessionStorage.setItem('isViewMeetingRoom','false')
      //Generel Requisition
      sessionStorage.setItem('isAddOfficeItemRequisition','false')
      sessionStorage.setItem('isViewOfficeItemRequisition','false')
      sessionStorage.setItem('isAddGatePassRequisition','false')
      sessionStorage.setItem('isViewGatePassRequisition','false')
      sessionStorage.setItem('isAddVisitingCardRequisition','false')
      sessionStorage.setItem('isViewVisitingCardRequisition','false')
      sessionStorage.setItem('isAddMeetingRoomRequisition','false')
      sessionStorage.setItem('isViewMeetingRoomRequisition','false')
      sessionStorage.setItem('isAddPoolCarRequisition','false')
      sessionStorage.setItem('isViewPoolCarRequisition','false')
      sessionStorage.setItem('isViewPoolCarRequisitionDetailsPermission','false')
      sessionStorage.setItem('isAddCarRequisition','false')
      sessionStorage.setItem('isViewCarRequisition','false')
      sessionStorage.setItem('isAddFoodRequisition','false')
      sessionStorage.setItem('isViewFoodRequisition','false')
      sessionStorage.setItem('isAddIdCardRequisition','false')
      sessionStorage.setItem('isViewIdCardRequisition','false')
      //Purchase Requisition
      sessionStorage.setItem('isAddMemoSheetRequisition','false')
      sessionStorage.setItem('isViewMemoSheetRequisition','false')
      sessionStorage.setItem('isAddNoteSheetRequisition','false')
      sessionStorage.setItem('isViewNoteSheetRequisition','false')

    } */

}

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  loginEvent = new EventEmitter();
  approvalEvent = new EventEmitter();
  rejectEvent = new EventEmitter();
  refreshEvent = new EventEmitter();
  OwnerTabRemove = new EventEmitter();
  MemoDataPass = new EventEmitter();
  constructor() { }
}

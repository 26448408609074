import { Component, OnInit } from '@angular/core';
import { User } from '../model/user';
import { MatSnackBar } from '@angular/material';
import { DatePipe, formatDate } from '@angular/common';
import { UserService } from '../services/user.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RequisitionService } from '../services/requisition.service';
import { Router } from '@angular/router';
import { Vehicle } from '../model/vehicle';
import { fuseAnimations } from '../animations';

// import { AmazingTimePickerService } from 'amazing-time-picker';

@Component({
  selector: 'app-create-pool-car-requisition',
  templateUrl: './create-pool-car-requisition.component.html',
  styleUrls: ['./create-pool-car-requisition.component.css'],
  animations :  fuseAnimations
})
export class CreatePoolCarRequisitionComponent implements OnInit {
  loader: boolean;
  employee: User[];
  poolCarForm: FormGroup;
  finalapprovalauthority$;
  recommendationapprovalauthority$;
  approvalauthority$;
  selectDate: any;
  vehicleList: Vehicle[]=[];
  spinner: boolean;
  additional_info: boolean;

  timeinfo: any = [];
  selectedVehicle: any;
  users: User[]=[];
  employee_spinner: boolean;


  constructor(
    private snackBar: MatSnackBar,
    public userService: UserService,
    private fb: FormBuilder,
    public requisitionService:  RequisitionService,
    public router: Router,
    public datepipe: DatePipe,
    // public atp: AmazingTimePickerService,
   
  ) { }
  // for time picker programatical open
//   formTime: string = "06:00";
//   Fromtime() {
//     const amazingTimePicker = this.atp.open();
//     amazingTimePicker.afterClose().subscribe(time => {
//         this.formTime = time;
//     });
// }

  createPoolCarRequisition(data) {
    this.loader = true;
    this.requisitionService.requisitionCreate(data)
      .subscribe(res => {
        this.loader = false;
        this.openSnackBar(res.message,'ok');
        this.router.navigate(['view-pool-car-requisition']);
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
    });
  }
  getEmployeeList() {
    this.loader = true;
    this.userService.getUserList()
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.employee = data;
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message :  JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      });
  }
processRequistion(){
  console.log(this.poolCarForm.value);
  this.poolCarForm.get('use_date').setValue(formatDate(this.poolCarForm.get('use_date').value ? this.poolCarForm.get('use_date').value : new Date(),'yyyy-MM-dd','en'));
    // this.poolCarForm.value.vehicle = this.poolCarForm.value.vehicle.id 
    this.poolCarForm.value.vehicle = this.poolCarForm.value.vehicle.id 
    this.poolCarForm.value.url = 'pool_car';
    this.poolCarForm.value.path = 'create';
    console.log('submit_payload',this.poolCarForm.value);
    
  this.createPoolCarRequisition(this.poolCarForm.value);
}
  poolCarFormSubmit() {
    const today = new Date();
    const pickDate = new Date(this.selectDate);
    let pickDay = pickDate.getDate();
    let todayDay = today.getDate();
    let pickMonth = pickDate.getMonth();
    let todayMonth = today.getMonth();
    let hour = today.getHours();
    let minute = today.getMinutes();
const time_from = (this.poolCarForm.get('time_from').value).split(':');
    let pickHour = parseInt(time_from[0]);
    let pickMinute = parseInt(time_from[1]);
const time_to = (this.poolCarForm.get('time_to').value).split(':');
     let endpickHour = parseInt(time_to[0]);
    let endpickMinute = parseInt(time_to[1]);
console.log('pickDay',pickDay,'todayDay',todayDay,'pickMonth',pickMonth,'todayMonth',todayMonth,'hour',hour,'minute',minute,'pickHour',pickHour,'pickMinute',pickMinute,'endpickHour',endpickHour,'endpickMinute',endpickMinute);
    
if(this.poolCarForm.invalid){
    this.openSnackBar('Please fill up the all required field then submit','ok');
}else if(pickDay === todayDay && pickMonth === todayMonth){
    if((hour === pickHour && pickMinute < minute) || (hour > pickHour) ){
            this.snackBar.open('You can not select previous time for use','ok',{
                duration : 2000
            })
    }

    else if ((endpickHour === pickHour && pickMinute > endpickMinute) || (endpickHour < pickHour)){
        this.snackBar.open('You can not select user end time less time of user start time','ok',{
            duration : 2000
        })
    }
    else{
      this.processRequistion();
    }
  }  
  else{
      this.processRequistion();
    }
  }


  dateChange(event){
    this.selectDate = event.target.value;
    const selectedTime = new Date(event.target.value).getTime() + 100000000;

    console.log('Selected time',selectedTime);
    const today = new Date().getTime();
    console.log('current time',today);

        if(selectedTime > today){
            console.log('ok',selectedTime);
            
        }else{
            this.openSnackBar('You can not select previous date for use','ok');
            this.poolCarForm.get('use_date').reset();   
        }  
        
}

SelectingVehicle(value){
  console.log('selected vehicle',value);
  if(this.poolCarForm.get('use_date').value){
    this.selectedVehicle = value;
      this.additional_info = true;
      // this.loader = true;
      this.spinner = true;
      const payload ={
        date : formatDate(this.poolCarForm.get('use_date').value,'yyyy-MM-dd','en'),
        vehicle : this.poolCarForm.get('vehicle').value.id
      }
      console.log('getbooked_time_payload',payload);
      
      this.userService.get_available_pool_time(payload).subscribe(res=>{
          console.log('booked time',res);
          this.spinner = false;
          this.timeinfo = res;
          // this.loader = false;
      },e=>{
        this.spinner = false;
        this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
          duration : 5000
        })
      })
  }else{
      this.snackBar.open('Please select pool date first','ok',{
        duration : 4000
      });
      this.poolCarForm.get('vehicle').reset();
  }
}

getAllVehicle(){
  this.loader = true;
  this.userService.getVehicleList().subscribe( res =>{
      console.log('ehicle_list',res)
      this.loader = false;
      this.vehicleList = res;
    },err=>{
      this.loader = false;
      if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
      else this.openSnackBar(" "+err.error, "ok");
  });
}

getUserList(){
  this.employee_spinner = true;
  this.userService.getUsers()
    .subscribe(data => {
      console.log('user_short_info');
      this.employee_spinner = false;
      this.users = data;
    }, err => {
      this.employee_spinner = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
        duration : 5000
      })
    });
}
selected_recomended_by(data){
  this.poolCarForm.get('recommended_by').setValue(data);
  console.log('form value',this.poolCarForm.value);
  
}
selected_approved_by(data){
  this.poolCarForm.get('approved_by').setValue(data);
  console.log('form value approved by',this.poolCarForm.value);
}

ngOnInit() {
    this.getUserList();
    this.getAllVehicle();
    this.approvalauthority$ = this.userService.getFinalApproveauthoritylist('pool_car');
    this.recommendationapprovalauthority$ = this.userService.getRecommendationApproveauthoritylist('pool_car');
    // this.approvalauthority$ = this.userService.getApproveApproveauthoritylist('pool_car');
    this.poolCarForm = this.fb.group({
      description: ['', Validators.required],
      use_date: ['', Validators.required],
      place_from: ['', Validators.required],
      place_to: ['', Validators.required],
      time_to: ['',Validators.required],
      time_from: ['',Validators.required],
      recommended_by: ['', Validators.required],
      approved_by: ['', Validators.required],
      vehicle : ['', Validators.required],
      final_approved_by: [''],
      /* file: [null] */
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.css']
})
export class CreateDepartmentComponent implements OnInit {

  departmentForm: FormGroup;

  loader: boolean = false;
  departmentId: number;
  departmentEdit: boolean;
  Dept_head_List : any[]=[];
  employee_spinner: boolean;
  constructor( 
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  createDepartment(data){/* department save service */
    this.loader = true;
    this.userService.departmentCreate(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Created", "ok");
      this.router.navigate(['view-department']);
    },err=>{
      this.loader = false;
      this.openSnackBar(" "+err.error.message, "ok");
    });
  }
selected_dept_head(event){
  this.departmentForm.get('dept_head').setValue(event);
  console.log('value',this.departmentForm.value);
   
}
  departmentFormSubmit(){/* department form submit */
    console.log(this.departmentForm.value);
    if( this.departmentEdit){
      let fd = new FormData()
      fd.append('dept_id', this.departmentId.toString());
      fd.append('dept_name', this.departmentForm.value.dept_name);
      fd.append('dept_head', this.departmentForm.value.dept_head);
      this.editDepartment(fd);
    }else{
      let fd = new FormData()
      fd.append('dept_name', this.departmentForm.value.dept_name);
      fd.append('dept_head', this.departmentForm.value.dept_head);
      this.createDepartment(fd);
    }

  }

  editDepartment(data){/* department save service */
    this.loader = true;
    this.userService.editDepartment(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Updated", "ok");
      this.router.navigate(['view-department']);
    },err=>{
      this.loader = false;
      if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
      else this.openSnackBar(" "+err.error, "ok");
    });
  }

  
  getSpecificDepartment(departmentId) {
    this.loader = true;
    this.userService.getParticularDepartment(departmentId)
      .subscribe(data => {
        console.log('dept. edit data',data);
        this.loader = false;
        this.departmentForm.patchValue({
          id: departmentId,
          dept_name: data[0].dept_name,
          dept_head : data[0].dept_head,
          
         
        });
        this.loader = false;
      }, e => {
      
        this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{duration:5000});
        this.loader = false;
      });
  }
getAlluser(){
  // this.loader = true;
  this.employee_spinner = true;
  this.userService.getUserList().subscribe(res=>{
    this.Dept_head_List = res;
    this.employee_spinner = false;
    // this.loader = false;
    console.log('userlist',this.Dept_head_List);
  },
  err=>{
    this.snackBar.open(err.error.message ? err.error.message : err.error,'ok',{duration : 5000});
 
  })
}
  ngOnInit() {
   
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.departmentId = +this.route.snapshot.paramMap.get('id');
      console.log(this.departmentId);
      this.departmentEdit = true;
      this.departmentForm = this.fb.group({
        id :['',Validators.required],
        dept_name: ['', Validators.required],
        dept_head : ['', Validators.required], 
      });
      this.getAlluser();
      this.getSpecificDepartment(this.departmentId);
    }else{
      this.getAlluser();
          /* department form create */
    this.departmentForm = this.fb.group({
      dept_name: ['', Validators.required],
      dept_head : ['', Validators.required], 

    });
      
    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

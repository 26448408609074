import { Process } from './../model/process';
// sadia  1663
// $2y$10$1FdVWDevVxW4xrJYS3AimOTFYzOuXMoTkrRmrrCJNqQcfvk05LXFe
// eashmin 1245
// $2y$10$4HBGVsQHdw13Zzu3uf..SOQkNbPTCSzx6u7JW4zRXWQwCfXb0iIpi
// mamun 0629
//$2y$10$kL1VJG6pyzASL.bG1u.09OF4aIKVzWLY94jI2t4aX9g02YMoOsPSq
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../model/user';
import { Department } from '../model/department';
import { Observable } from 'rxjs';
import { api } from '../commonUrl';
import { HttpHeaders } from '@angular/common/http';
import { Designation } from '../model/designation';
import { Vehicle } from '../model/vehicle';

import { Authority, AuthorityDetails } from '../model/authority';

import { VehicleType } from '../model/vehicleType';
import { OfficeItem } from '../model/officeItem';
import { MeetingRoom } from '../model/meetingRoom';
import { UserType } from '../model/userType';
// import { environment } from '../../environments/environment';
import { Menu } from '../model/menu';
import { GroupPermission } from '../model/userGroupPermission';
import { RequisitionNotification } from '../model/notification';
import { Memo } from '../model/memo';
import { catchError } from 'rxjs/operators';
/* add service in a folder
 ng g s services/backendApi --module=app.module
 */
/* user_type/menus/ */
const header: any = new HttpHeaders();
header.append('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class UserService {
  isLoggedIn: Boolean = false;
  redirectUrl: string;
  isMember: Boolean = false;
  loggedInUser: User[];
  userPermission: string[]  = [];
  isViewDashboard: Boolean = false;
  isViewUserPermission: Boolean = false;
  isAddUserPermission:  Boolean = false;
  isEditUserPermission: Boolean  = false;

  isAddUserRolePermission:boolean=false;
  isEditUserRolePermission:boolean=false;
  isViewUserRolePermission:boolean=false;

  isAddDepartmentPermission:boolean=false;
  isViewDepartmentPermission:boolean=false;
  isEditDepartmentPermission:boolean=false;

  isAddDesignationPermission:boolean=false;
  isViewDesignationPermission:boolean=false;
  isEditDesignationPermission:boolean=false;

  isAddVehiclePermission:boolean=false;
  isViewVehiclePermission:boolean=false;
  isEditVehiclePermission:boolean=false;
  isAddVehicleTypePermission:boolean=false;
  isViewVehicleTypePermission:boolean=false;
  isEditVehicleTypePermission:boolean=false;



  isCreateofficeItemctg:boolean = false;
  isViewofficeitemlist:boolean = false;
  isEditofficeItemctg:boolean = false;

  isAddOfficeItemPermission:boolean=false;
  isViewOfficeItemPermission:boolean=false;
  isEditOfficeItemPermission:boolean=false;

  isAddMeetingRoomPermission:boolean=false;
  isViewMeetingRoomPermission:boolean=false;
  isEditMeetingRoomPermission:boolean=false;

  isAddOfficeItemRequisitionPermission:boolean=false;
  iseditOfficeItemRequisition:boolean = false;
  isViewOfficeItemRequisitionPermission:boolean=false;
  ApprovalNoteListPermission:boolean = false;
  isPayment_note_permission:boolean = false;

  isAddGatePassRequisitionPermission:boolean=false;
  isViewGatePassRequisitionPermission:boolean=false;

  isAddVisitingCardRequisitionPermission:boolean=false;
  isViewVisitingCardRequisitionPermission:boolean=false;

  isAddMeetingRoomRequisitionPermission:boolean=false;
  isViewMeetingRoomRequisitionPermission:boolean=false;

  isAddPoolCarRequisitionPermission:boolean=false;
  isViewPoolCarRequisitionPermission:boolean=false;
  isViewPoolCarRequisitionDetailsPermission:boolean=false;

  isAddCarRequisitionPermission:boolean=false;
  isViewCarRequisitionPermission:boolean=false;

  isAddFoodRequisitionPermission:boolean=false;
  isViewFoodRequisitionPermission:boolean=false;

  isAddIdCardRequisitionPermission:boolean=false;
  isViewIdCardRequisitionPermission:boolean=false;

  isAddMemoSheetRequisitionPermission:boolean=false;
  isEditMemoSheetRequisitionPermission:boolean = false;
  isEditnoteSheetRequisitionPermission:boolean = false;
  isViewMemoSheetRequisitionPermission:boolean=false;
  isEditPaymentNoteRequisitionPermission:boolean=false;
  isAddPNoteSheetRequisitionPermission:boolean=false;

  isAddNoteSheetRequisitionPermission:boolean=false;
  isViewNoteSheetRequisitionPermission:boolean=false;
  //permission variable set for pending requistions
  isViewPendingMemoSheetRequisitionPermission:boolean=false;
  isViewPendingNoteSheetRequisitionPermission:boolean=false;
  isViewPendingGatePassRequisitionPermission:boolean=false;
  isViewPendingPoolCarRequisitionPermission:boolean=false;
  isViewPendingOfficeItemRequisitionPermission:boolean=false;
  isViewPendingIdCardRequisitionPermission:boolean=false;
  isViewPendingVisitingCardRequisitionPermission:boolean=false;
  isViewPendingMettingRoomRequisitionPermission:boolean=false;
  isViewPendingFoodRequisitionPermission:boolean=false;
    // report office item
  rep_officeitem_approvalauthoritywise: boolean;
  rep_officeitem_durationwise: boolean;
  rep_officeitem_employeewise: boolean;
  rep_officeitem_itemwise: boolean;
  rep_pendingpayment_note: boolean;

  // for gate pass
  rep_gatePass_approvalauthoritywise: boolean;
  rep_gatePass_durationwise: boolean;
  rep_gatePass_employeewise: boolean;
  rep_gatePass_itemwise: boolean;

  // for other's
  report_meetingromm: boolean;
  report_food: boolean;
  report_visitingCard: boolean;
  report_id_card: boolean;
  generel_notes_report : boolean;
  isAddAccountsHead: boolean;
  isEditAccountsHead: boolean;
  isViewAccountsHead: boolean;


  constructor(private http: HttpClient) {

  }

  notificationCountUpdate = new EventEmitter();

  loginUser(data):Observable<any> {
    const headers = new HttpHeaders({'No-Auth': 'True'});
    headers.append('Content-Type', 'application/json');
    return this.http.post<any>(api + 'login', data, {headers: headers});
  }
  getpermission():Observable<any>{
    return this.http.get<any>(api + 'get_permissions',{headers: header});
  }
  createUser(data){
    return this.http.post(api + 'user/create', data, {headers: header});
  }

  editUser(data:any){
    return this.http.post(api + 'user/edit', data, {headers: header});
  }


  getParticualrUser(data): Observable<User[]> {
    return this.http.get<User[]>(api + 'user/get_user/' + data,  {headers: header});
  }

  createUserType(data){
    return this.http.post(api + 'userType/create', data, {headers: header});
  }

  editUserType(data){
    return this.http.post(api + 'userType/edit', data, {headers: header});
  }

  getParticualrUserType(data): Observable<GroupPermission[]> {
    return this.http.get<GroupPermission[]>(api + 'user_type/menus/' + data,  {headers: header});
  }

  departmentCreate(data): Observable<Department[]> {
    return this.http.post<Department[]>(api + 'department/create', data,  {headers: header});
  }

  designationCreate(data): Observable<Designation[]> {
    return this.http.post<Designation[]>(api + 'designation/create', data,  {headers: header});
  }

  editDepartment(data): Observable<Department[]> {
    return this.http.post<Department[]>(api + 'department/update', data,  {headers: header});
  }

  editDesignation(data): Observable<Designation[]> {
    return this.http.post<Designation[]>(api + 'designation/update', data,  {headers: header});
  }

  editVehicle(data) : Observable<Vehicle[]> {
    return this.http.post<Vehicle[]>(api + 'vehicle/update', data,  {headers: header});
  }

  editVehicleType(data): Observable<Vehicle[]> {
    return this.http.post<Vehicle[]>(api + 'vehicle_type/update', data,  {headers: header});
  }

  editOfficeItem(data): Observable<OfficeItem[]> {
    return this.http.post<OfficeItem[]>(api + 'item/update', data,  {headers: header});
  }

  getMenus(): Observable<Menu[]> {
    return this.http.get<Menu[]>(api + 'user/get_menus',  {headers: header});
  }

  getDepartmentList(): Observable<Department[]>{
    return this.http.get<Department[]>(api + 'department/list',  {headers: header});
  }

  getDepartmentwiseEmployee(deptId):Observable<User[]> {
      return this.http.get<User[]>(api + 'user/get_dept_users/' + deptId, {headers: header});
  }

  getParticularDepartment(data): Observable<Department[]>{
    return this.http.get<Department[]>(api + 'department/details/' + data,  {headers: header});
  }

  getDesignationList(): Observable<Designation[]>{
    return this.http.get<Designation[]>(api + 'designation/list',  {headers: header});
  }

  getParticularDesignation(data): Observable<Designation[]> {
    return this.http.get<Designation[]>(api + 'designation/details/' + data, {headers: header});
  }

  getVehicleList(): Observable<Vehicle[]>{
    return this.http.get<Vehicle[]>(api + 'vehicle/list',  {headers: header});
  }

  getVehicleTypeList(): Observable<VehicleType[]> {
    return this.http.get<VehicleType[]>(api + 'vehicle_type/list',  {headers: header});
  }

  getParticularVehicle(data):Observable<Vehicle[]>{
    return this.http.get<Vehicle[]>(api + 'vehicle/details/' + data,  {headers: header});
  }

  getParticularVehicleType(data):Observable<VehicleType[]> {
    return this.http.get<VehicleType[]>(api + 'vehicle_type/details/' + data,  {headers: header});
  }

  getParticularOfficeItem(data):Observable<OfficeItem[]>{
    return this.http.get<OfficeItem[]>(api + 'item/details/' + data,  {headers: header});
  }

  getUserList(): Observable<User[]> {
    return this.http.get<User[]>(api + 'user/get_users',  {headers: header});
  }

  getPaginateUserlist(pageNumber, pageSize, search): Observable<any> {
    return this.http.get<User[]>(api + 'user/get_user_list',  {headers: header, params: new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('search', search.toString())
      .set('pageSize', pageSize.toString())
    });
  }

  getParticularUser(data):Observable<User[]>{
    return this.http.get<User[]>(api + 'user/get_user/' + data,  {headers: header});
  }

  getUserTypeList():Observable<UserType[]>{
    return this.http.get<UserType[]>(api + 'user/get_user_types',  {headers: header});
  }

  getOfficeItem() : Observable<OfficeItem[]> {
    return this.http.get<OfficeItem[]>(api + 'item/list',  {headers: header});
  }

  vehicleCreate(data) : Observable<Vehicle[]>{
    return this.http.post<Vehicle[]>(api + 'vehicle/create',data , {headers: header});
  }

  getMeetingRoomList(): Observable<MeetingRoom[]>{
    return this.http.get<MeetingRoom[]>(api + 'meeting_room/list',  {headers: header});
  }

  meetingRoomCreate(data): Observable<any> {
    return this.http.post<any>(api + 'meeting_room/create', data,  {headers: header});
  }

  editMeetingRoom(data): Observable<MeetingRoom[]> {
    return this.http.post<MeetingRoom[]>(api + 'meeting_room/update', data,  {headers: header});
  }

  getParticularMeetingRoom(data): Observable<MeetingRoom[]> {
    return this.http.get<MeetingRoom[]>(api + 'meeting_room/details/' + data, {headers: header});
  }

  vehicleTypeCreate(data) : Observable<Vehicle[]>{
    return this.http.post<Vehicle[]>(api + 'vehicle_type/create',data , {headers: header});
  }

  officeItemCreate(data) : Observable<OfficeItem[]> {
    return this.http.post<OfficeItem[]>(api + 'item/create', data,  {headers: header});
  }


  authorityCreate(data):Observable<Authority[]>{
    return this.http.post<Authority[]>(api + 'user/create_authority',data , {headers: header});
  }

  getauthorityList():Observable<Authority[]>{
    return this.http.get<Authority[]>(api + 'user/get_authorities' , {headers: header});
  }

  getParticularAuthority(data): Observable<any>{
    return this.http.get<any>(api + 'user/get_authority_details/' + data , {headers: header});
  }

  getGetPassRecommendauthoritylist(data):Observable<Authority[]>{
    return this.http.get<Authority[]>(api + 'user/get_recommend_users/'+ data , {headers: header});
  }

  editAuthority(data):Observable<Authority[]> {
    return this.http.post<Authority[]>(api + 'user/update_authority', data , {headers: header});
  }

  getGetPassApproveauthoritylist(data):Observable<Authority[]> {
    return this.http.get<Authority[]>(api + 'user/get_approval_users/' + data , {headers: header});
  }

  getFinalApproveauthoritylist(data):Observable<Authority[]>{
    return this.http.get<any>(api + 'user/get_final_approval_users/' + data , {headers: header});
  }

  getRecommendationApproveauthoritylist(data):Observable<Authority[]>{
    return this.http.get<any>(api + 'user/get_recommend_users/'+ data , {headers: header});
  }

  getApproveApproveauthoritylist(data): Observable<Authority[]> {
    return this.http.get<any>(api + 'user/get_approval_users/' + data , {headers: header});
  }

  markNotificationAsSeen(data): Observable<RequisitionNotification[]>{
    return this.http.get<RequisitionNotification[]>(api + 'user/notifications/' + data , {headers: header});
  }

  delegate(data): Observable<any> {
    return this.http.post(api + 'requisition/delegate', data , {headers: header});
  }

  getProcessList(): Observable<Process[]> {
    return this.http.get<Process[]>(api + 'process/list',  {headers: header});
  }

  getMemoList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'memo_sheet/user_requisition_approvals',  {headers: header});
  }

  getMemoOwnList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'memo_sheet/user_requisitions',  {headers: header});
  }
  getMemorfcList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'memo_sheet/returned_for_resubmit',  {headers: header});
  }
  getApprovedList(req_type:any): Observable<any> {
    return this.http.get<any>(api + 'approved_pending_requisitions/' + req_type + '/1',{headers: header});
  }
  submit_reqList(req_type:any,approve_type:number): Observable<any> {
    return this.http.get<any>(api + 'approved_pending_requisitions/' + req_type + '/' + approve_type,{headers: header});
  }

  getNoteList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'note_sheet/user_requisition_approvals',  {headers: header});
  }

  getNoteOwnList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'note_sheet/user_requisitions',  {headers: header});
  }
  getNoterfcList(): Observable<Memo[]> {
    return this.http.get<Memo[]>(api + 'note_sheet/returned_for_resubmit',  {headers: header});
  }

  getMemoDetailById(id): Observable<any> {
    return this.http.get<any>(api + 'memo_sheet/requisition_details/' + id,  {headers: header});
  }

  getNoteDetailById(id): Observable<any> {
    return this.http.get<any>(api + 'note_sheet/requisition_details/' + id,  {headers: header});
  }

  requisitionApprove(data): Observable<any> {
    return this.http.post<any>(api + 'memo_sheet/requisition_approve', data, {headers: header});
  }

  requisitionNoteApprove(data): Observable<any> {
    return this.http.post<any>(api + 'note_sheet/requisition_approve', data, {headers: header});
  }

  requisitionPaymentNoteApprove(data): Observable<any> {
    return this.http.post<any>(api + 'item/payment_note_approve', data, {headers: header});
  }


  requisitionReject(data): Observable<any> {
    return this.http.post<any>(api + 'item_send_for_resubmit', data, {headers: header});
  }
  memorequisitionReject(data): Observable<any> {
    return this.http.post<any>(api + 'send_for_resubmit', data, {headers: header});
  }

  officeItemNoteApprove(data): Observable<any> {
    return this.http.post<any>(api + 'item/note_approve', data, {headers: header});
  }




  getDelegationauthoritylist(data): Observable<Authority[]> {
    if (data.approvecategory === 'final_approved_by') {
      return this.http.get<Authority[]>(api + 'user/get_final_approval_users/' + data.url , {headers: header});
    } else if (data.approvecategory === 'approved_by') {
      return this.http.get<Authority[]>(api + 'user/get_approval_users/' + data.url , {headers: header});
    } else if (data.approvecategory === 'recommended_by') {
      return this.http.get<Authority[]>(api + 'user/get_recommend_users/' + data.url , {headers: header});
    } else {
      return this.http.get<any>(api + 'user/get_all_users' , {headers: header});
    }

  }

  getAssignList(): Observable<any> {
    return this.http.get<any>(api + 'pool_car/requisition_for_assign' , {headers: header});
  }

  getDriverlist(): Observable<any> {
    return this.http.get<any>(api + 'user/get_drivers' , {headers: header});
  }

  remarkDetails(id, type): Observable<any> {
    return this.http.get<any>(api + 'requisition_remarks/' + id + '/' + type,  {headers: header});
  }
  versionDetails(id, type): Observable<any> {
    return this.http.get<any>(api + 'requisition/versions/' + id + '/' + type,  {headers: header});
  }

  // for memo edit
  editMemo(data):Observable<any> {
    return this.http.post(api + 'memo_sheet/requisition_edit', data, {headers: header,reportProgress: true,
      observe: 'events'});
  }
  editNote(data):Observable<any> {
    return this.http.post(api + 'note_sheet/requisition_edit', data,{
      reportProgress: true,
      observe: 'events',
      headers: header
    })
  }

  editPaymetNote(data):Observable<any> {
    return this.http.post(api + 'item/payment_note_edit', data,{
      reportProgress: true,
      observe: 'events',
      headers: header
    })
  }

  getParticualrMemo(id): Observable <any>{
    return this.http.get<any>(api + 'memo_sheet/requisition_details/' + id, {headers: header});
  }
  getParticualrNote(id): Observable <any>{
    return this.http.get<any>(api + 'note_sheet/requisition_details/' + id, {headers: header});
  }

  dashboardData():Observable<any>{
    return this.http.get<any>(api + 'dashboard', {headers: header});
  }
  getAllapproved(requisition_no,approved_status):Observable<any>{
    return this.http.get<any>(api + 'approved_pending_requisitions/' + requisition_no + '/' + approved_status,{headers:header})
  }

  officeItemCategoryCreate(data) : Observable<any> {
    const newheaders: any = new HttpHeaders();
    newheaders.append('Content-Type', 'application/json');
    return this.http.post<any>(api + 'crud?model=item_category', data, {headers: newheaders});
  }
  editOfficeItemCategory(data,id):Observable<any> {
    const newheaders: any = new HttpHeaders();
    newheaders.append('Content-Type', 'application/json');
    return this.http.put<any>(api + 'crud/'+id+'?model=item_category', data,  {headers: newheaders});
  }
  getOfficeItemCategory():Observable<any>{
    // return this.http.get<any>(api + '', {headers: header});
    return this.http.get<any>(api + 'crud?model=item_category',{headers: header});
  }
  CategoryWiseItem(id):Observable<any>{
    return this.http.get<any>(api + 'item_list/' + id,{headers: header});
  }
  getSpeceficeItemCategory(id:any):Observable<any>{
    // return this.http.get<any>(api + '', {headers: header});
    return this.http.get<any>(api + 'crud/'+id+'?model=item_category',{headers: header});
  }
  getSpeceficeRequistionDetails(id:any):Observable<any>{
    return this.http.get<any>(api + 'item/requisition_details/' + id,{headers: header});
  }

  getSpeceficEditRequistionDetails(id:any):Observable<any>{
    return this.http.get<any>(api + 'item/edit_requisition_details/' + id,{headers: header});
  }

  getOfficeItemRequisitionList():Observable<any>{
    return this.http.get<any>(api + 'item/requisitions_for_approval',{headers: header});
  }

  itemrequitionApproved(data) : Observable<any> {
    return this.http.post<any>(api + 'item/approve', data,  {headers: header});
  }
  approvalNoteDetails(data): Observable<any> {
    return this.http.post<any>(api + 'item/requisitions_info', data,  {headers: header});
  }
  approvalNoteSubmit(data): Observable<any> {
    return this.http.post<any>(api + 'item/note_create', data,  {headers: header});
  }
  approvalNoteEdit(data): Observable<any> {
    return this.http.post<any>(api + 'item/note_edit', data,  {headers: header});
  }
  getApprovalNoteList():Observable<any>{
    return this.http.get<any>(api + 'item/approval_notes',{headers: header});
  }
  getOwnerApprovalNoteList():Observable<any>{
    return this.http.get<any>(api + 'item/user_approval_notes',{headers: header});
  }
  getOwnerPaymentList():Observable<any>{
    return this.http.get<any>(api + 'item/user_payment_notes',{headers: header});
  }
  getApprovalPaymentList():Observable<any>{
    return this.http.get<any>(api + 'item/paymentnotes',{headers: header});
  }
  getBudgetedList():Observable<any>{
    return this.http.get<any>(api + 'item/budgeted_approval_notes ',{headers: header});
  }
  adminHeadAcceptReject(data): Observable<any> {
    return this.http.post<any>(api + 'item/accept_note', data,  {headers: header});
  }

  accountsPersonBudget(data): Observable<any> {
    return this.http.post<any>(api + 'item/budget_note', data,  {headers: header});
  }
  AdminHeadRecomended(data): Observable<any> {
    return this.http.post<any>(api + 'item/recommend', data,  {headers: header});
  }
  Approval_authority(data): Observable<any> {
    return this.http.post<any>(api + 'item/note_approve', data,  {headers: header});
  }
  getAccountsPerson():Observable<any>{
    return this.http.get<any>(api + 'user/get_accounts_users',{headers: header});
  }
  getAuditPerson():Observable<any>{
    return this.http.get<any>(api + 'user/get_audit_users',{headers: header});
  }
  getOfficeItemOwnerRequistionList():Observable<any>{
    return this.http.get<any>(api + 'item/user_requisitions',{headers: header});
  }
  // for approval note
  getapprovalnoteRequestChangeList():Observable<any>{
    return this.http.get<any>(api + 'item/approvalnote/returned_for_resubmit',{headers: header});
  }
  getapprovalnoteApprovedList():Observable<any>{
    return this.http.get<any>(api + 'item/approvalnote/approved_list',{headers: header});
  }
  // for payment note
  getpaymentnoteRequestChangeList():Observable<any>{
    return this.http.get<any>(api + 'item/paymentnote/returned_for_resubmit',{headers: header});
  }
  getpaymentnoteApprovedList():Observable<any>{
    return this.http.get<any>(api + 'item/paymentnote/approved_list',{headers: header});
  }
  getpaymentnoteRejectedList():Observable<any>{
   return this.http.get<any>(api + 'item/paymentnote/rejected_list',{headers: header});
  }

  create_payment_note(data): Observable<any> {
    return this.http.post<any>(api + 'item/payment_note_create', data,  {headers: header});
  }

  paymentNote_check(data): Observable<any> {
    return this.http.post<any>(api + 'item/check_payment_note', data,  {headers: header});
  }
  Admin_head_paymentsNote():Observable<any>{
    return this.http.get<any>(api + 'item/admin_payment_notes',{headers: header});
  }
  AdminHeadPaymentNoteRecomended(data): Observable<any> {
    return this.http.post<any>(api + 'payment_note/recommend', data,  {headers: header});
  }
  PaymentNote_Approval_authority(data): Observable<any> {
    return this.http.post<any>(api + 'payment_note/approve', data,  {headers: header});
  }
  // pre_audit_person(data): Observable<any> {
  //   return this.http.post<any>(api + 'payment_note/pre_audit_person', data,  {headers: header});
  // }
  // audit_head(data): Observable<any> {
  //   return this.http.post<any>(api + 'payment_note/audit_head', data,  {headers: header});
  // }
  // pre_accounts(data): Observable<any> {
  //   return this.http.post<any>(api + 'payment_note/pre_accounts', data,  {headers: header});
  // }
  // accounts_head(data): Observable<any> {
  //   return this.http.post<any>(api + 'payment_note/accounts_head', data,  {headers: header});
  // }
  Accounts_cheque_issue(data): Observable<any> {
    return this.http.post<any>(api + 'payment_note/check_issue', data,  {headers: header});
  }

  getitemreport(data): Observable<any> {
    return this.http.post<any>(api + 'report/item_wise_item_requisition', data,  {headers: header});
  }
  getitemreportEmployee(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_item_requisition', data,  {headers: header});
  }
  getitemreportAuthorityWise(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_item_requisition', data,  {headers: header});
  }
  getitemreportduration(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_item_requisition', data,  {headers: header});
  }
  getpassreportCatwise(data): Observable<any> {
    return this.http.post<any>(api + 'report/category_wise_gate_pass_requisition', data,  {headers: header});
  }
  gatePassEmployeewise(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_gate_pass_requisition', data,  {headers: header});
  }
  gatePassAuthoritywise(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_gate_pass_requisition', data,  {headers: header});
  }
  gatePassDurationwise(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_gate_pass_requisition', data,  {headers: header});
  }
  IdCardEmployeeWise(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_id_card_requisition', data,  {headers: header});
  }
  idCardAuthoritywise(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_id_card_requisition', data,  {headers: header});
  }
  idCardDurationWise(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_id_card_requisition', data,  {headers: header});
  }
  visitingCardEmployeewise(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_visiting_card_requisition', data,  {headers: header});
  }
  visitingCardAuthoritywise(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_visiting_card_requisition', data,  {headers: header});
  }
  visitingCardDatewise(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_visiting_card_requisition', data,  {headers: header});
  }
  employee_wise_food_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_food_requisition', data,  {headers: header});
  }
  authority_wise_food_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_food_requisition', data,  {headers: header});
  }
  date_wise_food_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_food_requisition', data,  {headers: header});
  }

  employee_wise_meeting_room_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/employee_wise_meeting_room_requisition', data,  {headers: header});
  }
  authority_wise_meeting_room_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/authority_wise_meeting_room_requisition', data,  {headers: header});
  }
  date_wise_meeting_room_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/date_wise_meeting_room_requisition', data,  {headers: header});
  }
  room_wise_meeting_room_requisition(data): Observable<any> {
    return this.http.post<any>(api + 'report/room_wise_meeting_room_requisition', data,  {headers: header});
  }
  getUsers():Observable<any>{
    return this.http.get<any>(api + 'user/get_users_info',{headers: header});
  }
  get_noteCreator(data:any): Observable<any> {
    return this.http.post<any>(api + 'user/get_note_creators', data,  {headers: header});
  }
  get_available_room(data): Observable<any> {
    return this.http.post<any>(api + 'room_meeting_info', data,  {headers: header});
  }
  get_available_pool_time(data): Observable<any> {
    return this.http.post<any>(api + 'vehocle_book_info', data,  {headers: header});
  }
  getRemarks(id,type): Observable<any>{
    return this.http.get<any>(api + 'requisition_remarks'+'/'+ id + '/'+ type,{headers: header});
  }
  get_referance(data:any):Observable<any>{
    return this.http.get<any>(api + 'get_last_reference' + '/' + data,{headers: header});
  }
  passwordChange(data:any): Observable<any> {
    return this.http.post<any>(api + 'user/change_password', data,  {headers: header});
  }
  profileUpdate(data:any): Observable<any> {
    return this.http.post<any>(api + 'user/profile_update', data,  {headers: header});
  }
  forgetPassword(data:any):Observable<any>{
    console.log(data);
    const noauth = new HttpHeaders({'No-Auth': 'True'});
    noauth.append('Content-Type', 'application/json');
    return this.http.post<any>(api + 'user/password_reset_request',data,{headers: noauth});
  }
  resetPassword(data:any):Observable<any>{
    const noauth = new HttpHeaders({'No-Auth': 'True'});
    noauth.append('Content-Type', 'application/json');
    return this.http.post<any>(api + 'user/password_reset',data,{headers: noauth});
  }
  getDetailsByRef(ref_no:any):Observable<any>{
    return this.http.post<any>(api + 'memoref_details',{ref_id : ref_no},{headers: header});
  }
  editAccountsHeads(data: any):Observable<any> {
    return this.http.post<any>(api + 'edit_accounts_head', data,  {headers: header});
  }
  AccountsHeadCreate(data: any):Observable<any> {
    return this.http.post<any>(api + 'create_accounts_head', data,  {headers: header});
  }
  deleteHeads(data: any):Observable<any> {
    return this.http.delete<any>(api + 'accounts_head_delete/'+data.id,{headers: header});
  }
  getaccountsHeadList():Observable<any>{
    return this.http.get<any>(api + 'get_head_list',{headers: header});
  }
  getaccountsHeadYearly():Observable<any>{
    return this.http.get<any>(api + 'account_heads_yearly_budget',{headers: header});
  }
  budgetCreate(data: any):Observable<any> {
    return this.http.post<any>(api + 'budget_create', data,  {headers: header});
  }
  getBudgetInfo():Observable<any>{
    return this.http.get<any>(api + 'view_budget_info',{headers: header});
  }
  updateBudget(data: any):Observable<any> {
    return this.http.post<any>(api + 'update_budget', data,  {headers: header});
  }
  getspeceficBudget(data: any):Observable<any> {
    return this.http.get<any>(api + 'get_specefic_budget/'+ data,  {headers: header});
  }
  getApprovalNoteDetails(id: any):Observable<any>{
    return this.http.get<any>(api + 'item/approval_note_details/' + id, {headers: header})
  }
  getOfficeItemDetails(requisition_id:any):Observable<any>{
    return this.http.get<any>(api + 'item/requisition_details/'+requisition_id,{headers: header})
  }
  getOfficeItemRequestForChangeList():Observable<any>{
    return this.http.get<any>(api + 'item/requisitions_for_change',{headers: header})
  }
  getOfficeItemApprovedList():Observable<any>{
    return this.http.get<any>(api + 'item/requisitions_approved',{headers: header})
  }
  getOfficeItemRejectedList():Observable<any>{
    return this.http.get<any>(api + 'item/requisitions_rejected',{headers: header})
  }
  getApprovalNoteRejectedList():Observable<any>{
    return this.http.get<any>(api + 'item/approvalnote/rejected_list',{headers: header})
  }
  getPaymentNoteDetails(requisition_id: any):Observable<any>{
    return this.http.get<any>(api + 'item/payment_note_details/' + requisition_id, {headers: header})
  }



}

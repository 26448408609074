import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-office-item-details',
  templateUrl: './office-item-details.component.html',
  styleUrls: ['./office-item-details.component.css']
})
export class OfficeItemDetailsComponent implements OnInit {
  officeItemRequisitioForm: FormGroup;
  removeItemArray=[];
  remarks = '';
  isReadOnly:boolean = true;
  detailsList:any=[];
  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<OfficeItemDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar : MatSnackBar,
              public userService: UserService,
              ) { }


  TempCategoryData:any =[];
  approved(data){
    const payload = {
        status : data,
        remarks:this.remarks,
        checked_items : this.checkitemList
        // deleted_items : this.removeItemArray
        }
      
      
      this.dialogRef.close(payload);
   
  }

  reject(data){
    const payload = {
      status : data,
      remarks:this.remarks,
      // deleted_items : this.removeItemArray
      }
    
    this.dialogRef.close(payload);
  }
  requestForChange(data){
    const payload = {
      status : data,
      remarks:this.remarks,
      // deleted_items : this.removeItemArray
      }
    
    this.dialogRef.close(payload);
  }

  close(){
    this.dialogRef.close();
  }
checkitemList:any=[]
checkedTickets:any=[]

onclick:boolean=false;
isreject:boolean=false;

onCheck(value){
  
  if (!this.checkitemList.includes(value)) {
    this.checkitemList.push(value);
   
  } else {
    var index = this.checkitemList.indexOf(value);
    if (index > -1) {
      this.checkitemList.splice(index, 1);
     
    
    }
  }
  if(this.checkitemList.length>0){
    this.onclick=true;
    this.isreject=true;
  }
  else{
    this.onclick=false;
    this.isreject=false;
  }
 
  // console.log('idList',this.checkitemList);
}



  removeItem(catIndex,itemIndex){
    let item = this.data.category_items[catIndex].cat_items[itemIndex];
    this.removeItemArray.push(item);
    // console.log('remove item Array',this.removeItemArray);
    this.data.category_items[catIndex].cat_items.splice(itemIndex,1);
  }
  finalapp:boolean=false;
  ngOnInit() {
    this.userService.getOfficeItemDetails(this.data.id).subscribe(res=>{
      this.detailsList=res;
      console.log('data details', this.detailsList);
      if(this.detailsList.requisition_info.approved_by == this.detailsList.requisition_info.final_approved_by){
          this.finalapp = true;
           }else{
             this.finalapp= false;
            }
    })

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
}


}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})

export class DataTableComponent implements OnInit {

  @Input() tableData: any;
  @Input() rowHeadDef: any;
  @Input() showDlegate: boolean;
  showCarPool: boolean;
  approved: boolean;
  dataSource: MatTableDataSource<any>;
  dataList:any[]=[]
  spinner: boolean;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true} as any) sort: MatSort;
  constructor(public dialog: MatDialog, public router: Router) { }

  @Output() childEvent = new EventEmitter();
  @Output() delegateEvent = new EventEmitter();
  @Output() assignCarpoolEvent = new EventEmitter();
  showDetail(element){
      this.childEvent.emit(element);
  }

  delegate(element){
    this.delegateEvent.emit(element);
  }

  assignCarPool(data){
    console.log('assign carpool',data);
        this.router.navigate(['/pool-car-requisition-details/' + data.id ]);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log('value',this.dataSource.filter);
    
  }
  ngOnInit() {
    this.spinner = true;
    this.tableData.subscribe(res=>{
      this.dataList = res;
      this.dataSource = new MatTableDataSource<any>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.spinner = false;
      console.log('datatable data',res);
      
    })
    console.log(this.router.url);
    if (this.router.url === '/view-pool-car-requisition') {
      this.showCarPool = true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-office-item',
  templateUrl: './create-office-item.component.html',
  styleUrls: ['./create-office-item.component.css']
})
export class CreateOfficeItemComponent implements OnInit {
  officeItemForm:any;
  loader: boolean = false;
  officeItemId: number;
  officeItemEdit: boolean;
  category :any = [];
  constructor( 
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  createOfficeItem(data){/* department save service */
    this.loader = true;
    this.userService.officeItemCreate(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Created", "ok");
      this.router.navigate(['view-office-item']);
    },err=>{
      this.loader = false;
      this.openSnackBar(" "+err.error.message, "ok");
    });
  }

  editOfficeItem(data){/* department save service */
    this.loader = true;
    this.userService.editOfficeItem(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Updated", "ok");
      this.router.navigate(['view-office-item']);
    },err=>{
      this.loader = false;
      if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
      else this.openSnackBar(" "+err.error, "ok");
    });
  }
  
  getCategory(){
    this.loader = true;
    this.userService.getOfficeItemCategory().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.category = res;
      },err=>{
        this.loader = false;
        this.openSnackBar(err.error.message ? err.error.message : err.error ,"ok");
    });
  }
  
  getSpecificOfficeItemId(officeItemId) {
    this.loader = true;
    this.userService.getParticularOfficeItem(officeItemId)
      .subscribe(data => {
        console.log('specific',data)
        this.loader = false;
        this.officeItemForm.patchValue({
          id: officeItemId,
          title: data[0].title,
          category_id : data[0].category_id,
          unit: data[0].unit
        });
      }, error => {
        this.loader = false;
        this.openSnackBar("Internal error", "ok")
      });
  }

  ngOnInit() {
    this.getCategory();
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.officeItemId = +this.route.snapshot.paramMap.get('id');
      console.log(this.officeItemId);
      this.officeItemEdit = true;
      this.officeItemForm = this.fb.group({
        id :['',Validators.required],
        title: ['', Validators.required],
        category_id : [''],
        unit: ['', Validators.required]
      });
      this.getSpecificOfficeItemId(this.officeItemId);
    }else{
          /* department form create */
    this.officeItemForm = this.fb.group({
      title: ['', Validators.required],
      category_id : [''],
      unit: ['', Validators.required]
    });
      
    }

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  
  officeItemFormSubmit(){/* department form submit */
    console.log(this.officeItemForm.value);
    if( this.officeItemEdit){
      let fd = new FormData()
      fd.append('item_id', this.officeItemId.toString());
      fd.append('title', this.officeItemForm.value.title);
      fd.append('category_id', this.officeItemForm.value.category_id);
      fd.append('unit', this.officeItemForm.value.unit);
      this.editOfficeItem(fd);
    }else{
      let fd = new FormData()
      fd.append('title', this.officeItemForm.value.title);
      fd.append('category_id', this.officeItemForm.value.category_id);
      fd.append('unit', this.officeItemForm.value.unit);
      this.createOfficeItem(fd);
    }

  }


}

import { Component, OnInit, Inject, Output, EventEmitter,AfterContentChecked } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';
import { CommonService } from '../services/common.service';
import { SafePipeComponent } from '../pipe/pipe.component';
import { UserService } from '../services/user.service';
import * as _ from 'lodash';
import { FormControl, Validators } from '@angular/forms';
import { fuseAnimations } from '../animations';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-memo-details',
    templateUrl: './memo-details.component.html',
    styleUrls: ['./memo-details.component.css'],
    animations : fuseAnimations
})
export class MemoDetailsComponent implements OnInit{
    requestData : any;
    reqDetail: any;
    remarks:any = '';
    reqData: any;
    files: any[];
    fileUrl: string;
    mouseoverevent: boolean;
    printData: any;
    reqapprove: any;
    budget_amount  = new FormControl();
    sig: any;
    deptId: any;
    loader: boolean;
    type_of_purchase = new FormControl();
    selectedAccountsHead:any
    HeadList:any[]=[];
    budgetInfo: any[]=[];
    displayedColumn= [{display:'Head of Accounts',field : 'head_title'},{display:'Budget for the year',field : 'budget'},{display:'Consumed till now',field : 'consumed_balance'},{display:'Remaining Balance',field : 'head_title'},{display:'% of Remaining balance',field : 'head_title'}];
    headsinfo: any;
    remaining_balance : any;
    remaining_balance_percentage:any;
    inputOption: boolean;
    Acc_head_selection =  new FormControl('',Validators.required);
    userId: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public commonService: CommonService,
        public userService: UserService,
        public snackBar: MatSnackBar,
        public dialog_ref: MatDialogRef<MemoDetailsComponent>,
        public preview_dialog : MatDialog
        ) {
            this.printData = this.data.main;
            console.log('main', this.printData)
            this.type_of_purchase.setValue(this.printData.type_of_purchase);
            if(this.printData.head_title){
                this.headsinfo = {head_title : this.printData.head_title,budget: this.printData.budget,expense : this.printData.expense,head_id:this.printData.head_id};
                this.remaining_balance = this.printData.remaining_balance ? this.printData.remaining_balance : 0;
                this.remaining_balance_percentage = this.printData.remaining_balance_percentage ? this.printData.remaining_balance_percentage : 0;
            }

        }
    AccountsHeadChange(data){
        this.headsinfo = data.value;
        console.log('hdinfo', this.headsinfo)
        if(this.headsinfo.head_id === 0){
                this.inputOption = true;
        }else{
            this.inputOption = false;
        }
        this.remaining_balance = (this.headsinfo.budget - (this.headsinfo.expense + this.printData.amount)).toFixed(2);
        this.remaining_balance_percentage = ((100/this.headsinfo.budget)*this.remaining_balance).toFixed(2);
        console.log('selection change',data.value);
        console.log('remaining_balance',this.remaining_balance);
        console.log('remaining_balance_percentage',this.remaining_balance_percentage);

    }
    calculate(){
        this.remaining_balance = (this.headsinfo.budget - (this.headsinfo.expense + this.printData.amount)).toFixed(2);
        this.remaining_balance_percentage = ((100/this.headsinfo.budget)*this.remaining_balance).toFixed(2);
    }
  get acc_budget_person(){
      if(this.reqapprove[0].approver_id == this.userId && this.deptId == '9'){
        console.log('budget====================')
        return true;
      } else{
        console.log('non budget====================')
        return false
      }
  }
get accountsValidation(){
    if(this.acc_budget_person === true && this.Acc_head_selection.hasError('required')){
        return true;
    }else{
      return false;
    }
  }
    getAllHeads(){
        this.loader = true;
        this.userService.getaccountsHeadYearly().subscribe( res =>{
            console.log('yearly headlist',res)
            this.loader = false;
            this.HeadList = res;
            this.HeadList.push({head_id: 0,head_title: "Others",
            budget: 0,
            created_at: "2020-08-25 17:13:55",
            expense: 0,
            id : 0,
            updated_at: "2020-08-25 17:13:55",
            year: 2020});
            //this.openSnackBar("Success ", "ok");
          },err=>{
            this.loader = false;
            this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
        });
      }
    ngOnInit() {
        this.deptId = sessionStorage.getItem('dept_id');
        this.userId = sessionStorage.getItem('user_id');
        console.log('user',this.userId)
        console.log('deptId',this.deptId)

      // console.log('deptId',this.deptId,'userId',this.userId);

        if(this.deptId == '9'){
            this.getAllHeads();
        }

        console.log('reqDetail', this.data);
        this.sig = 'https://gdams.green-delta.com/services/public/uploads/user_signatures/';
        this.reqDetail = this.data;
        this.requestData =  this.data.detail
        this.reqapprove = this.data.detail.requisition_details;

        // this.fileUrl = this.data.detail.file_info[0].dirname + '/' + this.data.detail.file_info[0].basename;
        this.mouseoverevent = false;
    }


    type_of_budget(data){
       console.log('type_of_budget',this.type_of_purchase.value);
    }
    approve() {
        this.loader = true;
        const memoSheetApproveData:any = {
            requisition_id : this.data.main.id,
            requested_by : this.data.main.requested_by,
            remarks : this.remarks,

        };
        console.log('memosheet1',memoSheetApproveData)
        if(this.deptId == '9'){
            memoSheetApproveData.type_of_purchase = this.type_of_purchase.value;
            memoSheetApproveData.headsinfo = this.headsinfo;
            memoSheetApproveData.remaining_balance = this.remaining_balance;
            memoSheetApproveData.remaining_balance_percentage = this.remaining_balance_percentage;
            memoSheetApproveData.expense = this.printData.amount;
            // memoSheetApproveData.budget_amount = this.printData.amount;
        }
        console.log('payload',memoSheetApproveData);

        if (this.data.sheetType === 'Note') {
            this.userService.requisitionNoteApprove(memoSheetApproveData).subscribe((response) => {
                this.openSnackBar(response['message'], 'ok');
                this.loader = false;
                this.dialog_ref.close('reload');

            });
        } else {
        this.userService.requisitionApprove(memoSheetApproveData).subscribe((response) => {
            this.openSnackBar(response['message'], 'ok');
            this.dialog_ref.close('reload');
            this.loader = false;
        });
        }
    }

    deleteEditFile(data:any,index){
        this.snackBar.open("Sorry! You couldn't delete this file, read only!",'ok',{duration:3000});
      }
      download(f){
          const link = document.createElement('a');
          const downloadUrl = f;
          link.setAttribute('href',  downloadUrl);
          link.setAttribute('download', 'FileName');
          link.setAttribute('target', '_blank');
          document.body.appendChild(link); // Required for FF
          link.click();
      }

      previewFile(f) {
      const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
              data : {url : f}
          })
          dialog_ref.afterClosed().subscribe(res=>{
              console.log('preview_closed',res);
          })
      }


    thousand_separator(num){
        var num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return num_parts.join(".");
    }

    reject(status:number) {
        this.loader = true;
        const payload = {
            requisition_id : this.data.main.id,
            requisition_type : 'memo_sheet',
            remarks : this.remarks ? this.remarks : ' ',
            status : status,
        };
        this.userService.memorequisitionReject(payload).subscribe((response) => {
            this.snackBar.open(response['message'], 'ok',{duration:5000});
            this.loader = false;
            this.dialog_ref.close('reload');
        },err=>{
            this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:5000});
            this.loader = false;
        });

    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

    close() {
        this.dialog_ref.close('reload');
    }

}

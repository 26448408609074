import { Component, OnInit} from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar,MatDialog} from '@angular/material';
import { Router } from '@angular/router';
import {ViewDashboardComponent} from '../view-dashboard/view-dashboard.component';
import { fuseAnimations } from '../animations';
// import { CommonService } from '../services/common.service';

// @Injectable({
//   providedIn: 'root'
// })
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations : fuseAnimations
})
export class DashboardComponent implements OnInit {

  loader: boolean;
  totalVehicle: number;
  dashboard:any;
  template:boolean;
  allApprovedData:any;
  constructor(
    public userService:UserService,
    private snackBar:MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    // public commonService: CommonService,
  ) { }
  // dashboardchart=[];
  dashboardData(){
    this.loader = true;
    this.userService.dashboardData().subscribe( res => {
        this.dashboard = res;
        console.log('dashboardData',this.dashboard);
        // for(var i in res){
        //   this.dashboardchart.push([i,res[i]]);
        // }
        this.PieDataCount();
        console.log('PieChartData',this.PieData);
        this.ColChartDataCount();
        console.log('ColChartData',this.ColChart_data);
        this.MonthlyApprovedDataCount();
        this.template = true;
        this.loader = false;
      },error=>{
        this.loader = false;
        //if(error.status == 400) location.reload(true);
        if(error.error.message)this.openSnackBar(" "+error.error.message, "ok");
        else this.openSnackBar(" "+error.error, "ok");
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }
  ngOnInit() {
    this.dashboardData();
  }
// For Requistion Details Column Chart
ColChart_title = 'Requisition Details';
ColChart_type='ColumnChart';
ColChart_data = [];
ColChartDataCount(){
  this.ColChart_data = [
    ['Approval Memo', this.dashboard.total_memo_approved,this.dashboard.total_memo_approval],
    ['Payment Memo',  this.dashboard.total_note_approved,this.dashboard.total_note_approval],
    ['Gate Pass',this.dashboard.total_gate_pass_approved,this.dashboard.total_gate_pass_approval],
    ['Pool Car', this.dashboard.total_car_approved,this.dashboard.total_car_approval],
    ['Office Item', this.dashboard.total_item_approved, this.dashboard.total_item_approval],
    ['ID Card', this.dashboard.total_id_card_approved,this.dashboard.total_id_card_approval],
    ['Visiting Card',this.dashboard.total_visiting_card_approved,this.dashboard.total_visiting_card_approval],
    ['Metting Room',this.dashboard.total_meeting_room_approved,this.dashboard.total_meeting_room_approval],
    ['Food',this.dashboard.total_food_approved,this.dashboard.total_food_approval],

  ]
}
 ColChart_columnNames = ['Requisition','Approved','Pending'];
 ColChart_options = {
    // colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6','#ffffff'],
    is3D: true,
    animation: {
        startup : true,
        duration: 3000,
        easing: 'out',
      },
    hAxis: {
      title: 'Requisition'
     },
     vAxis:{
      title: 'Approved/Pending',
      minValue:0,

     },
     isStacked:true
 };
 ColChart_width = 520;
 ColChart_height = 400;
 ColChartDataSelect(data){
  this.loader = true;
  var requistion_no = data[0].row +1;
  var approved_peding = data[0].column;
  if(approved_peding == 1){
  this.userService.getAllapproved(requistion_no,approved_peding).subscribe(res=>{
    this.allApprovedData= res;
    console.log('allApprovedData',this.allApprovedData);
    const visible = "approved";
    if(requistion_no == 1){
      this.router.navigate(['view-pending-memo-sheet-requisition/',visible]);
    } else if(requistion_no == 2){
      this.router.navigate(['view-pending-note-sheet-requisition/',visible]);
    } else{
          this.dialog.open(ViewDashboardComponent, {
          data: res
      });
    }

  //   this.dialog.open(ViewDashboardComponent, {
  //     data: res
  // });
  },error=>{
    this.loader = false;
    if(error.error.message)this.openSnackBar(" "+error.error.message, "ok");
    else this.openSnackBar(" "+error.error, "ok");
});
}
else if(requistion_no == 1){
  const visible = false;
  this.router.navigate(['view-pending-memo-sheet-requisition/',visible]);


}
else if(requistion_no == 2){
  const visible =false;
  this.router.navigate(['view-pending-note-sheet-requisition/',visible]);

}
else if(requistion_no == 3){
  const visible =false;
  this.router.navigate(['view-pending-gate-pass-requisition/',visible]);

}
else if(requistion_no == 4){
  const visible =false;
  this.router.navigate(['view-pending-pool-car-requisition/',visible]);

}
else if(requistion_no == 5){
  const visible =false;
  this.router.navigate(['view-pending-office-item-requisition/',visible]);

}
else if(requistion_no == 6){
  const visible =false;
  this.router.navigate(['view-pending-id-card-requisition/',visible]);

}
else if(requistion_no == 7){
  const visible =false;
  this.router.navigate(['view-pending-visiting-card-requisition/',visible]);

}
else if(requistion_no == 8){
  const visible =false;
  this.router.navigate(['view-pending-meeting-room-requisition/',visible]);

}
else if(requistion_no == 9){
  const visible =false;
  this.router.navigate(['view-pending-food-requisition/',visible]);

}

 }

// For Pie Charts
Pietitle = 'Pending Requisition Percentage Wise';
Pietype='PieChart';
PieData=[];
PieDataCount(){
  this.PieData = [
    ['Approval Memo', this.dashboard.total_memo_approval],
    ['Payment Memo',  this.dashboard.total_note_approval],
    ['Gate Pass',this.dashboard.total_gate_pass_approval],
    ['Pool Car', this.dashboard.total_car_approval],
    ['Metting Room',this.dashboard.total_meeting_room_approval],
    ['ID Card', this.dashboard.total_id_card_approval],
    ['Visiting Card',this.dashboard.total_visiting_card_approval],
    ['Food',this.dashboard.total_food_approval],
    ['Office Item', this.dashboard.total_item_approval]
 ];
}
PiecolumnNames = ['Requisition Request', 'Total'];
Pieoptions = {
    // colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6','#ffffff'],
    is3D: true,
    animation: {
      duration: 1000,
     easing: 'in',
     startup: true
    }
 };
Piewidth = 520;
Pieheight = 400;
PieDataSelect(data){
  var row = data[0].row;
  var col = data[0].column;
  console.log('Pie_col',col,'Pie_row',row);
}

// for Line Chart
   MontlyCharttitle = 'Monthly Final Approved and Reject Requistion Details';
   MonthlyCharttype = 'AreaChart';
   MonthlyApprovedData = [];
   MonthlyApprovedDataCount(){
     this.MonthlyApprovedData = [
            ['Memo Sheet', 15,22],
            ['Note Sheet', 18,12],
            ['Gate Pass', 50,4],
            ['Pool Car', 34,21],
            ['Office Item', 12,1],
            ['ID Card', 31,51],
            ['Visiting Card', 44,62],
            ['Metting Room', 82,20],
            ['Food', 10,17]
     ]
  //   this.MonthlyApprovedData = [
  //     ["Jan", 100,40,50,40,60,85,14,85,76],
  //     ["Feb", 98,38,76,49,165,79,32,146,97],
  //     ["Mar", 74,96,48,79,16,46,79,46,85],
  //     ["Apr", 84,35,94,79,16,32,41,33,22],
  //     ["May", 21,24,38,76,16,24,58,62,63],
  //     ["Jun", 14,35,35,11,14,19,17,24,21],
  //     ["Jul", 17,15,13,71,51,31,94,61,24],
  //     ["Aug", 27,28,24,21,13,25,16,96,14],
  //     ["Sep", 13,30,24,80,64,14,23,46,14],
  //     ["Oct", 31,35,70,6,89,34,96,14,26],
  //     ["Nov", 31,14,23,10,20,35,14,65,14],
  //     ["Dec", 10,80,75,60,64,30,25,18,19]
  //  ]
   }
   MonthlyChartcolumnNames = ['Requisition','Approved','Rejected'];

   MonthlyChartoptions = {
    // colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6','#ffffff'],
    is3D: true,

    animation: {
      duration: 6000,
     easing: 'out',
     startup: true,
    },
    hAxis: {
      title: 'Requisition',
      minValue:0,

     },
     vAxis:{

      title: 'Approved/Reject',

     },
 };
 MonthChart_width = 1040;
 MonthChart_height = 400;
 OnSelectMonthlyApproved(data){


 }
 MonthlyRejectCharttype = 'Line';

}

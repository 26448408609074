import { CommonService } from './../services/common.service';
import { Component, OnInit, ɵConsole, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { RequisitionService } from '../services/requisition.service';
import { MatSnackBar, MatDialog, MatTableDataSource, MatPaginator } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MemoDetailsComponent } from '../memo-details/memo-details.component';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { NoteSheetDetailsComponent } from '../note-sheet-details/note-sheet-details.component';

@Component({
    selector: 'app-view-note-sheet-requisition',
    templateUrl: './view-note-sheet-requisition.component.html',
    styleUrls: ['./view-note-sheet-requisition.component.css']
})
export class ViewNoteSheetRequisitionComponent implements OnInit {

    loader: boolean;
    template:boolean=true;
    tabIndex:number = 0;
    p = 1;
    memoList: any[]=[];
    memoownList: any[]=[];
    reqChangeList : any[]=[];
    approvedList : any[]=[];
    rejected_list: any[]=[];
    ApproveCount:number;
    OwnerdisplayedColumns: string[] = ['Position', 'Subject','Requested_By', 'Description','status','Details'];
    displayedColumns: string[] = ['Position', 'Subject','Requested_By', 'Description','Details'];
    OwnerTableData : MatTableDataSource<any>;
    ApproverTableData :  MatTableDataSource<any>;
    requestChangeTableData :  MatTableDataSource<any>;
    approvedTableData :  MatTableDataSource<any>;
    rejected_table_data :  MatTableDataSource<any>;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('TableOnePaginator', {static: true} as any) ownerpaginator: MatPaginator;
    @ViewChild('TableTwoPaginator', {static: true} as any) ApproverPaginator: MatPaginator;
    @ViewChild('TableThreePaginator', {static: true} as any) reqTablepaginator: MatPaginator;
    @ViewChild('TableFourPaginator', {static: true} as any) approvedTablepaginator: MatPaginator;
    @ViewChild('TableFivePaginator', {static: true} as any) rejected_table_paginator: MatPaginator;

    constructor(
        public userService: UserService,
        public requisitionService: RequisitionService,
        public snackBar: MatSnackBar,
        public commonService: CommonService,
        private fb: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        public OwnerDetailsDialog : MatDialog
    ) { }

    createNoteSheet() {
        this.router.navigate(['create-note-sheet-requisition']);
    }

    OwnerTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.OwnerTableData.filter = filterValue.trim().toLowerCase();
        console.log('value',this.OwnerTableData.filter);

      }
    ApproverTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ApproverTableData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.NoteTabeData.filter);

    }
    reqChangeTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.requestChangeTableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }
    approvedTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.approvedTableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }
    rejectedTableFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.rejected_table_data.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.NoteTabeData.filter);

    }

    getAllMemo() {
        this.loader = true;
        this.userService.getNoteList().subscribe(res => {
            // console.log(res);
            this.loader = false;
            this.memoList = res;
            this.ApproverTableData = new MatTableDataSource<any>(this.memoList);
            this.ApproverTableData.paginator = this.ApproverPaginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message ? err.error.message : JSON.stringify(err.error) , 'ok',{duration:6000});
        });

        this.userService.getNoteOwnList().subscribe(res => {
            // console.log("Owner", res);
            this.loader = false;
            this.memoownList = res;
            this.OwnerTableData = new MatTableDataSource<any>(this.memoownList);
            this.OwnerTableData.paginator = this.ownerpaginator;
            
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
        })
        this.userService.getNoterfcList().subscribe(res => {
            // console.log("getNoterfcList", res);
            this.loader = false;
            this.reqChangeList = res;
            this.requestChangeTableData = new MatTableDataSource<any>(this.reqChangeList);
            this.requestChangeTableData.paginator = this.reqTablepaginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
        })
        this.userService.getApprovedList(2).subscribe(res => {
            // console.log("getApprovedList", res);
            this.loader = false;
            this.approvedList = res;
            this.approvedTableData = new MatTableDataSource<any>(this.approvedList);
            this.approvedTableData.paginator = this.approvedTablepaginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
        })
        this.userService.submit_reqList(2,2).subscribe(res => {
            // console.log("rejected_list", res);
            this.loader = false;
            this.rejected_list = res;
            this.rejected_table_data = new MatTableDataSource<any>(this.rejected_list);
            this.rejected_table_data.paginator = this.rejected_table_paginator;
        }, err => {
            this.loader = false;
            this.snackBar.open('' + err.error.message  ? err.error.message : JSON.stringify(err.error,null,1), 'ok',{duration:6000});
        })
    }

      getPageSizes(): number[] {
        if (this.OwnerTableData.data.length > 100) {
          return [5, 10, 25, 100, this.OwnerTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes2(): number[] {
        if (this.ApproverTableData.data.length > 100) {
          return [5, 10, 25, 100, this.ApproverTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes3(): number[] {
        if (this.requestChangeTableData.data.length > 100) {
          return [5, 10, 25, 100, this.OwnerTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes4(): number[] {
        if (this.approvedTableData.data.length > 100) {
          return [5, 10, 25, 100, this.approvedTableData.data.length];
        }
        else {
         return [5, 10, 25, 100];
        }
      }
      getPageSizes5(): number[] {
        if (this.rejected_table_data.data.length > 50) {
          return [5, 10, 25, 100, this.rejected_table_data.data.length];
        }
        else {
         return [5, 10, 25, 50];
        }
      }



    // test() {
    //     this.getAllMemo();
    // }
    viewNoteSheet(_element, approvecategory){
        this.loader = true;
          this.userService.getNoteDetailById(_element.id).subscribe(res => {
            this.OwnerDetailsDialog.open(NoteSheetDetailsComponent, {
                data: {
                  requestResponse: res,
                  main_row: _element,
                  approvecategory: approvecategory,

                }
            });
            this.loader = false;
        },err=>{
          this.snackBar.open('something went wrong'+ err.error.message ? err.error.message : err.error,'ok',{
            duration : 5000
          });
          this.loader =false
        });
    }
    editNote(id) {
    this.router.navigate(['edit-note/',id]);
    // console.log('NoteEdit Id',id);
    }

    text_collapse:boolean = true;
    expandText(){
    if(this.text_collapse == true){
        this.text_collapse = false;
    }
    else{
        this.text_collapse = true;
    }
    }
    ngOnInit() {
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 1;

        }
        this.getAllMemo();
       
    }

    showDetails(_element, approvecategory) {
        this.userService.getNoteDetailById(_element.id).subscribe(res => {
           const dialog_ref = this.dialog.open(NoteSheetDetailsComponent, {
                data: {
                    requestResponse: res,
                    main_row: _element,
                    approvecategory: approvecategory,
                    sheetType: 'Note'

                        }
                    });

                    dialog_ref.afterClosed().subscribe(res=>{
                        console.log('aftercloseData',res);
                        this.getAllMemo();
                    })
            },err=>{
                this.openSnackBar(JSON.stringify(err.error),'ok');
            });

    }

    delegate(data, approveCategory = '') {

        const testData = {
            requisition_id: data.id,
            approvecategory: approveCategory,
            tableName: 'note_sheet_approvals',
            req_type : 'note_sheet'
        };
      const dialog_ref =  this.dialog.open(DelegateModalComponent, {
            data: testData
        });
        dialog_ref.afterClosed().subscribe(res=>{
            this.getAllMemo();
        })

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

    isok:boolean=true;
    isprintok:boolean =false;
    isexport:boolean=true;
    gocheck(){
    this.isexport=false;
    this.isok = false;
    this.isprintok= true;
    if(this.isok == false){
        this.OwnerdisplayedColumns = ['Position', 'Subject','Requested_By', 'Description','status'];
       
    }
}
    isexport2:boolean=true;
    isok2:boolean= true;
    isprintok2:boolean= false;
    goApproval(){
        this.isexport2=false;
        this.isok2 = false;
        this.isprintok2= true;
        if(this.isok2 == false){
        this.displayedColumns = ['Position', 'Subject','Requested_By', 'Description','status'];
        }
    }
    isexport3:boolean=true;
    isok3:boolean= true;
    isprintok3:boolean= false;
    goRequest(){
        this.isexport3=false;
        this.isok3 = false;
        this.isprintok3= true;
        if(this.isok3 == false){
        this.displayedColumns = ['Position', 'Subject','Requested_By', 'Description','status'];
        }

    }
    isexport4:boolean=true;
    isok4:boolean= true;
    isprintok4:boolean= false; 
    goApproved(){
        this.isexport4=false;
        this.isok4 = false;
        this.isprintok4= true;
        if(this.isok4 == false){
        this.displayedColumns = ['Position', 'Subject','Requested_By', 'Description','status'];
        }

    }
    isexport5:boolean=true;
    isok5:boolean= true;
    isprintok5:boolean= false; 
    goRejected(){
        this.isexport5=false;
        this.isok5 = false;
        this.isprintok5= true;
        if(this.isok5 == false){
        this.displayedColumns = ['Position', 'Subject','Requested_By', 'Description','status'];
        }

    }

  
    
}

// print(){
//     let printContents, popupWin;
//     printContents = document.getElementById('print-section').innerHTML;
//     popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
//     popupWin.document.open();
//     popupWin.document.write(`
//   <html>
//     <head>
//       <title>Print tab</title>
//       <style>
//       //........Customized style.......
//       </style>
//     </head>
// <body onload="window.print();window.close()">${printContents}</body>
//   </html>`
//     );
//     popupWin.document.close();
// }
// }

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { fuseAnimations } from '../animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-meetingromm-report',
  templateUrl: './meetingromm-report.component.html',
  styleUrls: ['./meetingromm-report.component.css'],
  animations : fuseAnimations
})
export class MeetingrommReportComponent implements OnInit {
  loader: boolean;
  dataList:any=[];
  itemList : any =  [
    {id:1,title: 'Room Wise'},
    {id:2,title: 'Meetting Caller Wise'},
    {id:3,title: 'Duration Wise'},
  ];
  tableData: MatTableDataSource<any>;
  columnsFieldName: any;
  ReportForm: FormGroup;
  type:any;
  displayedColumns:any= [{display : 'Subject',field: 'subject'},{display : 'Requested By',field: 'requested_by'},{display : 'Requisition Date',field: 'date'},{display : 'Department',field: 'department'}];

  @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
  @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;
  employeeField: boolean;
  employeeId: any;
  Userlist: any;
  roomList: any;
  roomField: boolean;
  constructor(
    public userService: UserService,
    private snackBar: MatSnackBar,
    private fb : FormBuilder,
  ) { }
  typeOption(event){
    let value = event.value;
    this.type = value;
    if(this.type == 2){
      this.employeeField = true;
      this.roomField = false;
    }else if(value == 1){
      this.roomField = true;
      this.employeeField = false;
    }
    else{
      this.roomField = false;
      this.employeeField = false;
    }
  }
  selectedUser(event){
    this.employeeId = event;
  }
  getList(){
    this.loader = true;
    this.ReportForm.get('start_date').setValue(formatDate(this.ReportForm.get('start_date').value ? this.ReportForm.get('start_date').value : new Date(),'yyyy-MM-dd','en'));
    this.ReportForm.get('end_date').setValue(formatDate(this.ReportForm.get('end_date').value ? this.ReportForm.get('end_date').value : new Date(),'yyyy-MM-dd','en'));
    const payload = Object(this.ReportForm.value);
    console.log('payload',payload);
    if(this.type == 1){
            this.userService.room_wise_meeting_room_requisition(payload).subscribe(res=>{
              this.dataList = res;
              console.log('Mat table Data',res);
              this.loader = false;
              this.tableData = new MatTableDataSource<any>(this.dataList);
              this.tableData.paginator = this.tableOnePaginator;
              this.tableData.sort = this.tableOneSort;
              console.log('Mat table Data',this.tableData);
          },err=>{ 
              this.loader = false;
              this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
          })
    }else if(this.type == 2){
          payload.user_id = this.employeeId;
          this.userService.employee_wise_meeting_room_requisition(payload).subscribe(res=>{
            this.dataList = res;
            console.log('Mat table Data',res);
            this.loader = false;
            this.tableData = new MatTableDataSource<any>(this.dataList);
            this.tableData.paginator = this.tableOnePaginator;
            this.tableData.sort = this.tableOneSort;
            console.log('Mat table Data',this.tableData);
        },err=>{ 
            this.loader = false;
            this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
        })
    }
    else{
      this.userService.date_wise_meeting_room_requisition(payload).subscribe(res=>{
        this.dataList = res;
        console.log('Mat table Data',res);
        this.loader = false;
        this.tableData = new MatTableDataSource<any>(this.dataList);
        this.tableData.paginator = this.tableOnePaginator;
        this.tableData.sort = this.tableOneSort;
        console.log('Mat table Data',this.tableData);
    },err=>{ 
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
    }
   
}

getUser(){
  this.loader = true;
    this.userService.getUsers().subscribe(res=>{
      this.Userlist = res;
      console.log('userlist',res);
      this.loader = false;
      
    },err=>{
      this.openSnackBar('Somthing went wrong, Internal Error'+err,'ok');
    })
}

getMeetingRoom(){
  this.userService.getMeetingRoomList().subscribe(res=>{
    this.roomList = res;
    console.log('roomList',this.roomList);
    
  })
}
  ngOnInit() {
    this.getUser();
    this.getMeetingRoom();
    this.columnsFieldName = this.displayedColumns.map(item => item.field);
    this.ReportForm = this.fb.group({
        start_date : ['',Validators.required],
        end_date : ['',Validators.required],

        report_type : ['',Validators.required],
        item_id : [''],
        room_no : [''],
    })

  }
  openSnackBar(message: string, action: string,time?:number) {
    this.snackBar.open(message, action, {
        duration : time
    });
}

}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, FormArrayName, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { formatDate, DOCUMENT } from '@angular/common';
import { fuseAnimations } from '../animations';
import { UserService } from '../services/user.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import{currency} from '../json_data';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { Designation } from '../model/designation';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { RequisitionService } from '../services/requisition.service';
import { SharedDataService } from '../services/shared-data.service';
import { RefDetailsDialogComponent } from '../ref-details-dialog/ref-details-dialog.component';
import { PaymentNoteDetailsComponent } from '../payment-note-details/payment-note-details.component';
import { ApprovalNoteDetailsComponent } from '../approval-note-details/approval-note-details/approval-note-details.component';

@Component({
  selector: 'app-payment-note-edit',
  templateUrl: './payment-note-edit.component.html',
  styleUrls: ['./payment-note-edit.component.css']
})
export class PaymentNoteEditComponent implements OnInit {
  loader: boolean;
  notesheetForm: FormGroup;
  // myControl = new FormControl();
  notesheetId: number;
  notesheetEdit: boolean;
  designationList: Designation[];
  employeeList$ = [];
  emplist: any;
  filteredOptions: Observable<any[]>[] = [];
  notesheetData: any;
  files: any;
  url: any;
  fileUrl: string | ArrayBuffer;
  fileArray:any[]= [];
  selectedEmployee: any;
  Expension_Head :[];
  MemId;
  referelKey: string;
  filteredOption: Observable<any>;
  paymentNoteId:any;
  budgetLimit: any;
  paymentNoteCreate: boolean = false;
  memo_id: any;
  accounts_list:any[]=[];
  accounts_person_id: any;
  accounts_spinner: boolean;
  spinner: boolean;
  file_delete_option: boolean;
  editFiles:any[] = [];
  deletedFiles:any[]=[];
  progress: number = 0;
  ref_no_For_backend: string;
  CurrArray :any = currency;
  issame:boolean=false;
  newemp:any;
  isok:boolean= false;
  isadd:boolean= false;
  acc:boolean=false;
  generateFormMemo: boolean = false;

  constructor( public dialogRef: MatDialogRef<PaymentNoteEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public requisitionService: RequisitionService,
    private sharedService : SharedDataService,
    private preview_dialog : MatDialog,
    private ref_dialog : MatDialog
) { }

SelectedAccounts(data){
  this.issame=false;
  // console.log('newvalue',this.selectedEmployee)

  // console.log('selected accounts_person',data);
 
  if(data!= null){
   
    this.acc=true;
  }
  if(this.selectedEmployee.id === data){
      // console.log('found')
      this.issame = true;
  }
  if(this.newemp !== undefined)
  {
      for(let i = 0; i<this.newemp.length; i++)
      {
              if(this.newemp[i].employee_id.id === data)
              {
              // console.log('found')
              this.issame = true;
                break;
                
              }
         
          }

      }

      this.notesheetForm.get('accounts_person').setValue(data);

}
get employeeForms() {
  return this.notesheetForm.get('employeeName') as FormArray;
}

addMoreempItem() {
  this.isadd=false;
  if(this.notesheetEdit){
    // if(this.addempItem().value != ''){
this.employeeForms.push(this.addempItem());
    // }
    
    this.newemp = this.employeeForms.value;
    console.log('edit newemp',this.newemp)
    this.employListAutoCompleteControl(this.employeeForms.length - 1);
    if(this.newemp){
      for(let i=0;i<=this.editempform.length;i++){
        for(let j=0;j<=this.newemp.length;j++){
          if(this.editempform[i]==this.newemp[j].employee_id.id){
            this.isok=true;
          }
          else{
            this.isok = false;
          }
          if(!this.isok && this.newemp[j].employee_id !=''){
            this.editempform.push(this.newemp[j].employee_id.id)
            this.approveeditemp.push(this.newemp[j].employee_id.id)
            console.log('edit entry',this.editempform)
    
          }
        }
        
      }
    
    }
    
  }
  if(!this.notesheetEdit){
  this.employeeForms.push(this.addempItem());
  // console.log('empv', this.employeeForms.value)
  this.newemp = this.employeeForms.value;
  this.employListAutoCompleteControl(this.employeeForms.length - 1);
}
}

addempItem() {
  return this.fb.group({
      employee_id: ['']
  });
}

deleteItem(i) {
  this.issame=false;
  // console.log('deleteIndex', i);
  this.filteredOptions.splice(i, 1);
}
approvarDeleteList:any=[]
editempform:any=[]
delar:any=[]
x:boolean=false;
editdeleteempItem(i) {
   
  for(let j=0;j<=this.data.requisition_details.length;j++){
    if( i.approval_status!=1
      && i.approved_by== this.data.requisition_details[j].approved_by){
       this.x=true;
      
     this.editempform.splice(j, 1)
     console.log('editempform',this.editempform)
    //  this.approvarDeleteList.push(this.editempform.splice(j,1)[0]) 
     this.approvarDeleteList.push(this.data.requisition_details.splice(j,1)[0]) 
    //  this.newapprovarDeleteList.push(this.approvaldetails.approve_info.splice(j,1)[0]) 
    
     console.log('approvarDeleteList',this.approvarDeleteList)
    }
       if (!this.delar.some(el => el.id === this.data.requisition_details.id)) {
      this.delar.push(this.data.requisition_details);
      console.log('delar',this.data.requisition_details)
    }
  }
   
   
    
}
deleteempItem(i) {
  this.isok=false;
  const employeeArraySize = this.employeeForms.length - 1;
  if (employeeArraySize === 0) {
      this.notesheetForm.patchValue({
          employee_id: ''
      });
  }
  this.employeeForms.removeAt(i);
}
// getEmployee(value) {
//   this.isadd = true;
//   this.selectedEmployee = value;
//   console.log('val',value)
  
//   if(this.newemp !== undefined)
//   {
//       for(let i = 0; i<this.newemp.length; i++)
//       {
//               if(this.newemp[i].employee_id.id === value.id)
//               {
//                 this.isok=true;
//                 break;
//               }
//               else{
//                   this.isok=false;
//                   continue;
//               }
         
//           }

//       }
// }
getEmployee(value) {
  this.selectedEmployee = value;
  this.isadd = true;// new add
  console.log('value', value)
  console.log('editempform',this.editempform)
  if(this.notesheetEdit){
 

    // for(let i=0; i<=this.editempform.length; i++){
    //   if(this.editempform[i]==value.id){
    //     console.log(this.editempform[i],value.id)
    //     console.log('found')
    //     this.isok = true;
    //     break;

    //   }else{
    //     this.isok = false;
   
    //   }
    // }
    // if(this.approveeditemp){
       for(let i =0;i<=this.approveeditemp.length;i++){
            if(this.approveeditemp[i] == value.id){
                console.log('found')
                this.isok = true;
                 break;
      }else{
        this.isok = false;
      }
    }
  // }
    if(this.approvarDeleteList){
        for(let j=0;j<=this.approvarDeleteList.length;j++){
          if(this.approvarDeleteList[j].approved_by == value.id){
            this.isok = false;
            this.editempform.push(value.id)
            this.approveeditemp.push(value.id)
            this.approvarDeleteList.splice(j, 1)
             console.log('rem',this.editempform)
          
          }
          
        }
        if(!this.isok){
          console.log('push')
          
        }
   
      }
     
   
  }else{
      if(this.editempform.indexOf(value.id) == -1){
      this.editempform.push(value.id)
      this.isok= false;
    } 
    else{
      this.isok = true; 
    }
    // if(this.approvaldetails.)
    
    
  
 
  console.log('editenter',this.editempform) 
  console.log('data',this.data)

    if(this.selectedEmployee.id === this.data)
    {
        console.log('found')
        this.isok = true;   
        
    }
        
    if(this.newemp !== undefined )
    {
        for(let i = 0; i<this.newemp.length; i++)
        {
            
                if(this.newemp[i].employee_id.id === value.id)
                {
                  this.isok=true;
                  break;
                }
                
                else{
                    this.isok=false;
                    continue;
                }
           
            }

           
            
        }
  }

    // if(this.approvaldetails.approve_info.indexOf(value.id)== -1){
    //   this.editempform.push(value.id)
    //   console.log('rem',this.editempform)
    // }
  
        // if(this.NoteEdit && this.newemp !== undefined){

        // }
    }

getReferance(){
 
      // console.log('ref_id_backend',this.data.id);
      this.referelKey= this.data.requisition_info[0].ref_no
      // console.log('referelKey',this.referelKey);
    
  }
view(ref){
  // console.log(ref)
  if(this.data.requisition_info[0].ref_no== ref){
    // console.log('ref data',this.data.requisition_info[0].id);
    this.userService.getApprovalNoteDetails(this.data.requisition_info[0].id).subscribe(details_data=>{
        // console.log('newdata',details_data)
  
        const dialogRef = this.ref_dialog.open(ApprovalNoteDetailsComponent,{
          data: {
              detail: details_data,
              approvecategory: 'User',
              
          }
        })
      })
    }
    
    
  }
  employListAutoCompleteControl(index: number) {
    const arrayControl = this.notesheetForm.get('employeeName') as FormArray;
    this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.f_name),
            map(name => name ? this._filter(name) : this.employeeList$.slice())
        );

}
private _filter(value: any): string[] {
  const filterValue = value.toLowerCase();
  return this.employeeList$.filter(option =>{
      return option.f_name.toLowerCase().indexOf(filterValue) !== -1 ||  option.pf_no == value;
  })

}
displayFn(data?: any): string | undefined {
  return data ? data.f_name : undefined;
}

getSpecificPaymentNote(){
  this.notesheetForm.patchValue({
    
    apply_date : this.data.requisition_info[0].apply_date,
    amount: this.data.requisition_info[0].amount,
    cur_type : this.data.requisition_info[0].cur_type,
    subject: this.data.requisition_info[0].subject,
    Payee_account_no: this.data.requisition_info[0].Payee_account_no,
    routing_no: this.data.requisition_info[0].routing_no,
    payee_bankname: this.data.requisition_info[0].payee_bankname,
    payee_branchname: this.data.requisition_info[0].payee_branchname,
    pay_to:this.data.requisition_info[0].pay_to,
    payee_address: this.data.requisition_info[0].payee_address,
    vendor_bill_no: this.data.requisition_info[0].vendor_bill_no,
    vendor_bill_date: this.data.requisition_info[0].vendor_bill_date,
    purpose: this.data.requisition_info[0].purpose,
    tin : this.data.requisition_info[0].tin,
    bin_nid : this.data.requisition_info[0].bin_nid,
});
this.referelKey = this.data.requisition_info[0].ref_no;
this.notesheetForm.get('vendor_bill_date').setValue(new Date(this.data.requisition_info[0].vendor_bill_date));

}

createNotesheet(submitdata) {/* department save service */
    this.loader = true;
    this.requisitionService.officeItemNoteSheetCreate(submitdata).subscribe((event: HttpEvent<any>) => {
      
        switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              this.snackBar.open('File uploaded Successfull','ok');
              setTimeout(() => {
                this.progress = 0;
                this.openSnackBar('successfully created! ', 'ok');
                this.loader = false;
                this.router.navigate(['payment-note-list']);
                this.dialogRef.close('reload');
              });

          }


    }, err => {
        
        this.snackBar.open(' ' + err.error.message ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
    });
    // this.router.navigate(['view-notesheet']);

}
editNotesheet(editeddata) {/* department save service */
    this.loader = true;
    // console.log('edited data',editeddata)
     this.userService.editPaymetNote(editeddata).subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
               break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
               break;
            case HttpEventType.Response:
                this.snackBar.open('File uploaded Successfull','ok');
                setTimeout(() => {
                  this.progress = 0;
                  this.openSnackBar('successfully created! ', 'ok');
                  this.loader = false;
                  this.router.navigate(['payment-note-list']);
                  this.dialogRef.close('reload');
                });

          }
    }, err => {
        this.loader = false;
        if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    });
}

notesheetFormSubmit(status:any) {
  if (this.notesheetEdit) {
     
      const fd = new FormData();
      if(this.generateFormMemo){
          fd.append('memo_id', this.memo_id);
      }

      fd.append('ref_no', this.referelKey);
      fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
      fd.append('amount', this.notesheetForm.value.amount);
      fd.append('cur_type', this.notesheetForm.value.cur_type);
      fd.append('subject', this.notesheetForm.value.subject);
      fd.append('tin', this.notesheetForm.value.tin);
      fd.append('bin_nid', this.notesheetForm.value.bin_nid);
      fd.append('pay_to', this.notesheetForm.value.pay_to);
      fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
      fd.append('routing_no', this.notesheetForm.value.routing_no);
      fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
      fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
      fd.append('payee_address', this.notesheetForm.value.payee_address);
      fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
      fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
      fd.append('purpose', this.notesheetForm.value.purpose);
      fd.append('requisition_id', this.data.requisition_info[0].id);
      fd.append('status',status);
      fd.append('deletedFile',JSON.stringify(this.deletedFiles));
      if (this.fileArray) {
          if (this.fileArray.length > 0) {
              for (let x = 0; x < this.fileArray.length; x++) {
                  fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
              }
          }
      }
      
    fd.append('employeeName', JSON.stringify(this.employeeForms.value));
    fd.append('editapproval_list',JSON.stringify(this.editempform));
    fd.append('approvars',JSON.stringify(this.delar))
    fd.append('approvarDeleteList',JSON.stringify(this.approvarDeleteList)) 
      console.log('PaymentFormData',fd);
      this.editNotesheet(fd)
  } else {

      const fd = new FormData();
      if(this.generateFormMemo){
          fd.append('memo_id', this.data.id);
          fd.append('genFormMemo', '1');
          fd.append('ref_no', this.referelKey);
      }else{
          fd.append('memo_id', this.data.id);
          fd.append('genFormMemo', '0');
          fd.append('ref_no', this.referelKey);
      }
      const fc = this.fb.group({
          employee_id: [{id : this.notesheetForm.value.accounts_person, check_issue : 1}],
        })
      this.employeeForms.push(fc);
      console.log('employeforms_value',this.employeeForms.value);
      fd.append('employeeName', JSON.stringify(this.employeeForms.value));
      fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
      fd.append('pay_to',this.notesheetForm.value.pay_to)
      fd.append('amount', this.notesheetForm.value.amount);
      fd.append('cur_type', this.notesheetForm.value.cur_type);
      fd.append('subject', this.notesheetForm.value.subject);
      fd.append('tin', this.notesheetForm.value.tin);
      fd.append('bin_nid', this.notesheetForm.value.bin_nid);
      fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
      fd.append('routing_no',this.notesheetForm.value.routing_no);
      fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
      fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
      fd.append('payee_address', this.notesheetForm.value.payee_address);
      fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
      fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
      fd.append('purpose', this.notesheetForm.value.purpose);
      fd.append('paynote_id', this.MemId);
      fd.append('status',status);
      if (this.fileArray) {
          if (this.fileArray.length > 0) {
              for (let x = 0; x < this.fileArray.length; x++) {
                  fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
              }
          }
      }
      console.log('PaymentFormData',fd);
      this.createNotesheet(fd);
  }
// }
}
deleteon:boolean=true;
approveeditemp:any=[];
  ngOnInit() {
    console.log('noteEdit',this.data)
    if(this.data.requisition_info){
      this.notesheetEdit = true;
      if(this.data.requisition_details.approval_status == 1){
        this.deleteon = false;
    }
    else{
      this.deleteon = true;
    }
    if(this.data.requisition_details.length>0){
      for( let i=0;i<this.data.requisition_details.length;i++){
        if(this.data.requisition_details[i].approval_status!=1 && this.data.requisition_details[i].approved_by!=0){
          this.editempform.push(this.data.requisition_details[i].approved_by)
          console.log('empnew',this.editempform)
           }
           if(this.data.requisition_details[i].approved_by!=0){
            this.approveeditemp.push(this.data.requisition_details[i].approved_by)
            console.log('approveeditemp',this.approveeditemp)
           }
         
      }
      
    }
      this.notesheetForm = this.fb.group({
          apply_date : [''],
          subject : ['',Validators.required],
          tin : ['',Validators.required],
          bin_nid : ['',Validators.required],
          amount: ['', Validators.required],
          cur_type : ['BDT', Validators.required],
          Payee_account_no: ['', Validators.required],
          routing_no : [''],
          payee_bankname:[''],
          payee_branchname:[''],
          pay_to:[''],
          payee_address: [''],
          vendor_bill_no: ['' ],
          vendor_bill_date: [''],
          purpose: [''],
          accounts_person : [''],
          employeeName: this.fb.array([this.addempItem()]),
          files: [null]
      });
     this.getSpecificPaymentNote(); 
     this.employListAutoCompleteControl(0);
    }else{
      this.getReferance();
      this.notesheetForm = this.fb.group({
          apply_date : [''],
          subject : ['',Validators.required],
          tin : ['',Validators.required],
          bin_nid : ['',Validators.required],
          amount: ['', Validators.required],
          cur_type : ['BDT', Validators.required],
          Payee_account_no: ['', Validators.required],
          routing_no : [''],
          payee_bankname:[''],
          payee_branchname:[''],
          payee_address: [''],
          pay_to:[''],
          vendor_bill_no: ['' ],
          vendor_bill_date: [''],
          purpose: [''],
          accounts_person : [''],
          employeeName: this.fb.array([this.addempItem()]),
          files: [null]
      });
      this.employListAutoCompleteControl(0);

  }


  this.spinner = true;
  this.userService.getUserList().subscribe((res) => {
      this.employeeList$ = res;
     
      this.spinner = false;
    

  });
 

    }
    forbiddenNamesValidator(names:any): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        // below findIndex will check if control.value is equal to one of our options or not
        const index = names.findIndex(name => {
          return (new RegExp('\^' + name.f_name + '\$')).test(control.value.f_name);
        });
        return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
      };
    }
  
  
    close(){
      this.dialogRef.close();
     }
  
  
  openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
          horizontalPosition : "end",
      });
  }
    
      

  }



import { Component, OnInit } from '@angular/core';
import { dummy, dummysignatureUrl, dummyphotoUrl } from '../commonUrl';
import { FormBuilder, FormGroup,FormControl,FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Department } from '../model/department';
import { Designation } from '../model/designation';
import { UserType } from '../model/userType';
import {ErrorStateMatcher} from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  hide = true;
  dummyUrl = dummy;
  actualSignature = dummysignatureUrl;
  actualImage = dummyphotoUrl;
  userForm: FormGroup;
  selectedSignatutre: File;
  selectedImage: File;
  message: string;
  imagePath: any;
  imgURL: any;
  signatureURL: any;
  signaturePath: any;
  loader: boolean;
  departmentList: Department[];
  designationList: Designation[];
  userTypeList: UserType[];
  userId: number;
  userEdit: boolean;
  //is_budget_person = 0;

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  
  
  // email = new FormControl('', [Validators.required, Validators.email]);
  constructor(
    private fb: FormBuilder,
    public datepipe: DatePipe,
    public userService: UserService,
    private snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute,
   
  ) {}
  
  createUser(userTypeForm) {
    this.loader = true;
    this.userService.createUser(userTypeForm).subscribe(data => {
      console.log(data)
      this.openSnackBar("Create user success", "ok")
      this.loader = false;
      this.router.navigate(['view-user']);
    }, err => {
      this.loader = false;
      console.log(err)
      if (err.error.message) this.openSnackBar("Internal Error" + err.error.message, "ok")
      else this.openSnackBar("Opps!,Something went wrong,Internal Error", "ok");
    });
  }

  editUser(userForm) {
    this.loader = true;
    this.userService.editUser(userForm).subscribe(data => {
      this.loader = false;
      this.openSnackBar("Successfully Updated user information", "ok");
      this.router.navigate(['view-user']);
    }, error => {
      this.loader = false;
      this.openSnackBar("Internal error", "ok")
      console.log(error);
    });
  }

  getAllDesignation() {
    this.loader = true;
    this.userService.getDesignationList().subscribe(res => {
      console.log(res)
      this.loader = false;
      this.designationList = res;
      //this.openSnackBar("Success ", "ok");
    }, err => {
      this.loader = false;
      this.openSnackBar(" " + err.error.message, "ok");
    });
  }
  // isbudget(event){
  //   console.log('hlw')
  
  //  this.is_budget_person=event
  //  console.log('budget', this.is_budget_person)

  // }
  getAllDepartment() {
    this.loader = true;
    this.userService.getDepartmentList().subscribe(res => {
      console.log(res)
      this.loader = false;
      this.departmentList = res;
      //this.openSnackBar("Success ", "ok");
    }, err => {
      this.loader = false;
      this.openSnackBar(" " + err.error.message, "ok");
    });
  }

  getAllUserType() {
    this.loader = true;
    this.userService.getUserTypeList().subscribe(res => {
      console.log(res)
      this.loader = false;
      this.userTypeList = res;
    }, err => {
      this.loader = false;
      if (err.error.message) this.openSnackBar(" " + err.error.message, "ok");
      else this.openSnackBar(" " + err.error, "ok");
    });
  }

  getSpecificUser(userId) {
    this.loader = true;
    this.userService.getParticualrUser(userId)
      .subscribe(data => {
        console.log("Test", data)
        this.loader = false;
        console.log('call getUser:  ' + userId);
        console.log('call hfhfhfhfhfh:  ' + data[0].is_budget_person);
        console.log('call =========:  ' + data[0].is_active);
        
        this.userForm.patchValue({
          user_id: userId,
          f_name: data[0].f_name,
          //       l_name: data[0].l_name,
          email: data[0].email,
          phone: data[0].phone,
          designation_id: data[0].designation_id,
          type_id: data[0].type_id,
          dept_id: data[0].dept_id,
          pf_no : data[0].pf_no,
          dob: data[0].dob,
          is_budget_person: data[0].is_budget_person,
          is_active:data[0].is_active
          
          
        });
      
         if(data[0].is_active == 0){
          this.userForm.patchValue({
            is_active:0
          })
        }
          else if(data[0].is_active == 1){
            this.userForm.patchValue({
              is_active:1
            })
          }
          console.log('update', this.userForm)
       
       
        this.signatureURL = this.actualSignature + data[0].signature;
        this.imgURL = this.actualImage + data[0].user_image;
      }, error => {
        this.loader = false;
        this.openSnackBar("Internal error", "ok")
        console.log(error);
      });
  }

  handleSignatutreInput(event) {
    this.selectedSignatutre = <File>event.target.files[0];
    console.log(this.selectedSignatutre)
  }

  handleImageInput(event) {
    this.selectedImage = <File>event.target.files[0];
    console.log(this.selectedImage);

  }
  // getErrorMessage() {
  //   if (this.userForm.value.email.hasError('required')) {
  //     return 'You must enter a value';
  //   }

  //   return this.userForm.value.email.hasError('email') ? 'Not a valid email' : '';
  // }


  ngOnInit() {
    // this.is_budget_person = 0;
    this.getAllDepartment();
    this.getAllDesignation();
    this.getAllUserType();
    const { validate, operator } = require("bd-phone-number-validator");
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.userId = +this.route.snapshot.paramMap.get('id');
      this.userEdit = true;
      this.userForm = this.fb.group({
        user_id: [''],
        f_name: ['', Validators.required],
        //        l_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.pattern('(?:(?:\\+88|00)01[3-9])?\\d{11}')]],
        dob: ['', Validators.required],
        type_id: ['', Validators.required],
        dept_id: ['', Validators.required],
        designation_id: ['', Validators.required],
        pf_no: ['', Validators.required],
        user_image: [''],
        signature: [''],
        is_budget_person:[''],
        is_active:[],
    });
      this.getSpecificUser(this.userId);
    } else {
      this.userForm = this.fb.group({
        f_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.pattern('(?:(?:\\+88|00)01[3-9])?\\d{11}')]],
        password: ['', Validators.required],
        dob: ['', Validators.required],
        type_id: ['', Validators.required],
        dept_id: ['', Validators.required],
        designation_id: ['', Validators.required],
        pf_no: ['', Validators.required],
        user_image: [''],
        signature: [''],
        is_budget_person:[''],
        is_active:[],
        /*   user_image_file: [null],
          signature_file: [null] */

      });
    
    }

  }
  
  onUserSubmit() {
    this.userForm.get('dob').setValue(formatDate(this.userForm.get('dob').value ? this.userForm.get('dob').value : new Date(), 'yyyy-MM-dd', 'en'));
    if (this.userEdit === true) {
      let fd = new FormData();
      fd.append('user_id', this.userId.toString());
      fd.append('f_name', this.userForm.value.f_name);
      fd.append('email', this.userForm.value.email);
      fd.append('phone', this.userForm.value.phone);
      fd.append('dob', this.userForm.value.dob);
      fd.append('pf_no', this.userForm.value.pf_no);
      fd.append('type_id', this.userForm.value.type_id);
      fd.append('dept_id', this.userForm.value.dept_id);
      fd.append('designation_id', this.userForm.value.designation_id);
      if (this.selectedImage) fd.append('user_image', this.selectedImage, this.selectedImage.name);
      if (this.selectedSignatutre) fd.append('signature', this.selectedSignatutre, this.selectedSignatutre.name);
      fd.append('is_budget_person', this.userForm.value.is_budget_person);
      fd.append('is_active', this.userForm.value.is_active);
      console.log('edit',fd)
      this.editUser(fd)
    } else {
      console.log(this.userForm.value);
      let fd = new FormData();
      fd.append('f_name', this.userForm.value.f_name);
      fd.append('email', this.userForm.value.email);
      fd.append('phone', this.userForm.value.phone);
      fd.append('password', this.userForm.value.password);
      fd.append('dob', this.userForm.value.dob);
      fd.append('pf_no', this.userForm.value.pf_no);
      fd.append('type_id', this.userForm.value.type_id);
      fd.append('dept_id', this.userForm.value.dept_id);
      fd.append('designation_id', this.userForm.value.designation_id);
      if (this.selectedImage) fd.append('user_image', this.selectedImage, this.selectedImage.name);
      if (this.selectedSignatutre) fd.append('signature', this.selectedSignatutre, this.selectedSignatutre.name);
      fd.append('is_budget_person', this.userForm.value.is_budget_person);
      fd.append('is_active', this.userForm.value.is_active);
      console.log('form data', fd);

      this.createUser(fd);
    }

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  previewImage(files) {
    if (files.length === 0) {
      return;
    }
    // tslint:disable-next-line:prefer-const
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  previewSignature(files) {
    if (files.length === 0) {
      return;
    }
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.signaturePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.signatureURL = reader.result;
    };
  }


}

import { CommonService } from './../services/common.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { UserService } from '../services/user.service';
import { DialogComponent } from '../dialog/dialog.component';
import { RequisitionService } from '../services/requisition.service';


@Component({
    selector: 'app-notification-modal',
    templateUrl: './notification-modal.component.html',
    styleUrls: ['./notification-modal.component.css']
})
export class NotificationModalComponent implements OnInit {
    loader: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public userService: UserService,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        public commonService: CommonService,
        public requisitionService: RequisitionService
    ) {
    }

    readNotification(data) {
        this.userService.markNotificationAsSeen(data.id).subscribe((response) => {
            // add the line 25,26 here after fix console error
        });

        // this should be inside the 'markNotificationAsSeen' subscription
        this.userService.notificationCountUpdate.emit({ 'data': 'I am updated' });
        this.dialog.closeAll();
    }

    seeDetails(notification) {

        console.log('Before notification', notification);
        this.requisitionService.getNotificationDetail(notification).subscribe(response => {


            console.log('notificationpppppppppppp',response );
            const exampleData = {
                IsFromNotification: true,
                requisition_id: this.data.requisition_id,
                notification_id: this.data.id,
                // To: '',
                // Date: '',
                // Subject: this.data.subject,
                // Description: this.data.description,
                // Approve_by: '',
                // recommended_by: '',
                // final_approved_by: '',
                approval_status: response['requisition_info'][0].approval_status,
                final_approval_status:  response['requisition_info'][0].final_approval_status,
                recommendation_status: response['requisition_info'][0].recommendation_status,
                Category: response['requisition_details'],
                approvecategory: this.data.approvecategory,
                requested_by: this.data.notification_from,
               // requested_by_name: this.data.requested_by,
                url: this.data.requisition_type
            };
            console.log('lllllllllllllllllllllll', exampleData);
            this.dialog.open(DialogComponent, {
                data: exampleData
            });
        });
    }

    ngOnInit() {
       // console.log("PPPPPPPPPPPPP",this.data);
         this.commonService.approvalEvent.subscribe((data) => {
            this.giveApprove(data);
        });
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }

    giveApprove(data) {

        this.requisitionService.requisitionApprove(data).subscribe(res => {
          console.log(res);
          this.loader = false;
          this.openSnackBar('Successfull', 'ok');
        }, err => {
          this.loader = false;
          if (err.error.message) {
              this.openSnackBar('' + err.error.message, '');
          } else {
              this.openSnackBar('' + err.error, '');
          }
        });
      }

}

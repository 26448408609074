import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material';
import { Vehicle } from '../model/vehicle';
import { dummy } from '../commonUrl';
import { VehicleType } from '../model/vehicleType';
import { Router } from '@angular/router';


@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.css']
})
export class ViewVehicleComponent implements OnInit {

  loader: boolean;
  dummyUrl:string = dummy;
  p: number = 1;
  p2: number = 1;
  vehicleList: MatTableDataSource <Vehicle>;
  vehicleTypeList: MatTableDataSource<VehicleType>;
  displayedVehicleColumns: string[] = ['Position','Model','Registration','Type','Edit'];
  displayedVehicleTypeColumns: string[] = ['Position','Type','Edit'];
  @ViewChild('TableOnePaginator', {static: true} as any) tableOnePaginator: MatPaginator;
  @ViewChild('TableTwoPaginator', {static: true} as any) tableTwoPaginator: MatPaginator;
  vehicleTypeListdata: VehicleType[];
  vehicleListData: Vehicle[];
  constructor(
    public userService:UserService,
    private snackBar:MatSnackBar,
    public router: Router,
  ) { }

  editVehicle(id){
    console.log(id)
    this.router.navigate(['edit-vehicle/',id]);
  }

  editVehicleType(id){
    console.log(id)
    this.router.navigate(['edit-vehicle-type/',id]);
  }

  ngOnInit() {
  
    this.getAllVehicle();
    this.getAllVehicleType();
  }
  VehiclelistFilter(event:any) {
    const filterValue = event.target.value;
    this.vehicleList.filter = filterValue.trim().toLowerCase();
  }
  Vehiclelist_typeFilter(event:any) {
    const filterValue = event.target.value;
    this.vehicleTypeList.filter = filterValue.trim().toLowerCase();
  }
  getAllVehicle(){
    this.loader = true;
    this.userService.getVehicleList().subscribe( res =>{
      console.log('viheclelist',res);
        this.loader = false;
        this.vehicleListData = res;
        this.vehicleListData.sort((a,b)=>b.id - a.id);
        this.vehicleListData.map((v,i)=>v.sn = i+1);
        this.vehicleList = new MatTableDataSource<Vehicle>(this.vehicleListData);
        this.vehicleList.paginator = this.tableOnePaginator;
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getAllVehicleType(){
    this.loader = true;
    this.userService.getVehicleTypeList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.vehicleTypeListdata= res;
        this.vehicleTypeListdata.sort((a,b)=>b.id - a.id);
        this.vehicleTypeListdata.map((v,i)=>v.sn = i+1);
        this.vehicleTypeList = new MatTableDataSource<VehicleType>(this.vehicleTypeListdata);
        this.vehicleTypeList.paginator = this.tableTwoPaginator;
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

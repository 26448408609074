import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-designation',
  templateUrl: './create-designation.component.html',
  styleUrls: ['./create-designation.component.css']
})
export class CreateDesignationComponent implements OnInit {
  loader: boolean;
  designationForm: any;
  designationId: number;
  designationEdit: boolean;

  constructor(
    public userService:UserService,
    public snackBar:MatSnackBar,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  createDesignation(data){/* department save service */
    this.loader = true;
    this.userService.designationCreate(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Success ", "ok");
      this.router.navigate(['view-designation']);
    },err=>{
      this.loader = false;
      this.openSnackBar(" "+err.error.message, "ok");
    });
  }

  designationFormSubmit(){/* department form submit */
    console.log(this.designationForm.value);
    if( this.designationEdit){
      let fd = new FormData()
      fd.append('designation_id', this.designationId.toString());
      fd.append('title', this.designationForm.value.title);
      this.editDesignation(fd);
    }else{
      let fd = new FormData()
      fd.append('title', this.designationForm.value.title);
      this.createDesignation(fd);
    }
  }

  editDesignation(data){/* department save service */
    this.loader = true;
    this.userService.editDesignation(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Updated", "ok");
      this.router.navigate(['view-designation']);
    },err=>{
      this.loader = false;
      if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
      else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getSpecificDesignation(designationId) {
    this.loader = true;
    // let fd = new FormData()
    // fd.append('id', departmentId)
    this.userService.getParticularDesignation(designationId)
        .subscribe(data => {
          console.log(data)
          this.loader = false;
          this.designationForm.patchValue({
            id: designationId,
            title: data[0].title,
          });
        }, error => {
          this.loader = false;
          this.openSnackBar("Internal error", "ok")
          console.log(error);
        });
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.designationId = +this.route.snapshot.paramMap.get('id');
      console.log(this.designationId);
      this.designationEdit = true;
      this.designationForm = this.fb.group({
        id :['',Validators.required],
        title: ['', Validators.required],
      });
      this.getSpecificDesignation(this.designationId);
    }else{
      /* department form create */
      this.designationForm = this.fb.group({
        title: ['', Validators.required],
      });

    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { MeetingRoom } from '../model/meetingRoom';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-meeting-room',
  templateUrl: './view-meeting-room.component.html',
  styleUrls: ['./view-meeting-room.component.css']
})
export class ViewMeetingRoomComponent implements OnInit {
  loader: boolean; 
  p = 1;
  data:any;
  meetingRoomList: MatTableDataSource<MeetingRoom>;
  displayedColumns: string[] = ['Position','Room_Name','Floor_no','Room_No','Others','Edit'];
  @ViewChild(MatPaginator,{static : true} as any) paginator : MatPaginator;
  constructor(
      public userService: UserService,
      public snackBar: MatSnackBar,
      private router: Router
  ) { }

  editMeetingRoom(id) {
    this.router.navigate(['edit-meeting-room/', id]);
  }

  ngOnInit() {
    this.getAllMeetingRoom();
  }
  applyFilter(event:any){
    let filterValue = event.target.value;
    this.meetingRoomList.filter = filterValue.trim().toLowerCase();
  }
  getAllMeetingRoom() {
    this.loader = true;
    this.userService.getMeetingRoomList().subscribe( res =>{
      console.log(res)
      this.loader = false;
      this.data = res;
      this.data.sort((a,b)=> b.id - a.id);
      this.data.map((v,i)=>v.sn = i+1);
      this.meetingRoomList = new MatTableDataSource<MeetingRoom>(this.data);
      this.meetingRoomList.paginator = this.paginator;
      //this.openSnackBar("Success ", "ok");
    }, err => {
      this.loader = false;
      this.openSnackBar(" "+err.error.message, "ok");
    });
  }


  createMeetingRoom() {
    this.router.navigate(['/create-meeting-room']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

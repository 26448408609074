
import { Component, OnInit,  ViewChild} from '@angular/core';
import { MatSnackBar, MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { RequisitionService } from '../services/requisition.service';
import { OfficeItemRequisition } from '../model/officeItemRequisition';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { OfficeItem } from '../model/officeItem';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router,ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common.service';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { fuseAnimations } from '../animations';
import { SelectionModel } from '@angular/cdk/collections';
import { OfficeItemDetailsComponent } from '../office-item-details/office-item-details.component';
import { ApprovalNoteComponent } from '../approval-note/approval-note.component';
import * as _  from 'lodash';
import { HostListener } from "@angular/core";

@Component({
    selector: 'app-view-office-item-requisition',
    templateUrl: './view-office-item-requisition.component.html',
    styleUrls: ['./view-office-item-requisition.component.css'],
    animations : fuseAnimations
})
export class ViewOfficeItemRequisitionComponent implements OnInit {
    loader: boolean;
    template:boolean = true;

    aprovalNoteArray : any [] = [];
    requistionList:any=[];
    req_for_changeList:any=[];
    rejected_list : any[]=[];
    approvedList:any=[];
    OwnerrequistionList:any =[];

    newpayload:any;
    isselect:boolean=false;

    tableData: MatTableDataSource<any>;
    OwnertableData :  MatTableDataSource<any>;
    requestChangeTableData :  MatTableDataSource<any>;
    approvedTableData :  MatTableDataSource<any>;
    rejected_table_data :  MatTableDataSource<any>;
    
    columnsFieldName: any;
    displayedColumsforOwner:any;
    // displayedColumns:any= [{display: 'Reference No',field: 'ref_no'},{display : 'Subject',field: 'subject'},{display : 'Requested By',field: 'requested_by'},{display : 'Requisition Date',field: 'date'},{display : 'Department',field: 'department'}];
    displayedColumns:any= [{display : 'Subject',field: 'subject'},{display : 'Requested By',field: 'requested_by'},{display : 'Requisition Date',field: 'date'},{display : 'Department',field: 'department'}];


    scrHeight:any;
    scrWidth:any;

    @ViewChild('TableOnePaginator', {static: true} as any) ownerpaginator: MatPaginator;
    @ViewChild('TableOneSort', {static: true} as any) tableOneSort: MatSort;
    @ViewChild('TableTwoPaginator', {static: true} as any) ApproverPaginator: MatPaginator;
    @ViewChild('TableTwoSort', {static: true} as any) tableTwoSort: MatSort;
    @ViewChild('TableThreePaginator', {static: true} as any) reqTablepaginator: MatPaginator;
    @ViewChild('TableThreeSort', {static: true} as any) tableThreeSort: MatSort;
    @ViewChild('TableFourPaginator', {static: true} as any) approvedTablepaginator: MatPaginator;
    @ViewChild('TableFourSort', {static: true} as any) tableFourSort: MatSort;
    @ViewChild('TableFivePaginator', {static: true} as any) rejected_table_paginator : MatPaginator;
    @ViewChild('TableFiveSort', {static: true} as any) tableFiveSort: MatSort;
    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
    //   console.log(this.scrHeight, this.scrWidth);
}
    
    public selection = new SelectionModel<any>(true, []);
    note_creatorPermission: boolean = false;
    tabIndex: number=0;

    constructor(
        public userService: UserService,
        private snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private requisitionService: RequisitionService,
        public dialog: MatDialog,
        public commonService: CommonService,

    ) {this.getScreenSize(); }
// table filter functions
userTableFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
        this.OwnertableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.OwnertableData.filter);
}

ForApprovedTableFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
        this.tableData.filter = filterValue.trim().toLowerCase();
        // console.log('value',this.tableData.filter);
}

reqChangeTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.requestChangeTableData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.requestChangeTableData.filter);

}
approvedTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.approvedTableData.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.approvedTableData.filter);

}
rejectedTableFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.rejected_table_data.filter = filterValue.trim().toLowerCase();
    // console.log('value',this.rejected_table_data.filter);

}

getPageSizes(): number[] {
    if (this.OwnertableData.data.length > 100) {
      return [5, 10, 25, 100, this.OwnertableData.data.length];
    }
    else {
     return [5, 10, 25, 100];
    }
  }
  getPageSizes2(): number[] {
    if (this.tableData.data.length > 100) {
      return [5, 10, 25, 100, this.tableData.data.length];
    }
    else {
     return [5, 10, 25, 100];
    }
  }
  getPageSizes3(): number[] {
    if (this.requestChangeTableData.data.length > 100) {
      return [5, 10, 25, 100, this.requestChangeTableData.data.length];
    }
    else {
     return [5, 10, 25, 100];
    }
  }
  getPageSizes4(): number[] {
    if (this.approvedTableData.data.length > 100) {
      return [5, 10, 25, 100, this.approvedTableData.data.length];
    }
    else {
     return [5, 10, 25, 100];
    }
  }



isAllSelected() {
//    console.log('select')
    const numSelected = this.selection.selected.length
    
    // console.log('d',numSelected)  
   
    if(numSelected>0){
        this.isselect=true;
        
    }
  
    const numRows = this.tableData.data.length;
//    
   
      
    // console.log('n',this.isselect)
    return numSelected === numRows;
  }
  CreateNoteApproval() {
    this.aprovalNoteArray = [];
    this.selection.selected.forEach(item => {
        this.aprovalNoteArray.push(item.id);
    });
    const payload ={
        requisitions : this.aprovalNoteArray,
    }
    this.loader = true;
    this.userService.approvalNoteDetails(payload).subscribe(res=>{
        // console.log('payload',this.aprovalNoteArray);
        // console.log('orginal response',res);
        this.loader = false;
        res.requisitionArray = this.aprovalNoteArray;
        res.role = 'creator';
        // res.requisition_list.forEach(element => {
        //     element.category_items.forEach(element => {
        //        _.groupBy(element.cat_items,'dept_name');
        //     });

        // });
        // new
        res.category_items.forEach(element => {
            element.dept_items.forEach(item => {
                item.cat_items.forEach(citem => {
                    citem.sub_total = 0;
                });
            });

        });
        console.log('response Modified',res);
        const dialogRef = this.dialog.open(ApprovalNoteComponent,{
            data: res,
            panelClass : "custom-modalbox"
        })
        dialogRef.afterClosed().subscribe(res=>{
            if(res == 'reload'){
               this.getOfficeItemApprovalRequisitionList();
             }
        })
    },err=>{
       this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })

  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    console.log('hlw')
    this.isAllSelected() ?
      this.selection.clear() :
      
      this.tableData.data.forEach(row => this.selection.select(row));
     
      
  }

  getOfficeItemOwnerRequistionList(){
    this.loader = true;
    
    
    this.userService.getOfficeItemOwnerRequistionList().subscribe(res=>{
        this.OwnerrequistionList = res;
        this.loader = false;
        this.OwnertableData = new MatTableDataSource<any>(this.OwnerrequistionList);
        this.OwnertableData.paginator = this.ownerpaginator;
        this.OwnertableData.sort = this.tableOneSort;
        // console.log('OwnerList',this.OwnerrequistionList);
        
    },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}
getOfficeItemApprovalRequisitionList(){
    this.loader = true;
    this.userService.getOfficeItemRequisitionList().subscribe(res=>{
        this.requistionList = res;
        // console.log('item for requistionList',this.requistionList);

        this.loader = false;
        this.tableData = new MatTableDataSource<any>(this.requistionList);
        this.tableData.paginator = this.ApproverPaginator;
        this.tableData.sort = this.tableTwoSort;
        // console.log('Mat table Data',this.tableData);
    },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}
getOfficeItemRequestForChangeList(){
    
    this.userService.getOfficeItemRequestForChangeList().subscribe(res=>{
        this.req_for_changeList = res;
        // console.log('item request for cahange list',this.req_for_changeList);

        this.loader = false;
        this.requestChangeTableData = new MatTableDataSource<any>(this.req_for_changeList);
        this.requestChangeTableData.paginator = this.reqTablepaginator;
        this.requestChangeTableData.sort = this.tableThreeSort;
       
        // console.log('Mat table Data',this.tableData);
    },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}

getOfficeItemRequesitionApprovedList(){
    this.userService.getOfficeItemApprovedList().subscribe(res=>{
        this.approvedList = res;
        // console.log('item Approved list',this.approvedList);

        this.loader = false;
        this.approvedTableData = new MatTableDataSource<any>(this.approvedList);
        this.approvedTableData.paginator = this.approvedTablepaginator;
        this.approvedTableData.sort = this.tableFourSort;
       
        // console.log('approvedList Data',this.approvedList);
    },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })
}

getOfficeItemRequesitionRejectList(){
    this.userService.getOfficeItemRejectedList().subscribe(res=>{
        this.rejected_list = res;
        // console.log('item rejected_list',this.rejected_list);

        this.loader = false;
        this.rejected_table_data = new MatTableDataSource<any>(this.rejected_list);
        this.rejected_table_data.paginator = this.rejected_table_paginator;
        this.rejected_table_data.sort = this.tableFiveSort;
       
        // console.log('rejected_list Data',this.rejected_list);
    },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : err.error,'ok');
    })

}


getAllList(){
    this.getOfficeItemOwnerRequistionList();
    this.getOfficeItemApprovalRequisitionList();
    // this.getOfficeItemRequestForChangeList();
    // this.getOfficeItemRequesitionApprovedList();
    // this.getOfficeItemRequesitionRejectList();

 }

edit(data){
    // console.log('edit data',data);

    this.router.navigate(['edit-office-item-requisition/', data.id]);
    
}

createNewItem(){
   
    this.router.navigate(['create-office-item-requisition']);
}
showDetail(data,type?) {
   
    data.userType = type;
    data.note_creatorPermission = this.note_creatorPermission;
   
    console.log('data',data);
    
    const dialogRef = this.dialog.open(OfficeItemDetailsComponent,{
            data: data,
            panelClass : "custom-modalbox",
          
        })
       
            dialogRef.afterClosed().subscribe(res=>{
               
                if(res){
                   
                    this.loader = true;
                    if (data.approval_status == 1 && data.final_approval_status == 0){
                        this.newpayload = {
                            requisition_id : data.id,
                            final_approval_status : res.status,
                           
                        }
                    } else if (data.approval_status == 1 && data.final_approval_status == 1){
                        this.newpayload = {
                            requisition_id : data.id,
                            delivery_status : res.status,
                            
                            checked_items: res.checked_items
                        }
                    } else{
                        this.newpayload = {
                            requisition_id : data.id,
                            status : res.status,
                            
                        }
                    }
                    
                    console.log('payload',this.newpayload);
                    this.userService.itemrequitionApproved(this.newpayload).subscribe(res=>{
                            this.snackBar.open(res.message,'ok');
                            this.loader = false;
                            this.getAllList();
                    },err=>{
                        this.snackBar.open(err.error.message ? err.error.message  : err.error,'ok');
                        this.getAllList();
                        this.loader = false;
                    })
                }
            console.log('dialog was closed',res);
        })

}


    editOfficeItem(id) {
        // console.log('hlw',id);
        this.router.navigate(['edit-office-item/', id]);
    }

    ngOnInit() {
        // console.log('dept',sessionStorage.getItem('department'))
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }

        let note_creator:any = sessionStorage.getItem('note_creator')

        if(note_creator == 1){
            this.note_creatorPermission = true;
        }

        this.columnsFieldName = this.displayedColumns.map(item => item.field);
        // this.columnsFieldName.unshift('select');
        this.columnsFieldName.push('status');
        this.columnsFieldName.push('details');
        this.displayedColumsforOwner = this.displayedColumns.map(item => item.field);
        this.displayedColumsforOwner.push('status');
        this.displayedColumsforOwner.push('details');
        
        this.getAllList();
    }

    openSnackBar(message: string, action: string,time?:number) {
        this.snackBar.open(message, action, {
            duration : time
        });
    }



}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, FormArrayName, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { formatDate, DOCUMENT } from '@angular/common';
import { fuseAnimations } from '../animations';
import { UserService } from '../services/user.service';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import{currency} from '../json_data';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { Designation } from '../model/designation';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { RequisitionService } from '../services/requisition.service';
import { SharedDataService } from '../services/shared-data.service';
import { RefDetailsDialogComponent } from '../ref-details-dialog/ref-details-dialog.component';
import { PaymentNoteDetailsComponent } from '../payment-note-details/payment-note-details.component';
import { ApprovalNoteDetailsComponent } from '../approval-note-details/approval-note-details/approval-note-details.component';

@Component({
  selector: 'app-payment-note',
  templateUrl: './payment-note.component.html',
  styleUrls: ['./payment-note.component.css']
})
export class PaymentNoteComponent implements OnInit {

  loader: boolean;
  notesheetForm: FormGroup;
  // myControl = new FormControl();
  notesheetId: number;
  notesheetEdit: boolean;
  designationList: Designation[];
  employeeList$ = [];
  emplist: any;
  filteredOptions: Observable<any[]>[] = [];
  notesheetData: any;
  files: any;
  url: any;
  fileUrl: string | ArrayBuffer;
  fileArray:any[]= [];
  selectedEmployee: any;
  Expension_Head :[];
  MemId;
  referelKey: string;
  filteredOption: Observable<any>;
  paymentNoteId:any;
  budgetLimit: any;
  paymentNoteCreate: boolean = false;
  memo_id: any;
  accounts_list:any[]=[];
  accounts_person_id: any;
  accounts_spinner: boolean;
  spinner: boolean;
  file_delete_option: boolean;
  editFiles:any[] = [];
  deletedFiles:any[]=[];
  progress: number = 0;
  ref_no_For_backend: string;
  CurrArray :any = currency;
  issame:boolean=false;
  newemp:any;
  isok:boolean= false;
  isadd:boolean= false;
  acc:boolean=false;
  generateFormMemo: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public requisitionService: RequisitionService,
    private sharedService : SharedDataService,
    private preview_dialog : MatDialog,
    private ref_dialog : MatDialog
  ) { 

  }


  SelectedAccounts(data){
    this.issame=false;
    // console.log('newvalue',this.selectedEmployee)

    // console.log('selected accounts_person',data);
   
    if(data!= null){
     
      this.acc=true;
    }
    if(this.selectedEmployee.id === data){
        console.log('found')
        this.issame = true;
    }
    if(this.newemp !== undefined)
    {
        for(let i = 0; i<this.newemp.length; i++)
        {
                if(this.newemp[i].employee_id.id === data)
                {
                // console.log('found')
                this.issame = true;
                  break;
                  
                }
           
            }

        }

        this.notesheetForm.get('accounts_person').setValue(data);

}
get employeeForms() {
    return this.notesheetForm.get('employeeName') as FormArray;
}

addMoreempItem() {
    this.isadd=false;
    this.employeeForms.push(this.addempItem());
    // console.log('empv', this.employeeForms.value)
    this.newemp = this.employeeForms.value;
    this.employListAutoCompleteControl(this.employeeForms.length - 1);
}

addempItem() {
    return this.fb.group({
        employee_id: ['', Validators.required]
    });
}

deleteItem(i) {
    this.issame=false;
    // console.log('deleteIndex', i);
    this.filteredOptions.splice(i, 1);
}
deleteempItem(i) {
    this.isok=false;
    const employeeArraySize = this.employeeForms.length - 1;
    if (employeeArraySize === 0) {
        this.notesheetForm.patchValue({
            employee_id: ''
        });
    }
    this.employeeForms.removeAt(i);
}
createNotesheet(data) {/* department save service */
    this.loader = true;
    this.requisitionService.officeItemNoteSheetCreate(data).subscribe((event: HttpEvent<any>) => {
        // this.loader = false;
        switch (event.type) {
            case HttpEventType.Sent:
              console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
            //   console.log(`Uploaded! ${this.progress}%`);

              break;
            case HttpEventType.Response:
            //   console.log('User successfully created!', event.body);
              this.snackBar.open('File uploaded Successfull','ok');
              setTimeout(() => {
                this.progress = 0;
                this.openSnackBar('successfully created! ', 'ok');
                this.loader = false;
                this.router.navigate(['payment-note-list']);
                this.dialogRef.close('reload');
              });

          }


    }, err => {
        
        this.snackBar.open(' ' + err.error.message ? err.error.message : JSON.stringify(err.error), 'ok',{duration:6000});
    });
 

}
editNotesheet(editeddata) {/* department save service */
    this.loader = true;
    console.log('edited data',editeddata)
     this.userService.editPaymetNote(editeddata).subscribe((event: HttpEvent<any>) => {

        switch (event.type) {
            case HttpEventType.Sent:
            //   console.log('Request has been made!');
              break;
            case HttpEventType.ResponseHeader:
            //   console.log('Response header has been received!');
              break;
            case HttpEventType.UploadProgress:
              this.progress = Math.round(event.loaded / event.total * 100);
            //   console.log(`Uploaded! ${this.progress}%`);
              break;
            case HttpEventType.Response:
                // console.log('User successfully created!', event.body);
                this.snackBar.open('File uploaded Successfull','ok');
                setTimeout(() => {
                  this.progress = 0;
                  this.openSnackBar('successfully created! ', 'ok');
                  this.loader = false;
                  this.router.navigate(['payment-note-list']);
                  this.dialogRef.close('reload');
                });

          }
    }, err => {
        this.loader = false;
        if (err.error.message) { this.openSnackBar(' ' + err.error.message, 'ok'); } else { this.openSnackBar(' ' + err.error, 'ok'); }
    });
}

getEmployee(value) {
    this.isadd = true;
    this.selectedEmployee = value;
    // console.log('val',value)
    if(this.newemp !== undefined)
    {
        for(let i = 0; i<this.newemp.length; i++)
        {
                if(this.newemp[i].employee_id.id === value.id)
                {
                  this.isok=true;
                  break;
                }
                else{
                    this.isok=false;
                    continue;
                }
           
            }

        }
}
getErrorMessage(){
    let inputBudget = this.notesheetForm.get('amount').value;
    if(this.budgetLimit && inputBudget>this.budgetLimit){
        this.snackBar.open('The amount will not be exceed the budgeted amount,The budget amount is ' + this.budgetLimit,'ok');
        this.notesheetForm.get('amount').setValue(this.budgetLimit);
    }else{
        return false;
    }
}
notesheetFormSubmit(status:any) {
    if(this.notesheetForm.invalid){
        this.snackBar.open('Please fill up all required field,then try again','ok');
    }
    else{
    // console.log('form Value',this.notesheetForm.value,'myControl',this.myControl.value.id);
    // console.log('form Value',this.notesheetForm.value);

    if (this.notesheetEdit) {
        // console.log("EDIT",this.notesheetForm.value);
        const fd = new FormData();
        if(this.generateFormMemo){
            fd.append('memo_id', this.memo_id);
        }

        fd.append('ref_no', this.referelKey);
        fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
        fd.append('amount', this.notesheetForm.value.amount);
        fd.append('cur_type', this.notesheetForm.value.cur_type);
        fd.append('subject', this.notesheetForm.value.subject);
        fd.append('tin', this.notesheetForm.value.tin);
        fd.append('bin_nid', this.notesheetForm.value.bin_nid);
        fd.append('pay_to', this.notesheetForm.value.pay_to);
        fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
        fd.append('routing_no', this.notesheetForm.value.routing_no)
        fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
        fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
        fd.append('payee_address', this.notesheetForm.value.payee_address);
        fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
        fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
        fd.append('purpose', this.notesheetForm.value.purpose);
        fd.append('requisition_id', this.paymentNoteId);
        fd.append('status',status);
        fd.append('deletedFile',JSON.stringify(this.deletedFiles));
        if (this.fileArray) {
            if (this.fileArray.length > 0) {
                for (let x = 0; x < this.fileArray.length; x++) {
                    fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                }
            }
        }
       
        console.log('PaymentFormData',fd);
        this.editNotesheet(fd)
    } else {

        const fd = new FormData();
        if(this.generateFormMemo){
            fd.append('memo_id', this.data.id);
            fd.append('genFormMemo', '1');
            fd.append('ref_no', this.referelKey);
        }else{
            fd.append('memo_id', this.data.id);
            fd.append('genFormMemo', '0');
            fd.append('ref_no', this.referelKey);
        }
        const fc = this.fb.group({
            employee_id: [{id : this.notesheetForm.value.accounts_person, check_issue : 1}],
          })
        this.employeeForms.push(fc);
        console.log('employeforms_value',this.employeeForms.value);
        fd.append('employeeName', JSON.stringify(this.employeeForms.value));
        fd.append('apply_date', formatDate(new Date(this.notesheetForm.value.apply_date), 'yyyy-M-dd', 'en'));
        // fd.append('pay_to', this.myControl.value.f_name ? this.myControl.value.f_name : this.myControl.value);
        fd.append('pay_to',this.notesheetForm.value.pay_to)
        fd.append('amount', this.notesheetForm.value.amount);
        fd.append('cur_type', this.notesheetForm.value.cur_type);
        fd.append('subject', this.notesheetForm.value.subject);
        fd.append('tin', this.notesheetForm.value.tin);
        fd.append('bin_nid', this.notesheetForm.value.bin_nid);
        fd.append('Payee_account_no', this.notesheetForm.value.Payee_account_no);
        fd.append('routing_no',this.notesheetForm.value.routing_no)
        fd.append('payee_bankname', this.notesheetForm.value.payee_bankname);
        fd.append('payee_branchname', this.notesheetForm.value.payee_branchname);
        fd.append('payee_address', this.notesheetForm.value.payee_address);
        fd.append('vendor_bill_no', this.notesheetForm.value.vendor_bill_no);
        fd.append('vendor_bill_date', formatDate(new Date(this.notesheetForm.value.vendor_bill_date), 'yyyy-M-dd', 'en'));
        fd.append('purpose', this.notesheetForm.value.purpose);
        fd.append('paynote_id', this.MemId);
        fd.append('status',status);
        if (this.fileArray) {
            if (this.fileArray.length > 0) {
                for (let x = 0; x < this.fileArray.length; x++) {
                    fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                }
            }
        }
        console.log('PaymentFormData',fd);
        this.createNotesheet(fd);
    }
}
}



deleteFile(index){
    // console.log('index',index);
    this.fileArray.splice(index,1);

}


onFileInput(event: any) {
    this.files = event.target.files;
    if (event.target.files && event.target.files[0]) {

        for (let item = 0; item < this.files.length; item++) {

            const reader = new FileReader();
            reader.readAsDataURL(this.files[item]);
            reader.onload = (_event: any) => {
                this.fileUrl = _event.target.result;
                if(this.files[item].size > 358400){
                    this.snackBar.open('File size limit exceed,your file size is  ' + (this.files[item].size/1024).toFixed(2)+' KB, Upload file size will not be exceed 350 KB','ok',{duration:10000});
                }else{
                    this.fileArray.push({
                        files : this.files[item],
                        fileName: this.files[item].name,
                        fileUrl: this.fileUrl
                    });
                }
            };
        }
        // console.log('filesArray',this.fileArray);
        // console.log(this.fileArray);
    }
    // console.log('files',this.files);

}

employListAutoCompleteControl(index: number) {
    const arrayControl = this.notesheetForm.get('employeeName') as FormArray;
    this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
        .pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value.f_name),
            map(name => name ? this._filter(name) : this.employeeList$.slice())
        );

}

private _filter(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.employeeList$.filter(option =>{
        return option.f_name.toLowerCase().indexOf(filterValue) !== -1 ||  option.pf_no == value;
    })

}
displayFn(data?: any): string | undefined {
    return data ? data.f_name : undefined;
}


// Note sheet Edit Methods
getSpecificPaymentNote(n) {
    // console.log('edit',n)
//  console.log('oooooooooooooooooooooooooooooooooooooooooooo')
this.loader = true;
this.userService.getPaymentNoteDetails(n).subscribe(data => {
    this.loader = false;
         console.log('Paymentnote Edit data',data);
         this.editFiles = data.file_info;
         this.notesheetForm.patchValue({
            apply_date : data.requisition_info[0].apply_date,
            amount: data.requisition_info[0].amount,
            cur_type : data.requisition_info[0].cur_type,
            subject: data.requisition_info[0].subject,
            Payee_account_no: data.requisition_info[0].Payee_account_no,
            routing_no:data.requisition_info[0].routing_no,
            payee_bankname: data.requisition_info[0].payee_bankname,
            payee_branchname:data.requisition_info[0].payee_branchname,
            pay_to:data.requisition_info[0].pay_to,
            payee_address: data.requisition_info[0].payee_address,
            vendor_bill_no: data.requisition_info[0].vendor_bill_no,
            vendor_bill_date: data.requisition_info[0].vendor_bill_date,
            purpose: data.requisition_info[0].purpose,
            tin : data.requisition_info[0].tin,
            bin_nid : data.requisition_info[0].bin_nid,
    });

   
    this.referelKey = data.requisition_info[0].ref_no;
    this.notesheetForm.get('vendor_bill_date').setValue(new Date(data.requisition_info[0].vendor_bill_date));
  }, error => {
    this.loader = false;
    this.openSnackBar('Internal error' + error.error.message, 'ok');
    // console.log(error);
  });
}
deleteEditFile(data:any,index){
  this.deletedFiles.push(data);
  this.editFiles.splice(index,1);
}
download(f){
    const link = document.createElement('a');
    const downloadUrl = f;
    link.setAttribute('href',  downloadUrl);
    link.setAttribute('download', 'FileName');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
}

previewFile(f) {
const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
        data : {url : f}
    })
    dialog_ref.afterClosed().subscribe(res=>{
        // console.log('preview_closed',res);
    })
}
generateKey(lengthOfCode: number, possible: string) {
let text = "";
for (let i = 0; i < lengthOfCode; i++) {
  text += possible.charAt(Math.floor(Math.random() * possible.length));
}
  return text;
}

headsinfo:any;
thousand_separator(num){
var num_parts = num.toString().split(".");
num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
return num_parts.join(".");
}
getMemoData() {
  this.notesheetForm.get('amount').setValue(this.data.total_amount)
 const subscribe =  this.sharedService.DeliveryData.subscribe((routeDycriptData)=>{
        // console.log('data',routeDycriptData);
        if(routeDycriptData.id){
            this.generateFormMemo = true;
            this.referelKey = routeDycriptData.ref_no;
            // this.myControl.setValue({id : routeDycriptData.requested_by, f_name : routeDycriptData.created_by});
            this.budgetLimit = routeDycriptData.budget_amount;
            this.notesheetForm.get('subject').setValue(routeDycriptData.subject);
            this.notesheetForm.get('tin').setValue(routeDycriptData.tin);
            this.notesheetForm.get('bin_nid').setValue(routeDycriptData.bin_nid);
            this.notesheetForm.get('amount').setValue(routeDycriptData.amount);
            this.memo_id = routeDycriptData.id;
            this.headsinfo = {
                head_title : routeDycriptData.head_title,
                budget : routeDycriptData.budget,
                expense  : routeDycriptData.expense,
                amount : routeDycriptData.amount,
                remaining_balance : routeDycriptData.remaining_balance,
                remaining_balance_percentage : routeDycriptData.remaining_balance_percentage
            }

        }


});
this.sharedService.supplyData({});
subscribe.unsubscribe();
}
getAccounts_persons(){
this.accounts_spinner = true;
this.userService.getAccountsPerson().subscribe(res=>{
    this.accounts_list = res;
    this.accounts_spinner = false;

},err=>{
    this.accounts_spinner = false;
    this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error));
})
}


getReferance(){

    this.referelKey= this.data.ref_no;
   
}

close(){
   this.dialogRef.close();
  }

view(ref){

if(this.data.ref_no== ref){
//   console.log('ref data',this.data.id);
  this.userService.getApprovalNoteDetails(this.data.id).subscribe(details_data=>{
    //   console.log('newdata',details_data)

      const dialogRef = this.ref_dialog.open(ApprovalNoteDetailsComponent,{
        data: {
            detail: details_data,
            approvecategory: 'User',
            
        }
      })
    })
  }
  
  
}
getDetailsItemList(){
this.userService.getApprovalNoteDetails(this.data.id).subscribe(item_details=>{
// console.log('item_details',item_details.category_items)
})
}


// get f(){
//     return this.notesheetForm.controls;
// }
ngOnInit() {
    // console.log('editdata', data)
    this.getAccounts_persons();
        if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
            this.paymentNoteId = +this.route.snapshot.paramMap.get('id');
            this.notesheetEdit = true;
            // console.log('edit',this.notesheetEdit)
            this.notesheetForm = this.fb.group({
                apply_date : [''],
                subject : ['',Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                amount: ['', Validators.required],
                cur_type : ['BDT', Validators.required],
                Payee_account_no: ['', Validators.required],
                routing_no: [''],
                payee_bankname: [''],
                payee_branchname:[''],
                payee_address: [''],
                vendor_bill_no: ['' ],
                vendor_bill_date: [''],
                purpose: [''],
                files: [null]
            });
           this.getSpecificPaymentNote(this.paymentNoteId);
        } else {
            this.getReferance();
            this.notesheetForm = this.fb.group({
                apply_date : [''],
                subject : ['',Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                amount: ['', Validators.required],
                cur_type : ['BDT', Validators.required],
                Payee_account_no: ['', Validators.required],
                routing_no: [''],
                payee_bankname: [''],
                payee_branchname:[''],
                payee_address: [''],
                vendor_bill_no: ['' ],
                vendor_bill_date: [''],
                purpose: [''],
                accounts_person : ['',Validators.required],
                employeeName: this.fb.array([this.addempItem()]),
                files: [null]
            });
            this.employListAutoCompleteControl(0);

        }

        this.spinner = true;
        this.userService.getUserList().subscribe((res) => {
            this.employeeList$ = res;
            
            this.spinner = false;


        });
       
        this.getMemoData();

}
forbiddenNamesValidator(names:any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // below findIndex will check if control.value is equal to one of our options or not
      const index = names.findIndex(name => {
        return (new RegExp('\^' + name.f_name + '\$')).test(control.value.f_name);
      });
      return index < 0 ? { 'forbiddenNames': { value: control.value } } : null;
    };
  }





openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        horizontalPosition : "end",
    });
}

}

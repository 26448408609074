import { Component, OnInit, Inject } from '@angular/core';
import {MatSnackBar,MAT_DIALOG_DATA,MatDialogRef, MatDialog } from '@angular/material';
import { CommonService } from '../services/common.service';
import { UserService } from '../services/user.service';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import { RefDetailsDialogComponent } from '../ref-details-dialog/ref-details-dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
import { formatDate } from '@angular/common';
import { ApprovalNoteDetailsComponent } from '../approval-note-details/approval-note-details/approval-note-details.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-note-details',
  templateUrl: './payment-note-details.component.html',
  styleUrls: ['./payment-note-details.component.css'],
  // animations : fuseAnimations
})
export class PaymentNoteDetailsComponent implements OnInit {

  loader : boolean;
  reqDetail: any;
  remarks = '';
  note_approver: any;
  reqData: any;
  files: any[];
  fileUrl: string;
  mouseoverevent: boolean;
  printData: any;
  ExpensionHead:[];

  sig: any;
  requisitionInfo: any;
  remarksInfo: any;
  file_info: any;
  payment_way = new FormControl('',Validators.required);
  bank_name = new FormControl('',Validators.required);
  bank_branch_name = new FormControl('',Validators.required);
  ref_no = new FormControl('',Validators.required);
  cashing_date = new FormControl('',Validators.required);
  method =['Cash','Cheque','EFTN/BFTN','PO','DD/TT','RTGS']
  user_id: any;
  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,
                 public dialogref : MatDialogRef<PaymentNoteDetailsComponent>,
                 public commonService: CommonService,
                 public userService: UserService,
                 public snackBar: MatSnackBar,
                 private router: Router,
                 public preview_dialog : MatDialog,
                 private ref_dialog : MatDialog
              ) {
                // console.log('dialog data',data);
                if(this.data.main_row.payment_way)
                 this.payment_way.setValue(this.data.main_row.payment_way)
                if(this.data.main_row.bank_name)
                this.bank_name.setValue(this.data.main_row.bank_name)
                if(this.data.main_row.bank_branch_name)
                this.bank_branch_name.setValue(this.data.main_row.bank_branch_name)
                if(this.data.main_row.bank_ref_no)
                this.ref_no.setValue(this.data.main_row.bank_ref_no)
                if(this.data.main_row.cashing_date)
                this.cashing_date.setValue(this.data.main_row.cashing_date)
              }

            //   view_ref_details(ref){
            //     console.log('ref_work',ref);
            //     const dialog_ref = this.ref_dialog.open(RefDetailsDialogComponent,{
            //         data : {ref_no : ref}
            //     })
            //     dialog_ref.afterClosed().subscribe(res=>{
            //         console.log('diloag_close',res);
        
            //     })
            // }
            // view(ref){
            //   console.log(ref)
            //   if(this.data.ref_no== ref){
            //     console.log('ref data',this.data.id);
            //     this.userService.getApprovalNoteDetails(this.data.id).subscribe(details_data=>{
            //         console.log('newdata',details_data)
              
            //         const dialogRef = this.ref_dialog.open(ApprovalNoteDetailsComponent,{
            //           data: {
            //               detail: details_data,
            //               approvecategory: 'User',
                          
            //           }
            //         })
            //       })
            //     }
                
                
            //   }

  
            ngOnInit() {
              this.user_id = sessionStorage.getItem('user_id');
              // console.log('Note Detail', this.data);
              this.note_approver = this.data.detail.requisition_details;
              this.requisitionInfo = this.data.detail.requisition_info;
              this.remarksInfo = this.data.detail.remarks;
              this.file_info = this.data.detail.file_info;
              this.printData = this.data.main_row;
              // this.fileUrl = 'https://gdams.green-delta.com/services/public/uploads/users/';
              // this.sig = 'https://gdams.green-delta.com/services/public/uploads/user_signatures/';
              this.reqData = this.data.main;
              this.mouseoverevent = false;
      
          }
          thousand_separator(num){
              var num_parts = num.toString().split(".");
              num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              return num_parts.join(".");
          }
      
          get Validation(){
            if(this.note_approver[this.note_approver.length - 1].approved_by == this.user_id){
              if(this.payment_way.invalid || this.ref_no.invalid || this.cashing_date.invalid){
                return true;
              }else{
                return false;
              }
            }else{
              return false;
            }
          }
          SetValidation(){
            this.payment_way.valueChanges.subscribe(res=>{
              // console.log('changes',res);
      
              if(res === 'Cash'){
                // this.cashing_date.setValidators(Validators.required);
                // this.cashing_date.updateValueAndValidity();
                this.bank_name.disable();
                this.bank_branch_name.disable();
                this.ref_no.disable();
              }else{
                this.bank_name.enable();
                this.bank_branch_name.enable();
                this.ref_no.enable();
                // this.cashing_date.clearValidators();
              }
            })
          }
          approve(status) {
              this.loader = true;
              let memoSheetApproveData = {
                  requisition_id : this.printData.id,
                  requested_by : this.printData.requested_by,
                  remarks : this.remarks,
                  payment_way : this.payment_way.value ? this.payment_way.value : '',
                  bank_name : this.bank_name.value ?  this.bank_name.value : '',
                  bank_branch_name : this.bank_branch_name.value ?  this.bank_branch_name.value : '',
                  cashing_date : this.cashing_date.value ?  formatDate(this.cashing_date.value,'yyyy-MM-dd','en')  : '',
                  bank_ref_no : this.ref_no.value ? this.ref_no.value : '',
                  status : status,
      
              };
            // console.log('data_check',memoSheetApproveData);
            if (this.data.sheetType === 'Note') {
                  this.userService.requisitionPaymentNoteApprove(memoSheetApproveData).subscribe((response) => {
                    this.openSnackBar(response.message,'ok');
                    this.dialogref.close('reload');
                    this.router.navigate(['payment-note-list']);
                    
                    // this.commonService.refreshEvent.next();
                    this.loader = false;
                  },err=>{
                      this.openSnackBar(err.error.message ? err.error.message : err.error,'ok');
                      this.loader = false;
                  });
      
              } 
      
          }

          reject(status:number) {
 
            const payload = {
                requisition_id : this.printData.id,
                requisition_type : 'item_payment_memo',
                remarks : this.remarks ? this.remarks : ' ',
                status : status,
            };
            this.userService.requisitionReject(payload).subscribe((response) => {
                this.snackBar.open(response['message'], 'ok',{duration: 1000});
              
                this.dialogref.close('reload');
                
            },err=>{
                this.snackBar.open(err.error.message ? err.error.message :  JSON.stringify(err.error),'ok',{duration:3000});
                
            });
          
          }
    
      
      
          deleteEditFile(data:any,index){
              this.snackBar.open("Sorry! You couldn't delete this file, read only!",'ok',{duration:3000});
            }
            download(f){
                const link = document.createElement('a');
                const downloadUrl = f;
                link.setAttribute('href',  downloadUrl);
                link.setAttribute('download', 'FileName');
                link.setAttribute('target', '_blank');
                document.body.appendChild(link); // Required for FF
                link.click();
            }
      
            previewFile(f) {
            const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
                    data : {url : f}
                })
                dialog_ref.afterClosed().subscribe(res=>{
                    // console.log('preview_closed',res);
                })
            }
           
          openSnackBar(message: string, action: string) {
              this.snackBar.open(message, action, {
                  duration: 4000,
              });
          }
      
          close(){
              this.dialogref.close();
          }

}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Vehicle } from '../model/vehicle';
import { VehicleType } from '../model/vehicleType';
import { User } from '../model/user';
import { dummy } from '../commonUrl';

@Component({
  selector: 'app-pool-car-requisition-details',
  templateUrl: './pool-car-requisition-details.component.html',
  styleUrls: ['./pool-car-requisition-details.component.css']
})
export class PoolCarRequisitionDetailsComponent implements OnInit {
  loader: boolean;
  poolCarId: number;
  poolCarVheicleRequestForm: any;
  poolCarVheicleAlotmentForm:any;
  vehicleList: Vehicle[];
  vehicleTypeList:VehicleType[];
  employee: User[];
  Dummyurl=dummy;


  constructor(
    private snackBar:MatSnackBar,
    public requisitionService:RequisitionService,
    public userService:UserService,
    public route: ActivatedRoute,
    private fb : FormBuilder,
  ) { }

  createPoolCarRequisitionApproval(data){
    this.loader = true;
    this.requisitionService.poolCarRequisitionVheicleAllotCreate(data)
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.openSnackBar("Successfully alloted", "ok");
        //this.router.navigate(['view-pool-car-requisition']);
      }, err => {
        this.loader = false;
        if(err.status == 400) location.reload(true);
        if(err.error.message)this.openSnackBar("Internal error :"+err.error.message, "ok")
        else this.openSnackBar("Error :"+err.error, "ok")
    }); 

  }

  ngOnInit() {
    this.getAllVehicle();
    this.getAllVehicleType();
    this.getEmployeeList();

    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.poolCarId = +this.route.snapshot.paramMap.get('id');
      console.log(this.poolCarId);
      this.getSpecificPoolCarRequisition(this.poolCarId);
      this.poolCarVheicleRequestForm = this.fb.group({
        purpose: ['', Validators.required],
        date_of_use: ['', Validators.required],
        from_place: ['', Validators.required],
        to_place: ['', Validators.required],
        from_time: ['', Validators.required],
        to_time: ['', Validators.required],
        recommended_by: ['',Validators.required],
      });
      this.poolCarVheicleAlotmentForm = this.fb.group({
        type: ['', Validators.required],
        registration_no: ['', Validators.required],
        driver: ['', Validators.required],
        approved_by: ['', Validators.required],
    //    requisition_id: ['', Validators.required]
      });
    }

  }

  getSpecificPoolCarRequisition(poolCarId){
    this.loader = true;
    this.requisitionService.getSpecificPoolCarVheicleAllotment(poolCarId).subscribe( data =>{
        console.log(data)
        //this.requisitions = res.length;
        this.loader = false;
        this.poolCarVheicleRequestForm.patchValue({
          purpose:data[0].purpose,
          date_of_use: data[0].use_date,
          from_place: data[0].place_from,
          to_place: data[0].place_to,
          from_time: data[0].time_from,
          to_time: data[0].time_to,
          recommended_by: data[0].approved_by,

        }); 
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getAllVehicle(){
    this.loader = true;
    this.userService.getVehicleList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.vehicleList = res;
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getAllVehicleType(){
    this.loader = true;
    this.userService.getVehicleTypeList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.vehicleTypeList = res;
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getEmployeeList(){
    this.loader = true;
    this.userService.getDriverlist()
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.employee = data;
      }, err => {
        this.loader = false;
        if(err.status == 400) location.reload(true);
        if(err.error.message)this.openSnackBar("Internal error :"+err.error.message, "ok")
        else this.openSnackBar("Error :"+err.error, "ok")
      });
  }


   
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

  poolCarVheicleAlotmentFormSubmit(){
    console.log(this.poolCarVheicleAlotmentForm.value);
    let fd = new FormData();
    fd.append('requisition_id',this.poolCarId.toString());
    fd.append('vehicle_type',this.poolCarVheicleAlotmentForm.value.type);
    fd.append('driver',this.poolCarVheicleAlotmentForm.value.driver);
    fd.append('registration_no',this.poolCarVheicleAlotmentForm.value.registration_no);
    fd.append('approved_by',this.poolCarVheicleAlotmentForm.value.approved_by);

    this.createPoolCarRequisitionApproval(fd);
  }


}

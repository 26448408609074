import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { OfficeItem } from '../model/officeItem';
import { MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';
import { Router} from '@angular/router';

@Component({
  selector: 'app-view-office-item',
  templateUrl: './view-office-item.component.html',
  styleUrls: ['./view-office-item.component.css']
})
export class ViewOfficeItemComponent implements OnInit {
 
  loader: boolean;
  p: number = 1;
  officeItemList: MatTableDataSource<OfficeItem>;
  displayedOfficeItemColumns: string[] = ['Position','Title','Category','Edit'];
  @ViewChild(MatPaginator,{static:true}as any) paginator : MatPaginator;
  data: OfficeItem[]=[];
  constructor(
    public userService : UserService,
    private snackBar :MatSnackBar,
    private router: Router,

  ) { }

  editOfficeItem(id){
    console.log(id)
    this.router.navigate(['edit-office-item/', id]);
  }

  ngOnInit() {
   
    this.getAllOfficeItem();
  }
  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.officeItemList.filter = filterValue.trim().toLowerCase();
  }
  getAllOfficeItem(){
    this.loader = true;
    this.userService.getOfficeItem().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data = res;
        this.data.sort((a,b)=>b.id - a.id);
        this.data.map((v,i)=>v.sn = i+1);
        this.officeItemList = new MatTableDataSource<OfficeItem>(this.data);
        this.officeItemList.paginator = this.paginator;
      },err=>{
        this.loader = false;
        if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
        else this.openSnackBar(" "+err.error, "ok");
    });
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }


}

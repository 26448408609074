import { Component, OnInit } from '@angular/core';
import { dummy, dummysignatureUrl, dummyphotoUrl } from '../commonUrl';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Department } from '../model/department';
import { Designation } from '../model/designation';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  hide = true;
  dummyUrl = dummy;
  actualSignature = dummysignatureUrl;
  actualImage = dummyphotoUrl;
  userForm: FormGroup;
  selectedSignatutre: File;
  selectedImage: File;
  message: string;
  imagePath: any;
  imgURL: any;
  signatureURL: any;
  signaturePath: any;
  loader: boolean;
  departmentList:Department[];
  designationList: Designation[];
  user_id: any;
  constructor(
    private fb: FormBuilder,
    public userService:UserService,
    private snackBar:MatSnackBar,
    public router :Router,
  ) { }
  getSpecificUser(userId) {
    this.loader = true;
    this.userService.getParticualrUser(userId)
      .subscribe(data => {
        console.log("Test",data)
        this.loader = false;
        console.log('call getUser:  ' + userId);
        this.userForm.patchValue({
          user_id: userId,
          f_name: data[0].f_name,
   //       l_name: data[0].l_name,
          email: data[0].email,
          phone : data[0].phone,
          designation_id: data[0].designation,
          type_id:data[0].type_id ,
          dept_id:data[0].dept_name ,
          dob:data[0].dob ,
        });
        this.signatureURL = this.actualSignature+data[0].signature;
        this.imgURL = this.actualImage+data[0].user_image;
      }, e => {
        this.loader = false;
        this.snackBar.open(e.error.message ? e.error.message : JSON.stringify(e.error), "ok",{
          duration : 5000
        })
        console.log(e);
      });
  }



  handleSignatutreInput(event) {
    this.selectedSignatutre = <File>event.target.files[0];
    console.log(this.selectedSignatutre)
  }

  handleImageInput(event) {
    this.selectedImage = <File>event.target.files[0];
    console.log(this.selectedImage);
    
  }
  previewImage(files) {
    if (files.length === 0) {
      return;
    }
    // tslint:disable-next-line:prefer-const
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
  }
  previewSignature(files) {
    if (files.length === 0) {
      return;
    }
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }
    const reader = new FileReader();
    this.signaturePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.signatureURL = reader.result;
    };
  }
  onUserSubmit(){
  
      let fd = new FormData();
      fd.append('user_id',this.user_id);
      if (this.selectedImage)fd.append('user_image', this.selectedImage, this.selectedImage.name);
      if (this.selectedSignatutre)fd.append('signature', this.selectedSignatutre, this.selectedSignatutre.name);
      this.loader = true;
      console.log('payload',fd);
    this.userService.profileUpdate(fd).subscribe(data => {
      this.loader = false;
      this.snackBar.open(data.message, "ok",{
        duration : 4000
      });
      this.router.navigate(['view-user']);
    }, e => {
      this.loader = false;
      this.snackBar.open(e.error.message ? e.error.message : JSON.stringify(e.error), "ok",{
        duration : 5000
      })
      console.log(e);
    });
  }
  ngOnInit() {
    this.user_id = sessionStorage.getItem('user_id');
    this.userForm = this.fb.group({
      user_id: [''],
      f_name: ['', Validators.required],
//        l_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      dob: ['', Validators.required],
      type_id: ['', Validators.required],
      dept_id: ['', Validators.required],
      designation_id: ['', Validators.required],
      user_image: [''],
      signature: [''],
    });
    this.getSpecificUser(this.user_id);
  }

}

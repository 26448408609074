import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { MatSnackBar, MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { fuseAnimations } from '../animations';


@Component({
  selector: 'app-view-accounts-head',
  templateUrl: './view-accounts-head.component.html',
  styleUrls: ['./view-accounts-head.component.css'],
  animations : fuseAnimations
})
export class ViewAccountsHeadComponent implements OnInit {
  budgetForm: FormGroup;
  loader: boolean = false;
  accountsHeadId: number;
  budgetEdit: boolean;
  tabIndex:number=0;
  displayedColumns: string[] = ['Position','headsName','budget_amount','consumed_till_now','budget_year','Edit'];
  HeadList:any[] = [];
  budgetInfoList:any[] = [];
  BudgetTabledata : MatTableDataSource<any>;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;
  budgetId: any;
  constructor( 
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public userService: UserService,
    public router: Router,
    public route: ActivatedRoute,
    private confirm_dialog :MatDialog
  ) { }
  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.BudgetTabledata.filter = filterValue.trim().toLowerCase();
  }
  submitBudget(){
    this.loader = true;
    console.log('formData',this.budgetForm.value);
    this.userService.budgetCreate(this.budgetForm.value).subscribe(res => {
      this.loader = false;
      console.log('create res',res);
      this.snackBar.open(res.message,'ok',{duration:2000});
     this.tabIndex = 1;
     this.getAllBudgetList();
     this.budgetForm.reset();
    },err=>{
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }

deleteHead(data){
  const dialog_ref = this.confirm_dialog.open(ConfirmDialogComponent);
  dialog_ref.afterClosed().subscribe(res=>{
    console.log('dialog_ref',res);
    if(res === 1){
      this.loader = true;
      this.userService.deleteHeads(data.id).subscribe(res=>{
            this.snackBar.open(res.message,'ok',{duration:3000});
            this.loader = false;
            
            this.getAllBudgetList();
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
      })
    }
  })

}


updateBudget(){
    this.loader = true;
    const payload = this.budgetForm.value.budgetinfo[0];
    payload.budget_id = this.budgetId;
    console.log('update payload',payload);
    this.userService.updateBudget(payload).subscribe(res => {
      this.loader = false;
      this.snackBar.open(res.message,'ok',{duration:2000});
      console.log('edit res',res);
      
      this.tabIndex = 1;
      this.getAllBudgetList();
    },err=>{
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }

  getAllHeads(){
    this.loader = true;
    this.userService.getaccountsHeadList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.HeadList = res;
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:6000});
    });
  }
  getAllBudgetList(){
    this.loader = true;
    this.userService.getBudgetInfo().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.budgetInfoList = res;
        this.budgetInfoList.sort((a,b)=>b.id - a.id);
        this.budgetInfoList.map((v,i)=>v.sn = i+1);
        this.BudgetTabledata = new MatTableDataSource<any>(this.budgetInfoList);
        this.BudgetTabledata.paginator = this.paginator;
        //this.openSnackBar("Success ", "ok");
      },err=>{
        this.loader = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{duration:3000});
    });
  }
  editBudget(data:any){
    this.budgetEdit = true;
    this.tabIndex = 0;
    this.budgetinfoControl.patchValue([{
      head_id: data.head_id,
      budget_amount: data.budget,
      consumed_till_now : data.expense,
      budget_year: data.year,
    }])
    this.budgetId = data.id
  }
  reset(){
    this.budgetForm.reset();
    this.budgetEdit = false;
  }
  budgetItems() : FormGroup {
    return this.fb.group({
      head_id: [''],
      budget_amount: [''],
      consumed_till_now : [''],
      budget_year: [2020],
    });
  }
  headChange(_event){
    console.log('change',_event);
  }
  get budgetinfoControl () {
    return this.budgetForm.get('budgetinfo') as FormArray;
  }
  addbudgetItems() {
    (this.budgetForm.get('budgetinfo') as FormArray).push(this.budgetItems())
    console.log('controls',(this.budgetForm.get('budgetinfo') as FormArray).controls);
  }
  deletebudgetItems(index) {
    (this.budgetForm.get("budgetinfo") as FormArray).removeAt(index);
  }
  ngOnInit() {
    this.getAllHeads();
    this.getAllBudgetList();
    this.budgetForm = this.fb.group({
      budgetinfo : this.fb.array([this.budgetItems()])
    });
    
  }

}

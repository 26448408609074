// for client server
// export const dummyphotoUrl = 'https://gdams-test.green-delta.com/services/public/uploads/users/';
// export const dummysignatureUrl = 'https://gdams-test.green-delta.com/services/public/uploads/user_signatures/';
// export const dummy = 'https://gdams-test.green-delta.com/assets/images/';
// export const api = 'https://gdams-test.green-delta.com/services/public/api/';
// export const userImageUrl = 'https://gdams-test.green-delta.com/services/public/uploads/users/';
// export const sigantureUrl = 'https://gdams-test.green-delta.com/services/public/uploads/user_signatures/';

//Live
export const dummyphotoUrl = 'https://gdams.green-delta.com/services/public/uploads/users/';
export const dummysignatureUrl = 'https://gdams.green-delta.com/services/public/uploads/user_signatures/';
export const dummy = 'https://gdams.green-delta.com/assets/images/';
export const api = 'https://gdams.green-delta.com/services/public/api/';
export const userImageUrl = 'https://gdams.green-delta.com/services/public/uploads/users/';
export const sigantureUrl = 'https://gdams.green-delta.com/services/public/uploads/user_signatures/';

// export const dummyphotoUrl = 'http://192.168.44.44/services/public/uploads/users/';
// export const dummysignatureUrl = 'http://192.168.44.44/services/public/uploads/user_signatures/';
// export const dummy = 'http://localhost/assets/images/';
// export const api = 'http://192.168.44.44/services/public/api/';
// export const userImageUrl = 'http://192.168.44.44/services/public/uploads/users/';
// export const sigantureUrl = 'http://192.168.44.44/services/public/uploads/user_signatures/';

// for intoiit Server
// export const dummyphotoUrl = 'https://www.intoiit.com/gd_api/public/uploads/users/';
// export const dummysignatureUrl = 'https://www.intoiit.com/gd_api/public/uploads/user_signatures/';
// export const dummy = 'https://www.intoiit.com/gd/assets/images/';
// export const api = 'https://www.intoiit.com/gd_api/public/api/';
// export const userImageUrl = 'https://www.intoiit.com/gd_api/public/uploads/users/';
// export const sigantureUrl = 'https://www.intoiit.com/gd_api/public/uploads/user_signatures/';
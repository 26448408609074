import { DetailsMeetingRoomRequisitionComponent } from './../details-meeting-room-requisition/details-meeting-room-requisition.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { MeetingRoomRequisition } from '../model/meetingRoomRequisition';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { DialogComponent } from '../dialog/dialog.component';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';

@Component({
  selector: 'app-view-meeting-room-requisition',
  templateUrl: './view-meeting-room-requisition.component.html',
  styleUrls: ['./view-meeting-room-requisition.component.css']
})
export class ViewMeetingRoomRequisitionComponent implements OnInit{
  template : boolean = true;
  loader: boolean;
  p = 1;
  MeetingRoomRequisitionList: MeetingRoomRequisition[];
  displayedMeetingRoomRequisitionColumns: string[] = ['Position','Purpose','Requested', 'Approved', 'Status'];
  finalMeetingRoomRequisition$: Observable<any>;
    approvalRequisition$: Observable<any>;
    recommendRequisition$: Observable<any>;
    ownerRequisition$: Observable<any>;
  //  adminCarRequisition$: Observable<Gatepass[]>;
    tabText: string;
    config: any = {
        url: 'meeting_room',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName: 'meeting_room_requisitions'
    } ;
  tabIndex: number=0;
  constructor(
    private snackBar: MatSnackBar,
    public requisitionService: RequisitionService,
    private dialog: MatDialog,
    public router: Router,
    private route : ActivatedRoute,
    public commonService: CommonService
  ) { }
getAllrequistion(){
      // this.finalMeetingRoomRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.final);
      this.approvalRequisition$    = this.requisitionService.requisitionList(this.config.url, this.config.approve);
      this.recommendRequisition$   = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
      this.ownerRequisition$       = this.requisitionService.requisitionList(this.config.url, this.config.owner);
  }
  ngOnInit() {
    this.getAllrequistion();
    if (this.route.snapshot.paramMap.get('visible') !== null) {
      this.tabIndex = 2;
  }
    this.ownerRequisition$.subscribe(res=>{
      console.log('Ownerdatalist',res);
    })
  }




  meetingRoomDetails(id) {
    console.log(id);
    this.openUserViewerDialog(id);
  }

  openUserViewerDialog(id) {
    const dialogRef = this.dialog.open(DetailsMeetingRoomRequisitionComponent, {
      // height: '40%',
      width: '70%',
      data: { id: id },
    });
  }

  createMeetingRoom() {
    this.router.navigate(['/create-meeting-room-requisition']);
  }

  getAllMeetingRoomRequisition() {
    // this.loader = true;
    // this.requisitionService.meetingRoomRequisitionList().subscribe(res => {
    //   console.log(res)
    //   this.requisitions = res.length;
    //   this.loader = false;
    //   this.MeetingRoomRequisitionList = res;
    // }, err => {
    //   this.loader = false;
    //   if (err.error.message) this.openSnackBar(" " + err.error.message, "ok");
    //   else this.openSnackBar(" " + err.error, "ok");
    // });
  }

  giveApprove(data) {
    this.loader = true;
    this.requisitionService.requisitionApprove(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar('Successfull ', 'ok');
      this.getAllrequistion();
    }, err => {
      this.loader = false;
      this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
        duration : 4000
      });
      this.getAllrequistion();
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

  delegate(data, approveCategory = '') {
    const testData = {
         requisition_id: data.id,
         approvecategory: approveCategory,
         tableName : this.config.tableName,
         req_type : 'meeting_room',
         url: this.config.url,
     };
   const dialog_ref =  this.dialog.open(DelegateModalComponent, {
             data: testData
         });
 dialog_ref.afterClosed().subscribe(res=>{
      this.getAllrequistion();
      console.log('delegate_afterClosed',res); 
    })
 
  }

  showDetail(data, approveCategory = '') {
    // console.log('here',approveCategory);
    const testData = {
        To: data.final_approved_by,
        Date: data.created_at,
        Subject: data.subject,
        Description: data.description,
        purpose : data.purpose,
        meeting_date: data.meeting_date,
        meeting_end_time: data.meeting_end_time,
        meeting_start_time: data.meeting_start_time,
        Approve_by: data.approved_by,
        recommended_by: data.recommended_by,
        final_approved_by: data.final_approved_by,
        approval_status: data.approval_status,
        final_approval_status: data.final_approval_status,
        recommendation_status: data.recommendation_status,
        Category: null,
        approvecategory: approveCategory,
        requested_by: data.requested_from,
        requested_by_name: data.requested_by,
        requisition_id: data.id,
        url: this.config.url,
        approve_sig: data.approved_by_sig,
        final_approved_sig: data.final_approved_by_sig,
        requested_by_sig: data.requested_by_sig,
        recommended_sig: data.recommended_by_sig,
      //  tabName: this.tabText
    };

       const dialog_ref =  this.dialog.open(DialogComponent, {
            data: testData
        });
     dialog_ref.afterClosed().subscribe(res=>{
            if(res !== 'reload'){
              this.giveApprove(res);
            }
          console.log('afterclosedDAta',res);
          
        })
   
  }

}

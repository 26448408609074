import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar, MatPaginator, MatTableDataSource } from '@angular/material';
import { Designation } from '../model/designation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-designation',
  templateUrl: './view-designation.component.html',
  styleUrls: ['./view-designation.component.css']
})
export class ViewDesignationComponent implements OnInit {
  loader: boolean;
  p: number = 1;
  // designationList: Designation[];
  displayedColumns: string[] = ['Position','Designation','Edit'];
  data:any = [];
  designationList : MatTableDataSource<Designation>;
  @ViewChild(MatPaginator, {static: true} as any) paginator: MatPaginator;

  constructor(
    public userService:UserService,
    public snackBar: MatSnackBar,
    private router: Router
  ) { }

  editDesignation(id){
    console.log(id)
    this.router.navigate(['edit-designation/', id]);
  }

  ngOnInit() {
    this.getAllDesignation();
  }
  applyFilter(event:any) {
    const filterValue = event.target.value;
    this.designationList.filter = filterValue.trim().toLowerCase();
  }
  getAllDesignation(){
    this.loader = true;
    this.userService.getDesignationList().subscribe( res =>{
        console.log(res)
        this.loader = false;
        this.data =res;
        this.data.sort((a,b)=>b.id -a.id);
        this.data.map((v,i)=>v.sn = i+1);
        this.designationList = new MatTableDataSource<Designation>(this.data);
        this.designationList.paginator = this.paginator;
        //this.openSnackBar("Success ", "ok");
      },err=>{
        this.loader = false;
        this.openSnackBar(" "+err.error.message, "ok");
    });
  }

  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { User } from '../model/user';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { dummyphotoUrl } from '../commonUrl';
import { dummy } from '../commonUrl';
import { RequisitionService } from '../services/requisition.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-create-id-card-requisition',
  templateUrl: './create-id-card-requisition.component.html',
  styleUrls: ['./create-id-card-requisition.component.css']
})
export class CreateIdCardRequisitionComponent implements OnInit {


  loader: boolean;
  employee: User[] = [];
  idCardForm: FormGroup;
  employee_name: string;
  ImageUrl = dummyphotoUrl;
  assetsImage = dummy;
  employee_image: string;
  recommendAuthority$:Observable<any>;
  approveAuthority$:Observable<any>;
  finalapproveAuthority$:Observable<any>;
  employee_spinner: boolean;
  employeSelf: User[]=[];

  constructor(
    private snackBar: MatSnackBar,
    public userService: UserService,
    public requisitionService: RequisitionService,
    private fb: FormBuilder,
    public router: Router,
  ) { }

  createIdCardRequisition() {
    this.loader = true;
    this.idCardForm.get('date').setValue(formatDate(this.idCardForm.get('date').value ? this.idCardForm.get('date').value : new Date(),"yyyy-MM-dd","en"));
    const payload = Object(this.idCardForm.value);
    payload.url = 'id_card';
    payload.path = 'requisition_create';
    this.requisitionService.requisitionCreate(payload).subscribe(res => {
        console.log('res',res);
        this.loader = false;
        this.openSnackBar(res.message, "ok");
        this.router.navigate(['view-id-card-requisition']);
      }, err => {
        this.loader = false;
        if (err.status == 400) location.reload(true);
        if (err.error.message) this.openSnackBar("Internal error :" + err.error.message, "ok")
        else this.openSnackBar("Error :" + err.error, "ok")
      }); 
  }

  getEmployeeList(){
    this.employee_spinner = true;
    this.userService.getUsers()
      .subscribe(data => {
        console.log('user_short_info');
        this.employee_spinner = false;
        this.employee = data;
      }, err => {
        this.employee_spinner = false;
        this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
          duration : 5000
        })
      });
  }
  selected_recomended_by(data){
    this.idCardForm.get('recommend_by').setValue(data);
    console.log('form value',this.idCardForm.value);
    
  }
  selected_approved_by(data){
    this.idCardForm.get('approved_by').setValue(data);
    console.log('form value approved by',this.idCardForm.value);
  }

  getEmployee(id) {
    console.log(id)
    this.loader = true;
    this.userService.getParticularUser(id)
      .subscribe(data => {
        console.log(data);
        this.loader = false;
        this.employeSelf = data;
        let userId = data[0].id.toString();
        if (data[0].user_image) this.employee_image = data[0].user_image
        this.idCardForm.patchValue({
          name: userId,
          department: data[0].dept_name,
          designation: data[0].title,
          email: data[0].email,
          phone: data[0].phone,
          blood_group: data[0].blood_group,
          dob: data[0].dob,
          joining_date: data[0].joining_date,
        });
      }, err => {
        this.loader = false;
        if (err.status == 400) location.reload(true);
        if (err.error.message) this.openSnackBar("Internal error :" + err.error.message, "ok")
        else this.openSnackBar("Error :" + err.error, "ok")
      });
  }



  ngOnInit() {
    this.getEmployeeList();
    this.recommendAuthority$ = this.userService.getRecommendationApproveauthoritylist('id_card');
    // this.approveAuthority$ = this.userService.getApproveApproveauthoritylist('id_card');
    this.approveAuthority$ = this.userService.getFinalApproveauthoritylist('id_card');
    this.idCardForm = this.fb.group({
      approval_status:[],
      approved_by: [],
      date:[],
      requested_by: [],
      requested_from: [],
      subject:[],
      description:[],
      recommend_by:[],
      final_approve_by:[],
      recommand_status:[],
      final_status:[],
      /*         file: [null] */
    });
    let id = +sessionStorage.getItem('user_id');
    this.getEmployee(id);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
    });
  }

}

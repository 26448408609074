import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { FoodRequisition } from '../model/foodRequisition';

@Component({
  selector: 'app-details-food-requisition',
  templateUrl: './details-food-requisition.component.html',
  styleUrls: ['./details-food-requisition.component.css']
})
export class DetailsFoodRequisitionComponent implements OnInit {
  foodItem: FoodRequisition[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public requisitionService: RequisitionService,
  ) {
    console.log(data.id);
    this.getSpecificfoodItem(data.id);
  }

  ngOnInit() {
  }

  getSpecificfoodItem(id) {
    // this.loader = true;
    this.requisitionService.getSpecificfoodItem(id)
      .subscribe(data => {
        // this.loader = false;
        this.foodItem = data;
        console.log(data);
      }, error => {
        // this.loader = false;
        console.log(error);
      });
  }
}

import { RequisitionService } from '../services/requisition.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MeetingRoomRequisition } from '../model/meetingRoomRequisition';

@Component({
  selector: 'app-details-meeting-room-requisition',
  templateUrl: './details-meeting-room-requisition.component.html',
  styleUrls: ['./details-meeting-room-requisition.component.css']
})
export class DetailsMeetingRoomRequisitionComponent implements OnInit {
  meetingRoom: MeetingRoomRequisition[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public requisitionService: RequisitionService,
  ) {
    console.log(data.id);
    this.getSpecificMeetingRoom(data.id);
  }

  ngOnInit() {
  }

  getSpecificMeetingRoom(id) {
    // this.loader = true;
    this.requisitionService.getSpecificMeetingRoom(id)
      .subscribe(data => {
        // this.loader = false;
        this.meetingRoom = data;
        console.log(data);
      }, error => {
        // this.loader = false;
        console.log(error);
      });
  }

}

import { Department } from './../model/department';
import { Component, OnInit } from '@angular/core';
import { Designation } from '../model/designation';
import { UserService } from '../services/user.service';
import { FormBuilder, FormArray, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RequisitionService } from '../services/requisition.service';
import { SafePipeComponent } from '../pipe/pipe.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { formatDate, formatNumber } from '@angular/common';
import { MatSnackBar, MatDialog} from '@angular/material';
import { fuseAnimations } from '../animations';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FilePreviewerComponent } from '../file-previewer/file-previewer.component';
import{currency} from '../json_data';

@Component({
    selector: 'app-create-memo-sheet-requisition',
    templateUrl: './create-memo-sheet-requisition.component.html',
    styleUrls: ['./create-memo-sheet-requisition.component.css'],
    animations : fuseAnimations
})
export class CreateMemoSheetRequisitionComponent implements OnInit {
    loader: boolean;
    // (change)="accountsPersonRequired = !accountsPersonRequired"
    includeCheckbox = new FormControl();
    memoForm: FormGroup;
    memoId: number;
    memoEdit: boolean;
    designationList: Designation[];
    employeeList$ = [];
    emplist: any;
    filteredOptions: Observable<any[]>[] = [];
    memoData: any;
    files: any;
    url: any;
    fileUrl: string | ArrayBuffer;
    fileArray: any[] = [];
    editFiles:any[] = [];
    deletedFiles:any[] = [];
    selectedEmployee: any;
    progress: number = 0;
    accounts_list:any[]=[];
    accounts_person_name:any;
    accounts_person_list:any[]=[];
    isadd:boolean=false;
    approvarDeleteList:any=[];
    approveeditemp:any=[];
    x:boolean=false;
    deleteon:boolean=true;
    // expenseHeadList = ['New Purchase',
    //     'Replacement',
    //     'Budgeted',
    //     'Non-Budgeted',
    //     'Repair',
    //     'Servicing',
    //     'Insurance',
    //     'Renewal',
    //     'NO Applicable'];
    departmentList: Department[];
    budget_field: boolean;
    referelKey: string;
    editdata: any;
    spinner: boolean;
    accounts_spinner: boolean;
    CurrArray :any = currency;
    saveUsername:boolean;
    editempform:any=[];
    delar:any=[];
    approvaldetails:any=[];
    
    constructor(
        public userService: UserService,
        public requisitionService: RequisitionService,
        public snackBar: MatSnackBar,
        private fb: FormBuilder,
        public router: Router,
        public route: ActivatedRoute,
        public preview_dialog: MatDialog

    ) { }

    type_of_budget(data){
        if(data == 2) {
            this.openSnackBar("Please select first approver person from Accounts",'ok');
            // this.memoForm.addControl('budget_amount', new FormControl('',Validators.required));
            this.budget_field = true;
        }
        else{
            // this.memoForm.removeControl('budget_amount');
            this.budget_field = false;
        }


       console.log('typeofpurchase',data);

       }

       getErrorMessage() {
        if (this.memoForm.get('budget_amount').hasError('required')) {
          return 'You must enter a budget value,Please contact your accounts';
        }
    }

    // get designationForms() {
    //     return this.memoForm.get('designationName') as FormArray;
    // }

    get employeeForms() {
        return this.memoForm.get('employeeName') as FormArray;
    }
    issame:boolean=false;
    data:any;
    selectedValue:any
   
    SelectedAccounts(event){
        console.log(event)
        // console.log(event.value)
        this.data=event
        this.issame=false;
        console.log('selected accounts_person',this.data);
      console.log('length', this.accounts_person_list.length)
    
        // if(this.selectedEmployee.id === this.data){
        //     console.log('found')
        //     this.issame = true;
        // }
        // if(this.newemp !== undefined)
        // {
        //     for(let i = 0; i<this.newemp.length; i++)
        //     {
        //             if(this.newemp[i].employee_id.id === this.data)
        //             {
        //             console.log('found')
        //             this.issame = true;
        //               break;
                      
        //             }
        //             else{
        //                 // this.notesheetForm.get('accounts_person').setValue(data);
        //                 // this.isok = false;
        //                 // console.log('not found')
        //                 continue;
        //             }
               
        //         }
   
        //     }
        this.memoForm.get('accounts_person').setValue(this.data);
        console.log('employee value',this.employeeForms.value);

    }
    
    // compareFn(c1: Country, c2: Country): boolean {
    //     return c1 && c2 ? c1.id === c2.id : c1 === c2;
    // }
    // addMoreItem() {
    //     this.designationForms.push(this.addItem());
    // }

    // addItem() {
    //     return this.fb.group({
    //         designation_id: ['', Validators.required]
    //     });
    // }
    newemp:any
    addMoreempItem() {
        this.isadd=false;
        if(this.memoEdit){
    
            this.employeeForms.push(this.addempItem());
            this.newemp = this.employeeForms.value;
            console.log('edit newemp',this.newemp)
            this.employListAutoCompleteControl(this.employeeForms.length - 1);
            if(this.newemp){
              for(let i=0;i<=this.editempform.length;i++){
                for(let j=0;j<=this.newemp.length;j++){
                  if(this.editempform[i]==this.newemp[j].employee_id.id){
                    this.isok=true;
                  }
                  else{
                    this.isok = false;
                  }
                  if(!this.isok && this.newemp[j].employee_id !=''){
                    this.editempform.push(this.newemp[j].employee_id.id)
                    this.approveeditemp.push(this.newemp[j].employee_id.id)
                    console.log('edit entry',this.editempform)
                    console.log('edit entry1',this.approveeditemp)
            
                  }
                }
                
              }
            
            }
            
          }else{
            this.employeeForms.push(this.addempItem());
            console.log('empform',this.employeeForms.value)
            
             this.newemp = this.employeeForms.value;
             this.employListAutoCompleteControl(this.employeeForms.length - 1);
          }
        
       
    }

  

    addempItem() {
      if(this.memoEdit){
        return this.fb.group({
          employee_id: ['']
      }); 
      }else{
        return this.fb.group({
            employee_id: ['', Validators.required]
        });
      }
    }

    editdeleteempItem(i) {
  //  console.log('edit dlt', i)
  //  console.log('editempform',this.editempform)
        for(let j=0;j<=this.approvaldetails.requisition_details.length;j++){
          if( i.approval_status!=1
            && i.approver_id== this.approvaldetails.requisition_details[j].approver_id){
             this.x=true;
            
           this.editempform.splice(j, 1)
          //  console.log('editempform',this.editempform)
         
           this.approvarDeleteList.push(this.approvaldetails.requisition_details.splice(j,1)[0]) 
         
           console.log('approvarDeleteList',this.approvarDeleteList)
          }
             if (!this.delar.some(el => el.id === this.approvaldetails.requisition_details.id)) {
            this.delar.push(this.approvaldetails.requisition_details);
            console.log('delar',this.approvaldetails.requisition_details)
          }
        }
         
         
          
      }

    deleteItem(i) {
        console.log('deleteIndex', i);
        // console.log('formArraySize', this.designationForms.length);
        // const designationArraySize = this.designationForms.length - 1;
        // if (designationArraySize === 0) {
        //     this.memoForm.patchValue({
        //         process_id: ''
        //     });
        // }
        // this.designationForms.removeAt(i);
        this.filteredOptions.splice(i, 1);
    }
    deleteempItem(i) {
        this.isok=false;
        const employeeArraySize = this.employeeForms.length - 1;
        if (employeeArraySize === 0) {
            this.memoForm.patchValue({
                employee_id: ''
            });
        }
        this.employeeForms.removeAt(i);
    }
    createMemo(data) {
        this.loader = true;
        console.log('payload',data);
        this.requisitionService.memoCreate(data).subscribe((event: HttpEvent<any>)  => {
            switch (event.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  break;
                case HttpEventType.Response:
                  this.snackBar.open(event.body['message'],'ok',{
                    duration : 3000
                  });
                 this.router.navigate(['view-memo-sheet-requisition']);
                  this.loader = false;

                  setTimeout(() => {
                    this.progress = 0;
                  }, 1500);

              }
        }, err => {
            this.loader = false;
            this.snackBar.open(err.error.message ? ' ' + err.error.message :JSON.stringify(err.error), 'ok',{
                duration:5000
            });
        });


    }
    
isok:boolean=false;
    getEmployee(value) {
        this.selectedEmployee = value;
        this.isadd = true;// new add
        console.log('value', value)
        if(this.memoEdit){
            for(let i=0; i<=this.approveeditemp.length; i++){
                if(this.approveeditemp[i]==value.id){
                  // console.log(this.approveeditemp[i],value.id)
                  console.log('found')
                  this.isok = true;
                    break;
      
                }
                else{
                  this.isok = false;
             
                }
              }
             
              if(this.approvarDeleteList){
                  for(let j=0;j<=this.approvarDeleteList.length;j++){
                    if(this.approvarDeleteList[j].approver_id== value.id){
                      this.isok = false;
                      this.editempform.push(value.id)
                      this.approveeditemp.push(value.id)
                      this.approvarDeleteList.splice(j, 1)
                      //  console.log('rem',this.editempform)
                      //  console.log('rem1',this.approveeditemp)
                    
                    }
                    
                  }
                  if(!this.isok){
                    console.log('push')
                    
                  }
             
                } 
        }
        else{
            if(this.editempform.indexOf(value.id) == -1){
            this.editempform.push(value.id)
            this.isok= false;
          } 
          else{
            this.isok = true; 
          }
          // if(this.approvaldetails.)
          
          
        
       
        // console.log('editenter',this.editempform) 
        // console.log('data',this.data)
  
          if(this.selectedEmployee.id === this.data)
          {
              console.log('found')
              this.isok = true;   
              
          }
              
          if(this.newemp !== undefined )
          {
              for(let i = 0; i<this.newemp.length; i++)
              {
                  
                      if(this.newemp[i].employee_id.id === value.id)
                      {
                        this.isok=true;
                        break;
                      }
                      
                      else{
                          this.isok=false;
                          continue;
                      }
                 
                  }
  
                 
                  
              }
        }
    
        // if(this.selectedEmployee.id === this.data)
        // {
        //     console.log('found')
        //     this.isok = true;   
            
        // }
            
        // if(this.newemp !== undefined)
        // {
        //     for(let i = 0; i<this.newemp.length; i++)
        //     {
        //         // for(let j = 0; j<value.length; j++)
        //         // {
        //             if(this.newemp[i].employee_id.id === value.id)
        //             {
        //               this.isok=true;
        //               break;
        //             }
                    
        //             else{
        //                 this.isok=false;
        //                 continue;
        //             }
        //         // console.log('light switch',value[j].employee_id.f_name)
        //         }

               
                
        //     }
        }

        // console.log('jj',empvalue)
    // }

    memoFormSubmit(status:any) {/* department form submit */
        if (this.memoEdit) {
            const fd = new FormData();
            fd.append('date', formatDate(new Date(this.memoForm.value.date), 'yyyy-M-dd', 'en'));

            fd.append('requisition_id', this.memoId.toString());
            fd.append('from_dept', this.memoForm.value.from_dept);
            fd.append('request_to', this.memoForm.value.request_to);
            fd.append('subject', this.memoForm.value.subject);
            fd.append('tin', this.memoForm.value.tin);
            fd.append('bin_nid', this.memoForm.value.bin_nid);
            fd.append('objective', this.memoForm.value.objective);
            fd.append('background', this.memoForm.value.background);
            fd.append('decision', this.memoForm.value.decision);
            fd.append('benifits', this.memoForm.value.benifits);
            fd.append('riskChallenges', this.memoForm.value.riskChallenges);
            fd.append('paymentCondition', this.memoForm.value.paymentCondition);
            fd.append('negotiationHistory', this.memoForm.value.negotiationHistory);
            fd.append('financialBreakdown', this.memoForm.value.financialBreakdown);
            fd.append('implementationPlan', this.memoForm.value.implementationPlan);
            fd.append('conclusion', this.memoForm.value.conclusion);
            fd.append('type_of_purchase', this.memoForm.value.type_of_purchase);
            fd.append('amount', this.memoForm.value.amount);
            fd.append('summary', this.memoForm.value.summary);
            fd.append('deletedFile',JSON.stringify(this.deletedFiles));
            fd.append('budget_amount', this.memoForm.value.budget_amount ? this.memoForm.value.budget_amount : '');
            fd.append('status',status);
            // fd.append('ref_no',this.referelKey);
            if (this.fileArray) {
                if (this.fileArray.length > 0) {
                    for (let x = 0; x < this.fileArray.length; x++) {
                        fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                    }
                }
            }
            fd.append('editapproval_list',JSON.stringify(this.editempform));
            fd.append('approvars',JSON.stringify(this.delar))
            const employeeName:Array<any> = this.employeeForms.value;
            if(this.includeCheckbox.value === true){
              employeeName.unshift({employee_id : {id : this.memoForm.value.accounts_person}})
            }
            fd.append('employeeName', JSON.stringify(employeeName));

            this.editMemo(fd);  // Call Memo Edit function here
        } else {

            console.log('hlw',this.memoForm.value);
            const fd = new FormData();
            fd.append('date', formatDate(new Date(this.memoForm.value.date), 'yyyy-M-dd', 'en'));
            const employeeName:Array<any> = this.employeeForms.value;
            if(this.includeCheckbox.value === true){
              employeeName.unshift({employee_id : {id : this.memoForm.value.accounts_person}})
            }

            console.log('employeeName after unshift',employeeName);
            fd.append('employeeName', JSON.stringify(employeeName));
            fd.append('from_dept', this.memoForm.value.from_dept);
            fd.append('request_to', this.memoForm.value.request_to);
            fd.append('subject', this.memoForm.value.subject);
            fd.append('tin', this.memoForm.value.tin);
            fd.append('bin_nid', this.memoForm.value.bin_nid);
            fd.append('objective', this.memoForm.value.objective);
            fd.append('background', this.memoForm.value.background);
            fd.append('decision', this.memoForm.value.decision);
            fd.append('benifits', this.memoForm.value.benifits);
            fd.append('riskChallenges', this.memoForm.value.riskChallenges);
            fd.append('paymentCondition', this.memoForm.value.paymentCondition);
            fd.append('negotiationHistory', this.memoForm.value.negotiationHistory);
            fd.append('financialBreakdown', this.memoForm.value.financialBreakdown);
            fd.append('implementationPlan', this.memoForm.value.implementationPlan);
            fd.append('conclusion', this.memoForm.value.conclusion);
            fd.append('type_of_purchase', this.memoForm.value.type_of_purchase);
            fd.append('amount', this.memoForm.value.amount);
            fd.append('summary', this.memoForm.value.summary);
            fd.append('budget_amount', this.memoForm.value.budget_amount ? this.memoForm.value.budget_amount : '');
            let date = new Date();
            const refFormat = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'ApprovalMemo'+ '/';
            fd.append('ref_no',refFormat);
            fd.append('status',status);
            if (this.fileArray) {
                if (this.fileArray.length > 0) {
                    for (let x = 0; x < this.fileArray.length; x++) {
                        fd.append('uploadFile[]', this.fileArray[x].files, this.fileArray[x].fileName);
                    }
                }
            }
            this.createMemo(fd);
        }
    }

    editMemo(data) {
        this.loader = true;
        console.log('payload',data);
        this.userService.editMemo(data).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  console.log('Response header has been received!');
                  break;
                case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  break;
                case HttpEventType.Response:
                  this.snackBar.open('File uploaded successfull','ok')
                  setTimeout(() => {
                    this.progress = 0;
                    this.snackBar.open(event.body['message'],'ok',{
                        duration : 3000
                      });
                     this.router.navigate(['view-memo-sheet-requisition']);
                      this.loader = false;
                  }, 1500);

              }

        },e=>{
            this.loader = false;
            this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
              duration : 5000
            })
          });
    }

    getSpecificMemo(memoId) {
        this.loader = true;
        this.userService.getParticualrMemo(memoId)
          .subscribe(data => {
            this.editFiles = data.file_info;
            this.loader = false;
            this.editdata = data;
            this.referelKey = data.requisition_info[0].ref_no;

            this.memoForm.patchValue({
             request_to: data.requisition_info[0].request_to,
              from_dept: data.requisition_info[0].from_dept,
              requisition_id : memoId,
              date: data.requisition_info[0].date,
              subject: data.requisition_info[0].subject,
              description: data.requisition_info[0].description,
              objective: data.requisition_info[0].objective,
              background: data.requisition_info[0].background,
              decision: data.requisition_info[0].decision,
              paymentCondition: data.requisition_info[0].paymentCondition,
              riskChallenges: data.requisition_info[0].riskChallenges,
              benifits: data.requisition_info[0].benifits,
              negotiationHistory: data.requisition_info[0].negotiationHistory,
              financialBreakdown: data.requisition_info[0].financialBreakdown,
              implementationPlan: data.requisition_info[0].implementationPlan,
              conclusion: data.requisition_info[0].conclusion,
              type_of_purchase: data.requisition_info[0].type_of_purchase,
              amount: data.requisition_info[0].amount,
              summary: data.requisition_info[0].summary,
              budget_amount: data.requisition_info[0].budget_amount,
              tin: data.requisition_info[0].tin,
              bin_nid: data.requisition_info[0].bin_nid,
            });

          },e=>{
            this.loader = false;
            this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
              duration : 5000
            })
          });
      }

      deleteEditFile(data:any,index){
        this.deletedFiles.push(data);
        this.editFiles.splice(index,1);
      }
      download(f){
          const link = document.createElement('a');
          const downloadUrl = f;
          link.setAttribute('href',  downloadUrl);
          link.setAttribute('download', 'FileName');
          link.setAttribute('target', '_blank');
          document.body.appendChild(link); // Required for FF
          link.click();
      }

      previewFile(f) {
      const dialog_ref = this.preview_dialog.open(FilePreviewerComponent,{
              data : {url : f}
          })
          dialog_ref.afterClosed().subscribe(res=>{
              console.log('preview_closed',res);
          })
      }

    getDesignationList() {
        this.userService.getDesignationList()
            .subscribe(data => {
                this.designationList = data;
            }, e => {
                this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
                    duration : 5000
                  })
            });
    }

    getDepartmentList(){
        this.userService.getDepartmentList()
            .subscribe(data => {
                this.departmentList = data;
            }, e=> {
                this.snackBar.open(e.error.message ? e.error.message  : JSON.stringify(e.error),'ok',{
                    duration : 5000
                  })
            });
    }

    // selectProcess(event) {


    //     this.requisitionService.procesUdetail(event.value).subscribe(data => {

    //         this.memoForm.setControl('designationName',
    //             this.fb.array(Object.values(data).map(item => {
    //                 return this.fb.group({
    //                     designation_id: ['', Validators.required]
    //                 });
    //             }))
    //         );
    //         this.emplist = data;
    //     });
    // }

    onFileInput(event: any) {
        console.log('event',event);
        this.files = event.target.files;

        console.log('files',this.files);
        if (event.target.files && event.target.files[0]) {

            for (let item = 0; item < this.files.length; item++) {

                const reader = new FileReader();
                reader.readAsDataURL(this.files[item]);
                reader.onload = (_event: any) => {
                    console.log('file event',_event);

                    this.fileUrl = _event.target.result;
                    if(this.files[item].size > 358400){
                        this.snackBar.open('File size limit exceed,your file size is  ' + (this.files[item].size/1024).toFixed(2)+' KB, Upload file size will not be exceed 350 KB','ok',{duration:10000});
                    }else{
                        this.fileArray.push({
                            files :  this.files[item],
                            fileName: this.files[item].name,
                            fileUrl: this.fileUrl
                        });
                    }

                };
            }
            console.log(this.fileArray);
        }
    }
    deleteFile(index){
        console.log('index',index);
        this.fileArray.splice(index,1);

    }

    employListAutoCompleteControl(index: number) {
        const arrayControl = this.memoForm.get('employeeName') as FormArray;
        console.log('empv', arrayControl)
        this.filteredOptions[index] = arrayControl.at(index).get('employee_id').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.f_name),
                map(name => name ? this._filter(name) : this.employeeList$.slice())
            );

    }

    displayFn(data?: any): string | undefined {
        return data ? data.f_name : undefined;
    }
    generateKey(lengthOfCode: number, possible: string) {
        let text = "";
        for (let i = 0; i < lengthOfCode; i++) {
          text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
          return text;
      }

      getReferance(){
          
        this.userService.get_referance('approval_note').subscribe(res=> {
            console.log('ref_id_backend',res);
            if(res.id){
                var str = "" + ++res.id;
            }else{
                var str = "" + 1;
            }
            let date = new Date();
            var pad = "0000"
            var ans = pad.substring(0, pad.length - str.length) + str
            const refFormat = "GDI/"+ (sessionStorage.getItem('department') != null ? sessionStorage.getItem('department').trim() : ' ') + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + '/' + 'ApprovalMemo'+ '/' + ans;
            this.referelKey  = refFormat;
            console.log('referelKey',this.referelKey);
             });
        }
        getAccounts_persons(){
            //console.log('niloy testing', event )
            //if(event.checked == true){
                this.accounts_spinner = true;
                this.userService.getAccountsPerson().subscribe(res=>{
                    this.accounts_list = res;
                    for(let i =0;i<this.accounts_list.length;i++){
                        if(this.accounts_list[i].is_budget_person== 1){
                            this.accounts_person_list.push({f_name:this.accounts_list[i].f_name,
                            id:this.accounts_list[i].id,
                            pf_no:this.accounts_list[i].pf_no})
                            // console.log('accounts_person_name', this.accounts_person_list )
                        }
                    }

                    console.log('accounts_person_name', this.accounts_person_list[0].id )

                    this.memoForm.patchValue({
                        accounts_person : this.accounts_person_list[0].id
                    })
                    //console.log('accounts_personList',this.accounts_person_list);
                    // this.memoForm.controls['accounts_person'].setValue( this.accounts_person_list[0], {onlySelf: true});
                    if(this.accounts_person_list.length===1){
                        this.selectedValue = this.accounts_person_list[0].f_name
                        console.log('selectvalue',this.selectedValue)
                    }
                    
                
                    this.accounts_spinner = false;

                },err=>{
                    this.accounts_spinner = false;
                    this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error));
                })
            //} else{
                //this.memoForm.accounts_person = null;
            //}
            
            // this.setDefaultValue()
        }
        //setDefaultValue(){
            //console.log('newgggggggggggggggg',this.accounts_person_list)
            // console.log('hlw', this.accounts_person_list.length)
            // for(let i=0;i<this.accounts_person_list.length;i++){
                
            //     console.log('hlwww',this.accounts_person_list[i].f_name)
            // }
            // this.memoForm.patchValue({
            //     accounts_person : this.accounts_person_list[0].value
            // })
            // console.log('new',this.accounts_person_list[0].f_name)
          //}
    ngOnInit() {
       
      this.includeCheckbox.valueChanges.subscribe(res=>{
        const accountsPerson = this.memoForm.get('accounts_person');
        if(res === true){
         accountsPerson.setValidators(Validators.required);
          accountsPerson.updateValueAndValidity();
          this.getAccounts_persons();
        }else{
          accountsPerson.clearValidators();
          accountsPerson.reset();
        }

      })
    
        this.userService.getUserList().subscribe((res) => {
            this.employeeList$ = res;
            console.log('userlist',res);
    

        });
        
        if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
            this.memoId = +this.route.snapshot.paramMap.get('id');
            this.memoEdit = true;
            this.memoForm = this.fb.group({
                request_to: [''],
                from_dept: [''],
                subject: ['', Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                date: ['', Validators.required],
                requsition_id: [''],
                objective: [''],
                background: [''],
                decision: [''],
                paymentCondition: [''],
                riskChallenges: [''],
                benifits: [''],
                cur_type : ['BDT',Validators.required],
                negotiationHistory: [''],
                financialBreakdown: [''],
                implementationPlan: [''],
                conclusion: [''],
                type_of_purchase : [''],
                amount : ['',Validators.required],
                summary : [''],
                files: [null],
                employeeName: this.fb.array([this.addempItem()]),
               
            });
            this.getSpecificMemo(this.memoId);
            this.userService.getParticualrMemo(this.memoId).subscribe(data=>{
                this.approvaldetails=data;
                console.log('empnew',this.approvaldetails)
                if(this.approvaldetails.requisition_details.length>0){
                    for( let i=0;i<this.approvaldetails.requisition_details.length;i++){
                      if(this.approvaldetails.requisition_details[i].approval_status!=1){
                        this.editempform.push(this.approvaldetails.requisition_details[i].approver_id)
                        this.approveeditemp.push(this.approvaldetails.requisition_details[i].approver_id)
                        console.log('approveeditemp',this.approveeditemp)
                    }
                }
                    
                  }
                
            })
            if(this.approvaldetails.requisition_details){
             
                 if(this.approvaldetails.requisition_details.approval_status == 1){
                     this.deleteon = false;
                 }
                 else{
                   this.deleteon = true;
                 }
            
              }
        } else {
            this.getReferance();
            //this.getAccounts_persons();
            //console.log('lengthllllllll', this.accounts_person_list)
            //this.setDefaultValue()
            this.memoForm = this.fb.group({
                request_to: ['',],
                from_dept: ['',],
                subject: ['', Validators.required],
                tin : ['',Validators.required],
                bin_nid : ['',Validators.required],
                date: [new Date(), Validators.required],
                objective: [''],
                background: [''],
                decision: [''],
                paymentCondition: [''],
                riskChallenges: [''],
                benifits: [''],
                negotiationHistory: [''],
                financialBreakdown: [''],
                implementationPlan: [''],
                accounts_person : [''],
                employeeName: this.fb.array([this.addempItem()]),
                conclusion: [''],
                type_of_purchase : [''],
                amount : ['',Validators.required],
                cur_type : ['BDT',Validators.required],
                summary : [''],
                files: [null]
            });
           
           
        }
        this.employListAutoCompleteControl(0);



        // this.getDesignationList();
        // this.getDepartmentList();
        
    }


    private _filter(value: any): string[] {
        const filterValue = value.toLowerCase();
        
        return this.employeeList$.filter(option => {
            return option.f_name.toLowerCase().indexOf(filterValue) !== -1 || option.pf_no == value;
        });
       
    }


    openSnackBar(message: string, action: string,time?:number) {
        this.snackBar.open(message, action, {
            duration: time,
        });
    }

}

import { Component, OnInit,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
@Component({
  selector: 'app-view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class ViewDashboardComponent implements OnInit {
  dialogData:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog:MatDialog,
  ) { }
  columnsToDisplay: string[] = ['Subject', 'created_at', 'Department', 'lastupdate'];
  ngOnInit() {
    this.dialogData = this.data;
    console.log('dialogData',this.dialogData);
  }
  close() {
    this.dialog.closeAll();
}
}

import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-meeting-room',
  templateUrl: './create-meeting-room.component.html',
  styleUrls: ['./create-meeting-room.component.css']
})
export class CreateMeetingRoomComponent implements OnInit {
  loader: boolean;
  meetingRoomForm: any;
  meetingRoomId: number;
  meetingRoomEdit: boolean;

  constructor(
      public userService:UserService,
      public snackBar:MatSnackBar,
      private fb: FormBuilder,
      public router: Router,
      public route: ActivatedRoute,
  ) { }

  createMeetingRoom(data){/* department save service */
    if(this.meetingRoomForm.valid){
      this.loader = true;
      this.userService.meetingRoomCreate(data).subscribe(res => {
       this.loader = false;
       console.log('res',res)
       this.openSnackBar(res.message, "ok");
       this.router.navigate(['view-meeting-room']);
     },err=>{
       this.loader = false;
       this.openSnackBar("Internal Error: "+err.error.message, "ok");
     }); 
    } else{
      this.openSnackBar('Please fill up all required field,then try again','ok');
    }
  
  }

  meetingRoomFormSubmit(){/* department form submit */
    //console.log(this.meetingRoomForm.value);
    if( this.meetingRoomEdit){
      debugger
      let fd = new FormData()
      fd.append('room_id', this.meetingRoomId.toString());
      fd.append('room_title',this.meetingRoomForm.value.room_title);
      fd.append('capacity',this.meetingRoomForm.value.capacity);
      fd.append('floor_no',this.meetingRoomForm.value.floor_no);
      fd.append('projector',this.meetingRoomForm.value.printer ? '1' : '0');
      fd.append('sound',this.meetingRoomForm.value.sound ? '1' : '0');
      fd.append('tv',this.meetingRoomForm.value.tv ? '1' : '0');
      fd.append('wifi',this.meetingRoomForm.value.wifi ? '1' : '0');
      fd.append('room_no',this.meetingRoomForm.value.room_no);
      this.editMeetingRoom(fd);
    }else{
      /* let fd = new FormData()
      fd.append('room_title', this.meetingRoomForm.value.room_title);
      fd.append('room_no', this.meetingRoomForm.value.room_no);
      this.createMeetingRoom(fd); */

      console.log(this.meetingRoomForm.value);
      let fd = new FormData();
      fd.append('room_title',this.meetingRoomForm.value.room_title);
      fd.append('capacity',this.meetingRoomForm.value.capacity);
      fd.append('floor_no',this.meetingRoomForm.value.floor_no);
      fd.append('projector',this.meetingRoomForm.value.printer ? '1' : '0');
      fd.append('sound',this.meetingRoomForm.value.sound ? '1' : '0');
      fd.append('tv',this.meetingRoomForm.value.tv ? '1' : '0');
      fd.append('wifi',this.meetingRoomForm.value.wifi ? '1' : '0');
      fd.append('room_no',this.meetingRoomForm.value.room_no);
      this.createMeetingRoom(fd);
    } 
  
  }

  editMeetingRoom(data){/* department save service */
    this.loader = true;
    this.userService.editMeetingRoom(data).subscribe(res => {
      this.loader = false;
      this.openSnackBar("Successfully Updated", "ok");
      this.router.navigate(['view-meeting-room']);
    },err=>{
      this.loader = false;
      if(err.error.message)this.openSnackBar(" "+err.error.message, "ok");
      else this.openSnackBar(" "+err.error, "ok");
    });
  }

  getSpecificMeetingRoom(meetingRoomId) {
    this.loader = true;
    // let fd = new FormData()
    // fd.append('id', departmentId)
    this.userService.getParticularMeetingRoom(meetingRoomId)
        .subscribe(data => {
          console.log("Kaisar",data)
          this.loader = false;
          this.meetingRoomForm.patchValue({
            id: meetingRoomId,
            room_title: data[0].room_title,
            floor_no: data[0].floor_no,
            room_no: data[0].room_no,
            capacity: data[0].capacity,
            printer: data[0].projector,
            sound: data[0].sound,
            tv: data[0].tv,
            wifi: data[0].wifi
          });
        }, err => {
          this.loader = false;
          this.snackBar.open(err.error.message ? err.error.message : JSON.stringify(err.error),'ok',{
            duration : 5000
          })
          console.log(err);
        });
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('id') !== null) { // The JavaScript (+) operator converts the string to a number
      this.meetingRoomId = +this.route.snapshot.paramMap.get('id');
      console.log(this.meetingRoomId);
      this.meetingRoomEdit = true;
      this.meetingRoomForm = this.fb.group({
        id :['',Validators.required],
        room_title: ['', Validators.required],
        capacity: ['', Validators.required],
        floor_no: ['', Validators.required],
        room_no : [''],
        printer: [''],
        sound: [''],
        tv: [''],
        wifi: ['']
      });
      this.getSpecificMeetingRoom(this.meetingRoomId);
    } else {
      this.meetingRoomForm = this.fb.group({
        room_title: ['', Validators.required],
        capacity: ['', Validators.required],
        floor_no: ['', Validators.required],
        room_no : [''],
        printer: [''],
        sound: [''],
        tv: [''],
        wifi: ['']
      });

    } 
  
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

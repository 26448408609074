import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common'
import { AppComponent } from './app.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';
import { MatTableExporterModule } from 'mat-table-exporter';
import { GoogleChartsModule } from 'angular-google-charts';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import { MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatListModule,
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatBadgeModule,
  MatCardModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTreeModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
  MatDialogModule,
  MatFormFieldAppearance,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS, 
  
} from '@angular/material';

import { AmazingTimePickerModule } from 'amazing-time-picker';
import { MainNavComponent } from './main-nav/main-nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { CreateUserRoleComponent } from './create-user-role/create-user-role.component';
import { ViewUserRoleComponent } from './view-user-role/view-user-role.component';
import { CreateDepartmentComponent } from './create-department/create-department.component';
import { ViewDepartmentComponent } from './view-department/view-department.component';
import { CreateDesignationComponent } from './create-designation/create-designation.component';
import { ViewDesignationComponent } from './view-designation/view-designation.component';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
import { CreateOfficeItemComponent } from './create-office-item/create-office-item.component';
import { ViewVehicleComponent } from './view-vehicle/view-vehicle.component';
import { ViewOfficeItemComponent } from './view-office-item/view-office-item.component';
import { CreateMeetingRoomComponent } from './create-meeting-room/create-meeting-room.component';
import { ViewMeetingRoomComponent } from './view-meeting-room/view-meeting-room.component';
import { CreateVehicleTypeComponent } from './create-vehicle-type/create-vehicle-type.component';
import { CreateGetPassRequisitionComponent } from './create-get-pass-requisition/create-get-pass-requisition.component';
import { CreateMeetingRoomRequisitionComponent } from './create-meeting-room-requisition/create-meeting-room-requisition.component';
import { CreateVisitingCardRequisitionComponent } from './create-visiting-card-requisition/create-visiting-card-requisition.component';
import { LoginComponent } from './auth/login/login.component';

import { CreateOfficeItemRequisitionComponent } from './create-office-item-requisition/create-office-item-requisition.component';
import { CreateIdCardRequisitionComponent } from './create-id-card-requisition/create-id-card-requisition.component';
import { CreateFoodRequisitionComponent } from './create-food-requisition/create-food-requisition.component';
import { ViewOfficeItemRequisitionComponent } from './view-office-item-requisition/view-office-item-requisition.component';
import { ViewIdCardRequisitionComponent } from './view-id-card-requisition/view-id-card-requisition.component';
import { ViewFoodRequisitionComponent } from './view-food-requisition/view-food-requisition.component';
import { ViewVisitingCardRequisitionComponent } from './view-visiting-card-requisition/view-visiting-card-requisition.component';
import { ViewMeetingRoomRequisitionComponent } from './view-meeting-room-requisition/view-meeting-room-requisition.component';
import { ViewGatePassRequisitionComponent } from './view-gate-pass-requisition/view-gate-pass-requisition.component';
import { CreateMemoSheetRequisitionComponent } from './create-memo-sheet-requisition/create-memo-sheet-requisition.component';
import { ViewMemoSheetRequisitionComponent } from './view-memo-sheet-requisition/view-memo-sheet-requisition.component';
import { CreateNoteSheetRequisitionComponent } from './create-note-sheet-requisition/create-note-sheet-requisition.component';
import { ViewNoteSheetRequisitionComponent } from './view-note-sheet-requisition/view-note-sheet-requisition.component';
import { CreatePoolCarRequisitionComponent } from './create-pool-car-requisition/create-pool-car-requisition.component';
import { ViewPoolCarRequisitionComponent } from './view-pool-car-requisition/view-pool-car-requisition.component';
import { CreateCarRequisitionComponent } from './create-car-requisition/create-car-requisition.component';
import { ViewCarRequisitionComponent } from './view-car-requisition/view-car-requisition.component';
import { ReportOfficeItemAcceptedItemWiseComponent } from './report-office-item-accepted-item-wise/report-office-item-accepted-item-wise.component';
import { ReportOfficeItemAcceptedEmployeeWiseComponent } from './report-office-item-accepted-employee-wise/report-office-item-accepted-employee-wise.component';
import { ReportOfficeItemAcceptedApprovalAuthorityWiseComponent } from './report-office-item-accepted-approval-authority-wise/report-office-item-accepted-approval-authority-wise.component';
import { ReportOfficeItemAcceptedDurationWiseComponent } from './report-office-item-accepted-duration-wise/report-office-item-accepted-duration-wise.component';
import { ReportOfficeItemRejectedItemWiseComponent } from './report-office-item-rejected-item-wise/report-office-item-rejected-item-wise.component';
import { ReportOfficeItemRejectedEmployeeWiseComponent } from './report-office-item-rejected-employee-wise/report-office-item-rejected-employee-wise.component';
import { ReportOfficeItemRejectedApprovalAuthorityWiseComponent } from './report-office-item-rejected-approval-authority-wise/report-office-item-rejected-approval-authority-wise.component';
import { ReportOfficeItemRejectedDurationWiseComponent } from './report-office-item-rejected-duration-wise/report-office-item-rejected-duration-wise.component';
import { AuthGuard } from './auth/auth.guard';
import { PoolCarRequisitionDetailsComponent } from './pool-car-requisition-details/pool-car-requisition-details.component';
import { DetailsMeetingRoomRequisitionComponent } from './details-meeting-room-requisition/details-meeting-room-requisition.component';
import { DetailsGatePassRequisitionComponent } from './details-gate-pass-requisition/details-gate-pass-requisition.component';
import { DetailsOfficeItemRequisitionComponent } from './details-office-item-requisition/details-office-item-requisition.component';
import { DetailsVisitingCardRequisitionComponent } from './details-visiting-card-requisition/details-visiting-card-requisition.component';
import { DetailsIdCardRequisitionComponent } from './details-id-card-requisition/details-id-card-requisition.component';
import { DetailsFoodRequisitionComponent } from './details-food-requisition/details-food-requisition.component';

import { FlexLayoutModule, BREAKPOINT } from '@angular/flex-layout';
import { CreateAuthorityComponent } from './create-authority/create-authority.component';
import { ViewAuthorityComponent } from './view-authority/view-authority.component';
import { DialogComponent } from './dialog/dialog.component';
import { DataTableComponent } from './data-table/data-table.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { DelegateModalComponent } from './delegate-modal/delegate-modal.component';
import { CreateProcessComponent } from './create-process/create-process.component';
import { ViewProcessComponent } from './view-process/view-process.component';
import { SafePipeComponent } from './pipe/pipe.component';
import { MemoDetailsComponent } from './memo-details/memo-details.component';
import { GenerateNotesheetComponent } from './generate-notesheet/generate-notesheet.component';
import { VehicleassignComponent } from './vehicleassign/vehicleassign.component';
import { NoteSheetDetailsComponent } from './note-sheet-details/note-sheet-details.component';
import { VersionComponent } from './version/version.component';
import { VersionDetailsComponent } from './version-details/version-details.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { ViewDashboardComponent } from './view-dashboard/view-dashboard.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { CreateOfficeItemCategoryComponent } from './create-office-item-category/create-office-item-category.component';
import { ViewItemCategorylistComponent } from './view-item-categorylist/view-item-categorylist.component';
import { OfficeItemDetailsComponent } from './office-item-details/office-item-details.component';
import { ApprovalNoteComponent } from './approval-note/approval-note.component';
import { ApprovalNoteListComponent } from './approval-note-list/approval-note-list.component';

import { PaymentNoteListComponent } from './payment-note-list/payment-note-list.component';
import { PaymentNoteDetailsComponent } from './payment-note-details/payment-note-details.component';
import { SpecialApprovalNoteComponent } from './special-approval-note/special-approval-note.component';
import { SpecialPaymentNoteComponent } from './special-payment-note/special-payment-note.component';
import { GatePassItemWiseComponent } from './gate-pass-item-wise/gate-pass-item-wise.component';
import { GatePassEmployeewiseComponent } from './gate-pass-employeewise/gate-pass-employeewise.component';
import { GatePassApprovalAuthoritywiseComponent } from './gate-pass-approval-authoritywise/gate-pass-approval-authoritywise.component';
import { MeetingrommReportComponent } from './meetingromm-report/meetingromm-report.component';
import { FoodReportComponent } from './food-report/food-report.component';
import { VisitingCardReportComponent } from './visiting-card-report/visiting-card-report.component';
import { IDCardReportComponent } from './id-card-report/id-card-report.component';
import { GenerelApprovalsNotesReportsComponent } from './generel-approvals-notes-reports/generel-approvals-notes-reports.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AuthorityDetailsComponent } from './authority-details/authority-details.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ProfileComponent } from './profile/profile.component';
import { FilePreviewerComponent } from './file-previewer/file-previewer.component';
import { RefDetailsDialogComponent } from './ref-details-dialog/ref-details-dialog.component';
import { AccountsHeadEntryComponent } from './accounts-head-entry/accounts-head-entry.component';
import { ViewAccountsHeadComponent } from './view-accounts-head/view-accounts-head.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { PendingPaymentNoteComponent } from './pending-payment-note/pending-payment-note.component';
import { ApprovalNoteDetailsComponent } from './approval-note-details/approval-note-details/approval-note-details.component';
import { PaymentNoteComponent } from './payment-note/payment-note.component';
import { PaymentNoteEditComponent } from './payment-note-edit/payment-note-edit.component';
import { NgxSummernoteModule } from 'ngx-summernote';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

const appRoutes: Routes = [

  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'create-user', component: CreateUserComponent, canActivate: [AuthGuard] },
  { path: 'view-user', component: ViewUserComponent, canActivate: [AuthGuard] },
  { path: 'edit-user/:id', component: CreateUserComponent, canActivate: [AuthGuard] },
  { path: 'create-user-role', component: CreateUserRoleComponent, canActivate: [AuthGuard] },
  { path: 'view-user-role', component: ViewUserRoleComponent, canActivate: [AuthGuard] },
  { path: 'edit-user-role/:id/:name', component: CreateUserRoleComponent, canActivate: [AuthGuard] },

  { path: 'create-department', component: CreateDepartmentComponent, canActivate: [AuthGuard] },
  { path: 'view-department', component: ViewDepartmentComponent, canActivate: [AuthGuard] },
  { path: 'edit-department/:id', component: CreateDepartmentComponent, canActivate: [AuthGuard] },

  { path: 'create-accounts_head', component: AccountsHeadEntryComponent, canActivate: [AuthGuard] },
  { path: 'view-accounts_head', component: ViewAccountsHeadComponent, canActivate: [AuthGuard] },
  { path: 'edit-accounts_head/:id', component: AccountsHeadEntryComponent, canActivate: [AuthGuard] },

  { path: 'create-designation', component: CreateDesignationComponent, canActivate: [AuthGuard] },
  { path: 'view-designation', component: ViewDesignationComponent, canActivate: [AuthGuard] },
  { path: 'edit-designation/:id', component: CreateDesignationComponent, canActivate: [AuthGuard] },

  { path: 'create-vehicle', component: CreateVehicleComponent, canActivate: [AuthGuard] },
  { path: 'view-vehicle', component: ViewVehicleComponent, canActivate: [AuthGuard] },
  { path: 'create-vehicle-type', component: CreateVehicleTypeComponent, canActivate: [AuthGuard] },
  { path: 'vehicle-assign', component: VehicleassignComponent },

  { path: 'edit-vehicle/:id', component: CreateVehicleComponent, canActivate: [AuthGuard] },
  { path: 'edit-vehicle-type/:id', component: CreateVehicleTypeComponent, canActivate: [AuthGuard] },

  {path : 'create-officeItem-ctg',component : CreateOfficeItemCategoryComponent,canActivate: [AuthGuard]},
  { path: 'view-officeItem-ctg', component:  ViewItemCategorylistComponent, canActivate: [AuthGuard]},
  { path: 'edit-officeItem-ctg/:id', component: CreateOfficeItemCategoryComponent, canActivate: [AuthGuard] },

  { path: 'create-office-item', component: CreateOfficeItemComponent, canActivate: [AuthGuard] },
  { path: 'view-office-item', component: ViewOfficeItemComponent , canActivate: [AuthGuard]},
  { path: 'edit-office-item/:id', component: CreateOfficeItemComponent, canActivate: [AuthGuard] },
  { path: 'approval-note-list', component: ApprovalNoteListComponent , canActivate: [AuthGuard]},
  { path: 'payment-note-list', component: PaymentNoteListComponent , canActivate: [AuthGuard]},
  // { path: 'approval-note-details', component: ApprovalNoteDetailsComponent , canActivate: [AuthGuard]},

  { path: 'approval-note-list/:visible', component: ApprovalNoteListComponent , canActivate: [AuthGuard]},
  { path: 'payment-note-list/:visible', component: PaymentNoteListComponent , canActivate: [AuthGuard]},

  { path: 'create-meeting-room', component: CreateMeetingRoomComponent, canActivate: [AuthGuard] },
  { path: 'view-meeting-room', component: ViewMeetingRoomComponent, canActivate: [AuthGuard] },
  { path: 'edit-meeting-room/:id', component: CreateMeetingRoomComponent, canActivate: [AuthGuard] },

  { path: 'create-authority', component: CreateAuthorityComponent, canActivate: [AuthGuard] },
  { path: 'view-authority', component: ViewAuthorityComponent, canActivate: [AuthGuard] },
  { path: 'edit-authority/:id', component: CreateAuthorityComponent, canActivate: [AuthGuard] },

  { path: 'create-process', component: CreateProcessComponent, canActivate: [AuthGuard] },
  { path: 'view-process', component: ViewProcessComponent, canActivate: [AuthGuard] },
  { path: 'edit-process/:id', component: CreateProcessComponent, canActivate: [AuthGuard] },

  // Generel requisition
  // office-item-requisition

  { path: 'create-office-item-requisition', component: CreateOfficeItemRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'edit-office-item-requisition/:id', component: CreateOfficeItemRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-office-item-requisition', component: ViewOfficeItemRequisitionComponent, canActivate: [AuthGuard] },
  // gate-pass-requisition
  { path: 'create-gate-pass-requisition', component: CreateGetPassRequisitionComponent , canActivate: [AuthGuard]},
  { path: 'view-gate-pass-requisition', component: ViewGatePassRequisitionComponent, canActivate: [AuthGuard] },
  // visiting-card-requisition
  { path: 'create-visiting-card-requisition', component: CreateVisitingCardRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-visiting-card-requisition', component: ViewVisitingCardRequisitionComponent, canActivate: [AuthGuard] },
  // meeting-room-requisition
  { path: 'create-meeting-room-requisition', component: CreateMeetingRoomRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-meeting-room-requisition', component: ViewMeetingRoomRequisitionComponent, canActivate: [AuthGuard] },
  // pool-car-requisition
  { path: 'create-pool-car-requisition', component: CreatePoolCarRequisitionComponent , canActivate: [AuthGuard]},
  { path: 'view-pool-car-requisition', component: ViewPoolCarRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'pool-car-requisition-details/:id', component: PoolCarRequisitionDetailsComponent, canActivate: [AuthGuard] },
  // car-requisition
  { path: 'create-car-requisition', component: CreateCarRequisitionComponent , canActivate: [AuthGuard]},
  { path: 'view-car-requisition', component: ViewCarRequisitionComponent, canActivate: [AuthGuard] },
  // food-requisition
  { path: 'create-food-requisition', component: CreateFoodRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-food-requisition', component: ViewFoodRequisitionComponent, canActivate: [AuthGuard] },
  // id-card-requisition
  { path: 'create-id-card-requisition', component: CreateIdCardRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-id-card-requisition', component: ViewIdCardRequisitionComponent, canActivate: [AuthGuard] },
  // Purchase-requisition
  // memo-sheet-requisition
  // edit memo route


  //memo-sheet-requisition
  { path: 'create-memo-sheet-requisition', component: CreateMemoSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'edit-memo/:id', component: CreateMemoSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-memo-sheet-requisition', component: ViewMemoSheetRequisitionComponent, canActivate: [AuthGuard] },

  // note-sheet-requisition

  { path: 'create-note-sheet-requisition', component: CreateNoteSheetRequisitionComponent, canActivate: [AuthGuard]},
  { path: 'edit-note/:id', component: CreateNoteSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-note-sheet-requisition', component: ViewNoteSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'edit-paymentnote/:id', component: PaymentNoteComponent, canActivate: [AuthGuard]},
  { path: 'create-paymentnote', component: PaymentNoteComponent, canActivate: [AuthGuard]},

  { path: 'login', component: LoginComponent },

  //view only pending requisition route
  { path: 'view-pending-memo-sheet-requisition/:visible', component: ViewMemoSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-note-sheet-requisition/:visible', component: ViewNoteSheetRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-gate-pass-requisition/:visible', component: ViewGatePassRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-pool-car-requisition/:visible', component: ViewPoolCarRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-office-item-requisition/:visible', component: ViewOfficeItemRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-id-card-requisition/:visible', component: ViewIdCardRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-visiting-card-requisition/:visible', component: ViewVisitingCardRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-meeting-room-requisition/:visible', component: ViewMeetingRoomRequisitionComponent, canActivate: [AuthGuard] },
  { path: 'view-pending-food-requisition/:visible', component: ViewFoodRequisitionComponent, canActivate: [AuthGuard] },

  //Office item reports component routes
  { path: 'rep_pendingpayment_note', component: PendingPaymentNoteComponent, canActivate: [AuthGuard]},
  { path: 'rep_officeitem_itemwise', component: ReportOfficeItemAcceptedItemWiseComponent, canActivate: [AuthGuard] },
  { path: 'rep_officeitem_employeewise', component: ReportOfficeItemAcceptedEmployeeWiseComponent, canActivate: [AuthGuard] },
  { path: 'rep_officeitem_durationwise', component: ReportOfficeItemAcceptedDurationWiseComponent, canActivate: [AuthGuard] },
  { path: 'rep_officeitem_approvalauthoritywise', component: ReportOfficeItemAcceptedApprovalAuthorityWiseComponent, canActivate: [AuthGuard] },

// gate pass reports routes
{ path: 'rep_gatePass_itemwise', component: GatePassItemWiseComponent, canActivate: [AuthGuard] },
{ path: 'rep_gatePass_employeewise', component: GatePassEmployeewiseComponent, canActivate: [AuthGuard] },
{ path: 'rep_gatePass_approvalauthoritywise', component: GatePassApprovalAuthoritywiseComponent, canActivate: [AuthGuard] },
// gate pass meeting room routes
{ path: 'report_meetingromm', component: MeetingrommReportComponent, canActivate: [AuthGuard] },
// food reports
{ path: 'report_food', component: FoodReportComponent, canActivate: [AuthGuard] },
// visiting card
{ path: 'report_visitingCard', component: VisitingCardReportComponent, canActivate: [AuthGuard] },
// id card report
{ path: 'report_id_card', component: IDCardReportComponent, canActivate: [AuthGuard] },
{ path: 'generel_notes_report', component: GenerelApprovalsNotesReportsComponent, canActivate: [AuthGuard] },
{ path: 'profile', component: ProfileComponent},

];

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    DashboardComponent,
    CreateUserComponent,
    CreateDepartmentComponent,
    CreateUserRoleComponent,
    ViewDepartmentComponent,
    CreateDesignationComponent,
    ViewDesignationComponent,
    CreateVehicleComponent,
    CreateOfficeItemComponent,
    ViewVehicleComponent,
    ViewOfficeItemComponent,
    CreateMeetingRoomComponent,
    CreateAuthorityComponent,
    ViewAuthorityComponent,
    ViewProcessComponent,
    ViewMeetingRoomComponent,
    ViewUserComponent,
    ViewUserRoleComponent,
    CreateVehicleTypeComponent,
    CreateGetPassRequisitionComponent,
    CreateMeetingRoomRequisitionComponent,
    CreateVisitingCardRequisitionComponent,
    LoginComponent,
    CreateOfficeItemRequisitionComponent,
    CreateIdCardRequisitionComponent,
    CreateFoodRequisitionComponent,
    ViewOfficeItemRequisitionComponent,
    ViewIdCardRequisitionComponent,
    ViewFoodRequisitionComponent,
    ViewVisitingCardRequisitionComponent,
    ViewMeetingRoomRequisitionComponent,
    ViewGatePassRequisitionComponent,
    CreateMemoSheetRequisitionComponent,
    ViewMemoSheetRequisitionComponent,
    CreateNoteSheetRequisitionComponent,
    ViewNoteSheetRequisitionComponent,
    CreatePoolCarRequisitionComponent,
    ViewPoolCarRequisitionComponent,
    CreateCarRequisitionComponent,
    ViewCarRequisitionComponent,
    ReportOfficeItemAcceptedItemWiseComponent,
    ReportOfficeItemAcceptedEmployeeWiseComponent,
    ReportOfficeItemAcceptedApprovalAuthorityWiseComponent,
    ReportOfficeItemAcceptedDurationWiseComponent,
    ReportOfficeItemRejectedItemWiseComponent,
    ReportOfficeItemRejectedEmployeeWiseComponent,
    ReportOfficeItemRejectedApprovalAuthorityWiseComponent,
    ReportOfficeItemRejectedDurationWiseComponent,
    PoolCarRequisitionDetailsComponent,
    DetailsMeetingRoomRequisitionComponent,
    DetailsGatePassRequisitionComponent,
    DetailsOfficeItemRequisitionComponent,
    DetailsVisitingCardRequisitionComponent,
    DetailsIdCardRequisitionComponent,
    DetailsFoodRequisitionComponent,
    DialogComponent,
    DataTableComponent,
    NotificationModalComponent,
    DelegateModalComponent,
    CreateProcessComponent,
    SafePipeComponent,
    MemoDetailsComponent,
    GenerateNotesheetComponent,
    VehicleassignComponent,
    NoteSheetDetailsComponent,
    VersionComponent,
    VersionDetailsComponent,
    RequestDetailsComponent,
    ViewDashboardComponent,
    CreateOfficeItemCategoryComponent,
    ViewItemCategorylistComponent,
    OfficeItemDetailsComponent,
    ApprovalNoteComponent,
    ApprovalNoteListComponent,
    PaymentNoteListComponent,
    PaymentNoteDetailsComponent,
    SpecialApprovalNoteComponent,
    SpecialPaymentNoteComponent,
    GatePassItemWiseComponent,
    GatePassEmployeewiseComponent,
    GatePassApprovalAuthoritywiseComponent,
    MeetingrommReportComponent,
    FoodReportComponent,
    VisitingCardReportComponent,
    IDCardReportComponent,
    GenerelApprovalsNotesReportsComponent,
    AutocompleteComponent,
    AuthorityDetailsComponent,
    PasswordChangeComponent,
    ProfileComponent,
    FilePreviewerComponent,
    RefDetailsDialogComponent,
    AccountsHeadEntryComponent,
    ViewAccountsHeadComponent,
    ConfirmDialogComponent,
    PendingPaymentNoteComponent,
    ApprovalNoteDetailsComponent,
    PaymentNoteComponent,
    PaymentNoteEditComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    LayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTreeModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MaterialTimePickerModule,
    DragScrollModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialTimePickerModule,
    AmazingTimePickerModule,
    FlexLayoutModule,
    MatTableExporterModule,
    NgxSummernoteModule,
    // NgxDocViewerModule,
    NgxDocViewerModule,
    GoogleChartsModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(1,0,0,0.1)',
      backdropBorderRadius: '4px',
      fullScreenBackdrop: true,
      primaryColour: '#fff45f',
      secondaryColour: '#ff46ff',
      tertiaryColour: '#ff345f'
    }),
    NgxPaginationModule,
    NgxPrintModule,

    EditorModule,
    RouterModule.forRoot(
      appRoutes,
    {onSameUrlNavigation : 'reload',useHash : true}
    )
  ],
  providers: [
    DatePipe,
    {
      provide : HTTP_INTERCEPTORS,
      useClass : AuthInterceptor,
      multi : true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  entryComponents: [
    DetailsMeetingRoomRequisitionComponent,
    DetailsGatePassRequisitionComponent,
    DetailsOfficeItemRequisitionComponent,
    DetailsVisitingCardRequisitionComponent,
    DetailsIdCardRequisitionComponent,
    DetailsFoodRequisitionComponent,
    DialogComponent,
    NotificationModalComponent,
    DelegateModalComponent,
    MemoDetailsComponent,
    GenerateNotesheetComponent,
    NoteSheetDetailsComponent,
    VersionComponent,
    VersionDetailsComponent,
    RequestDetailsComponent,
    ViewDashboardComponent,
    OfficeItemDetailsComponent,
    ApprovalNoteComponent,
    PaymentNoteDetailsComponent,
    SpecialApprovalNoteComponent,
    SpecialPaymentNoteComponent,
    AuthorityDetailsComponent,
    PasswordChangeComponent,
    FilePreviewerComponent,
    RefDetailsDialogComponent,
    ConfirmDialogComponent,
    PendingPaymentNoteComponent,
    ApprovalNoteDetailsComponent,
    PaymentNoteComponent,
    PaymentNoteEditComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

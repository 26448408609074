import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-file-previewer',
  templateUrl: './file-previewer.component.html',
  styleUrls: ['./file-previewer.component.css']
})
export class FilePreviewerComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialog_ref : MatDialogRef<FilePreviewerComponent>,
  ) {
    this.dialog_ref.addPanelClass('custom-modalbox');
    console.log('file_preview_data',data);
    
   }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Process } from '../model/process';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector: 'app-view-process',
    templateUrl: './view-process.component.html',
    styleUrls: ['./view-process.component.css']
})
export class ViewProcessComponent implements OnInit {

    loader: boolean;
    p = 1;
    processList: Process[];
    displayedColumns: string[] = ['Position', 'Process', 'Designation', 'Edit'];

    constructor(
        public userService: UserService,
        public snackBar: MatSnackBar,
        private router: Router
    ) { }

    editProcess(id) {
        this.router.navigate(['edit-process/', id]);
    }

    ngOnInit() {
        this.getAllProcess();
    }

    getAllProcess() {
        this.loader = true;
        this.userService.getProcessList().subscribe(res => {
            console.log(res)
            this.loader = false;
            this.processList = res;
            //this.openSnackBar("Success ", "ok");
        }, err => {
            this.loader = false;
            this.openSnackBar(" " + err.error.message, "ok");
        });
    }


    createProcess() {
        this.router.navigate(['/create-process']);
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 4000,
        });
    }

}

import { CommonService } from './../services/common.service';
import { DetailsFoodRequisitionComponent } from './../details-food-requisition/details-food-requisition.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { RequisitionService } from '../services/requisition.service';
import { FoodRequisition } from '../model/foodRequisition';

import { DialogComponent } from '../dialog/dialog.component';
import { Observable, Subscription } from 'rxjs';
import { DelegateModalComponent } from '../delegate-modal/delegate-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-view-food-requisition',
    templateUrl: './view-food-requisition.component.html',
    styleUrls: ['./view-food-requisition.component.css']
})
export class ViewFoodRequisitionComponent implements OnInit, OnDestroy {
    loader: boolean;
    p = 1;
    template :boolean = true;
    FoodRequisitionList: FoodRequisition[];
    displayedFoodRequisitionColumns: string[] = ['Position', 'Description', 'Requested', 'Approved', 'Status'];
    requisitions: any;
    config: any = {
        url: 'food',
        final: 'user_requisition_final_approvals',
        approve: 'user_requisition_approvals',
        recommend: 'user_requisition_for_recommendation',
        owner: 'user_requisitions',
        tableName: 'food_requisitions'
    } ;

    finalFoodRequisition$: Observable<FoodRequisition[]>;
    approvalFoodRequisition$: Observable<FoodRequisition[]>;
    recommendFoodRequisition$: Observable<FoodRequisition[]>;
    ownerFoodRequisition$: Observable<FoodRequisition[]>;
    adminFoodRequisition$: Observable<FoodRequisition[]>;
    subscriptions: Subscription[] = [];
    tabIndex: number = 0;


    constructor(
        private snackBar: MatSnackBar,
        public requisitionService: RequisitionService,
        public dialog: MatDialog,
        private commonService: CommonService,
        private route : ActivatedRoute
    ) { }

        getAllListData(){
            this.finalFoodRequisition$ = this.requisitionService.requisitionList(this.config.url, this.config.final);
            this.approvalFoodRequisition$ = this.requisitionService.requisitionList(this.config.url, this.config.approve);
            this.recommendFoodRequisition$ = this.requisitionService.requisitionList(this.config.url, this.config.recommend);
            this.ownerFoodRequisition$ = this.requisitionService.requisitionList(this.config.url, this.config.owner);
        }
    ngOnInit() {
        this.getAllListData();
        if (this.route.snapshot.paramMap.get('visible') !== null) {
            this.tabIndex = 2;
        }
        this.subscriptions.push(this.commonService.approvalEvent.subscribe((data) => {
            console.log('Common approval event fetch', data);
            this.giveApprove(data);
        }));
        this.subscriptions.push(this.commonService.rejectEvent.subscribe((data) => {
            // this.rejectGatePass(data);
            console.log('Common reject event fetch', data);
            console.log('reject', data);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    giveApprove(data) {
        this.loader = true;
        this.requisitionService.requisitionApprove(data).subscribe(res => {
            this.loader = false;
            this.openSnackBar('Successfull', 'ok');
            console.log('approved api',res);
            this.getAllListData();
            
        }, err => {
            this.loader = false;
            this.getAllListData();
            if (err.error.message) {
                this.openSnackBar('' + err.error.message, 'ok');
            } else {
                this.openSnackBar('' + err.error, 'ok');
            }
        });

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
        });
    }
    delegate(data, approveCategory = '') {
        const testData = {
             requisition_id: data.id,
             approvecategory: approveCategory,
             tableName : this.config.tableName,
             req_type : 'food'
         };
       const dialog_ref = this.dialog.open(DelegateModalComponent, {
                 data: testData
             });
             dialog_ref.afterClosed().subscribe(res=>{
                 this.getAllListData();
             })
     }


    showDetail(data, approveCategory = '') {
        // console.log('here',approveCategory);
        const testData = {
            requisition_id: data.id,
            To: data.final_approved_by,
            Date: data.date,
            Subject: data.subject,
            Description: data.description,
            Approve_by: data.approved_by,
            recommended_by: data.recommended_by,
            final_approved_by: data.final_approved_by,
            approval_status: data.approval_status,
            final_approval_status: data.final_status,
            recommendation_status: data.recommand_status,
            Category: null,
            approvecategory: approveCategory,
            requested_by: data.requested_from,
            requested_by_name: data.requested_by,
            approve_sig: data.approved_by_sig,
            final_approved_sig: data.final_approved_by_sig,
            requested_by_sig: data.requested_by_sig,
            recommended_sig: data.recommended_by_sig,
            url: 'food'
        };

      const dialog_ref =  this.dialog.open(DialogComponent, {
            data: testData
        });
        dialog_ref.afterClosed().subscribe(res=>{
            if(res !== 'reload'){
                this.giveApprove(res);
            }
            console.log('afterclosedData',res);
            
        })
    }

}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
     }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }

        if (sessionStorage.getItem('token') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set('X-Authorization', 'Bearer ' + sessionStorage.getItem('token')),
            });
            const started = Date.now();
            return next.handle(clonedreq).pipe(
                tap(event => {
                  if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started;
                    console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
                  }
                }, error => {
                  console.error('NICE ERROR', error);
                  if (error.status === 401) {
                        this.router.navigateByUrl('/login');
                        console.log('401 url',this.router.url);
                  }
                 
                })
            );
                /* .pipe(
                succ => { },
                err => {
                    if (err.status === 401)
                        this.router.navigateByUrl('/login');
                }
            ); */
        } else {
            this.router.navigateByUrl('/login');
            console.log('Login url',this.router.url);
            
        }
    }
}
